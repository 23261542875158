<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  
  <div class="container start-page">
    <!-- <i class="nh-table-mobile d-lg-none d-md-block arrow left" (click)="displayNextSection('contactInfo')"></i>
    <i class="nh-table-mobile d-lg-none d-md-block arrow right" (click)="displayNextSection('contactInfo')"></i> -->
    <p *ngIf="isRenewBenefits" class="mt-3">{{'RMB_STARTSCREEN_TEXT_1' | translate}}</p>
    <h5 *ngIf="isRenewBenefits" class="section-header mt-4">{{'RMB_STARTSCREEN_TEXT_2' | translate}}</h5>
    <hr />
    <div class="d-none d-lg-block d-md-block"  [formGroup]="getControl('extras.helpers')">
    <p *ngIf="isRenewBenefits" class="pb-4" [innerHTML]="'RMB_STARTSCREEN_TEXT_3' | translate"></p>
      <!--Head of Household-->
      <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['hoh']"
            (expandedChange)="onExpand('hoh', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" alt="" src="assets/images/Report changes/Contact Info.svg">
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'HOH' | translate}}</span>
                      <ng-container *ngIf="expandedPanels['hoh']">
                        <div class="radio-buttons-container">
                          <mat-radio-group style="flex-direction: unset" class="smallRadio" (click)="toggleValue('hoh', $event)" formControlName="hoh">
                            <mat-radio-button value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['hoh'], 'pad_lt': !expandedPanels['hoh']}">
                    {{'RMB_STARTSCREEN_TEXT_4' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_5' | translate"></span><b>&nbsp;{{'HOH' | translate}}.</b>
              </p>
            </div>
            <div class="pt-3 pb-3">
              <table class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th style="width:23%" scope="col">{{'HOH_NAME' | translate}}</th>
                    <th style="width:20%" scope="col">{{'HOME_ADDRESS' | translate}}</th>
                    <th style="width:20%" scope="col">{{'MAILING_ADDRESS_RMB' | translate}}</th>
                    <th style="width:20%" scope="col">{{'PHONE_NUMBER_WITHDRAWAL_1' | translate}}</th>
                    <th style="width:17%"scope="col">{{'ASSISTING_PERSON' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of hohInfoRows; index as i; last as isLast">
                    <td style="width:20%">
                      {{ row.headOfHousehold }}
                    </td>
                    <td style="width:20%">
                      <span *ngIf="row.homeAddressLine1">{{ row.homeAddressLine1 | translate }},<br><span *ngIf="row.homeAddressLine2"> {{row.homeAddressLine2}},<br></span> {{row.homeAddressCity}},<br>{{row.homeAddressState}}, {{row.homeAddressZip}} <span *ngIf="row.sahMailId"><br>SAH ID: {{row.sahMailId}}</span></span>
                    </td>
                    <td style="width:20%">
                      <span *ngIf="row.sahMailId && row.homeAddressLine1">{{ row.homeAddressLine1 | translate }},<br><span *ngIf="row.homeAddressLine2"> {{row.homeAddressLine2}},<br></span> {{row.homeAddressCity}},<br>{{row.homeAddressState}}, {{row.homeAddressZip}} <span *ngIf="row.sahMailId"><br>SAH ID: {{row.sahMailId}}</span></span>
                      <span *ngIf="!row.sahMailId"><span *ngIf="row.mailingAddressLine1">{{ row.mailingAddressLine1 | translate }},<br><span *ngIf="row.mailingAddressLine2"> {{row.mailingAddressLine2}},<br></span> {{row.mailingAddressCity}},<br>{{row.mailingAddressState}}, {{row.mailingAddressZip}}</span></span>
                    </td>
                    <td style="width:20%">
                      <span *ngIf="row.home || row.cell || row.extenssion || row.message">
                        <span *ngIf="row.home">{{'(Home)' | translate}} <br>{{ row.home | phone}}</span> <span *ngIf="row.cell"><br *ngIf="row.home">{{'(Cell)' | translate}} <br>{{row.cell | phone}}</span>  <span *ngIf="row.message"><br *ngIf="row.home || row.cell">{{'(Work)' | translate}} <br>{{row.message | phone}}</span> <span *ngIf="row.extenssion"><br *ngIf="row.home || row.cell || row.message">{{'Ext' | translate}}. {{row.extenssion}}</span>
                    </span>
                    <span *ngIf="(row.home === null) && (row.cell === null) && (row.extenssion === null) && (row.message === null)">{{'Not Answered' | translate}}</span>
                    </td>
                    <td style="width:20%">
                      {{ row.assistingPerson }} <br *ngIf="row.assistingRelation">{{row.assistingRelation}} <span *ngIf="row.assistingOrganization">- <br>{{row.assistingOrganization}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!--People in your Home-->
      <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['peopleInYourHome']"
            (expandedChange)="onExpand('peopleInYourHome', $event)">
            <mat-expansion-panel-header  role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" aria-label="peopleInYourHome"
                      src="assets/images/Report changes/Household Members.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'People in Your Home' | translate}}</span>
                      <ng-container *ngIf="expandedPanels['peopleInYourHome']">
                        <div class="radio-buttons-container">
                          <mat-radio-group style="flex-direction: unset" class="smallRadio" (click)="toggleValue('peopleInYourHome', $event)" formControlName="peopleInYourHome">
                            <mat-radio-button value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </ng-container>
    
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['peopleInYourHome'], 'pad_lt': !expandedPanels['peopleInYourHome']}">
                    {{'RMB_STARTSCREEN_TEXT_14' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text" [innerHTML]="'RMB_STARTSCREEN_TEXT_6' | translate"></p>
            </div>
            <div class="pt-3 pb-3">
              <table class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th style="width:25%" scope="col">{{'Name' | translate}}</th>
                    <th style="width:30%" scope="col">{{'Citizenship/Residency' | translate}}</th>
                    <th style="width:25%" scope="col">{{'LIVING_ARRANGEMENT' | translate}}</th>
                    <th style="width:20%" scope="col">{{'Additional Details' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of peopleInfoRows; index as i; last as isLast">
                    <td style="width:25%">
                      {{ row.member }} <br> {{row.ssn}}
                    </td>
                    <td style="width:30%">
                      {{ row.citizenship | translate }} <br> {{row.tnResident | translate}}
                    </td>
                    <td style="width:25%">
                      {{ row.livingArrangement }}
                    </td>
                    <td style="width:20%">
                      {{ row.additionalDetails | translate}} <span *ngIf="row.dueDate !== null"><br *ngIf="row.dueDate !== null"> {{'Due Date' | translate}}: {{row.dueDate | date: 'MM/dd/yyyy'}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!--Household information-->
      <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['householdInfo']"
            (expandedChange)="onExpand('householdInfo', $event)">
            <mat-expansion-panel-header  role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" aria-label="houseHoldInfo" src="assets/images/Report changes/Household Info.png" />
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'RMB_STARTSCREEN_TEXT_8' | translate}}</span>
                      <ng-container *ngIf="expandedPanels['householdInfo']">
                        <div class="radio-buttons-container">
                          <mat-radio-group style="flex-direction: unset" class="smallRadio" (click)="toggleValue('householdInfo', $event)" formControlName="householdInfo">
                            <mat-radio-button value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['householdInfo'], 'pad_lt': !expandedPanels['householdInfo']}">
                    {{'RMB_STARTSCREEN_TEXT_15' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_30' | translate"></span>
              </p>
            </div>
            <!-- Relationship Information -->
            <div *ngIf="relationshipRows.length" class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RELATIONSHIP' | translate}}</strong></p>
              <table *ngIf="relationshipRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width:70%">{{'Relationship' | translate}}</th>
                    <th scope="col" style="width:30%">{{'Primary Caregiver' | translate}}
                      <nh-popover [iconMode]="'transparent'" [content]="'PrimaryCareGiver_PopUp' | translate" class="pl-1"></nh-popover>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of relationIndivLeverArray; index as i; last as isLast">
                    <td style="width:70%">
                      <ul class="no-bullets"><li *ngFor="let indiv of row"> {{indiv.sourceName}} {{ indiv.relation }} {{indiv.refIndivName}}</li></ul>
                    </td>
                    <td style="width:30%" >
                      <ul class="no-bullets"><li *ngFor="let indiv of row"> {{indiv.primaryCareGiver}}</li></ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <p *ngIf="!relationshipRows.length" class="mb-5" [innerHTML]="'RMB_RelationData' | translate">{{'RMB_RelationData' | translate }}</p> -->
            </div>
            <!-- School and Employment Information -->
            <div *ngIf="displaySchoolAndEmployment" class="pt-3 pb-3">
              <p class="text-color"><strong>{{'SCHOOL' | translate}}&nbsp;{{'AND_TEXT' | translate}}&nbsp;{{'EMPLOYMENT' |
                  translate}}</strong></p>
                  <table *ngIf="schoolAndEmploymentRows.length" class="table w-100 table-striped">
                    <thead>
                      <tr>
                        <th style="width:25%;" scope="col">{{'Name' | translate}}</th>
                        <th style="width:40%;" scope="col">{{'Under age 22 and enrolled in school?' | translate}}</th>
                        <th style="width:35%;" scope="col">{{'Working full time' | translate}} </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of schoolAndEmploymentRows; index as i; last as isLast">
                        <td style="width:25%">
                           {{ row.name }}
                        </td>
                        <td style="width:40%">
                          {{ row.education | translate }}
                        </td>
                        <td style="width:35%">
                          {{ row.isWorkingFullTime | translate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p *ngIf="!schoolAndEmploymentRows.length" class="mb-5" [innerHTML]="'RMB_SchoolEmpoyment' | translate"></p>
            </div>
            <!-- Other Health Insurance Information -->
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'OTHER_COVERAGE' | translate}}</strong></p>
              <table *ngIf="otherHealthInsuranceRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width:20%">{{'Policy Holder Name' | translate}}</th>
                    <th scope="col" style="width:20%">{{'Who is Covered' | translate }}</th>
                    <th scope="col" style="width:20%">{{'Coverage Type & Start Date' | translate }}</th>
                    <th scope="col" style="width:20%">{{'Policy Number/ Group Number' | translate }}</th>
                    <th scope="col" style="width:20%">{{'Premium Amount' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of otherHealthInsuranceRows; index as i; last as isLast">
                    <td style="width:20%">
                      {{ row.policyHolderName }}
                    </td>
                    <td style="width:20%">
                      <ul class="no-bullets"><li *ngFor="let owner of row.whoIsCovered">{{owner.name}}</li></ul>
                    </td>
                    <td style="width:20%">
                      {{ row.coverageType | translate }} <span *ngIf="row.companyName !== null">- {{row.companyName}}</span><br><span *ngIf="row.coverageSTartDate !== null">{{'Starting ' | translate}}</span> {{row.coverageSTartDate | date: 'MM/dd/yyyy'}}
                    </td>
                    <td style="width:20%">
                      <span *ngIf="row.policyNum">{{'Policy Number' | translate}}: {{ row.policyNum | translate }} <br></span>
                      <span *ngIf="row.groupNum">{{'Group Number' | translate}}: {{ row.groupNum | translate }}</span>
                    </td>
                    <td style="width:20%">
                      {{ row.premiumAmount | translate }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!otherHealthInsuranceRows.length" class="mb-5" [innerHTML]="'RMB_OtherHealth' | translate"></p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!--Tax Information-->
      <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['taxInformation']"
            (expandedChange)="onExpand('taxInformation', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" aria-label="taxInfo" src="assets/images/Report changes/TaxInformation.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'RMB_STARTSCREEN_TEXT_9' | translate}}</span>
                      <ng-container *ngIf="expandedPanels['taxInformation']">
                        <div class="radio-buttons-container">
                          <mat-radio-group style="flex-direction: unset" class="smallRadio" (click)="toggleValue('taxInformation', $event)" formControlName="taxInformation">
                            <mat-radio-button value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['taxInformation'], 'pad_lt': !expandedPanels['taxInformation']}">
                    {{'RMB_STARTSCREEN_TEXT_16' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_29' | translate"></span>
              </p>
            </div>
            <!-- Tax Filer Inside Household -->
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RMB_TAX_INSIDE' | translate}}</strong></p>
              <table *ngIf="taxRows.length && taxRows[0]?.name" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width:20%">{{'Tax Filer' | translate}}</th>
                    <th scope="col" style="width:20%">{{'Filing Jointly With' | translate}}</th>
                    <th scope="col" style="width:20%">{{'Tax Dependents' | translate}}</th>
                    <th scope="col" style="width:20%">{{'RMB_TAX_DEDUCTIONS' | translate}}</th>
                    <th scope="col" style="width:20%">{{'Outside Household Dependents' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of taxRows">
                    <td style="width:20%">
                      {{ row.name | translate }}
                    </td>
                    <td style="width:20%">
                      {{ row.filingJointly | translate }}
                    </td>
                    <td style="width:20%">
                      <ul *ngIf="row.dependents?.length" class="no-bullets"><li *ngFor="let dependent of row.dependents">{{dependent.name}}</li></ul>
                      <span *ngIf="!row.dependents?.length">{{'No' | translate}}</span>
                    </td>
                    <td style="width:20%">
                      <ul *ngIf="row.deductions?.length" class="no-bullets"><li *ngFor="let deduction of row.deductions; last as isLast">
                        <span *ngIf="deduction.type">{{deduction.type | translate}}</span><span *ngIf="deduction.amount"><br>{{deduction.amount | translate}} {{deduction.frequency | translate}}</span><span *ngIf="row.deductions.length > 1 && !isLast">,</span></li></ul>
                      <span *ngIf="!row.deductions?.length">{{'No' | translate}}</span>
                    </td>
                    <td style="width:20%">
                      <ul *ngIf="row.outOfHouseDependents?.length" class="no-bullets"><li *ngFor="let outOfHouse of row.outOfHouseDependents">
                        <span *ngIf="outOfHouse.name">{{outOfHouse.name | translate}}</span></li></ul>
                        <span *ngIf="!row.outOfHouseDependents?.length">{{'No' | translate}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="(taxRows.length && !taxRows[0].name) || (!taxRows.length)" class="mb-5" [innerHTML]="'RMB_TAX_FILER_INSIDE' | translate"></p>
            </div>
            <!-- Tax Filer Ouside Household -->
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RMB_TAX_OUTSIDE' | translate}}</strong></p>
              <table *ngIf="taxOutsideRows?.length > 0" class="table w-100 table-striped" >
                <thead>
                  <tr>
                    <th scope="col" style="width:25%">{{'Tax Filer' | translate}}</th>
                    <th scope="col" style="width:40%">{{'RMB_OUTSIDE_STATIC1' | translate}}</th>
                    <th scope="col" style="width:35%">{{'RMB_OUTSIDE_STATIC2' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of taxOutsideRows">
                    <td style="width:25%">
                      {{ row.outOfHouseFiler | translate }}
                    </td>
                    <td style="width:40%">
                      {{ row.claimedDependent | translate }}
                    </td>
                    <td style="width:35%">
                      {{ row.relationshipToOutsideTaxFiler | translate }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="taxOutsideRows?.length === 0" class="mb-5" [innerHTML]="'RMB_TAX_FILER_OUTSIDE' | translate"></p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!--Income and Employment-->
      <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['incomeEmployment']"
            (expandedChange)="onExpand('incomeEmployment', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" aria-label="incomeEmp" src="assets/images/Report changes/Income&Employment.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'RMB_STARTSCREEN_TEXT_10' | translate}}</span>
                      <ng-container *ngIf="expandedPanels['incomeEmployment']">
                        <div class="radio-buttons-container">
                          <mat-radio-group style="flex-direction: unset" class="smallRadio" (click)="toggleValue('incomeEmployment', $event)" formControlName="incomeEmployment">
                            <mat-radio-button value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['incomeEmployment'], 'pad_lt': !expandedPanels['incomeEmployment']}">
                    {{'RMB_STARTSCREEN_TEXT_17' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_27' | translate"></span>
              </p>
            </div>
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'EMPLOYMENT_INCOME' | translate}}</strong></p>
              <table *ngIf="employmentIncomeRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width:25%">{{'Name' | translate}}</th>
                    <th scope="col" style="width:25%">{{'EMPLOYMENT_STATIC_TEXT5' | translate}}</th>
                    <th scope="col" style="width:25%">{{'Start Date' | translate}}</th>
                    <th scope="col" style="width:25%">{{'Amount & How Often' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of employmentIncomeRows; index as i; last as isLast">
                    <td style="width:25%">
                      {{ row.name | translate }}
                    </td>
                    <td style="width:25%">
                      {{ row.employer | translate }}
                    </td>
                    <td style="width:25%">
                      <span *ngIf="row.startDate">{{ row.startDate | date: 'MM/dd/yyyy' }}</span>
                      <span *ngIf="!row.startDate">{{ 'Not Answered' }}</span>
                    </td>
                    <td style="width:25%">
                      {{ row.wage | translate }} {{row.frequency}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!employmentIncomeRows.length" class="mb-5" [innerHTML]="'RMB_EMPLOYMENT' | translate"></p>
            </div>
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'SELF_EMPLOYMENT_INCOME' | translate}}</strong></p>
              <table *ngIf="selfEmploymentIncomeRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width:20%">{{'Name' | translate}}</th>
                    <th scope="col" style="width:20%">{{'Business Type' | translate}}</th>
                    <th scope="col" style="width:20%">{{'Net Income' | translate}}</th>
                    <th scope="col" style="width:20%">{{'SELF_EMPLOYMENT_STATIC_TEXT4' | translate}}</th>
                    <th scope="col" style="width:20%">{{'CO-OWENERS_HEADER1' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of selfEmploymentIncomeRows; index as i; last as isLast">
                    <td style="width:20%">
                      {{ row.member | translate }}
                    </td>
                    <td style="width:20%">
                      {{ row.bussinessType | translate }}
                    </td>
                    <td style="width:20%">
                      {{ row.netIncome }}
                    </td>
                    <td style="width:20%">
                      {{ row.bussinessName }}
                    </td>
                    <td style="width:20%"><ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                        <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!selfEmploymentIncomeRows.length" class="mb-5" [innerHTML]="'RMB_SELF_EMPLOYMENT' | translate"></p>
            </div>
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'OTHER_INCOME' | translate}}</strong></p>
              <table *ngIf="otherIncomeRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width:25%">{{'Name' | translate}}</th>
                    <th scope="col" style="width:25%">{{'Income Type' | translate}}</th>
                    <th scope="col" style="width:25%">{{'Start Date' | translate}}</th>
                    <th scope="col" style="width:25%">{{'Amount & How Often' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of otherIncomeRows; index as i; last as isLast">
                    <td style="width:25%">
                      {{ row.name | translate }}
                    </td>
                    <td style="width:25%">
                      {{ row.incometype | translate }}
                    </td>
                    <td style="width:25%">
                      <span *ngIf="row.startDate">{{ row.startDate | date: 'MM/dd/yyyy' }}</span>
                      <span *ngIf="!row.startDate">{{ 'N/A' }}</span>
                    </td>
                    <td style="width:25%">
                      {{ row.wage | translate }} {{row.frequency}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!otherIncomeRows.length" class="mb-5" [innerHTML]="'RMB_OTHER_INCOME' | translate"></p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!--Other Health Care-->
      <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['otherBenefits']"
            (expandedChange)="onExpand('otherBenefits', $event)">
            <mat-expansion-panel-header role="region" class="mat-row">
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" aria-label="otherBenifits" src="assets/images/Report changes/OtherBenefits.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title">{{'Other Health Care' | translate}}</span>
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['otherBenefits'], 'pad_lt': !expandedPanels['otherBenefits']}">
                    {{'RMB_STARTSCREEN_TEXT_18' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_31' | translate"></span>
              </p>
            </div>
            <!-- othe health Care questions -->
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'Other Health Care Questions' | translate}}</strong></p>
              <table *ngIf="otherBenefitsRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th style="width:50%" scope="col">{{'Assistance' | translate}}</th>
                    <th style="width:20%" scope="col"></th>
                    <th style="width:30%" scope="col">{{'Response' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of otherBenefitsRows; index as i; last as isLast">
                    <td style="width:50%">
                      {{ row.key | translate }}
                    </td>
                    <td style="width:20%"></td>
                    <td style="width:30%">
                      {{ row.value | translate }}
                    </td>
                </tbody>
              </table>
              <p *ngIf="!otherBenefitsRows.length" class="mb-5" [innerHTML]="'RMB_OTHER_BENEFITS' | translate"></p>
            </div>

          <!-- Katie Beckett Referral -->
          <!-- <div class="pt-3 pb-3">
            <p class="text-color"><strong>{{'RMB_KBR' | translate }}</strong></p>
            <table *ngIf="kbRows.length" class="table w-100 table-striped">
              <thead>
                <tr>
                  <th scope="col">{{'Name' | translate}}</th>
                  <th scope="col">{{'Reason' | translate}}</th>
                  <th scope="col">{{'CONTACT_INFO' | translate}}</th>
                  <th scope="col">{{'Relationship' | translate}}</th>
                  <th scope="col">{{'KATIE_BECKETT_STATIC_TEXT21' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of kbRows">
                  <td>
                    {{ row.name | translate }}
                  </td>
                  <td>
                    <ul *ngIf="row.reasons.length" class="no-bullets"><li *ngFor="let r of row.reasons;index as i;last as isLast">{{r.reason}}<span *ngIf="row.reasons.length > 1 && !isLast">,</span></li></ul>
                <span *ngIf="!row.reasons.length">{{'Not Answered' | translate}}</span>
                  </td>
                  <td>
                    <span>{{ row.name | translate }} <br></span>
                    <span *ngIf="row.addressLine1">{{ row.addressLine1 | translate }},<span *ngIf="row.addressLine2"> {{row.addressLine2}},</span> {{row.addressCity}},<br>{{row.addressState}}, {{row.addressZip}}</span>
                  </td>
                  <td>
                    {{ row.relationship | translate }}
                  </td>
                  <td>
                    {{ row.livingArrangement | translate }}
                  </td>
              </tbody>
            </table>
            <p *ngIf="!kbRows.length" class="mb-5" [innerHTML]="'RMB_KB_REFERRAL' | translate"></p>
          </div> -->

            <!-- Transferred Resources -->
            <!-- <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'OTHER_HEALTHCARE_STATIC_TEXT44' | translate}}</strong></p>
              <table *ngIf="transferredRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Original Owner' | translate}}</th>
                    <th scope="col">{{'Resource Type' | translate}}</th>
                    <th scope="col">{{'New Owner & Date' | translate}}</th>
                    <th scope="col">{{'Total Value' | translate}}</th>
                    <th scope="col">{{'Amount Received' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of transferredRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.newOwner | translate }} <span *ngIf="row.date"><br> {{row.date | date: 'MM/dd/yyyy'}}</span>
                    </td>
                    <td>
                      {{ row.totalValue | translate }}
                    </td>
                    <td>{{ row.amountRecvd | translate }}</td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!transferredRows.length" class="mb-5" [innerHTML]="'RMB_TRANSFERRED_RESOURCES' | translate"></p>
            </div>           -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!--Expenses-->
      <!-- <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['expenses']"
            (expandedChange)="onExpand('expenses', $event)">
            <mat-expansion-panel-header role="region" class="mat-row">
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" aria-label="expenses" src="assets/images/Report changes/Expenses.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title">{{'RMB_STARTSCREEN_TEXT_12' | translate}}</span>
                      <ng-container *ngIf="expandedPanels['expenses']">
                        <div class="radio-buttons-container">
                          <mat-radio-group class="smallRadio" (click)="toggleValue('expenses', $event)" formControlName="expenses">
                            <mat-radio-button value="edit">{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange">{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['expenses'], 'pad_lt': !expandedPanels['expenses']}">
                    {{'RMB_STARTSCREEN_TEXT_19' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_28' | translate"></span>
              </p>
            </div>
            //Shelter and Utility
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_22' | translate}}</strong></p>
              <table *ngIf="utilityExpensesRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th style="width:33.3%" scope="col">{{'Name' | translate}}</th>
                    <th style="width:33.3%" scope="col">{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate}}</th>
                    <th style="width:33.3%" scope="col">{{'Amount & How Often' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of utilityExpensesRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!utilityExpensesRows.length" class="mb-5" [innerHTML]="'RMB_UTILITY_EXPENSES' | translate"></p>
            </div>
            //Dependent Care Expenses
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_23' | translate}}</strong></p>
              <table *ngIf="dependentCareExpensesRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Person who pays for this care' | translate}}</th>
                    <th scope="col">{{'Person this expense is paid for' | translate}}</th>
                    <th scope="col">{{'Amount & How Often' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dependentCareExpensesRows; index as i; last as isLast">
                    <td>
                      {{ row.whoPays | translate }}
                    </td>
                    <td>
                      {{ row.paidFor | translate }}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!dependentCareExpensesRows.length" class="mb-5" [innerHTML]="'RMB__DEPENDENT_EXPENSES' | translate"></p>
            </div>
            //Mandatory Payments
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_24' | translate}}</strong></p>
              <table *ngIf="mandatoryExpensesRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Person who makes the payments' | translate}}</th>
                    <th scope="col">{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate}}</th>
                    <th scope="col">{{'Amount & How Often' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of mandatoryExpensesRows; index as i; last as isLast">
                    <td>
                      {{ row.whoPays | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      <span *ngIf="row.amount">{{ row.amount | translate }} {{'Monthly' | translate}}</span>
                      <span *ngIf="!row.amount">{{'Not Answered' | translate}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!mandatoryExpensesRows.length" class="mb-5" [innerHTML]="'RMB_MANDATORY_PAYMENTS' | translate"></p>
            </div>
              //Medical Bills Expenses
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_25' | translate}}</strong></p>
              <p *ngIf="medicialBillsExpensesRows.length" [innerHTML]="'RMB_MEDICAL_BILLS_STATIC1' | translate"></p>
              <table *ngIf="medicialBillsExpensesRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Person who paid the bill' | translate}}</th>
                    <th scope="col">{{'Person who received the care' | translate}}</th>
                    <th scope="col">{{'Bill type' | translate}}</th>
                    <th scope="col">{{'Amount & How Often' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of medicialBillsExpensesRows; index as i; last as isLast">
                    <td>
                      {{ row.whoPaid | translate }}
                    </td>
                    <td>
                      {{ row.paidFor | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!medicialBillsExpensesRows.length" class="mb-5" [innerHTML]="'RMB_MEDICAL_BILL_EXPENSES' | translate"></p>
            </div>
            //Access to Other Coverage
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_26' | translate}}</strong></p>
              <table *ngIf="accesstoOtherCoverageRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Name' | translate}}</th>
                    <th scope="col">{{'Qyalifying Health Coverage?' | translate}}</th>
                    <th scope="col">{{'Premium Amount/How Often' | translate}}</th>
                    <th scope="col">{{'COBRA Plan' | translate}}</th>
                    <th scope="col">{{'Retiree Health Plan' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of accesstoOtherCoverageRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.qualify | translate }}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                    <td>
                      {{ row.plan | translate }}
                    </td>
                    <td>
                      {{ row.retireHealthPlan | translate }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!accesstoOtherCoverageRows.length" class="mb-5" [innerHTML]="'RMB_ACCESS_TO_OTHER_COVERAGE' | translate"></p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div> -->
      <!--Resources-->
      <!-- <div class="pb-4">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['resources']"
            (expandedChange)="onExpand('resources', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="pl-2 mat-cell-panel-header">
                  <div class="d-flex">
                    <img class="icon" aria-label="resources" src="assets/images/Report changes/Resources.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title">{{'RMB_STARTSCREEN_TEXT_13' | translate}}</span>
                      <ng-container *ngIf="expandedPanels['resources']">
                        <div class="radio-buttons-container">
                          <mat-radio-group class="smallRadio" (click)="toggleValue('resources', $event)" formControlName="resources">
                            <mat-radio-button value="edit">{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange">{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['resources'], 'pad_lt': !expandedPanels['resources']}">
                    {{'RMB_STARTSCREEN_TEXT_20' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="sub_content">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_21' | translate"></span>
              </p>
            </div>
            //Financial Resources
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'FINANCIAL_RESOURCES_STATIC_TEXT1' | translate}}</strong></p>
              <table *ngIf="financialRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Owner' | translate}}</th>
                    <th scope="col">{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate}}</th>
                    <th scope="col">{{'FINANCIAL_RESOURCES_STATIC_TEXT18' | translate}}</th>
                    <th scope="col">{{'Total Value' | translate}}</th>
                    <th scope="col">{{'CO-OWENERS_HEADER1' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of financialRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.bankName | translate }} {{row.frequency}}
                    </td>
                    <td>
                      {{ row.totalValue | translate }} {{row.frequency}}
                    </td>
                      <td><ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                        <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!financialRows.length" class="mb-5" [innerHTML]="'RMB_FINANCIAL_RESOURCES' | translate"></p>
            </div>
          //Vehicle Resources
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'Vehicles' | translate}}</strong></p>
              <table *ngIf="vehicleRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Owner' | translate}}</th>
                    <th scope="col">{{'Vehicle' | translate}}</th>
                    <th scope="col">{{'Total Value' | translate}}</th>
                    <th scope="col">{{'Amount Owed' | translate}}</th>
                    <th scope="col">{{'CO-OWENERS_HEADER1' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of vehicleRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}<span *ngIf="row.makeYear"><br>{{ row.makeYear | translate }} </span><span *ngIf="row.make">{{ row.make | translate }} </span><span *ngIf="row.model">{{ row.model | translate }}</span>
                    </td>
                    <td>
                      {{ row.totalValue | translate }} {{row.frequency}}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                      <td><ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                        <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></td>
                  </tr>
                </tbody>
              </table>
               <p *ngIf="!vehicleRows.length" class="mb-5" [innerHTML]="'RMB_VEHICLE_RESOURCES' | translate"></p>
            </div>

            //Property Resources
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'Property' | translate}}</strong></p>
              <table *ngIf="propertyRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Owner' | translate}}</th>
                    <th scope="col">{{'Property Type' | translate}}</th>
                    <th scope="col">{{'Total Value' | translate}}</th>
                    <th scope="col">{{'Amount Owed' | translate}}</th>
                    <th scope="col">{{'CO-OWENERS_HEADER1' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of propertyRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.totalValue | translate }} {{row.frequency}}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                      <td><ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                      <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!propertyRows.length" class="mb-5" [innerHTML]="'RMB_PROPERTY_RESOURCES' | translate"></p>
            </div>
            //Burrial Resources
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'Burial Resources' | translate}}</strong></p>
              <table *ngIf="burrialRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Owner' | translate}}</th>
                    <th scope="col">{{'Burial Type' | translate}}</th>
                    <th scope="col">{{'FINANCIAL_RESOURCES_STATIC_TEXT18' | translate}}</th>
                    <th scope="col">{{'Total Value' | translate}}</th>
                    <th scope="col">{{'Amount Owed' | translate}}</th>
                    <th scope="col">{{'CO-OWENERS_HEADER1' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of burrialRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.bankName | translate }}
                    </td>
                    <td>
                      {{ row.totalValue | translate }} {{row.frequency}}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                      <td><ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                        <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!burrialRows.length" class="mb-5" [innerHTML]="'RMB_BURRIAL_RESOURCES' | translate"></p>
            </div>

            // Life Insurance Resources
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'Life Insurance' | translate}}</strong></p>
              <table *ngIf="lifeResourcesRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Who is covered' | translate}}</th>
                    <th scope="col" style="text-align: center;">{{'Insurance Type' | translate}}</th>
                    <th scope="col">{{'Face Value' | translate}}</th>
                    <th scope="col">{{'Cash Surrender Value' | translate}}</th>
                    <th scope="col">{{'POLICY_NUMBER' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of lifeResourcesRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.faceValue | translate }}
                    </td>
                    <td>
                      {{ row.cashSurrenderValue | translate }} {{row.frequency}}
                    </td>
                    <td>
                      {{ row.policyNumber | translate }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!lifeResourcesRows.length" class="mb-5" [innerHTML]="'RMB_LIFE_RESOURCES' | translate"></p>
            </div>

           //  Other Resources
            <div class="pt-3 pb-3">
              <p class="text-color"><strong>{{'Other Resources' | translate}}</strong></p>
              <table *ngIf="otherResourceRows.length" class="table w-100 table-striped">
                <thead>
                  <tr>
                    <th scope="col">{{'Owner' | translate}}</th>
                    <th scope="col">{{'Type' | translate}}</th>
                    <th scope="col">{{'Value' | translate}}</th>
                    <th scope="col">{{'Amount Owed' | translate}}</th>
                    <th scope="col">{{'CO-OWENERS_HEADER1' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of otherResourceRows; index as i; last as isLast">
                    <td>
                      {{ row.name | translate }}
                    </td>
                    <td>
                      {{ row.type | translate }}
                    </td>
                    <td>
                      {{ row.value | translate }}
                    </td>
                    <td>
                      {{ row.amount | translate }} {{row.frequency}}
                    </td>
                    <td><ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                      <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></td>
                  </tr>
                </tbody>
              </table>
              <p *ngIf="!otherResourceRows.length" class="mb-5" [innerHTML]="'RMB_OTHER_RESOURCES' | translate"></p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div> -->
    </div>
    <div class="nh-table-mobile d-lg-none d-md-block" [formGroup]="getControl('extras.helpers')" #carouselContainer>
      <p *ngIf="isRenewBenefits" class="pb-4" [innerHTML]="'RMB_STARTSCREEN_TEXT_32' | translate"></p>
      <i id="leftarrow" class="leftarrow left" (click)="owlCar.prev()"></i>
      <i id="rightarrow" class="rightarrow right" (click)="owlCar.next()"></i>
      <nh-alert type="warning" [isOpen]="showBanner">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span [innerHTML]="'RMB_BANNER_MESSAGE' | translate"></span>
          </div>
        </div>
      </nh-alert>
      <owl-carousel-o [options]="customOptions" #owlCar (translated)="onSlideChange($event)">
        <ng-template class="slide" carouselSlide>
      <!--Mobile browser HOH Information-->
      <div  id="slide1" class="pb-4 slide-content">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['hoh']" (expandedChange)="onExpand('hoh', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="mat-cell-panel-header">
                  <div class="d-flex pt-3">
                  <img class="icon" alt="" src="assets/images/Report changes/Contact Info.svg">
                  <div class="sub_header">
                    <span class="mat-cell title"><span class="text-danger">*</span>{{'HOH' | translate}}</span>
                  </div>
                  </div>
                  <br>
                    <ng-container *ngIf="expandedPanels['hoh']">
                      <div class="radio-buttons-container">
                        <mat-radio-group class="smallRadio" style="flex-direction: unset" (click)="toggleValue('hoh', $event)" formControlName="hoh">
                            <mat-radio-button value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </ng-container>
                  <br>
                  <p
                    [ngClass]="{'panel_sub_text': expandedPanels['hoh'], 'pad_lt': !expandedPanels['hoh']}">
                    {{'RMB_STARTSCREEN_TEXT_4' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="sub_content pb-4">
              <p class="sub_text">
                <span [innerHTML]="'RMB_STARTSCREEN_TEXT_5' | translate"></span><b>&nbsp;{{'HOH' | translate}}.</b>
              </p>
            </div>
            <ng-container >
							<div *ngFor="let row of hohInfoRows; let last = last">
								<div class="table-row mb-3 pb-3 item active" [ngClass]="{'last-row': last}">
									<div class="mobile-table-header">{{'HOH_NAME' | translate}}</div>
									<hr>
									<div  class="mobile-table-data">{{row.headOfHousehold}}</div>
									<br>
									<div class="mobile-table-header">{{'HOME_ADDRESS' | translate }}</div>
									<hr>
									<div class="mobile-table-data"><span *ngIf="row.homeAddressLine1">{{ row.homeAddressLine1 | translate }},<br><span *ngIf="row.homeAddressLine2"> {{row.homeAddressLine2}},<br></span> {{row.homeAddressCity}},<br>{{row.homeAddressState}}, {{row.homeAddressZip}} <span *ngIf="row.sahMailId"><br>SAH ID: {{row.sahMailId}}</span></span></div>
									<br>
									<div class="mobile-table-header">{{'MAILING_ADDRESS_RMB' | translate }}</div>
                  <hr>
									<div class="mobile-table-data"><span *ngIf="row.sahMailId && row.homeAddressLine1">{{ row.homeAddressLine1 | translate }},<br><span *ngIf="row.homeAddressLine2"> {{row.homeAddressLine2}},<br></span> {{row.homeAddressCity}},<br>{{row.homeAddressState}}, {{row.homeAddressZip}} <span *ngIf="row.sahMailId"><br>SAH ID: {{row.sahMailId}}</span></span>
                    <span *ngIf="!row.sahMailId"><span *ngIf="row.mailingAddressLine1">{{ row.mailingAddressLine1 | translate }},<br><span *ngIf="row.mailingAddressLine2"> {{row.mailingAddressLine2}},<br></span> {{row.mailingAddressCity}},<br>{{row.mailingAddressState}}, {{row.mailingAddressZip}}</span></span></div>
                  <br>
									<div class="mobile-table-header">{{'PHONE_NUMBER_WITHDRAWAL_1'| translate}}</div>
                  <hr>
									<div class="mobile-table-data">
                    <span *ngIf="row.home || row.cell || row.extenssion || row.message">
                      <span *ngIf="row.home">{{'(Home)' | translate}} <br>{{ row.home | phone}}</span> <span *ngIf="row.cell"><br *ngIf="row.home">{{'(Cell)' | translate}} <br>{{row.cell | phone}}</span>  <span *ngIf="row.message"><br *ngIf="row.home || row.cell">{{'(Work)' | translate}} <br>{{row.message | phone}}</span> <span *ngIf="row.extenssion"><br *ngIf="row.home || row.cell || row.message">{{'Ext' | translate}}. {{row.extenssion}}</span>
                    </span>
                    <span *ngIf="(row.home === null) && (row.cell === null) && (row.extenssion === null) && (row.message === null)">{{'Not Answered' | translate}}</span>
                  </div>
                    <br>
									<div class="mobile-table-header">{{'ASSISTING_PERSON' | translate }}</div>
                  <hr>
									<div class="mobile-table-data">{{ row.assistingPerson }} <br *ngIf="row.assistingRelation">{{row.assistingRelation}} <span *ngIf="row.assistingOrganization">- <br>{{row.assistingOrganization}}</span></div>
								</div>
							</div>
						</ng-container>
						<p *ngIf="!hohInfoRows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      </ng-template>
      <ng-template class="slide" carouselSlide>
      <!--Mobile browser People In your Home-->
      <div  id="slide2"  class="pb-4 slide-content" >
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['peopleInYourHome']" (expandedChange)="onExpand('peopleInYourHome', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="mat-cell-panel-header">
                  <div class="d-flex pt-3">
                    <img class="icon" aria-label="peopleInYourHome"
                      src="assets/images/Report changes/Household Members.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'People in Your Home' | translate}}</span>  
                    </div>
                  </div>
                  <br>
                  <ng-container *ngIf="expandedPanels['peopleInYourHome']">
                    <div class="radio-buttons-container">
                      <mat-radio-group class="smallRadio" style="flex-direction: unset" (click)="toggleValue('peopleInYourHome', $event)" formControlName="peopleInYourHome">
                        <mat-radio-button  value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                        <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </ng-container>
                  <br>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['peopleInYourHome'], 'pad_lt': !expandedPanels['peopleInYourHome']}">
                    {{'RMB_STARTSCREEN_TEXT_14' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="sub_content pb-4">
              <p class="sub_text" [innerHTML]="'RMB_STARTSCREEN_TEXT_6' | translate"></p>
            </div>
            <ng-container *ngIf="peopleInfoRows.length">
							<div *ngFor="let row of peopleInfoRows; let last = last" [ngClass]="{'data_container': !last}">
								<div class="table-row mb-3 pb-3 item active" [ngClass]="{'last-row': last}">
									<div class="mobile-table-header">{{'Name' | translate}}</div>
									<hr>
									<div  class="mobile-table-data">{{ row.member }} <br> {{row.ssn}}</div>
									<br>
									<div class="mobile-table-header">{{'Citizenship/Residency' | translate }}</div>
									<hr>
									<div class="mobile-table-data">{{ row.citizenship | translate }} <br> {{row.tnResident | translate}}</div>
									<br>
									<div class="mobile-table-header">{{'LIVING_ARRANGEMENT' | translate }}</div>
                  <hr>
									<div class="mobile-table-data">{{row.livingArrangement}}</div>
                  <br>
									<div class="mobile-table-header">{{'Additional Details' | translate }}</div>
                  <hr>
									<div class="mobile-table-data" [ngClass]="{ 'column_padding': !last}">{{row.additionalDetails}} <span *ngIf="row.dueDate !== null"><br *ngIf="row.dueDate !== null"> {{'Due Date' | translate}}: {{row.dueDate | date: 'MM/dd/yyyy'}}</span></div>
								</div>
							</div>
						</ng-container>
						<p *ngIf="!peopleInfoRows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      </ng-template>
      <ng-template class="slide" carouselSlide>
      <!--Mobile browser Household information-->
      <div  id="slide3"  class="pb-4 slide-content">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['householdInfo']" (expandedChange)="onExpand('householdInfo', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="mat-cell-panel-header">
                  <div class="d-flex pt-3">
                    <img class="icon" aria-label="houseHoldInfo"
                    src="assets/images/Report changes/Household Info.png" />
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'RMB_STARTSCREEN_TEXT_8' | translate}}</span>
                    </div>
                  </div>
                  <br>
                  <ng-container *ngIf="expandedPanels['householdInfo']">
                    <div class="radio-buttons-container">
                      <mat-radio-group class="smallRadio" style="flex-direction: unset" (click)="toggleValue('householdInfo', $event)" formControlName="householdInfo">
                        <mat-radio-button  value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                        <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </ng-container>
                  <br>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['householdInfo'], 'pad_lt': !expandedPanels['householdInfo']}">
                    {{'RMB_STARTSCREEN_TEXT_15' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="sub_content pb-4">
              <p class="sub_text"><span [innerHTML]="'RMB_STARTSCREEN_TEXT_30' | translate"></span></p>
            </div>
            <ng-container>
              <!-- Relationship Information -->
              <p *ngIf="relationshipRows.length" class="text-color sub_module_header pb-3"><strong>{{'RELATIONSHIP' | translate}}</strong></p>
              <ng-container *ngIf="relationshipRows.length">
							<div *ngFor="let row of relationshipRows; let last = last" class="data_container">
								<div class="table-row mb-3 pb-3 item active">
									<div class="mobile-table-header">{{'Relationship' | translate}}</div>
									<hr>
									<div  class="mobile-table-data"> {{row.sourceName}} {{ row.relation }} {{row.refIndivName}}</div>
									<br>
									<div class="mobile-table-header">{{'Primary Caregiver' | translate }} <nh-popover [content]="'PrimaryCareGiver_PopUp' | translate" class="pl-1"></nh-popover></div>
									<hr>
									<div class="mobile-table-data column_padding">{{ row.primaryCareGiver | translate }}</div>
								</div>
							</div>
            </ng-container>
              <!-- <p *ngIf="!relationshipRows.length" class="mb-5" [innerHTML]="'RMB_RelationData' | translate"></p> -->
               <!-- School and Employment Information -->
              <div *ngIf="displaySchoolAndEmployment">
                <p class="text-color sub_module_header pb-3"><strong>{{'SCHOOL_AND_EMPLOYMENT' | translate}}</strong></p>
                <ng-container *ngIf="schoolAndEmploymentRows.length">
                  <div *ngFor="let row of schoolAndEmploymentRows; let last = last" class="data_container">
                    <div class="table-row mb-3 pb-3 item active">
                      <div class="mobile-table-header">{{'Name' | translate}}</div>
                      <hr>
                      <div class="mobile-table-data"> {{ row.name }}</div>
                      <br>
                      <div class="mobile-table-header">{{'Under age 22 and enrolled in school?' | translate }}</div>
                      <hr>
                      <div class="mobile-table-data">{{ row.education | translate }}</div>
                      <br>
                      <div class="mobile-table-header">{{'Working full time' | translate }}</div>
                      <hr>
                      <div class="mobile-table-data column_padding">{{ row.isWorkingFullTime | translate }}</div>
                    </div>
                  </div>
                </ng-container>
                <p *ngIf="!schoolAndEmploymentRows.length" class="mb-5" [innerHTML]="'RMB_SchoolEmpoyment' | translate"></p>
              </div>
               <!-- Other Health Insurance Information -->
              <p class="text-color sub_module_header pb-3"><strong>{{'OTHER_COVERAGE' | translate}}</strong></p>
              <ng-container *ngIf="otherHealthInsuranceRows.length">
              <div *ngFor="let row of otherHealthInsuranceRows; let last = last" [ngClass]="{'data_container': !last}">
								<div class="table-row mb-3 pb-3 item active"  [ngClass]="{'last-row': last}">
									<div class="mobile-table-header">{{'Policy Holder Name' | translate}}</div>
									<hr>
									<div  class="mobile-table-data"> {{ row.policyHolderName }}</div>
									<br>
									<div class="mobile-table-header">{{'Who is Covered' | translate }}</div>
									<hr>
									<div class="mobile-table-data"><ul class="no-bullets"><li *ngFor="let owner of row.whoIsCovered">{{owner.name}}</li></ul></div>
                  <br>
                  <div class="mobile-table-header">{{'Coverage Type & Start Date' | translate }}</div>
									<hr>
									<div class="mobile-table-data">{{ row.coverageType | translate }} <br><span *ngIf="row.coverageSTartDate">{{'Starting ' | translate}}</span> {{row.coverageSTartDate | date: 'MM/dd/yyyy'}}</div>
                  <br>
                  <div class="mobile-table-header">{{'Policy Number/Group Number' | translate }}</div>
									<hr>
									<div class="mobile-table-data" *ngIf="row.policyNum">{{'Policy Number' | translate}}: {{ row.policyNum | translate }}</div>
                  <div class="mobile-table-data" *ngIf="row.groupNum">{{'Group Number' | translate}}: {{ row.groupNum | translate }}</div>
                  <br>
                  <div class="mobile-table-header">{{'Premium Amount' | translate }}</div>
									<hr>
									<div class="mobile-table-data" [ngClass]="{ 'column_padding': !last}">{{ row.premiumAmount | translate }}</div>
								</div>
							</div>
            </ng-container>
              <p *ngIf="!otherHealthInsuranceRows.length" class="mb-5" [innerHTML]="'RMB_OtherHealth' | translate"></p>
						</ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>      
      </ng-template>
      <ng-template class="slide" carouselSlide>
      <!-- Tax Information -->
      <div  id="slide4"  class="pb-4 slide-content">
        <mat-accordion displayMode="" multi class="mat-table">
          <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['taxInformation']" (expandedChange)="onExpand('taxInformation', $event)">
            <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
              <mat-panel-title>
                <div class="mat-cell-panel-header">
                  <div class="d-flex pt-3">
                    <img class="icon" aria-label="taxInfo" src="assets/images/Report changes/TaxInformation.svg" />
                    <div class="sub_header">
                      <span class="mat-cell title"><span class="text-danger">*</span>{{'RMB_STARTSCREEN_TEXT_9' | translate}}</span>
                    </div>
                  </div>
                      <br>
                      <ng-container *ngIf="expandedPanels['taxInformation']">
                        <div class="radio-buttons-container">
                          <mat-radio-group class="smallRadio" style="flex-direction: unset"  (click)="toggleValue('taxInformation', $event)" formControlName="taxInformation">
                            <mat-radio-button  value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                            <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                          </mat-radio-group>
                         </div>
                      </ng-container>
                  <br>
                  <p class="mat-cell"
                    [ngClass]="{'panel_sub_text': expandedPanels['taxInformation'], 'pad_lt': !expandedPanels['taxInformation']}">
                    {{'RMB_STARTSCREEN_TEXT_16' | translate}}</p>
                </div>
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="sub_content pb-4">
              <p class="sub_text" [innerHTML]="'RMB_STARTSCREEN_TEXT_29' | translate"></p>
            </div>
            <ng-container >
            <!-- Tax Filer Inside Household -->
            <p class="text-color sub_module_header pb-3"><strong>{{'RMB_TAX_INSIDE' | translate}}</strong></p>
             <div *ngIf="taxRows?.length && taxRows[0].name">
							<div *ngFor="let row of taxRows">
								<div class="table-row mb-3 pb-3 item active">
									<div class="mobile-table-header">{{'Tax Filer' | translate}}</div>
									<hr>
									<div  class="mobile-table-data">{{ row.name }}</div>
									<br>
                  <div class="mobile-table-header">{{'Filing Jointly With' | translate }}</div>
                  <hr>
									<div class="mobile-table-data">{{row.filingJointly}}</div>
                  <br>
									<div class="mobile-table-header">{{'Tax Dependents' | translate }}</div>
									<hr>
                  <div class="mobile-table-data"><ul *ngIf="row.dependents?.length" class="no-bullets"><li *ngFor="let dependent of row.dependents">{{dependent.name}}</li></ul>
                    <span *ngIf="!row.dependents?.length">{{'No' | translate}}</span></div>
                  <br>
                  <div class="mobile-table-header">{{'RMB_TAX_DEDUCTIONS' | translate }}</div>
                  <hr>
                  <div class="mobile-table-data"><ul *ngIf="row.deductions?.length" class="no-bullets"><li *ngFor="let deduction of row.deductions; last as isLast">
                    <span *ngIf="deduction.type">{{deduction.type | translate}}</span><span *ngIf="deduction.amount"><br>{{deduction.amount | translate}} {{deduction.frequency | translate}}</span><span *ngIf="row.deductions.length > 1 && !isLast">,</span></li></ul>
                  <span *ngIf="!row.deductions?.length">{{'No' | translate}}</span></div>
                  <br>
                  <div class="mobile-table-header">{{'Outside Household Dependents' | translate }}</div>
                  <hr>
									<div class="mobile-table-data column_padding"><ul *ngIf="row.outOfHouseDependents?.length" class="no-bullets"><li *ngFor="let outOfHouse of row.outOfHouseDependents">
                  <span *ngIf="outOfHouse.name">{{outOfHouse.name | translate}}</span></li></ul>
                  <span *ngIf="!row.outOfHouseDependents?.length">{{'No' | translate}}</span></div>
								</div>
								<br>
							</div>
            </div>
            <p *ngIf="(taxRows?.length && !taxRows[0].name) || (!taxRows?.length)" class="mb-5" [innerHTML]="'RMB_TAX_FILER_INSIDE' | translate"></p>

            <!-- Tax Filer Ouside Household -->
            <p class="text-color sub_module_header pb-3"><strong>{{'RMB_TAX_OUTSIDE' | translate}}</strong></p>
            <div *ngIf="taxOutsideRows?.length && taxOutsideRows[0]?.outOfHouseFiler">
             <div *ngFor="let row of taxOutsideRows; let last = last" [ngClass]="{'data_container': !last}">
               <div class="table-row mb-3 pb-3 item active" [ngClass]="{'last-row': last}">
                 <div class="mobile-table-header">{{'Tax Filer' | translate}}</div>
                 <hr>
                 <div  class="mobile-table-data">{{ row.outOfHouseFiler }}</div>
                 <br>
                 <div class="mobile-table-header">{{'RMB_OUTSIDE_STATIC1' | translate }}</div>
                 <hr>
                 <div class="mobile-table-data">{{row.claimedDependent}}</div>
                 <br>
                 <div class="mobile-table-header">{{'RMB_OUTSIDE_STATIC2' | translate }}</div>
                 <hr>
                 <div class="mobile-table-data" [ngClass]="{ 'column_padding': !last}">{{ row.relationshipToOutsideTaxFiler | translate }}</div>
               </div>
             </div>
           </div>
           <p *ngIf="!taxOutsideRows?.length" class="mb-5" [innerHTML]="'RMB_TAX_FILER_OUTSIDE' | translate"></p>
						</ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-template>
    <ng-template class="slide" carouselSlide>
    <!--------Income and employment------------------------->
    <div  id="slide5" class="pb-4 slide-content">
      <mat-accordion displayMode="" multi class="mat-table">
        <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['incomeEmployment']" (expandedChange)="onExpand('incomeEmployment', $event)">
          <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
            <mat-panel-title>
              <div class="mat-cell-panel-header">
                <div class="d-flex pt-3">
                  <img class="icon" aria-label="incomeEmp" src="assets/images/Report changes/Income&Employment.svg" />
                <div class="sub_header">
                  <span class="mat-cell title"><span class="text-danger">*</span>{{'RMB_STARTSCREEN_TEXT_10' | translate}}</span>
                 
                </div>
                </div>
                <br>
                  <ng-container *ngIf="expandedPanels['incomeEmployment']">
                    <div class="radio-buttons-container">
                      <mat-radio-group class="smallRadio" style="flex-direction: unset" (click)="toggleValue('incomeEmployment', $event)" formControlName="incomeEmployment">
                        <mat-radio-button  value="edit" >{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                        <mat-radio-button value="noChange" >{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </ng-container>
                <br>
                <p
                  [ngClass]="{'panel_sub_text': expandedPanels['incomeEmployment'], 'pad_lt': !expandedPanels['incomeEmployment']}">
                  {{'RMB_STARTSCREEN_TEXT_17' | translate}}</p>
              </div>
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>

          <div class="sub_content pb-4">
            <p class="sub_text">
              <span [innerHTML]="'RMB_STARTSCREEN_TEXT_27' | translate"></span>
            </p>
          </div>
          <ng-container >
            <p class="text-color sub_module_header pb-3"><strong>{{'EMPLOYMENT_INCOME' | translate}}</strong></p>
            <div *ngIf="employmentIncomeRows.length" >
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of employmentIncomeRows">
                <div class="mobile-table-header">{{'Name' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'EMPLOYMENT_STATIC_TEXT5' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.employer| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Start Date' | translate }}</div>
                <hr>
                <div class="mobile-table-data" *ngIf="row.startDate">{{ row.startDate | date: 'MM/dd/yyyy'  }}</div>
                <div class="mobile-table-data" *ngIf="!row.startDate">{{ 'Not Answered'  }}</div>
                <br>
                <div class="mobile-table-header">{{'Amount & How Often' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.wage | translate }} {{row.frequency}}</div>
                <br>
              </div>
            </div>
            <p *ngIf="!employmentIncomeRows.length" class="mb-5" [innerHTML]="'RMB_EMPLOYMENT' | translate"></p>
            <p class="text-color sub_module_header pb-3"><strong>{{'SELF_EMPLOYMENT_INCOME' | translate}}</strong></p>
            <div *ngIf="selfEmploymentIncomeRows.length" >
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of selfEmploymentIncomeRows">
                <div class="mobile-table-header">{{'Name' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.member  }}</div>
                <br>
                <div class="mobile-table-header">{{'Business Type' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.bussinessType| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Net Income' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.netIncome | translate }}</div>
                <br>
                <div class="mobile-table-header">{{'SELF_EMPLOYMENT_STATIC_TEXT4' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.bussinessName | translate }}</div>
                <br>
                <div class="mobile-table-header">{{'CO-OWENERS_HEADER1' | translate }}</div>
                <hr>
                <div class="mobile-table-data column_padding"><ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                  <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></div>
                <br>
              </div>
            </div>
            <p *ngIf="!selfEmploymentIncomeRows.length" class="mb-5" [innerHTML]="'RMB_SELF_EMPLOYMENT' | translate"></p>
            <p class="text-color sub_module_header pb-3"><strong>{{'OTHER_INCOME' | translate}}</strong></p>
            <ng-container *ngIf="otherIncomeRows.length">
            <div *ngFor="let row of otherIncomeRows; let last = last" [ngClass]="{'data_container': !last}">
              <div class="table-row mb-3 pb-3 item active"  [ngClass]="{'last-row': last}">
                <div class="mobile-table-header">{{'Name' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'Income Type' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.incometype| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Start Date' | translate }}</div>
                <hr>
                <div class="mobile-table-data" *ngIf="row.startDate">{{ row.startDate | date: 'MM/dd/yyyy' }}</div>
                <div class="mobile-table-data" *ngIf="!row.startDate">{{ 'N/A' }}</div>
                <br>
                <div class="mobile-table-header">{{'Amount & How Often' | translate }}</div>
                <hr>
                <div class="mobile-table-data" [ngClass]="{ 'column_padding': !last}">{{ row.wage | translate }} {{row.frequency}}</div>
                <br>
              </div>
            </div>
          </ng-container>
            <p *ngIf="!otherIncomeRows.length" class="mb-5" [innerHTML]="'RMB_OTHER_INCOME' | translate"></p>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    </ng-template>
    <ng-template class="slide" carouselSlide>
        <!-- Other Health Care -->
    <div  id="slide6" class="pb-4 slide-content">
      <mat-accordion displayMode="" multi class="mat-table">
        <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['otherBenefits']" (expandedChange)="onExpand('otherBenefits', $event)">
          <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
            <mat-panel-title>
              <div class="mat-cell-panel-header">
                <div class="d-flex pt-3">
                  <img class="icon" aria-label="otherBenifits" src="assets/images/Report changes/OtherBenefits.svg" />
                  <div class="sub_header">
                    <span class="mat-cell title">{{'Other Health Care' | translate}}</span>
                  </div>
                </div>
                <br><br>
                <p class="mat-cell pb-4"
                  [ngClass]="{'panel_sub_text': expandedPanels['otherBenefits'], 'pad_lt': !expandedPanels['otherBenefits']}">
                  {{'RMB_STARTSCREEN_TEXT_18' | translate}}</p>
              </div>
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="sub_content">
            <p class="sub_text">
              <span [innerHTML]="'RMB_STARTSCREEN_TEXT_31' | translate"></span>
            </p>
          </div>
          <ng-container >
            <!-- othe health Case questions -->
            <p class="text-color sub_module_header pb-3"><strong>{{'Other Health Care Questions' | translate}}</strong></p>
            <ng-container *ngIf="otherBenefitsRows.length">
            <div *ngFor="let row of otherBenefitsRows; let last = last" [ngClass]="{'data_container': !last}">
              <div class="table-row mb-3 pb-3 item active" [ngClass]="{'last-row': last}">
                <div class="mobile-table-header">{{'Assistance' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.key | translate  }}</div>
                <br>
                <div class="mobile-table-header">{{'Response' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.value | translate }}</div>
              </div>
            </div>
          </ng-container>
            <p *ngIf="!otherBenefitsRows.length" class="mb-5" [innerHTML]="'RMB_OTHER_BENEFITS' | translate"></p>
          
             <!-- Katie Beckett Referral -->
             <!-- <p class="text-color"><strong>{{'RMB_KBR' | translate }}</strong></p>
             <div *ngIf="kbRows.length" >
               <div class="table-row mb-3 pb-3 item active" *ngFor="let row of kbRows">
                <div class="mobile-table-header">{{'Name' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                 <div class="mobile-table-header">{{'Reason' | translate }}</div>
                 <hr>
                 <div  class="mobile-table-data"> <ul *ngIf="row.reasons.length" class="no-bullets"><li *ngFor="let r of row.reasons;index as i;last as isLast">{{r.reason}}<span *ngIf="row.reasons.length > 1 && !isLast">,</span></li></ul>
                <span *ngIf="!row.reasons.length">{{'Not Answered' | translate}}</span>
                </div>
                 <br>
                 <div class="mobile-table-header">{{'CONTACT_INFO' | translate }}</div>
                 <hr>
                 <div class="mobile-table-data"><span>{{ row.name | translate }} <br></span><span *ngIf="row.addressLine1">{{ row.addressLine1 | translate }},<span *ngIf="row.addressLine2"> {{row.addressLine2}},</span> {{row.addressCity}},<br>{{row.addressState}}, {{row.addressZip}}</span></div>
                 <br>
                 <div class="mobile-table-header">{{'Relationship' | translate }}</div>
                 <hr>
                 <div class="mobile-table-data">{{ row.relationship| translate }}</div>
                 <br>
                 <div class="mobile-table-header">{{'KATIE_BECKETT_STATIC_TEXT21' | translate }}</div>
                 <hr>
                 <div class="mobile-table-data">{{ row.livingArrangement| translate }}</div>
                 <br>
               </div>
             </div>            
             <p *ngIf="!kbRows.length" class="mb-5" [innerHTML]="'RMB_KB_REFERRAL' | translate"></p> -->
          
            <!-- Transferred Resources -->
            <!-- <p class="text-color"><strong>{{'OTHER_HEALTHCARE_STATIC_TEXT44' | translate}}</strong></p>
            <div *ngIf="transferredRows.length">
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of transferredRows">
                <div class="mobile-table-header">{{'Original Owner' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'Resource Type' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'New Owner & Date' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.newOwner | translate }} <span *ngIf="row.date"><br> {{row.date | date: 'MM/dd/yyyy'}}</span></div>
                <br>
                <div class="mobile-table-header">{{'Total Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.totalValue | translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Amount Received' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.amountRecvd | translate }} </div>
                <br>
              <hr>
              </div>
            </div>   
            <p *ngIf="!transferredRows.length" class="mb-5" [innerHTML]="'RMB_TRANSFERRED_RESOURCES' | translate"></p>           -->
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    </ng-template>
    <!-- Expenses -->
    <!-- <div  id="blueBox" *ngIf="displayExpenses" class="pb-4">
      <i id="leftarrow" class="leftarrow left" (click)="displayPrevSection('expenses')"></i>
        <i id="rightarrow" class="rightarrow right" (click)="displayNextSection('expenses')"></i>
      <mat-accordion displayMode="" multi class="mat-table">
        <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['expenses']" (expandedChange)="onExpand('expenses', $event)">
          <mat-expansion-panel-header role="region" aria-label="expensesExpanssion" class="mat-row" hideToggle>
            <mat-panel-title>
              <div class="pl-2 mat-cell-panel-header">
                <div class="d-flex">
                  <img class="icon" aria-label="expenses" src="assets/images/Report changes/Expenses.svg" />
                  <div class="sub_header">
                    <span class="mat-cell title">{{'RMB_STARTSCREEN_TEXT_12' | translate}}</span>
                  </div>
                </div>
                <br><br>
                <ng-container *ngIf="expandedPanels['expenses']">
                  <div class="radio-buttons-container">
                    <mat-radio-group  class="smallRadio" style="flex-direction: unset" (click)="toggleValue('expenses', $event)" formControlName="expenses">
                      <mat-radio-button value="edit">{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                      <mat-radio-button value="noChange">{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </ng-container>
                <br><br>
                <p class="mat-cell"
                  [ngClass]="{'panel_sub_text': expandedPanels['expenses'], 'pad_lt': !expandedPanels['expenses']}">
                  {{'RMB_STARTSCREEN_TEXT_19' | translate}}</p>
              </div>
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="sub_content">
            <p class="sub_text"><span [innerHTML]="'RMB_STARTSCREEN_TEXT_28' | translate"></span></p>
          </div>
          <ng-container >
            //Shelter and Utility
            <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_22' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of utilityExpensesRows">
                <div class="mobile-table-header">{{'Name' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Amount & How Often' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}</div>
                <br>
              <hr>
              </div>
              <p *ngIf="!utilityExpensesRows.length" class="mb-5" [innerHTML]="'RMB_UTILITY_EXPENSES' | translate"></p>
            </div>
            //Dependent Care Expenses
            <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_23' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of dependentCareExpensesRows">
                <div class="mobile-table-header">{{'Person who pays for this care' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.whoPays  }}</div>
                <br>
                <div class="mobile-table-header">{{'Person this expense is paid for' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.paidFor }}</div>
                <br>
                <div class="mobile-table-header">{{'Amount & How Often' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}</div>
                <br>
              <hr>
              </div>
              <p *ngIf="!dependentCareExpensesRows.length" class="mb-5" [innerHTML]="'RMB__DEPENDENT_EXPENSES' | translate"></p>
            </div>

            //Mandatory Payments
            <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_24' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of mandatoryExpensesRows">
                <div class="mobile-table-header">{{'Person who makes the payments' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.whoPays  }}</div>
                <br>
                <div class="mobile-table-header">{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type }}</div>
                <br>
                <div class="mobile-table-header">{{'Amount & How Often' | translate }}</div>
                <hr>
                <div class="mobile-table-data">  <span *ngIf="row.amount">{{ row.amount | translate }} {{'Monthly' | translate}}</span>
                  <span *ngIf="!row.amount">{{'Not Answered' | translate}}</span></div>
                <br>
              <hr>
              </div>
              <p *ngIf="!mandatoryExpensesRows.length" class="mb-5" [innerHTML]="'RMB_MANDATORY_PAYMENTS' | translate"></p>
            </div>

           //Medical Bills Expenses
           <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_25' | translate}}</strong></p>
           <p *ngIf="medicialBillsExpensesRows.length" [innerHTML]="'RMB_MEDICAL_BILLS_STATIC1' | translate"></p>
           <div>
             <div class="table-row mb-3 pb-3 item active" *ngFor="let row of medicialBillsExpensesRows">
               <div class="mobile-table-header">{{'Person who paid the bill' | translate }}</div>
               <hr>
               <div  class="mobile-table-data"> {{ row.whoPaid  }}</div>
               <br>
               <div class="mobile-table-header">{{'Person who received the care' | translate }}</div>
               <hr>
               <div class="mobile-table-data">{{ row.paidFor }}</div>
               <br>
               <div class="mobile-table-header">{{'Bill type' | translate }}</div>
               <hr>
               <div class="mobile-table-data"> {{ row.type | translate }}</div>
               <br>
               <div class="mobile-table-header">{{'Amount & How Often' | translate }}</div>
               <hr>
               <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}</div>
               <br>
             <hr>
             </div>
             <p *ngIf="!medicialBillsExpensesRows.length" class="mb-5" [innerHTML]="'RMB_MEDICAL_BILL_EXPENSES' | translate"></p>
           </div>

           //Access to Other Coverage
           <p class="text-color"><strong>{{'RMB_STARTSCREEN_TEXT_26' | translate}}</strong></p>
           <p [innerHTML]="'RMB_MEDICAL_BILLS_STATIC1' | translate"></p>
           <div>
             <div class="table-row mb-3 pb-3 item active" *ngFor="let row of accesstoOtherCoverageRows">
               <div class="mobile-table-header">{{'Name' | translate }}</div>
               <hr>
               <div  class="mobile-table-data"> {{ row.name  }}</div>
               <br>
               <div class="mobile-table-header">{{'Qyalifying Health Coverage?' | translate }}</div>
               <hr>
               <div class="mobile-table-data">{{ row.qualify }}</div>
               <br>
               <div class="mobile-table-header">{{'Premium Amount/How Often' | translate }}</div>
               <hr>
               <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}}</div>
               <br>
               <div class="mobile-table-header">{{'COBRA Plan' | translate }}</div>
               <hr>
               <div class="mobile-table-data"> {{ row.plan | translate }} {{row.frequency}}</div>
               <br>
               <div class="mobile-table-header">{{'Retiree Health Plan' | translate }}</div>
               <hr>
               <div class="mobile-table-data"> {{ row.retireHealthPlan | translate }} {{row.frequency}}</div>
               <br>
             <hr>
             </div>
             <p *ngIf="!accesstoOtherCoverageRows.length" class="mb-5" [innerHTML]="'RMB_ACCESS_TO_OTHER_COVERAGE' | translate"></p>
           </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->

    <!-- Resources -->
         
    <!-- <div  id="blueBox" *ngIf="displayResources" class="pb-4">
      <i id="leftarrow" class="leftarrow left" (click)="displayPrevSection('resources')"></i> 
      <mat-accordion displayMode="" multi class="mat-table">
        <mat-expansion-panel class="start-expansion-panel" [expanded]="expandedPanels['resources']" (expandedChange)="onExpand('resources', $event)">
          <mat-expansion-panel-header role="region" class="mat-row" hideToggle>
            <mat-panel-title>
              <div class="pl-2 mat-cell-panel-header">
                <div class="d-flex">
                  <img class="icon" aria-label="resources" src="assets/images/Report changes/Resources.svg" />
                  <div class="sub_header">
                    <span class="mat-cell title">{{'RMB_STARTSCREEN_TEXT_13' | translate}}</span>
                  </div>
                </div>
                <br><br>
                <ng-container *ngIf="expandedPanels['resources']">
                  <div class="radio-buttons-container">
                    <mat-radio-group class="smallRadio" style="flex-direction: unset" (click)="toggleValue('resources', $event)" formControlName="resources">
                      <mat-radio-button value="edit">{{'EDIT_BUTTON' | translate}}</mat-radio-button>
                      <mat-radio-button value="noChange">{{'NO_CHANGE_BUTTON' | translate}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </ng-container>
                <br><br>
                <p class="mat-cell"
                  [ngClass]="{'panel_sub_text': expandedPanels['resources'], 'pad_lt': !expandedPanels['resources']}">
                  {{'RMB_STARTSCREEN_TEXT_20' | translate}}</p>
              </div>
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="sub_content">
            <p class="sub_text"><span [innerHTML]="'RMB_STARTSCREEN_TEXT_21' | translate"></span></p>
          </div>
          <ng-container >
           // Financial Resources
            <p class="text-color"><strong>{{'FINANCIAL_RESOURCES_STATIC_TEXT1' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of financialRows">
                <div class="mobile-table-header">{{'Owner' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'FINANCIAL_RESOURCES_STATIC_TEXT18' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.bankName | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'Total Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.totalValue | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'CO-OWENERS_HEADER1' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> <ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                  <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></div>
                <br>
              <hr>
              </div>
              <p *ngIf="!financialRows.length" class="mb-5" [innerHTML]="'RMB_FINANCIAL_RESOURCES' | translate"></p>
            </div>

           // Vehicle Resources
            <p class="text-color"><strong>{{'Vehicles' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of vehicleRows">
                <div class="mobile-table-header">{{'Owner' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'Vehicle' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type | translate }}<span *ngIf="row.makeYear"><br>{{ row.makeYear | translate }} </span><span *ngIf="row.make">{{ row.make | translate }} </span><span *ngIf="row.model">{{ row.model | translate }}</span></div>
                <br>
                <div class="mobile-table-header">{{'Total Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.totalValue | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'Amount Owed' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'CO-OWENERS_HEADER1' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> <ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                  <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></div>
                <br>
              <hr>
              </div>
              <p *ngIf="!vehicleRows.length" class="mb-5" [innerHTML]="'RMB_VEHICLE_RESOURCES' | translate"></p>
            </div>

            //Property Resources
            <p class="text-color"><strong>{{'Property' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of propertyRows">
                <div class="mobile-table-header">{{'Owner' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'Property Type' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Total Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.totalValue | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'Amount Owed' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'CO-OWENERS_HEADER1' | translate }}</div>
                <hr>
                <div class="mobile-table-data" *ngIf="row.coOwner?.length"> <ul class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                  <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></div>
                <br>
              <hr>
              </div>
              <p *ngIf="!propertyRows.length" class="mb-5" [innerHTML]="'RMB_PROPERTY_RESOURCES' | translate"></p>
            </div>

            //Burrial Resources
            <p class="text-color"><strong>{{'Burial Resources' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of burrialRows">
                <div class="mobile-table-header">{{'Owner' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'Burial Type' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'FINANCIAL_RESOURCES_STATIC_TEXT18' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.bankName | translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Total Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.totalValue | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'Amount Owed' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'CO-OWENERS_HEADER1' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> <ul class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul></div>
                <div class="mobile-table-data"> <ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                  <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></div>
                <br>
              <hr>
              </div>
              <p *ngIf="!burrialRows.length" class="mb-5" [innerHTML]="'RMB_BURRIAL_RESOURCES' | translate"></p>
            </div>

            //Life Insurance Resources
            <p class="text-color"><strong>{{'Life Insurance' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of lifeResourcesRows">
                <div class="mobile-table-header">{{'Who is covered' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'Insurance Type' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Face Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.faceValue | translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Cash Surrender Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.cashSurrenderValue | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'POLICY_NUMBER' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.policyNumber | translate }} {{row.frequency}}</div>
                <br>
              <hr>
              </div>
              <p *ngIf="!lifeResourcesRows.length" class="mb-5" [innerHTML]="'RMB_LIFE_RESOURCES' | translate"></p>
            </div>

           // Other Resources
            <p class="text-color"><strong>{{'Other Resources' | translate}}</strong></p>
            <div>
              <div class="table-row mb-3 pb-3 item active" *ngFor="let row of otherResourceRows">
                <div class="mobile-table-header">{{'Owner' | translate }}</div>
                <hr>
                <div  class="mobile-table-data"> {{ row.name  }}</div>
                <br>
                <div class="mobile-table-header">{{'Type' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.type| translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Value' | translate }}</div>
                <hr>
                <div class="mobile-table-data">{{ row.value | translate }}</div>
                <br>
                <div class="mobile-table-header">{{'Amount Owed' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> {{ row.amount | translate }} {{row.frequency}}</div>
                <br>
                <div class="mobile-table-header">{{'OWENERS_HEADER1' | translate }}</div>
                <hr>
                <div class="mobile-table-data"> <ul *ngIf="row.coOwner?.length" class="no-bullets"><li *ngFor="let owner of row.coOwner">{{owner.namePercent}}</li></ul>
                  <span *ngIf="!row.coOwner?.length">{{'No' | translate}}</span></div>
                <br>
              <hr>
              </div>
              <p *ngIf="!otherResourceRows.length" class="mb-5" [innerHTML]="'RMB_OTHER_RESOURCES' | translate"></p>
            </div>

          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->
    </owl-carousel-o>
    <div class="pagination">
      {{currentSlide + 1}} of {{totalSlides}}
    </div>
    <nh-alert type="warning" [isOpen]="finalBanner">
      <div class="row">
        <div class="icon">
          <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span [innerHTML]="'RMB_BANNER_MESSAGE_TEXT_1' | translate"></span>
          <span [innerHTML]="missingSelectionText"></span>
          <span>&nbsp;{{'RMB_BANNER_MESSAGE_TEXT_2' | translate }}</span>
        </div>
      </div>
    </nh-alert>
  </div>
  </div>
</nh-form>