<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" id="my-modal-title">{{ 'HEALTH_PLAN_CANCEL_POPUP_TEXT1' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <p class="pb-2 pt-2">{{'HEALTH_PLAN_CANCEL_POPUP_TEXT2' | translate: {date: healthPlanDetails.requestSubmittedDate |
      translate} }}</p>
  </div>
</div>