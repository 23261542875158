<div class="p-1 mt-1">
  <h5 class="section-header">{{'UPLOAD_DOCUMENT1' | translate }}</h5>
  <hr>
   <ngx-datatable *ngIf="!isMobileView && !uploadDocumentFlag" class='bootstrap striped' limit="5" #table [rows]='rows' [columnMode]='"force"'
    [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
    <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of columns">
    </ngx-datatable-column>
  </ngx-datatable>
  <ngx-datatable *ngIf="uploadDocumentFlag" class='bootstrap striped' limit="5" #table [rows]='rowsData' [columns]="columnData" [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"' [columnMode]='"force"'></ngx-datatable>
 
  <div *ngIf="isMobileView && !row.refId && uploadDocumentFlag && !isMedicalAppeal">
    <ng-container *ngIf="rowsData.length">
      <div *ngFor="let row of rowsData">
        <p><strong>{{columnData[0].name | translate }}</strong></p>
        <hr>
        <p>{{row.fullName}}</p>
        <br>
        <p><strong>{{columnData[1].name | translate }}</strong></p>
        <hr>
        <p *ngIf="translatedLang === 'en'">{{row.verfDesc}}</p></div>
         </ng-container>
    <p *ngIf="!rows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p></div>


  <div *ngIf="isMobileView && !row.refId && !uploadDocumentFlag && !isMedicalAppeal">
    <ng-container *ngIf="rows.length">
      <div *ngFor="let row of rows">
        <p><strong>{{columns[0].name | translate }}</strong></p>
        <hr>
        <p>{{row.fullName}}</p>
        <br>
        <p><strong>{{columns[1].name | translate }}</strong></p>
        <hr>
        <p *ngIf="translatedLang === 'en'">{{row.proofType}}</p>
        <p *ngIf="translatedLang !== 'en'">{{row.proofTypeEs}}</p>
        <br>
        <div *ngIf = "!(row.verfDesc === 'Documents for Appeals' || row.verfDesc === 'Documentos para Apelaciones')">
          <p><strong>{{columns[2].name | translate }}</strong></p>
          <hr>
          <p>{{row.verfDue}}</p>
          <br>
        </div>
        <div *ngIf = "!(row.verfDesc === 'Appeal Proof Documents' || row.verfDesc === 'Documentos de comprobación para apelación')">
          <p><strong>{{columns[2].name | translate }}</strong></p>
          <hr>
          <p>{{row.verfDue}}</p>
          <br>
        </div>
      </div>
    </ng-container>
    <p *ngIf="!rows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
  </div>

  <div *ngIf="isMobileView && !row.refId && isMedicalAppeal">
    <ng-container *ngIf="rows.length">
      <div *ngFor="let row of rows">
        <p><strong>{{columns[0].name | translate }}</strong></p>
        <hr>
        <p>{{row.fullName}}</p>
        <br>
        <p><strong>{{columns[1].name | translate }}</strong></p>
        <hr>
        <p *ngIf="isMedicalAppeal">{{row.verfDesc}}</p>
        <br>
        <div *ngIf = "(row.verfDesc === 'Additional Information Form' || row.verfDesc === 'Formulario para Información Adicional')">
          <p><strong>{{columns[2].name | translate }}</strong></p>
          <hr>
          <p>{{row.appealDueDate}}</p>
          <br>
        </div>
      </div>
    </ng-container>
    <p *ngIf="!rows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
  </div>

  <h5 *ngIf="translatedLang === 'en' && !uploadDocumentFlag && !isMedicalAppeal" class="section-header">{{row.fullName}}'s {{this.rows[0]['proofType']}}</h5>
  <h5 *ngIf="translatedLang !== 'en' && !uploadDocumentFlag && !isMedicalAppeal" class="section-header">{{row.fullName}}'s {{this.rows[0]['proofTypeEs']}}</h5>
  <h5 *ngIf="translatedLang === 'en' && uploadDocumentFlag && !isMedicalAppeal" class="section-header">{{row.fullName}}'s {{this.vfdProofType}}</h5>
  <h5 *ngIf="translatedLang !== 'en' && uploadDocumentFlag && !isMedicalAppeal" class="section-header">{{row.fullName}}'s {{this.vfdProofType}}</h5>
  <h5 *ngIf="translatedLang === 'en' && isMedicalAppeal" class="section-header">{{row.fullName}}'s {{this.vfdProofType}} </h5>
  <h5 *ngIf="translatedLang !== 'en' && isMedicalAppeal" class="section-header">{{this.vfdProofType}} {{row.fullName}}</h5>
  <hr>
  <p class="static-text" *ngIf="error && !isMedicalAppeal" [innerHtml]="'DOC_BEFORE_UPLOAD_TEXT' | translate">
  </p>
  <p class="static-text" *ngIf="!error" [innerHtml]="'DOC_AFTER_UPLOAD_TEXT' | translate">
  </p>
  <p class="static-text" *ngIf="error && isMedicalAppeal" [innerHtml]="'DOC_BEFORE_UPLOAD_TEXT_MEDAPP' | translate">
  </p>
  <div *ngFor="let doc of documents; index as i">
    <div class="row">
      <div class="col-md-12">
        <span class="text-danger">*</span><label for="docType" class="label-section">{{'DOCUMENT_TYPE' | translate }}</label>
        <p *ngIf="!isMedicalAppeal" class="static-text" [innerHtml]="'DOCUMENT_TEXT' | translate"></p>
        <a *ngIf="isMobileView && !doc.file && i !== 0" (click)="close(i)" class="mobile-upload-btn-close"><span class="pr-2">{{'CLOSE' | translate }}</span><i class="fa fa-times-circle"></i></a>
        <div class="mt-3">
          <select  *ngIf="translatedLang === 'en'" class="custom-select-info" aria-label="documentType" [(ngModel)]="doc.docType" name="doc.docType"
            (change)="selectedOption(i)" required>
            <option [ngValue]="null">Pick an option</option>
            <option *ngFor="let option of options" [ngValue]="option">{{option.enDescription}}</option>
          </select>
          <select *ngIf="translatedLang !== 'en'"  class="custom-select-info" aria-label="documentType" [(ngModel)]="doc.docType" name="doc.docType"
          (change)="selectedOption(i)" required>
          <option [ngValue]="null">Elija una opción</option>
          <option *ngFor="let option of options" [ngValue]="option">{{option.spDescription}}</option>
        </select>
        </div>
        <div *ngIf="doc.error" class="error-messages position-relative">
          <span class="form-text invalid-tooltip d-block">
            <ng-container>{{'REQUIRED' | translate }}</ng-container>
          </span>
        </div>
      </div>
      <div *ngIf="!isMobileView && !doc.file && i !== 0" class="col-md-2 offset-md-4">
        <div class="row pl-1 pt-1 hover-red upload-btn-close">
          <a (click)="close(i)"><span class="pr-2">{{'CLOSE' | translate }}</span><i class="fa fa-times-circle"></i></a>
        </div>
      </div>
    </div>
    <div class="row pt-2" *ngIf="doc.show">
      <div class="col-md-12">
        <nh-file-upload (passFileData)="getFileDetails($event, i)" (fileUploadStarted)="getFileProgress($event)">
        </nh-file-upload>
      </div>
    </div>
    <div class="row uploaded-documentname" *ngIf="displayLoading">
      <div class="col-8">
        <span class="pr-2 file-icon"><img alt="uploadedDoc"
            src="../../../../../../assets/images/⚛️ icons-document-attach-outline.svg"></span>
        <span class="file-name" style="max-width:440px">{{docName}}</span>
      </div>
      <div class="col-4">
        <div class="option-lables">
          <img src="../../../../../../assets/images/⚛️ File_Uploader_Loader.svg">
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center p-3" *ngIf="doc.file">
      <div class="row uploaded-documentname">
        <div class="pr-2 file-icon"><img alt="uploadedDoc" src="../../../../../../assets/images/⚛️ icons-document-attach-outline.svg">
        </div>
        <div class="file-name" style="max-width:440px">
          <span>{{doc.file.name}}</span>
        </div>
        <div class="hover-red option-lables">
          <a class="btn btn-link hover-red" (click)="viewPdf(i)">
            <span class="pr-2">{{'View' | translate }}</span>
            <svg id="_icons_search-outline" data-name="⚛️ icons/search-outline" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Background" width="24" height="24" fill="#174a7c" />
                </clipPath>
              </defs>
              <g id="Mask_Group_1" data-name="Mask Group 1" clip-path="url(#clip-path)">
                <path id="Combined_Shape" data-name="Combined Shape"
                  d="M18.232,19.292l-.013-.012-4.927-4.927a8.122,8.122,0,1,1,1.061-1.061l4.927,4.927a.75.75,0,0,1-1.049,1.073ZM1.5,8.114A6.614,6.614,0,1,0,8.114,1.5,6.621,6.621,0,0,0,1.5,8.114Z"
                  transform="translate(2.25 2.25)" fill="#174a7c" />
              </g>
            </svg>
          </a>

          <a class="btn btn-link hover-red" (click)="removeItem(i)">
            <span class="pr-2">{{'REMOVE' | translate }}</span>
            <nh-remove-svg></nh-remove-svg>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row mt-3 col-md-12">
      <div class="ml-2">
        <button id="addAnother" (click)="addAnother()" class="btn btn-block btn-outline-secondary btn-add-another"
          [hidden]="error">{{'ADD_1' | translate }}
          <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" viewBox="0 0 24 24">
            <path id="Combined_Shape" data-name="Combined Shape"
              d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z"
              transform="translate(2.25 2.25)" fill="#174a7c" />
          </svg></button>
      </div>
      <div class="ml-2">
        <button [hidden]="error || showCZPRmessage" routerLinkActive="active" (click)="submitDocuments()" id="continue"
          class="btn btn-secondary">{{'SUBMIT' | translate }}</button>
      </div>
    </div>
  </div>

  <div style="border:0px solid #f5e0af;margin-top: 15px;" *ngIf="showCZPRmessage">{{'UPLOAD_DOC_TEXT2' | translate }}</div>

</div>
