import { Pipe, PipeTransform } from '@angular/core';
import { gender } from '@app/helpers/person.helpers';
import { Individual } from '@app/models/individual.model';
import { individualName, individualNameSuffixValue } from '@app/helpers/people.helpers';
import { age } from '@app/helpers/person.helpers';

@Pipe({
  name: 'nameagegender'
})
export class NameagegenderPipe implements PipeTransform {

  transform(individual: Individual, alternative = 'this person'): string {
    const name = individualName(alternative, individual);
    if(individual && individual.name && individual.name.suffix){
      return name + " " + individualNameSuffixValue(alternative,individual.name)+', '+age(individual)+' '+ gender(individual);
    }else{
      return name +', '+age(individual)+' '+ gender(individual);
    }
  }
}
