import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { ComponentsModule } from '@app/components/components.module';

import { SectionGuard } from './section.guard';
import { SectionRouteReuseStrategy } from './section.strategy';
import { NotificationsService, MessagesToken } from '@app/services/notification/notification.service';

import { SectionComponent } from './section.component';

import { PeopleComponent } from './forms/people/people-details/people.component';
import { DemographicsComponent } from './forms/people/details/demographics/demographics.component';
import { AdditionalComponent } from './forms/people/details/additional/additional.component';
import { ImmigrationComponent } from './forms/people/details/immigration/immigration.component';

import { TaxComponent } from './forms/tax-information/tax-details/tax.component';
import { TaxFilingComponent } from './forms/tax-information/details/tax-filing/tax-filing.component';
import { TaxDependentsOutsideComponent } from './forms/tax-information/details/tax-dependents-outside/tax-dependents-outside.component';
import { TaxDeductionsComponent } from './forms/tax-information/details/tax-deductions/tax-deductions.component';
import { JointFilersOutsideComponent } from './forms/tax-information/details/joint-filer-outside/joint-filer-outside.component';
import { TaxFilerOutsideComponent } from './forms/tax-information/details/tax-filer-outside/tax-filer-outside.component'
import { CarouselModule } from 'ngx-owl-carousel-o';

import {
  // Programs
  NewStartComponent,
  AddStartComponent,
  EasyRMBStartComponent,
  StartPresumptiveComponent,
  NewbornInformationComponent,
  NewbornSummaryComponent,
  // Report Change
  ReportChangeComponent,
  // Household
  HouseholdComponent,
  HouseholdPresumptiveComponent,
  PatientInformationComponent,
  FacilityInformationComponent,
  NewbornImmigrationComponent,
   //BCCExtensionTreatment
   BccExtensionInformation,
   //IncurredMedicalExpensesInformation
   IncurredMedicalExpInfo,
   //IncurredMedicalExpDetails
   IncurredMedicalExpDetails,
  // Household Information
  RelationshipsComponent,
  HealthCoverageComponent,
  SchoolAndEmploymentComponent,
  RelationshipsPresumptiveComponent,
  // Income
  EmploymentComponent,
  SelfEmploymentComponent,
  OtherIncomeComponent,
  IncomeTaxComponent,
  // Expenses
  UtilitiesComponent,
  MedicareComponent,
  DependentCareComponent,
  SupportPaymentsComponent,
  OtherExpensesComponent,
  // Assets
  LiquidComponent,
  VehicleComponent,
  LifeInsuranceComponent,
  BurialComponent,
  RealEstateComponent,
  TransferComponent,
  OtherResourcesComponent,
  // Other
  OtherBenefitsComponent,
  CommentsComponent,
  KtReferralComponent,
  // Finish
  SummaryComponent,
  StatementsComponent,
  SubmitComponent,
  SummaryPresumptiveComponent,
  SummaryAppealComponent,
  SignSubmitComponent,
  NewbornSignsubmitComponent,
  EndCoverageComponent,
  ChooseHealthPlanComponent,
  //Appeals
  AppealRequestComponent,
  AppealDetailsComponent,
  AppealDemographicsComponent,
  AppealRepresentativeDetailsComponent
} from '@app/section/forms';
import { DemographicsCheckboxListComponent } from './forms/people/details/demographics/demographics-checkbox-list/demographics-checkbox-list.component';
import { DemographicsCheckboxComponent } from './forms/people/details/demographics/demographics-checkbox/demographics-checkbox.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
const entryComponents = [
  SectionComponent,
  // Programs
  NewStartComponent,
  AddStartComponent,
  EasyRMBStartComponent,
  StartPresumptiveComponent,
  NewbornInformationComponent,
  // Report Change
  ReportChangeComponent,
  // Household
  HouseholdComponent,
  HouseholdPresumptiveComponent,
  PatientInformationComponent,
  FacilityInformationComponent,
  NewbornImmigrationComponent,
  // Household Information
  RelationshipsComponent,
  HealthCoverageComponent,
  SchoolAndEmploymentComponent,
  RelationshipsPresumptiveComponent,
  // People
  PeopleComponent,
  DemographicsComponent,
  DemographicsCheckboxListComponent,
  DemographicsCheckboxComponent,
  AdditionalComponent,
  ImmigrationComponent,
  // Tax Information
  TaxComponent,
  TaxFilingComponent,
  TaxDeductionsComponent,
  TaxDependentsOutsideComponent,
  JointFilersOutsideComponent,
  TaxFilerOutsideComponent,
  // Income
  EmploymentComponent,
  SelfEmploymentComponent,
  OtherIncomeComponent,
  IncomeTaxComponent,
  // Expenses
  UtilitiesComponent,
  MedicareComponent,
  DependentCareComponent,
  SupportPaymentsComponent,
  OtherExpensesComponent,
  // Assets
  LiquidComponent,
  VehicleComponent,
  LifeInsuranceComponent,
  BurialComponent,
  RealEstateComponent,
  TransferComponent,
  OtherResourcesComponent,
  // Other
  OtherBenefitsComponent,
  CommentsComponent,
  KtReferralComponent,
  // Finish
  SummaryComponent,
  StatementsComponent,
  SubmitComponent,
  SummaryPresumptiveComponent,
  SummaryAppealComponent,
  SignSubmitComponent,
  NewbornSignsubmitComponent,
  EndCoverageComponent,
  NewbornSummaryComponent,
  ChooseHealthPlanComponent,
  //Appeals
  AppealRequestComponent,
  AppealDetailsComponent,
  AppealDemographicsComponent,
  AppealRepresentativeDetailsComponent,
  //BCCExtensionTreatment
  BccExtensionInformation,
  //IncurredMedicalExpensesInformation
  IncurredMedicalExpInfo,
  //IncurredMedicalExpDetails
  IncurredMedicalExpDetails,
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule.withConfig({
    callSetDisabledState: 'whenDisabledForLegacyCode',
  }), ComponentsModule, TranslateModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatExpansionModule,
    MatTableModule,
    MatDividerModule,
    MatIconModule,
    MatRadioModule,
    CarouselModule
],
  declarations: entryComponents,
  providers: [
    SectionGuard,
    { provide: RouteReuseStrategy, useClass: SectionRouteReuseStrategy, multi: false },
    { provide: MessagesToken, useClass: NotificationsService, multi: false },
  ],
  entryComponents,
})
export class SectionModule {}
