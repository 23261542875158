<div>
  <div class="bg-custom">
    <div class="container">
      <p>{{'MY_SUBMISSION_STATIC_1' | translate }}</p>
      <section>
        <div>
          <h5 class="section-header">{{'MY_SUBMISSION_STATIC_2' | translate }}</h5>
          <hr>
        <section *ngIf="!isLinkedAcc && !isPartnerPortal">
          <p>{{'MY_SUBMISSION_STATIC_4' | translate }}<a [routerLink]="['/user-profile', 'case-link']">{{'LINK_CASE_TITLE' | translate }}</a> {{'MY_SUBMISSION_STATIC_5' | translate }}</p>
        </section>
        <p *ngIf="(hoh && showSubmitRenewalMessage && (daysAfterRenewal > 90))">{{'MY_SUBMISSION_STATIC_3' | translate }} </p>
        <p *ngIf="(!hoh && isLinkedAcc) && !isPartnerPortal">{{'MY_SUBMISSION_STATIC_6' | translate }}</p>
        <p *ngIf="(hoh && renewalDisable && (daysPastRenewalDueDate <= 90))">{{'MY_SUBMISSION_STATIC_7' | translate }} <a (click)="renewBenefits()">{{'RENEW_MY_COVERAGE' | translate}}.</a></p>
        <p *ngIf="false">{{'MY_SUBMISSION_STATIC_8' | translate }}<a href="">{{'RENEW_MY_COVERAGE' | translate}}</a> {{'MY_SUBMISSION_STATIC_9' | translate}}</p>
        <p *ngIf="">You can start renewing your coverage on date. You must submit your renewal by date or your coverage
          may end.</p>
          <p *ngIf="renewals && renewals.length > 0">{{'MY_SUBMISSION_STATIC_10' | translate }}</p>
          <p *ngIf="renewals && renewals.length > 0">{{'MY_SUBMISSION_STATIC_11' | translate }}</p>
          <p *ngIf="isLinkedAcc && (renewals?.length === 0 || !renewals)">{{'MY_SUBMISSION_STATIC_12' | translate }}</p>
          <span *ngIf="renewals && renewals?.length !== 0"><br></span>
          <table class="table" *ngIf="renewals?.length > 0">
            <thead>
              <tr>
                <th>{{'Submitted By' | translate }}</th>
                <th>{{'MY_SUBMISSION_STATIC_14' | translate }}</th>
                <th>{{'Status' | translate }}</th>
                <th>{{'Date of Submission' | translate }}</th>
                <th>{{'View' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of renewals">
                <td>{{ item.submittedBy }}</td>
                <td><span *ngIf="item.status === 'SB'">{{ item.wpAppnumber }}</span>
                  <span *ngIf="item.status === 'ST'" class="hover-red">
                    <a class="text-left p-0 btn btn-link btn-lg hover-red w-100" (click)="continueRenewal()">
                      <span>{{'COVERAGE_RENEWAL_CONTINUE' | translate }}</span>
                    </a>
                  </span>
                </td>
                <td><div *ngIf="item.status === 'SB'">{{'Submitted' | translate }}</div>
                  <div *ngIf="item.status === 'ST'">{{'In Progress' | translate }}</div>
                </td>
                <td><div *ngIf="item.status === 'SB'">{{ item.submissionDate }}</div>
                  <div *ngIf="item.status === 'ST'"></div>
                </td>
                <td>
                  <span *ngIf="item.status === 'SB'" class="hover-red">
                    <a class="btn btn-link hover-red" (click)="onViewApp(item.tccAppnumber)"><i class="fa fa-search"></i></a>
                  </span>
                  <span *ngIf="item.status === 'ST'"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

  <section>
      <div>
          <h5 class="indivSw && section-header">{{'MY_SUBMISSION_STATIC_15' | translate }}</h5>
          <hr>
          <section *ngIf="!isLinkedAcc && !isPartnerPortal">
            <p>{{'MY_SUBMISSION_STATIC_4' | translate }} <a [routerLink]="['/user-profile', 'case-link']">{{'LINK_CASE_TITLE' | translate }}</a> {{'MY_SUBMISSION_STATIC_5' | translate }}</p>
          </section>
          <p *ngIf="(!hoh && isLinkedAcc && !isPartnerPortal)">{{'MY_SUBMISSION_STATIC_16' | translate }}</p>
          <p *ngIf="reportChanges && reportChanges.length > 0">{{'MY_SUBMISSION_STATIC_17' | translate }}</p>
          <p *ngIf="reportChanges && reportChanges.length > 0">{{'MY_SUBMISSION_STATIC_18' | translate }}</p>
          <p *ngIf="indivSw && isLinkedAcc && (reportChanges?.length === 0 || !reportChanges)">{{'MY_SUBMISSION_STATIC_19'| translate }}</p>
          <table class="table" *ngIf="reportChanges?.length > 0">
            <thead>
              <tr>
                <th>{{'Submitted By' | translate }}</th>
                <th>{{'MY_SUBMISSION_STATIC_20' | translate }}</th>
                <th>{{'MY_SUBMISSION_STATIC_21' | translate }}</th>
                <th>{{'Date of Submission' | translate }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of reportChanges">
                <td>{{ item.submittedBy }}</td>
                <td><span *ngIf="item.status === 'SB'">{{ item.caseNumber }}</span>
                  <span *ngIf="item.status === 'ST'" class="hover-red">
                    <span>{{ item.caseNumber }}</span>
                  </span>
                </td>
                <td>
                  <div *ngFor="let change of item.changesArray; index as i">
                    <span *ngIf="item.typeOfChange && item.typeOfChange !== ''">{{change | translate }}</span>
                  </div>
                  <div *ngIf="!item.typeOfChange || item.typeOfChange === ''">{{''}}</div>
                </td>
                <td>
                  <div *ngIf="item.status === 'SB'">{{ item.submissionDate }}</div>
                  <div *ngIf="item.status === 'ST'"></div>
                </td>
                <td>
                  <span *ngIf="item.status === 'SB'" class="hover-red">
                    <a class="btn btn-link hover-red" (click)="onViewApp(item.tccAppnumber)"><i
                        class="fa fa-search"></i></a>
                  </span>
                  <span *ngIf="item.status === 'ST'"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section>
        <div>
          <h5 class="indivSw && section-header">{{'MY_SUBMISSION_STATIC_30' | translate }}</h5>
          <hr>
          <p *ngIf="appeals && appeals.length > 0">{{'MY_SUBMISSION_STATIC_32' | translate }}</p>
          <p *ngIf="indivSw && isLinkedAcc && (appeals?.length === 0 || !appeals)">{{'MY_SUBMISSION_STATIC_31'| translate }}
          </p>
          <div class="d-none d-lg-block" *ngIf="appeals?.length > 0">
            <ngx-datatable class='bootstrap striped' #table [rows]='appeals' [columnMode]='"force"' [headerHeight]='"auto"'
              [footerHeight]='50' [rowHeight]='"auto"' [limit]="5" >
              <ngx-datatable-column name="{{'Submitted By' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  {{ row.submittedBy }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'MY_SUBMISSION_STATIC_33' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  {{ row.wpAppnumber }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'Appeal Reason' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <div *ngIf="translatedLang === 'en'">{{ row.reason?.value }}</div>
                  <div *ngIf="translatedLang === 'sp'">{{ row.reason?.spValue }}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'Submission Type' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <div *ngIf="translatedLang === 'en'">{{ row.submissionType?.value }}</div>
                  <div *ngIf="translatedLang === 'sp'">{{ row.submissionType?.spValue }}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'Date of Submission' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  {{ row.submissionDate }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column>
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <span class="hover-red">
                    <a class="btn btn-link hover-red" (click)="onViewAppAppeal(row.tccAppnumber, row)"><i
                        class="fa fa-search"></i></a>
                        </span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
          <div class="nh-table-mobile d-lg-none" *ngIf="appeals?.length > 0">
            <div class="table-row mb-3 pb-3" *ngFor="let item of appeals">
              <div class="mobile-table-header">{{'Submitted By' | translate }}</div>
              <hr>
              <div class="mobile-table-data">{{ item.submittedBy }}</div>
              <br>
              <div class="mobile-table-header">{{'MY_SUBMISSION_STATIC_33' | translate }}</div>
              <hr>
              <div class="mobile-table-data">{{ item.wpAppnumber }}</div>
              <br>
              <div class="mobile-table-header">{{'Appeal Reason' | translate }}</div>
              <hr>
              <div class="mobile-table-data" *ngIf="translatedLang === 'en'">{{ item.reason?.value }}</div>
              <div class="mobile-table-data" *ngIf="translatedLang === 'sp'">{{ item.reason?.spValue }}</div>
              <br>
              <div class="mobile-table-header">{{'Submission Type' | translate }}</div>
              <hr>
              <div class="mobile-table-data" *ngIf="translatedLang === 'en'">{{ item.submissionType?.value }}</div>
              <div class="mobile-table-data" *ngIf="translatedLang === 'sp'">{{ item.submissionType?.spValue }}</div>
              <br>
              <div class="mobile-table-header">{{'Date of Submission' | translate }}</div>
              <hr>
              <div class="mobile-table-data">{{ item.submissionDate }}</div>
              <br>
              <button class="btn btn-secondary btn-sm w-100" (click)="onViewAppAppeal(item.tccAppnumber, item)">
                {{'VIEW' | translate }}
              </button>
            </div>
          </div>
        </div>
      </section>
     <!-- QFR start -->
     <section>
      <div>
        <h5 class="section-header">{{'MY_SUBMISSION_STATIC_QFR' | translate }}</h5>
        <hr>
        <section *ngIf="!isLinkedAcc && !isPartnerPortal">
          <p>{{'MY_SUBMISSION_STATIC_4_QFR' | translate }}<a [routerLink]="['/user-profile', 'case-link']">{{'LINK_CASE_TITLE' | translate }}</a> {{'MY_SUBMISSION_STATIC_5' | translate }}</p>
        </section>
        <p *ngIf="qfrRows && qfrRows.length > 0">{{'MY_SUBMISSION_STATIC_10_QFR' | translate }}</p>
        <p *ngIf="isLinkedAcc && qfrRows?.length === 0">{{'MY_SUBMISSION_STATIC_12_QFR'| translate }}
        </p>
        <div class="d-none d-lg-block" *ngIf="qfrRows?.length > 0">
          <ngx-datatable class='bootstrap striped' #table [rows]='qfrRows' [columnMode]='"force"' [headerHeight]='"auto"'
            [footerHeight]='50' [rowHeight]='"auto"' [limit]="5" >
            <ngx-datatable-column name="{{'Submitted By' | translate }}">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ row.submittedBy }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'MY_SUBMISSION_STATIC_14_QFR' | translate }}">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ row.wpAppnumber }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Status' | translate }}">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <div>{{'Submitted' | translate }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Date of Submission' | translate }}">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ row.submissionDate }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'View' | translate }}">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <span class="hover-red">
                  <a class="btn btn-link hover-red" (click)="onViewApp(row.tccAppnumber)"><i
                      class="fa fa-search"></i></a>
                      </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="nh-table-mobile d-lg-none" *ngIf="qfrRows?.length > 0">
          <div class="table-row mb-3 pb-3" *ngFor="let item of qfrRows">
            <div class="mobile-table-header">{{'Submitted By' | translate }}</div>
            <hr>
            <div class="mobile-table-data">{{ item.submittedBy }}</div>
            <br>
            <div class="mobile-table-header">{{'MY_SUBMISSION_STATIC_14_QFR' | translate }}</div>
            <hr>
            <div class="mobile-table-data" *ngIf="item.status === 'SB'">{{ item.wpAppnumber }}</div>
            <br>
            <div class="mobile-table-header">{{'Status' | translate }}</div>
            <hr>
            <div class="mobile-table-data">{{'Submitted' | translate }}</div>
            <br>
            <div class="mobile-table-header">{{'Date of Submission' | translate }}</div>
            <hr>
            <div class="mobile-table-data">{{ item.submissionDate }}</div>
            <br>
            <button class="btn btn-secondary btn-sm w-100" (click)="onViewApp(item.tccAppnumber)">
              {{'VIEW' | translate }}
            </button>
          </div>
        </div>
      </div>
    </section>
     <!-- QFR End -->
      <section>
        <div>
          <h5 class="section-header">{{'MY_SUBMISSION_STATIC_22' | translate }}</h5>
          <hr>
            <section>
              <p *ngIf="showAFBLink">{{'MY_SUBMISSION_STATIC_23' | translate }}<a (click)="startApp()" class="view-tennCare-title">{{'Apply For Coverage' | translate }}.</a></p>
              <p *ngIf="!afbLinkToggle">{{'MY_SUBMISSION_STATIC_23' | translate }}<a (click)="startApp()" class="view-tennCare-title">{{'Apply For Coverage' | translate }}.</a></p>
            </section>
            <div>
              <p *ngIf="!isLinkedAcc && rows?.length > 0">{{'MY_SUBMISSION_STATIC_24' | translate }}</p>
              <p *ngIf="!isLinkedAcc">{{'MY_SUBMISSION_STATIC_25' | translate }}<a [routerLink]="['/user-profile', 'case-link']">{{'LINK_CASE_TITLE' | translate }}</a>.</p>
              <p *ngIf="!isLinkedAcc && rows?.length > 0">{{'MY_SUBMISSION_STATIC_26' | translate }}</p>
            </div>
            <p *ngIf="rows?.length === 0">{{'MY_SUBMISSION_STATIC_27' | translate }}</p>
            <p *ngIf="rows?.length > 0">{{'MY_SUBMISSION_STATIC_28' | translate }}</p>
            <p *ngIf="!isLinkedAcc && isPartnerPortal">{{'MY_SUBMISSION_STATIC_29' | translate }}</p>
            <div class="d-lg-block d-none">
            <ngx-datatable *ngIf="rows?.length > 0" class='bootstrap striped' #table [rows]='rows' [columnMode]='"force"' [headerHeight]='"auto"'
              [footerHeight]='50' [rowHeight]='"auto"' [limit]="5">
              <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of columns">
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'APP_NUMBER' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <span *ngIf="row.status === 'SB'">{{ row.wpAppnumber }}</span>
                  <span *ngIf="row.status === 'ST'" class="hover-red">
                    <a class="p-0 text-left btn btn-link btn-lg hover-red" (click)="continueApp()">
                      <span>{{'COVERAGE_APPLICATION_CONTINUE' | translate }}</span>
                    </a>
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'Status' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <div *ngIf="row.status === 'SB'">{{'Submitted' | translate }}</div>
                  <div *ngIf="row.status === 'ST'">{{'In Progress' | translate }}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'Date of Submission' | translate }}">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <div *ngIf="row.status === 'SB'">{{ row.submissionDate }}</div>
                  <div *ngIf="row.status === 'ST'">{{ row.lastAccessedDate }}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column>
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <span *ngIf="row.status === 'SB'" class="hover-red">
                    <a class="btn btn-link hover-red" (click)="onViewApp(row.tccAppnumber)"><i
                        class="fa fa-search"></i></a>
                  </span>
                  <span *ngIf="row.status === 'ST'" class="hover-red">
                    <a class="hover-red btn btn-link" (click)="onDeleteApp(row)"><i class="fa fa-trash"></i></a>
                  </span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
          <div class="d-lg-none nh-table-mobile">
            <div class="table-row mb-3 pb-3" *ngFor="let item of myApplications">
              <div class="mobile-table-header">{{'Submitted By' | translate }}</div>
              <hr>
              <div class="mobile-table-data">{{ item.submittedBy }}</div><br>
              <div class="mobile-table-header">{{'Head of Household' | translate }}</div>
              <hr>
              <div class="mobile-table-data">{{ item.headOfHoushold }}</div><br>
              <div class="mobile-table-header">{{'APP_NUMBER' | translate }}</div>
              <hr>
              <div class="mobile-table-data">
                <span *ngIf="item.status === 'SB'">{{ item.wpAppnumber }}</span>
                <span *ngIf="item.status === 'ST'" class="text-left hover-red">
                  <a class="text-left p-0 btn btn-link btn-lg hover-red w-100" (click)="continueApp()">
                    <span>{{'COVERAGE_APPLICATION_CONTINUE' | translate }}</span>
                  </a>
                </span>
              </div><br>
              <div class="mobile-table-header">{{'Status' | translate }}</div>
              <hr>
              <div class="mobile-table-data">
                <div *ngIf="item.status === 'SB'">{{'Submitted' | translate }}</div>
                <div *ngIf="item.status === 'ST'">{{'In Progress' | translate }}</div>
              </div><br>
              <div class="mobile-table-header">{{'Date of Submission' | translate }}</div>
              <hr>
              <div class="mobile-table-data">
                <div *ngIf="item.status === 'SB'">{{ item.submissionDate }}</div>
                <div *ngIf="item.status === 'ST'">{{ item.lastAccessedDate }}</div>
              </div><br>
              <button *ngIf="item.status === 'SB'" class="btn btn-secondary btn-sm w-100">
                {{'VIEW' | translate }}
              </button>
              <button *ngIf="item.status === 'ST'" class="btn btn-secondary btn-sm w-100">
                {{'REMOVE' | translate }}</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="row">
  </div>
</div>
