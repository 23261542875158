import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from '@app/services/storage/storage.service';
import { SpinnerService } from '@app/services/spinner/spinner.service';
import { ServiceConfigService } from '@app/services/service-config.service';

@Component({ template: `` })
export class NotFoundComponent {
  constructor(
    private router: Router, //
    private spinner: SpinnerService,
    private storage: StorageService,
    private serverConfig: ServiceConfigService,
  ) {
    const url = this.router.url;
    const campaighUrl_gclid = this.serverConfig.getServerConfig().campaighUrl_gclid;
    const campaighUrl_Meta = this.serverConfig.getServerConfig().campaighUrl_Meta;
    const campaighUrl_Facebook = this.serverConfig.getServerConfig().campaighUrl_Facebook;
    let allMetaUrls : string[] = [];
    allMetaUrls.push(...campaighUrl_Meta.split(','));
    let allGclidUrls : string[] = [];
    allGclidUrls.push(...campaighUrl_gclid.split(','));
    let allFaceBookUrls : string[] = [];
    allFaceBookUrls.push(...campaighUrl_Facebook.split(','));
    if((allGclidUrls.includes(url)) || (allMetaUrls.includes(url)) || (allFaceBookUrls.includes(url))){
     this.router.navigate(['/'])
    } else {
      this.spinner.increment();
      this.router
      .navigate(['/application/', this.storage.getAppNumber()])
      .then(() => this.spinner.decrement());
    }
    
  }
}
