<p *ngIf="isRmbRmc && exported">{{ "RENEWAL_TEXT" | translate }}</p>
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label">{{ selectedIndividual | fullname }}</h6>
</div><br>
<nh-form [formGroup]="getForm()" [mode]="mode" [individuals]="checkForDropdown ? finalListAll : individuals" (validate)="onValidate($event)"
  class="label-section"
  [topLevelName] = "'tr'"
  individualQuestion="{{'TRANSFERED_INDIV_QUES' | translate }}"
  topLevelQuestion="{{'TRANSFERED_TOPLEVEL_QUES' | translate }}"
  [popupText]="'TRANSFERED_TOPLEVEL_POPUP' | translate">

  <section [formGroup]="getControl('data')">

    <h5 class="section-header" *ngIf="this.translatedLang === 'en'">Sold, Traded, or Given Away Resources</h5>
    <h5 class="section-header" *ngIf="this.translatedLang !== 'en'">Los recursos vendidos, negociados o regalados de {{ selectedIndividual | fullname }}</h5>
    <hr>
    <nh-field id = "tr_typeOfAsset" label="{{'TRANSFERED_STATIC_TEXT1' | translate }} {{ selectedIndividual | fullname }}{{'VEHICLE_STATIC_TEXT3' | translate }}  <div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{'TRANSFERED_STATIC_TEXT2' | translate }}</div>" class="label-section">
      <select nh name="typeOfAsset" aria-label="typeOfAsset" [items]="tables.RESOURCETRANSFCATEGORY"
        formControlName="typeOfAsset" (change)="typeOfAsset(getControl('data.typeOfAsset'), true)"></select>
    </nh-field>

    <nh-field id = "tr_specificAsset"  label="{{'TRANSFERED_STATIC_TEXT3' | translate }} {{getValue('data.typeOfAsset').value}} {{'TRANSFERED_STATIC_TEXT4' | translate }} {{ selectedIndividual | fullname }}{{'VEHICLE_STATIC_TEXT3' | translate }}"
      class="label-section">
      <select nh name="specificAsset" aria-label="specificAsset" [items]="specificAssets"
        formControlName="specificAsset"></select>
    </nh-field>

    <nh-field id = "tr_assetTransferredTo"
      label="{{'TRANSFERED_STATIC_TEXT5' | translate }} {{ selectedIndividual | fullname }} {{'TRANSFERED_STATIC_TEXT6' | translate }}"
      class="label-section">
      <input type="text" nh aria-label="assetTransferredTo" name="assetTransferredTo"
        formControlName="assetTransferredTo" class="form-control" maxLength="11">
    </nh-field>

    <nh-field id = "tr_assetTransferDate" label="{{'TRANSFERED_STATIC_TEXT7' | translate }}" class="label-section">
      <nh-datepicker>
        <input type="text" name="assetTransferDate" aria-label="assetTransferDate" class="form-control"
          formControlName="assetTransferDate" bsDatepicker nh [minDate]="minimumTransferDateDate" [maxDate]="today"
          maxLength="10">
      </nh-datepicker>
    </nh-field>

    <nh-field id = "tr_valueOfAsset" label="{{'TRANSFERED_STATIC_TEXT8' | translate }}"
      class="label-section">
      <nh-popover class="pl-1" [content]="'TRANSFERED_STATIC_POPUP' | translate"></nh-popover>
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" aria-label="valueOfAsset" name="valueOfAsset" formControlName="valueOfAsset" class="form-control">
      </nh-input-group>
    </nh-field>

    <nh-field id = "tr_inReturnAmount" [mandatory]="false" label="{{'TRANSFERED_STATIC_TEXT9' | translate }}
    <div class=hint>{{'TRANSFERED_STATIC_TEXT10' | translate }}</div>" class="label-section">
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" aria-label="inReturnAmount" name="inReturnAmount" formControlName="inReturnAmount" class="form-control">
      </nh-input-group>
    </nh-field>
  </section>
</nh-form>