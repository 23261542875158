export const proofData = [
    {
        key: 'TYPES_OF_PROOF_STATIC6',
        code: ['INPR', 'LOPR', 'IKPR', 'EIPR', 'OFPR', 'SSPR', 'SEPR', 'UIPR', 'OHPR'],
        value: [
            "INCOME_PROOF_STATC1",
            'INCOME_PROOF_STATC2',
            'INCOME_PROOF_STATC3',
            'INCOME_PROOF_STATC4',
            'INCOME_PROOF_STATC5',
            "INCOME_PROOF_STATC6",
            "INCOME_PROOF_STATC7",
            "INCOME_PROOF_STATC8",
            "INCOME_PROOF_STATC9",
            "INCOME_PROOF_STATC10",
            "INCOME_PROOF_STATC11",
        ], show: false
    },
    {
        key: "TYPES_OF_PROOF_STATIC7",
        code: ['CZPR'],
        value: {
            general: {
                generalHeader: 'CITIZENSHIP_PROOF_STATIC1',
                generalProof: ["CITIZENSHIP_PROOF_STATIC2",
                    "CITIZENSHIP_PROOF_STATIC3",
                    "CITIZENSHIP_PROOF_STATIC4"]
            },
            otherProof: 'CITIZENSHIP_PROOF_STATIC5',
            listA: {
                listAHeader: 'CITIZENSHIP_PROOF_STATIC6',
                listAProof: ["CITIZENSHIP_PROOF_STATIC7",
                    "CITIZENSHIP_PROOF_STATIC8",
                    "CITIZENSHIP_PROOF_STATIC9",
                    "CITIZENSHIP_PROOF_STATIC10",
                    "CITIZENSHIP_PROOF_STATIC11",
                    "CITIZENSHIP_PROOF_STATIC12",
                    "CITIZENSHIP_PROOF_STATIC13",
                    "CITIZENSHIP_PROOF_STATIC14"],
            },
            listB: {
                listBHeader: 'CITIZENSHIP_PROOF_STATIC15',
                listBProof: ["CITIZENSHIP_PROOF_STATIC16",
                    "CITIZENSHIP_PROOF_STATIC17",
                    "CITIZENSHIP_PROOF_STATIC18",
                    "CITIZENSHIP_PROOF_STATIC19",
                    "CITIZENSHIP_PROOF_STATIC20"]
            }
        }, show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC8', code: ['PGPR'], value
            : [
                "PREGNACY_PROOF_STATIC1"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC9',
        code: ['DBPR'],
        value: [
            "DOB_PROOF_STATIC1",
            "DOB_PROOF_STATIC2",
            "DOB_PROOF_STATIC3",
            "DOB_PROOF_STATIC4",
            "DOB_PROOF_STATIC5",
            "DOB_PROOF_STATIC6",
            "DOB_PROOF_STATIC7",
            "DOB_PROOF_STATIC8",
            "DOB_PROOF_STATIC9"
        ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC10',
        code: ['EXPR', 'CSPR', 'TXPR', 'DCPR', 'MBPR', 'EMPR', 'SEPR', 'SUPR'],
        value: [
            "EXPENCES_PROOF_STATIC1",
            "EXPENCES_PROOF_STATIC2",
            "EXPENCES_PROOF_STATIC3",
            "EXPENCES_PROOF_STATIC4",
            "EXPENCES_PROOF_STATIC5",
            "EXPENCES_PROOF_STATIC6",
            "EXPENCES_PROOF_STATIC7",
            "EXPENCES_PROOF_STATIC8",
            "EXPENCES_PROOF_STATIC9",
            "EXPENCES_PROOF_STATIC10",
            "EXPENCES_PROOF_STATIC11",
            "EXPENCES_PROOF_STATIC12",
            "EXPENCES_PROOF_STATIC13",
            "EXPENCES_PROOF_STATIC14",
        ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC11',
        code: ['IDPR', 'DBPR', 'SNPR'],
        value: [
            "IDENTITY_PROOF_STATIC1",
            "IDENTITY_PROOF_STATIC2",
            "IDENTITY_PROOF_STATIC3",
            "IDENTITY_PROOF_STATIC4",
            "IDENTITY_PROOF_STATIC5",
            "IDENTITY_PROOF_STATIC6",
            "IDENTITY_PROOF_STATIC7",
            "IDENTITY_PROOF_STATIC8",
            "IDENTITY_PROOF_STATIC9"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC12",
        code: ['ISPR', 'CZPR'],
        value: [
            "IMMIGRATION_PROOF_STATIC1",
            "IMMIGRATION_PROOF_STATIC2",
            "IMMIGRATION_PROOF_STATIC3",
            "IMMIGRATION_PROOF_STATIC4",
            "IMMIGRATION_PROOF_STATIC5",
            "IMMIGRATION_PROOF_STATIC6",
            "IMMIGRATION_PROOF_STATIC7",
            "IMMIGRATION_PROOF_STATIC8",
            "IMMIGRATION_PROOF_STATIC9"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC13",
        code: ['MBPR'],
        value: [
            "MEDICAL_PROOF_STATIC1",
            "MEDICAL_PROOF_STATIC2",
            "MEDICAL_PROOF_STATIC3",
            "MEDICAL_PROOF_STATIC4",
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC14",
        code: ['MCPR', 'OSPR'],
        value: [
            "MEDICARE_PROOF_STATIC1",
            "MEDICARE_PROOF_STATIC2"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC15",
        code: ['OHPR', 'PCPR'],
        value: [
            "OTHER_HEALTH_PROOF_STATIC1",
            "OTHER_HEALTH_PROOF_STATIC2"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC16",
        code: ['RLPR'],
        value: [
            "RELATION_PROOF_STATIC1",
            "RELATION_PROOF_STATIC2",
            "RELATION_PROOF_STATIC3",
            "RELATION_PROOF_STATIC4",
            "RELATION_PROOF_STATIC5"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC17",
        code: ['RDPR', 'NHPR', 'MFPR', 'DCSR'],
        valueA: [
            "RESIDENCY_PROOF_STATIC1",
            "RESIDENCY_PROOF_STATIC2",
            "RESIDENCY_PROOF_STATIC3",
            "RESIDENCY_PROOF_STATIC4",
            "RESIDENCY_PROOF_STATIC5",
            "RESIDENCY_PROOF_STATIC6",
            "RESIDENCY_PROOF_STATIC7"
        ],
        valueB: [
            "RESIDENCY_PROOF_STATIC8"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC18",
        code: ['RSPR', 'BUPR', 'FRPR', 'LIPR', 'LBPR', 'OTPR', 'IAPR', 'RPPR', 'RTPR', 'TRPR', 'VEPR', 'SRPR', 'PSPR'],
        value: [
            "RESOURCES_PROOF_STATIC1",
            "RESOURCES_PROOF_STATIC2",
            "RESOURCES_PROOF_STATIC3",
            "RESOURCES_PROOF_STATIC4",
            "RESOURCES_PROOF_STATIC5",
            "RESOURCES_PROOF_STATIC6",
            "RESOURCES_PROOF_STATIC7",
            "RESOURCES_PROOF_STATIC8",
            "RESOURCES_PROOF_STATIC9",
            "RESOURCES_PROOF_STATIC10",
            "RESOURCES_PROOF_STATIC11",
            "RESOURCES_PROOF_STATIC12",
            "RESOURCES_PROOF_STATIC13",
            "RESOURCES_PROOF_STATIC14",
            "RESOURCES_PROOF_STATIC15",
            "RESOURCES_PROOF_STATIC16"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC19",
        code: ['SNPR'],
        value: [
            "SSN_PROOF_STATIC1",
            "SSN_PROOF_STATIC2"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC20",
        code: ['APPR', 'ASPR'],
        value: [
            "ASSISTNG_PERSON_PROOF_STATIC1",
            "ASSISTNG_PERSON_PROOF_STATIC2",
            "ASSISTNG_PERSON_PROOF_STATIC3",
            "ASSISTNG_PERSON_PROOF_STATIC4"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC21",
        code: ['BLPR', 'BDPR', 'DVPR'],
        value: [
            "BLIND_DISABLE_PROOF_STATIC1"
        ], show: false
    }, {
        key: "TYPES_OF_PROOF_STATIC22",
        code: ['FCPR'],
        value: [
            "FC_PROOF_STATIC1",
            "FC_PROOF_STATIC2"
        ], show: false
    }, {
        key: 'TYPES_OF_PROOF_QFR_STATIC8', code: ['QKPR'], value
            : [
                "QFR_PROOF_STATIC1"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_BCC_STATIC8', code: ['BCPR'], value
            : [
                "BCC_PROOF_STATIC1"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC24', code: ['BTPR'], value
            : [
                "TAX_DEDUCTIONS_PROOF_STATIC1",
                "TAX_DEDUCTIONS_PROOF_STATIC2",
                "TAX_DEDUCTIONS_PROOF_STATIC3",
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC25', code: ['CTPR'], value
            : [
                "CARETAKER_PROOF_STATIC1",
                "CARETAKER_PROOF_STATIC2",
                "CARETAKER_PROOF_STATIC3",
            ], show: false
    },{
        key: 'TYPES_OF_PROOF_STATIC27', code: ['DTPR'], value
            : [
                "DEATH_PROOF_STATIC1",
                "DEATH_PROOF_STATIC2",
                "DEATH_PROOF_STATIC3"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC28', code: ['HCPR'], value
            : [
                "HEALTH_INSURANCE_PROOF_STATIC1",
                "HEALTH_INSURANCE_PROOF_STATIC2",
                "HEALTH_INSURANCE_PROOF_STATIC3"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC29', code: ['HHPR'], value
            : [
                "LIVE_WITH_PROOF_STATIC1",
                "LIVE_WITH_PROOF_STATIC2",
                "LIVE_WITH_PROOF_STATIC3"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC30', code: ['HIPR'], value
            : [
                "HEALTH_INSURANCE_PROOF_STATIC1",
                "HEALTH_INSURANCE_PROOF_STATIC2",
                "HEALTH_INSURANCE_PROOF_STATIC3"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC31', code: ['HUPR'], value
            : [
                "MED_FACILITY_PROOF_STATIC1"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC32', 
        code: ['KA', 'RSA', 'SMF'], 
        valueA: ["FINANCIAL_RESOURCES_PROOF_STATIC1"],
        valueB: ["FINANCIAL_RESOURCES_PROOF_STATIC2"],
        valueC: ["FINANCIAL_RESOURCES_PROOF_STATIC3"],
        show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC33', code: ['MEPR'], value
            : [
                "INACCESS_RESOURCES_PROOF_STATIC1"
            ], show: false
    }, {
        key: 'TYPES_OF_PROOF_STATIC34', code: ['MAPR'], value
            : [
                "MEDICAL_APPEAL_PROOF_STATIC1"
            ], show: false
    }
]