import { FormsConfiguration } from '@app/models/section.model';

import { NewStartComponent } from './start/new/start.component';
import { AddStartComponent } from './start/add/start.component';
import { EasyRMBStartComponent } from './start/easyRmb/easyRmb.component';
import { StartPresumptiveComponent } from './start/start-presumptive/start-presumptive.component';
import { ReportChangeComponent } from './report-change/report-change.component';
import { NewbornInformationComponent } from './household/newborn-information/newborn-information.component';
import { NewbornImmigrationComponent } from './household/newborn-immigration/newborn-immigration.component';
import { NewbornSummaryComponent } from './finish/newborn-summary/newborn-summary.component';
import { EndCoverageComponent } from './end-coverage/end-coverage.component';

import { HouseholdComponent } from './household/household-new/household.component';
import { HouseholdPresumptiveComponent } from './household/household-presumptive/household-presumptive.component';
import { PatientInformationComponent } from './household/patient-information/patient-information.component';
import { FacilityInformationComponent } from './household/facility-information/facility-information.component';
import { AppealRequestComponent } from './myAppeals/appealrequest/appeal-request.component'
import { RelationshipsComponent, SchoolAndEmploymentComponent, HealthCoverageComponent, RelationshipsPresumptiveComponent } from './household-information'
import { AppealDemographicsComponent } from './myAppeals/appealfiling/appealant-demographics/appeal-demographics.component';
import { AppealDetailsComponent } from './myAppeals/appealfiling/appealdetails/appeal-details.component';
import { AppealRepresentativeDetailsComponent } from './myAppeals/appealRepresentativeDetails/appeal-representative-details.component';
import { BccExtensionInformation } from './household/bccExtensionTreatment/bccExtensionInformation.component';
import { IncurredMedicalExpInfo } from './household/incurredMedicalExpenses/incurredMedicalExpInformation/incurredMedicalExpInformation.component';
import { IncurredMedicalExpDetails } from './household/incurredMedicalExpenses/incurredMedicalExpDetails/incurredMedicalExpDetails.component';

import {
  BurialComponent,
  LifeInsuranceComponent,
  LiquidComponent,
  RealEstateComponent,
  TransferComponent,
  VehicleComponent,
  OtherResourcesComponent
} from './assets';

import {
  DependentCareComponent,
  MedicareComponent,
  OtherExpensesComponent,
  SupportPaymentsComponent,
  UtilitiesComponent,
} from './expenses';

import { EmploymentComponent, OtherIncomeComponent, SelfEmploymentComponent, IncomeTaxComponent } from './income';

import {
  OtherBenefitsComponent,
  CommentsComponent,
  KtReferralComponent
} from './other';

import {
  SummaryComponent,
  SubmitComponent,
  StatementsComponent,
  SummaryPresumptiveComponent,
  SummaryAppealComponent,
  NewbornSignsubmitComponent,
  SignSubmitComponent,
  ChooseHealthPlanComponent
} from './finish';

import SACHS from './start/new/start.configuration';
import RMBSL from './start/easyRmb/easyRmb.configuration';
import HACHS from './start/add/start.configuration';
import SURCH from './report-change/report-change.configuration';
import PRHAS from './start/start-presumptive/start-presumptive.configuration'
import NBINF from './household/newborn-information/newborn-information.configuration';
import NBIMM from './household/newborn-immigration/newborn-immigration.configuration';
import NBSUM from './finish/newborn-summary/newborn-summary.configuration';
import BCEXS from './household/bccExtensionTreatment/bccExtensionInformation.configuration';
import IMEST from './household/incurredMedicalExpenses/incurredMedicalExpInformation/incurredMedicalExpInformation.configuration';
import IMEDT from './household/incurredMedicalExpenses/incurredMedicalExpDetails/incurredMedicalExpDetails.configuration';
import HHAPL from './household/household-new/household.configuration';
import PRAPL from './household/household-presumptive/household-presumptive.configuration';
import ADPIN from './household/patient-information/patient-information.configuration';
import ADNFI from './household/facility-information/facility-information.configuration';

import HHIND from './people/people-details/people.configuration';
import HHSUR from './people/people-details/people-sum.configuration';

import HHRLS from './household-information/relationships-new/relationships-new.configuration';
import HHHLC from './household-information/health-coverage/health-coverage.configuration';
import SCAEM from './household-information/school-employment/school-employment.configuration';
import PRRLS from './household-information/relationships-presumptive/relationships-presumptive.configuration';

import HHTJF from './tax-information/tax-details/tax.configuration';

import ICEMI from './income/employment/employment.configuration';
import ICOTH from './income/other-income/other-income.configuration';
import ICSEM from './income/self-employment/self-employment.configuration';
import PRIND from './income/income-tax/income-tax.configuration';

import XPDEP from './expenses/dependent-care/dependent-care.configuration';
import INMED from './expenses/medicare/medicare.configuration';
import XPOTH from './expenses/other-expenses/other-expenses.configuration';
import XPSUP from './expenses/support-payments/support-payments.configuration';
import XPUTL from './expenses/utilities/utilities.configuration';

import ASBUR from './assets/burial/burial.configuration';
import ASLIF from './assets/life-insurance/life-insurance.configuration';
import ASLIQ from './assets/liquid/liquid.configuration';
import ASPRP from './assets/real-estate/real-estate.configuration';
import ASCLD from './assets/transfer/transfer.configuration';
import ASVEH from './assets/vehicle/vehicle.configuration';
import OTHRS from './assets/other-resources/other-resources.configuration';

import SUCOM from './other/comments/comments.configuration';
import AIBAO from './other/other-benefits/other-benefits.configuration';
import OTKBR from './other/kt-referral/kt-referral.configurations';

import SUPSM from './finish/summary/summary.configuration';
import SUSIG from './finish/submit/submit.configuration';
import SUSGN from './finish/statements/statements.configuration';
import PRASM from './finish/summary-presumptive/summary-presumptive.configuration';
import APSUM from './finish/summary-appeal/summary-appeal.configuration';
import ADSUB from './finish/sign-submit/sign-submit.configuration';
import NBSUB from './finish/newborn-signsubmit/newborn-signsubmit.component.configuration'
import SUCOV from './end-coverage/end-coverage.configuration';
import SUHLP from './finish/choose-healthplan/choose-healthplan.configuration';
import APREQ from './myAppeals/appealrequest/appeal-request.configuration';
import PRSEM from './household-information/school-employment/school-employment.configuration';
import APDET from './myAppeals/appealfiling/appealdetails/appeal-details.configuration';
import ARPDT from './myAppeals/appealRepresentativeDetails/appeal-representative-details.configuration';
import APDEM from './myAppeals/appealfiling/appealant-demographics/appeal-demographics.configuration';

export const sections: FormsConfiguration = {
  // Start
  SACHS, // NEW, RESUME
  RMBSL, //EASY RMB
  HACHS, // ADD_PROGRAM
  PRHAS, // PRESUMPTIVE_APP_ONLY
  SURCH, // REPORT_CHANGE
  // Household
  HHAPL,
  PRAPL, // PRESUMPTIVE_APP_ONLY
  ADPIN, // PATIENT_INFORMATION
  ADNFI, // FACILITY_INFORMATION
  NBINF, // NEWBORN_INFORMATION
  NBIMM, // NEWBORN_IMMIGRATION
  NBSUM, // Newborn summary
  BCEXS, // BCC Extension Information
  IMEST, //IncurredMedicalExpensesInformation
  IMEDT, //IncurredMedicalExpDetails
  // People
  HHIND,
  HHSUR,
  // Household Information
  HHRLS,
  HHHLC,
  SCAEM,
  PRRLS, // PRESUMPTIVE_APP_ONLY
  PRSEM,
  // Tax Information
  HHTJF,
  // Income
  ICEMI,
  ICSEM,
  ICOTH,
  PRIND, // PRESUMPTIVE_APP_ONLY
  // Expenses
  XPDEP,
  INMED,
  XPOTH,
  XPSUP,
  XPUTL,
  // Assets
  ASBUR,
  ASLIF,
  ASLIQ,
  ASPRP,
  ASCLD,
  ASVEH,
  OTHRS,
  // Other
  SUCOM,
  AIBAO,
  OTKBR,
  // Finish
  SUPSM,
  SUSIG,
  SUSGN,
  PRASM, // PRESUMPTIVE_APP_ONLY
  ADSUB, // PRESUMPTIVE_APP_ONLY
  NBSUB, //NEWBORN_SIGN_SUBMIT
  SUCOV,
  SUHLP,
  APSUM, //AppealSummary
  //Appeals
  APREQ,
  APDET,
  ARPDT,
  APDEM
};

export {
  // Programs
  NewStartComponent,
  AddStartComponent,
  EasyRMBStartComponent,
  StartPresumptiveComponent,
  ReportChangeComponent,
  // Household
  HouseholdComponent,
  HouseholdPresumptiveComponent,
  PatientInformationComponent,
  NewbornInformationComponent,
  FacilityInformationComponent,
  NewbornImmigrationComponent,
  // Household Information
  RelationshipsComponent,
  HealthCoverageComponent,
  SchoolAndEmploymentComponent,
  RelationshipsPresumptiveComponent,
  // Income
  EmploymentComponent,
  SelfEmploymentComponent,
  OtherIncomeComponent,
  IncomeTaxComponent,
  // Expenses
  UtilitiesComponent,
  MedicareComponent,
  DependentCareComponent,
  SupportPaymentsComponent,
  OtherExpensesComponent,
  // Assets
  LiquidComponent,
  VehicleComponent,
  LifeInsuranceComponent,
  BurialComponent,
  RealEstateComponent,
  TransferComponent,
  OtherResourcesComponent,
  // Other
  OtherBenefitsComponent,
  CommentsComponent,
  KtReferralComponent,
  // Finish
  SummaryComponent,
  StatementsComponent,
  SubmitComponent,
  SummaryPresumptiveComponent,
  SummaryAppealComponent,
  SignSubmitComponent,
  NewbornSignsubmitComponent,
  NewbornSummaryComponent,
  EndCoverageComponent,
  ChooseHealthPlanComponent,
  //Appeals
  AppealRequestComponent,
  AppealDetailsComponent,
  AppealDemographicsComponent,
  AppealRepresentativeDetailsComponent,
   //BCCExtensionTreatment
  BccExtensionInformation,
   //IncurredMedicalExpenses
  IncurredMedicalExpInfo,
  //IncurredMedicalExpDetails
  IncurredMedicalExpDetails,
};
