import { identity } from 'ramda';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { individualsFilters } from '@app/helpers/individual.helpers';
import taxFilingLabels from '../details/tax-filing/tax-filing';
import taxFilerOutsideLabels from '../details/tax-filer-outside/tax-filer-outside';
import taxDependentsOutsideLabels from '../details/tax-dependents-outside/tax-dependents-outside';
import taxDeductionsLabels from '../details/tax-deductions/tax-deductions';
import jointFilerOutsideLabels from '../details/joint-filer-outside/joint-filer-outside';

const labels = {...taxFilingLabels, ...taxFilerOutsideLabels, ...taxDependentsOutsideLabels, ...taxDeductionsLabels, ...jointFilerOutsideLabels};
const removedLabels = {...labels, ...{removed: {taxFilingEndDate: 'indiv was removed as a tax filer '}}}
const indivName = ({ identifier }, { individual }) => {
  return `${individual.transform({ identifier })}`;
};

const taxDeductions = ({ deductionTypes }) => deductionTypes ? `${ deductionTypes }` : 'N/A';
const taxDep = ({ dependentsOusideHousehold }) => dependentsOusideHousehold ? `${ dependentsOusideHousehold }` : 'N/A';
const jointFiler = ({ jointFilerOutsideHousehold }) => jointFilerOutsideHousehold ? `${ jointFilerOutsideHousehold }` : 'N/A';

export default sectionConfiguration(
  null,
  identity,
  [ 'NAMESUFFIX', 'YESNO', 'MPDEDUCTIONS', 'RELATIONTYPE'],
  [
    { name: 'Household Member', pipe: 'domain', args: indivName },
    { name: 'Tax Filing<br> Status', prop: 'data.taxFilingStatus' },
    { name: 'Tax Dependent<br> Outside Household', prop: 'data', pipe: 'domain', args: taxDep },
    { name: 'Joint Filer<br> Outside Household', prop: 'data', pipe: 'domain', args: jointFiler },
    { name: 'Deduction Type', prop: 'data', pipe: 'domain', args: taxDeductions },
  ],
  {...{}, ...removedLabels},
  {
    individualsFilter: individualsFilters(identity),
  }
);
