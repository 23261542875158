<nh-form [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">

  <section [formGroup]="getControl('data')">
    <p [innerHTML]="'KATIE_BECKETT_STATIC_TEXT1' | translate"></p>
    <p>{{'KATIE_BECKETT_STATIC_TEXT2' | translate }} {{selectedChild?.name?.fullName}}{{'KATIE_BECKETT_STATIC_TEXT3' | translate }}</p>
    <p>{{'KATIE_BECKETT_STATIC_TEXT4' | translate }}</p>
    <p>{{'KATIE_BECKETT_STATIC_TEXT5' | translate }}</p>
    <h5 class="section-header">{{'KATIE_BECKETT_STATIC_TEXT6' | translate }}</h5>
    <hr>
    <nh-field id = "kt_houseMember" label="{{'KATIE_BECKETT_STATIC_TEXT7' | translate }}" class="label-section">
      <select nh aria-label="houseMember" name="houseMember" [attr.disabled]="exported?true:null" [items]="individuals18Under" bindValue="identifier" bindLabel="name.fullName"
        formControlName="houseMember"></select>
    </nh-field>
    <br />
    <div *ngIf="this.appType === 'RC' && this.showKbQuestion === true">
    <h5 class="section-header">{{'KATIE_BECKETT_STATIC_TEXT30' | translate }}</h5>
    <hr>
    <nh-field id = "kt_kbChange" label="{{'KATIE_BECKETT_STATIC_TEXT31' | translate }}" class="label-section">
      <select nh aria-label="kbChange" name="kbChange" [items]="tableCodeValue('MPKBCHNG', 'data.kbChange')" formControlName="kbChange"></select>
    </nh-field>
    <br />
    </div>
    <h5 class="section-header">{{'KATIE_BECKETT_STATIC_TEXT8' | translate }}</h5>
    <hr>
    <div class="mb-2 pb-2 pt-2">
      <label for="reasonsForApplyingToKb"><strong class="text-danger">*</strong>{{'KATIE_BECKETT_STATIC_TEXT9' | translate }} {{selectedChild?.name?.fullName}}{{'KATIE_BECKETT_STATIC_TEXT10' | translate }} </label>
      <div class="hint">{{'KATIE_BECKETT_STATIC_TEXT11' | translate }}</div>
      <nh-checkbox-list uniqueString="reasons" [checkboxGroup]="getControl('extras.helpers.reasonsForApplyingToKb')"
        [referenceTable]="tables.MPAPLKBRSN" (change)="onReasonSelect($event)"></nh-checkbox-list>
    </div>
    <span *ngIf="!isReasonSelected" class="form-text invalid-name d-block">{{'REQUIRED' | translate}}</span>
    <br>

    <section *ngIf='!isDonotWantToContinueSelected'>

      <h5 class="section-header">{{'KATIE_BECKETT_STATIC_TEXT12' | translate }}</h5>
      <hr>
      <p>{{'KATIE_BECKETT_STATIC_TEXT13' | translate }}</p>
      <label for="contact details"><strong class="text-danger">*</strong>{{'KATIE_BECKETT_STATIC_TEXT14' | translate }} {{selectedChild?.name?.fullName}}{{'KATIE_BECKETT_STATIC_TEXT15' | translate}} </label>
      <div>
        <div class="row align-items-center p-3">
          <div class="{{ headSelected ?'col card ktbecketta-address-card-selected':'col card ktbecketta-address-card'}}">
        
            <div class="row card-body" (click)="contactAddressDetails('HOH')">
        
              <div class="row align-items-center pl-3"><img class="col dot" aria-label="primaryIndividual1" src="../../../../../assets/images/Icons/KBReferralTileIcon.svg"></div>
        
              <div class="section-column">
                <h5 class="tile_header"><strong>{{primaryIndividual?.name?.fullName}}</strong></h5>
                <div *ngIf="primaryIndividual?.physicalAddress[0]?.address">
                  <p class="card-text">{{primaryIndividual?.physicalAddress[0]?.address?.addressLine1}}</p>
                  <p class="card-text">{{primaryIndividual?.physicalAddress[0]?.address?.addressLine2}}</p>
                  <p class="card-text">{{primaryIndividual?.physicalAddress[0]?.address?.city}},
                    {{primaryIndividual?.physicalAddress[0]?.address?.state?.code}} {{this.hohZip}}</p>
                  <p class="card-text">{{physicalHomeNumber}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div *ngIf="primaryIndividual?.assistingPerson?.name?.fullName" class="row align-items-center p-3">
        <div class="{{ assistantSelected ?'col card ktbecketta-address-card-selected':'col card ktbecketta-address-card'}}">
          <div class="row card-body" (click)="contactAddressDetails('ATH')">
            <div class="row align-items-center pl-3"><img class="col dot" aria-label="primaryIndividual2" src="../../../../../assets/images/Icons/KBReferralTileIcon.svg"></div>
            <div class="section-column">
              <h5 class="tile_header"><strong>{{primaryIndividual?.assistingPerson?.name?.fullName}}</strong></h5>
              <p class="card-text">{{primaryIndividual?.assistingPerson?.address?.addressLine1}}</p>
              <p class="card-text">{{primaryIndividual?.assistingPerson?.address?.addressLine2}}</p>
              <p *ngIf="primaryIndividual?.assistingPerson?.address?.city" class="card-text">{{primaryIndividual?.assistingPerson?.address?.city}},
                {{primaryIndividual?.assistingPerson?.address?.state?.code}} {{this.assisZip}}</p>
              <p *ngIf="primaryIndividual?.assistingPerson?.address?.addrApoFpo?.value" class="card-text">{{primaryIndividual?.assistingPerson?.address?.addrApoFpo?.value}},
                {{primaryIndividual?.assistingPerson?.address?.addrAaAeAp?.value}} {{primaryIndividual?.assistingPerson?.address?.zip}}</p>
              <p class="card-text">{{assistingHomeNumber}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center p-3">
        <div class="{{ otherSelected ?'col card ktbecketta-address-card-other-selected':'col card ktbecketta-address-card-other'}}">
          <div class="card-body" (click)="contactAddressDetails('OTH')">
            <h5 class="tile_header"><strong>{{'KATIE_BECKETT_STATIC_TEXT16' | translate }}</strong></h5>
          </div>
        </div>
      </div>
      </div>
      <span *ngIf="!headSelected && !assistantSelected && !otherSelected" class="form-text invalid-name d-block">{{'REQUIRED' | translate}}</span>
      <br />

      <section [formGroup]="getControl('data.contactInformation')" *ngIf="isOtherPersonInformationEnabled">
        <nh-name-address [addressname] = "'kt_contactInformation'" [personalName]="getControl('data.contactInformation.name')" [showPopup]="false"
          [tables]="tables">
        </nh-name-address>
        <br />

        <nh-contact-phones [contactPhoneName] = "'kt_contactPhones'" *ngIf="isEnabled('data.contactInformation.contactPhones')"
          [phoneNumbers]="getControl('data.contactInformation.contactPhones')"></nh-contact-phones>

        <nh-field id = "kt_kbContactEmail" *ngIf="isEnabled('data.contactInformation.kbContactEmail')" label="{{'KATIE_BECKETT_STATIC_TEXT17' | translate }}"
          class="label-section">
          <input type="text" name="kbContactEmail" formControlName="kbContactEmail" class="form-control"
            placeholder="name@address.com" maxlength="40">
        </nh-field>
      </section>

      <nh-field id = "kt_kbContactRelationship" *ngIf="isEnabled('data.kbContactRelationship')"
        label="{{'KATIE_BECKETT_STATIC_TEXT18' | translate }} {{selectedChild?.name?.fullName}}? " class="label-section">
        <select nh aria-label="kbContactRelationship" name="kbContactRelationship" [items]="tableCodeValue('PRIMCONTREL', 'data.kbContactRelationship')" formControlName="kbContactRelationship"></select>
      </nh-field>

      <nh-field id = "kt_kbContactInterpreterSwitch" *ngIf="isEnabled('data.kbContactInterpreterSwitch')"
        label="{{'KATIE_BECKETT_STATIC_TEXT19' | translate }}" class="label-section">
        <select nh aria-label="kbContactInterpreterSwitch" name="kbContactInterpreterSwitch" [items]="tableCodeValue('YESNO', 'data.kbContactInterpreterSwitch')" formControlName="kbContactInterpreterSwitch"></select>
      </nh-field>

      <nh-field id = "kt_kbContactLanguage" *ngIf="isEnabled('data.kbContactLanguage')" label="{{'KATIE_BECKETT_STATIC_TEXT20' | translate }}" class="label-section">
        <select nh aria-label="kbContactLanguage" name="kbContactLanguage" [items]="tableCodeValue('PRIMARYLANGUAGE', 'data.kbContactLanguage')" formControlName="kbContactLanguage"></select>
      </nh-field>

      <h5 class="section-header">{{'KATIE_BECKETT_STATIC_TEXT21' | translate }}</h5>
      <hr>
      <section [formGroup]="getControl('data.livingArrangement')">
        <div class="mb-2 pb-2 pt-2">
          <label for="pastYearLivingArrangement"><strong class="text-danger">*</strong>{{'HAS_1' | translate }}{{selectedChild?.name?.fullName}} {{'KATIE_BECKETT_STATIC_TEXT22' | translate }}</label>
          <div class="hint">{{'KATIE_BECKETT_STATIC_TEXT11' | translate }}</div>
          <nh-checkbox-list uniqueString="living" [checkboxGroup]="getControl('extras.helpers.pastYearLivingArrangement')"
            [referenceTable]="tables.MPKBLIVING"(change)="onPastYearLivArrangementSelect($event)"></nh-checkbox-list>
        </div>
        <span *ngIf="!isPastYearLivArrangementSelected" class="form-text invalid-name d-block">{{'REQUIRED' | translate}}</span>
        <br>
        <nh-field id = "kt_currentLivingArrangement" *ngIf="isEnabled('data.livingArrangement.currentLivingArrangement')"
          label="{{'KATIE_BECKETT_STATIC_TEXT23' | translate }} {{selectedChild?.name?.fullName}} {{'KATIE_BECKETT_STATIC_TEXT24' | translate }}" class="label-section">
          <select nh aria-label="currentLivingArrangement" name="currentLivingArrangement" [items]="tableCodeValue('MPKBLIVINGNOW', 'data.livingArrangement.currentLivingArrangement')"
            formControlName="currentLivingArrangement"></select>
        </nh-field>


        <!-- new field for physical address -->
        <!-- <section>
          <div [formGroup]="getControl('data.livingArrangement')" *ngIf="isEnabled('data.livingArrangement.kbOtherAddressFormat')">
            <p>{{'WHAT_IS' | translate }}{{selectedChild?.name?.fullName}}{{'KATIE_BECKETT_STATIC_TEXT28' | translate }}</p>
            <div *ngIf="isEnabled('data.livingArrangement.kbOtherAddressFormat')">
              <nh-field id = "kt_kbOtherAddressFormat" name="kbOtherAddressFormat" label="{{'ADDRESS_FORMAT' | translate }}" class="label-section">
                <select nh aria-label="kbOtherAddressFormat" name="kbOtherAddressFormat" [items]="tables.ADDRESSFORMATTYPES"
                  formControlName="kbOtherAddressFormat"></select>
              </nh-field>
            </div>
            <nh-name-address [addressname] = "'kt_kbOtherAddressFormat'" *ngIf="isEnabled('data.livingArrangement.otherAddress')"
              [addressFormat]="getControl('data.livingArrangement.kbOtherAddressFormat')"
              [address]="getControl('data.livingArrangement.otherAddress')" [showPopup]="false" [tables]="tables">
            </nh-name-address><br>
          </div>
        </section> -->


        <nh-field id = "kt_kbOtherPhysicalAddressSwitch" *ngIf="isEnabled('data.livingArrangement.kbOtherPhysicalAddressSwitch')"
          label="{{'DOES_2' | translate }}{{selectedChild?.name?.fullName}} {{'KATIE_BECKETT_STATIC_TEXT25' | translate }}"
          class="label-section">
          <select nh aria-label="kbOtherPhysicalAddressSwitch" (change)="addressSwitch(this.getControl('data.livingArrangement.kbOtherPhysicalAddressSwitch'))" name="kbOtherPhysicalAddressSwitch" [items]="tableCodeValue('YESNO', 'data.livingArrangement.kbOtherPhysicalAddressSwitch')"
            formControlName="kbOtherPhysicalAddressSwitch"></select>
        </nh-field>

        <section>
          <div [formGroup]="getControl('data.livingArrangement')" *ngIf="isEnabled('data.livingArrangement.kbAddressFormat')">
            <p>{{'KATIE_BECKETT_STATIC_TEXT26' | translate }} {{selectedChild?.name?.fullName}}.
              {{'KATIE_BECKETT_STATIC_TEXT27' | translate }} {{selectedChild?.name?.fullName}}{{'KATIE_BECKETT_STATIC_TEXT28' | translate }}</p>
            <div *ngIf="isEnabled('data.livingArrangement.kbAddressFormat')">
              <nh-field id = "kt_kbAddressFormat" name="kbAddressFormat" label="{{'ADDRESS_FORMAT' | translate }}" class="label-section">
                <select nh aria-label="kbAddressFormat" name="kbAddressFormat" [items]="tables.ADDRESSFORMATTYPES"
                  formControlName="kbAddressFormat"></select>
              </nh-field>
            </div>
            <nh-name-address [addressname] = "'kt_kbAddressFormat'" *ngIf="isEnabled('data.livingArrangement.address')"
              [addressFormat]="getControl('data.livingArrangement.kbAddressFormat')"
              [address]="getControl('data.livingArrangement.address')" [showPopup]="false" [tables]="tables">
            </nh-name-address><br>
          </div>
        </section>

        <nh-field id = "kt_kbSchoolSwitch" *ngIf="!this.isHideSchoolSw"
          label="{{'DOES_2' | translate}}{{selectedChild?.name?.fullName}} {{'KATIE_BECKETT_STATIC_TEXT29' | translate }}" class="label-section">
          <select nh aria-label="kbSchoolSwitch" name="kbSchoolSwitch" [items]="tableCodeValue('YESNO', 'data.livingArrangement.kbSchoolSwitch')" formControlName="kbSchoolSwitch"></select>
        </nh-field>
      </section>
    </section>
  </section>

</nh-form>