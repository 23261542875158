import { isNil , and } from 'ramda';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Location } from '@angular/common';

import { ContainerDirective } from '@app/directives/container/container.directive';
import { AuthService } from '@app/gateway/services/auth.service';
import { IdleService } from '@app/services/idle/idle.service';
import { StateService } from '@app/services/state/state.service';
import { StorageService } from '@app/services/storage/storage.service';
import { NotificationsService, ErrorsToken } from './services/notification/notification.service';

import { ifItems } from '@app/helpers/list.helper';

import { Notifications } from './models/partials.model';
import { TranslateService } from "@ngx-translate/core";
import { delay, filter, map, switchMap, tap } from "rxjs/operators";

import { NavigationEnd, Router } from '@angular/router';
import { CreateAccountService } from 'app/gateway/services/createAccount.service';
import { PeopleFacade } from './state/people/people.facade';
import { JoyrideService } from 'ngx-joyride';
import { ApiService } from './gateway/services/api.service';
import { SigninService } from './gateway/signin/signin.service';
import { ServiceConfigService } from './services/service-config.service';
import { Title } from '@angular/platform-browser';
import { isPartner } from '@app/helpers/mode.helpers';
import { UserProfileService } from "../app/gateway/user-profile/user-profile.service";

declare let gtag: Function;
@Component({
  selector: 'nh-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  @ViewChild(ContainerDirective, { static: true })
  top: ContainerDirective;
  notifications: Observable<Notifications>;
  identifier$ = this.state.identifier$;
  title$: Observable<string>;
  secondTitle$: Observable<string> = of('Getting Health Care with TennCare');
  sidebarOpened = false;
  isOnApp = false;
  isStepper = true;
  isAppNum = true;
  isPartner;
  isSummary$ = this.createAccountService.getIsSummary().pipe(delay(0));
  isHHTJF = false;
  isHHIND = false;
  blockTitle: string;
  isStartPage: boolean;
  isPresumptive = false;
  appType;
  userType;
  isMobileProgBarOpen = false;
  isSUSIG = false;
  isSUPSM = false;
  rmbTitle;
  screenHeight: number;
  screenWidth: number;
  isMobileView: boolean;
  mobileWidth = 480;
  isSiteWideAlertEnabled: boolean;
  isPPRenewalAlertEnabled: boolean;
  isPPDDARenewalAlertEnabled: boolean;
  isPPDentalBannerSwitchEnabled: boolean;
  siteWideText: string;
  renewalText: string;
  renewalPPText: string;
  enSiteWideAlert: string;
  enRenewalAlert: string;
  spRenewalAlert: string;
  enRenewalPPAlert: string;
  ppDdaBannerMessage: string;
  spRenewalPPAlert: string;
  dentalBenefitsText: string;
  dentalBenefitsPPText: string;
  enPartnerDentalBenefitsAlert: string;
  spPartnerDentalBenefitsAlert: string;
  enDentalBenefitsAlert: string;
  spDentalBenefitsAlert: string;
  spSiteWideAlert: string;
  isSubTitleEnabled$: Observable<boolean>;
  isEnvironmentAlertEnabled: boolean;
  isDentalBannerSwitchEnabled: boolean;
  isMasterBannerSwitchEnabled: boolean;
  environmentAlertText: string;
  environmentName: string;
  isBCCEXT = sessionStorage.getItem('appType') === 'BXT';
  isIME = sessionStorage.getItem('appType') === 'IME';
  ppDental = this.serverConfig.getServerConfig().isPPDentalBannerSwitchEnabled;
  renewalPPAlertSwitch = this.serverConfig.getServerConfig().renewalPPAlertSwitch;
  sitewidePPSwitch = this.serverConfig.getServerConfig().isSiteWidePPAlertEnabled;
  siteWideMasterSwitch;
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;


  constructor(
    private idle: IdleService,
    private state: StateService,
    private authService: AuthService,
    public translateService: TranslateService,
    private router: Router,
    private location: Location,
    private createAccountService: CreateAccountService,
    private people: PeopleFacade,
    private readonly joyrideService: JoyrideService,
    public apiService: ApiService,
    public storageService: StorageService,
    private signinService: SigninService,
    private serverConfig: ServiceConfigService,
    private titleService: Title,
    private userProfileService: UserProfileService,
    @Inject(ErrorsToken) private notify: NotificationsService
  ) {
    this.signinService.userLoggedIn.subscribe((res) => {
      if (res) {
        this.idle.start();
      } else {
        this.isAppNum = false;
        this.isOnApp = false;
      }

      const translateLang = sessionStorage.getItem('currentLang');
      if(translateLang){
        this.translateService.setDefaultLang(translateLang);
      }
      
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const getAppType = sessionStorage.getItem('appType')
        this.appType = sessionStorage.getItem('appType');
        gtag('config', 'UA-118572218-43', { page_path: event.urlAfterRedirects })
        gtag('config', 'G-3CWERHG4JP', { page_path: event.urlAfterRedirects })
        gtag('config', 'AW-686185300', { page_path: event.urlAfterRedirects })
        if (getAppType === 'APL') {
          if(event.url === "/user-profile/my-appeals") {
          this.isOnApp = false;
          sessionStorage.removeItem('appType')
        }
      };
    }

      this.siteWideMasterSwitch = this.showPPBannerMessage() && this.sitewidePPSwitch;
      this.isPPRenewalAlertEnabled = this.showPPBannerMessage() && this.renewalPPAlertSwitch;
      this.isPPDentalBannerSwitchEnabled = this.showPPBannerMessage() && this.ppDental;
      this.isPPDDARenewalAlertEnabled = this.showPPBannerMessage() && this.renewalPPAlertSwitch && (this.ppDdaBannerMessage && this.ppDdaBannerMessage !== "");
    });
    this.isSiteWideAlertEnabled = this.serverConfig.getServerConfig().isSiteWideAlertEnabled;
    this.isMasterBannerSwitchEnabled = this.serverConfig.getServerConfig().isMasterBannerSwitchEnabled;
    this.isPPRenewalAlertEnabled = this.showPPBannerMessage() && this.renewalPPAlertSwitch ;
    this.isEnvironmentAlertEnabled = this.serverConfig.getServerConfig().isEnvironmentAlertEnabled;
    this.isDentalBannerSwitchEnabled = this.serverConfig.getServerConfig().isDentalBannerSwitchEnabled;
    this.isPPDentalBannerSwitchEnabled = this.showPPBannerMessage() && this.ppDental;
    this.siteWideMasterSwitch = this.showPPBannerMessage() && this.sitewidePPSwitch;
    this.environmentName = this.serverConfig.getServerConfig().environmentName;
    this.enSiteWideAlert = this.serverConfig.getServerConfig().enSiteWideAlert;
    this.spSiteWideAlert = this.serverConfig.getServerConfig().spSiteWideAlert;
    this.enRenewalAlert = this.serverConfig.getServerConfig().enRenewalAlert;
    this.spRenewalAlert = this.serverConfig.getServerConfig().spRenewalAlert;
    this.enRenewalPPAlert = this.serverConfig.getServerConfig().enRenewalPPAlert;
    this.spRenewalPPAlert = this.serverConfig.getServerConfig().spRenewalPPAlert;
    this.enDentalBenefitsAlert = this.serverConfig.getServerConfig().enDentalBenefitsAlert;
    this.spDentalBenefitsAlert = this.serverConfig.getServerConfig().spDentalBenefitsAlert;
    this.enPartnerDentalBenefitsAlert = this.serverConfig.getServerConfig().enPartnerDentalBenefitsAlert;
    this.spPartnerDentalBenefitsAlert = this.serverConfig.getServerConfig().spPartnerDentalBenefitsAlert;
    const enEnvironmentAlertText = this.serverConfig.getServerConfig().enEnvironmentAlertText;
    const spEnvironmentAlertText = this.serverConfig.getServerConfig().spEnvironmentAlertText;
    const enPartnerSiteWideAlert = this.serverConfig.getServerConfig().enPartnerSiteWideAlert;
    const spPartnerSiteWideAlert = this.serverConfig.getServerConfig().spPartnerSiteWideAlert;
    const ppAccess = ["PRG", "BCC", "HPE", "TCA", "NBN", "ADF", "LOC", "TCC", "KPU"];
    this.ppDdaBannerMessage = this.serverConfig.getServerConfig().PP_DDA_BannerMessage;

    const appNum = sessionStorage.getItem('appNum');
    this.appType = sessionStorage.getItem('appType');
    this.userType = sessionStorage.getItem('userType');
    //const isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
    this.isPartner = isPartner(this.userType);
    this.isPresumptive = this.appType === 'HPE' || this.appType === 'PRG' || this.appType === 'BCC';
    this.notifications = this.notify.list.pipe(tap(ifItems(() => this.onActivate())));
    const hasPPAccess = ppAccess.includes(this.appType);

    if (this.translateService.getDefaultLang() === "sp") {
      this.siteWideMasterSwitch = this.showPPBannerMessage() && this.sitewidePPSwitch && (spPartnerSiteWideAlert !== null || this.spSiteWideAlert !== null);
      this.siteWideText = hasPPAccess ? spPartnerSiteWideAlert : this.spSiteWideAlert;
      this.isPPRenewalAlertEnabled = this.showPPBannerMessage()  && this.renewalPPAlertSwitch && (this.spRenewalPPAlert && this.spRenewalPPAlert !== "");
      this.isPPDentalBannerSwitchEnabled = this.showPPBannerMessage()  && this.ppDental && (this.spPartnerDentalBenefitsAlert && this.spPartnerDentalBenefitsAlert !== "");
      this.environmentAlertText = spEnvironmentAlertText;
      this.renewalText = this.spRenewalAlert;
      this.renewalPPText = this.showPPBannerMessage() ? this.spRenewalPPAlert : "";
      this.dentalBenefitsPPText = this.showPPBannerMessage() ? this.spPartnerDentalBenefitsAlert : "";
      this.dentalBenefitsText = this.spDentalBenefitsAlert;
    } else {
      this.siteWideMasterSwitch = this.showPPBannerMessage() && this.sitewidePPSwitch && (enPartnerSiteWideAlert !== null || this.enSiteWideAlert !== null);
      this.siteWideText = hasPPAccess ? enPartnerSiteWideAlert : this.enSiteWideAlert;
      this.isPPRenewalAlertEnabled = this.showPPBannerMessage() && this.renewalPPAlertSwitch && (this.enRenewalPPAlert && this.enRenewalPPAlert !== "");
      this.isPPDentalBannerSwitchEnabled = this.showPPBannerMessage() && this.ppDental && (this.enPartnerDentalBenefitsAlert && this.enPartnerDentalBenefitsAlert !== "");
      this.environmentAlertText = enEnvironmentAlertText;
      this.renewalText = this.enRenewalAlert;
      this.renewalPPText = this.showPPBannerMessage() ? this.enRenewalPPAlert : "";
      this.dentalBenefitsPPText = this.showPPBannerMessage() ? this.enPartnerDentalBenefitsAlert : "";
      this.dentalBenefitsText = this.enDentalBenefitsAlert;
    }
    this.translateService.onDefaultLangChange.subscribe((resLang) => {
      if (resLang.lang === "sp") {
        this.siteWideMasterSwitch = this.showPPBannerMessage() && this.sitewidePPSwitch && (spPartnerSiteWideAlert !== null || this.spSiteWideAlert !== null);
        this.siteWideText = hasPPAccess ? spPartnerSiteWideAlert : this.spSiteWideAlert;
        this.isPPRenewalAlertEnabled = this.showPPBannerMessage() && this.renewalPPAlertSwitch && (this.spRenewalPPAlert && this.spRenewalPPAlert !== "");
        this.isPPDentalBannerSwitchEnabled = this.showPPBannerMessage() && this.ppDental && (this.spPartnerDentalBenefitsAlert && this.spPartnerDentalBenefitsAlert !== "");
        this.environmentAlertText = spEnvironmentAlertText;
        this.renewalText = this.spRenewalAlert;
        this.renewalPPText = this.showPPBannerMessage() ? this.spRenewalPPAlert : "";
        this.dentalBenefitsPPText = this.showPPBannerMessage() ? this.spPartnerDentalBenefitsAlert : "";
        this.dentalBenefitsText = this.spDentalBenefitsAlert;
      } else {
        this.siteWideMasterSwitch = this.showPPBannerMessage() && this.sitewidePPSwitch && (enPartnerSiteWideAlert !== null || this.enSiteWideAlert !== null);;
        this.siteWideText = hasPPAccess ? enPartnerSiteWideAlert : this.enSiteWideAlert;
        this.isPPRenewalAlertEnabled = this.showPPBannerMessage() && this.renewalPPAlertSwitch && (this.enRenewalPPAlert && this.enRenewalPPAlert !== "");
        this.isPPDentalBannerSwitchEnabled = this.showPPBannerMessage() && this.ppDental && (this.enPartnerDentalBenefitsAlert && this.enPartnerDentalBenefitsAlert !== "");
        this.environmentAlertText = enEnvironmentAlertText;
        this.renewalText = this.enRenewalAlert;
        this.renewalPPText = this.showPPBannerMessage() ? this.enRenewalPPAlert : "";
        this.dentalBenefitsPPText = this.showPPBannerMessage() ? this.enPartnerDentalBenefitsAlert : "";
        this.dentalBenefitsText = this.enDentalBenefitsAlert; 
      }
      let allOptions = document.getElementsByTagName('option');
      for (let i = 0; i < allOptions.length; i++) {
        if (resLang.lang === "en" && allOptions[i].text == 'Elija una opción') {
          allOptions[i].text = 'Pick an option';

        }
        else if (resLang.lang === "sp" && allOptions[i].text == 'Pick an option') {
          allOptions[i].text = 'Elija una opción';

        }
      }

    })
    this.router.events.subscribe(() => {
      if (!this.location.path().includes('create-account-new/details') && sessionStorage.getItem('userData')) {
        this.createAccountService.remove()
      }
      if (appNum && this.location.path().includes(appNum)) {
        this.isAppNum = true;
      }
      this. isBCCEXT = sessionStorage.getItem('appType') === 'BXT'
      if(this.isBCCEXT && this.location.path().includes("/api/prov/atglance?")) {
        this.isOnApp = false;
        sessionStorage.removeItem('appType')
    }
    this.isIME = sessionStorage.getItem('appType') === 'IME';
    if(this.isIME && this.location.path().includes("/api/prov/atglance?")) {
      this.isOnApp = false;
      sessionStorage.removeItem('appType')
  }
    this.screenWidth = window.innerWidth;
    this.isMobileView = this.screenWidth <= this.mobileWidth;
    if(this.isMobileView && this.mobileUser && this.location.path().includes("/congrats/")){
      this.isOnApp = false;
    }
    });
    this.state.identifier$.subscribe(res => this.isOnApp = !isNil(res));
    this.idle.isSessionExpired$.subscribe(res => this.isOnApp = !res);
    this.state.saveAndExit$.subscribe(res => {
      if (res) {
        this.mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
        this.userType = sessionStorage.getItem('userType');
        if (this.userType === 'RP') {
          this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId()).then(() => {
            this.isOnApp = false;
            this.isAppNum = false;
          })
        } else if(this.mobileUser) {
          const caseNumber = sessionStorage.getItem('caseNum') ? sessionStorage.getItem('caseNum') : null;
          this.isOnApp = false;
          this.isAppNum = false;
          this.router.navigateByUrl('/renewMyBenefit?caseNumber=' + caseNumber)
        } else {
          this.router.navigate(['/user-profile']).then(() => {
            this.isOnApp = false;
            this.isAppNum = false;
          })
        }
      }
    })
    this.state.continueApp$.subscribe(res => this.isAppNum = res);

    this.title$ = this.state.title$.pipe(switchMap((res: string) => {
      return this.people.activeBlock$.pipe(map(blockresult => [res, blockresult]))
    })).pipe(switchMap((res) => {
      return this.createAccountService.getIsSummary().pipe(map(resp => [res[0], res[1], resp]))
    })).pipe(switchMap((res) => {
      return this.state.identifier$.pipe(map(resp => [res[0], res[1], res[2], resp]))
    })).pipe(switchMap((res: any) => {
      let title: string = res[0];
      const isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
      let isFinReassessment;
      let isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));
      const applicationType = createAccountService.sessionStorage.appType;
      if (!isNil(createAccountService.sessionStorage.isReassessment)) {
        isFinReassessment = JSON.parse(createAccountService.sessionStorage.isReassessment)
      }
      if (res[3] === 'HHTJF') {
        if (!res[2]) {
          switch (res[1] ?.identifier ?.recordId) {
            case 'taxfiling':
              title = 'Tax Filing';
              break;
            case 'taxdeductions':
              title = 'Tax Deductions';
              break;
            case 'taxdependoutside':
              title = 'Tax Dependents Outside Household';
              break;
            case 'jointfileroutside':
              title = 'Joint Filer Outside Household';
              break;
            case 'taxfileroutside':
              title = 'Tax Filer Outside Household';
              break;
          };
        } else {
          title = 'Tax Information';
        }
      }

      if (res[1] ?.identifier ?.sectionId === 'HHIND') {  
        title = 'People in Your Home';
        if (res[1] ?.identifier ?.recordId === 'additional') {
          title = res[2] ? 'People in Your Home' : 'Additional Details';
        } else if (res[1] ?.identifier ?.recordId === 'immigration') {
          title = res[2] ? 'People in Your Home' : 'Immigration';
        }
      } else if (!res[1] ?.identifier && res[3] === 'HHIND' && !sessionStorage.getItem('isAddingPerson')) {
        title = res[2] ? 'People in Your Home' : 'Immigration';
      } else if (!res[1] ?.identifier && res[3] === 'HHIND' && JSON.parse(sessionStorage.getItem('isAddingPerson'))) {
        title = 'People in Your Home';
        sessionStorage.removeItem('isAddingPerson');
      }

      if (isFinReassessment) {
        if ((res[0] === 'Sign Your Application' || res[0] === 'Sign Your Renewal')) {
          title = 'Signing Your Financial Reassessment for Medicaid Coverage';
        } else if ((res[0] === 'Application Summary')) {
          title = 'Financial Reassessment Summary';
        }
      } else if (isPreterm) {
        if ((res[0] === 'Sign Your Application' || res[0] === 'Sign Your Renewal')) {
          title = 'Sign Your Questions for Review';
        } else if ((res[0] === 'Application Summary') || (res[0]=== 'Renewal Summary')) {
          title = 'Questions for Review Summary';
        }
      } else if (applicationType === 'RC' && res[0] === 'Application Summary') {
        title = 'Change Summary';
      } else if ((applicationType === 'RB' || (isKepro && applicationType === 'REDETERMINATION')) && res[0] === 'Application Summary' && !isFinReassessment && !isPreterm) {
        title = 'Renewal Summary';
      } else if(this.isBCCEXT && res[0] === 'Sign Your Application') {
        title = 'Signing the BCC Treatment Plan Extension Form';
      } else if(this.isIME && res[0] === 'Sign Your Application') {
        title = 'Signing the Incurred Medical Expense';
      }

      if (res[0] && res[0] === 'Getting Health Care with TennCare' || res[0] === 'Select Programs') {
        const defaultLanguage = this.translateService.getDefaultLang();
        const mode = sessionStorage.getItem('appType');        
        if (defaultLanguage === "en") {
          title = (applicationType === 'RB' && !isPreterm && isNil(isFinReassessment)) || (isKepro && !isPreterm && (mode === 'REDETERMINATION' || mode === 'RB')) ? 'Renew My Benefits' : applicationType === 'RC' ? 'Report My Changes' : (applicationType === 'RB' && isFinReassessment) ? 'Financial Reassessment for Institutional Medicaid Coverage' : ((applicationType === 'RB' || applicationType === 'REDETERMINATION') && isPreterm) ? 'Complete Questions for Review' : 'Apply For Coverage';
        } else {
          title = (applicationType === 'RB' && !isPreterm) ? 'Renovar mis beneficios' : applicationType === 'RC' ? 'Reportar mis cambios.' : ((applicationType === 'RB' || applicationType === 'REDETERMINATION') && isPreterm) ? 'Complete las Preguntas para la Revisión' :'Solicitar cobertura';
        }
      }

      if (res[0] && res[0] === 'Sign Your Application' && !isPreterm) {
        if ((applicationType === 'RB'|| (applicationType === 'RENEW_BENEFITS' && isKepro)) && !isFinReassessment) {
          title = 'Sign Your Renewal'
        } else if (applicationType === 'RC' || (applicationType === 'CHANGE_REPORT' && isKepro)) {
          title = 'Sign Your Change'
        }
      }

      if (res[0] && res[0] === 'Before You Begin') {
        if ((applicationType === 'RB'|| (applicationType === 'RENEW_BENEFITS' && isKepro)) && !isFinReassessment && !isPreterm) {
          title = 'Renew My Benefits'
        } else if (isFinReassessment) {
          title = 'Financial Reassessment for Institutional Medicaid Coverage'
        } else if(isPreterm) {
          title = 'Complete Questions for Review'
        }
      }

      return of(title);
    })).pipe(switchMap((res) => {
      return this.state.title$.pipe(map(result => {
        let title = res;
        if (result == null) {
          title = null;
        }
        return title;
      }))
    })).pipe(delay(0));

    this.createAccountService.getIsStartPage().subscribe(res => this.isHHTJF = res);
    // tslint:disable-next-line: no-unused-expression
    typeof (this.joyrideService);
    this.createAccountService.getIsStepperOn().subscribe(res => this.isOnApp = res);
  }

  ngOnInit() {
    // this.metaService.createCanonicalURL();
    this.isSubTitleEnabled$ = this.state.blockLinks$.pipe(map((res) => {
      return (res ? (res.length <= 0) : true);
    })).pipe(delay(0));

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router)
    ).subscribe(() => {
      const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
      this.titleService.setTitle(title);
      if (document.querySelector('.ng-sidebar-container')) {
      document.querySelector('.ng-sidebar-container').scrollTop = 0;
      }
    });

    this.userProfileService.fomMobileApp$.subscribe((bool) => {
      if(sessionStorage.getItem('isMobileUser') === 'true') {
        this.mobileUser = true;
      } else {
        this.mobileUser = bool;
      }
    });
    const originalLang = document.documentElement.lang;
    const observer = new MutationObserver(() => {
      const currentLang = document.documentElement.lang;
      if (currentLang !== originalLang) {
        this.sendTranslateEventToGA(currentLang);
      }
    })
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] })
    this.userProfileService.showDDABannerMessage$.subscribe((bool) => {
      this.isPPDDARenewalAlertEnabled = this.showPPBannerMessage() && this.renewalPPAlertSwitch && (this.ppDdaBannerMessage && this.ppDdaBannerMessage !== "");
      if(bool && this.showPPBannerMessage()) {
        this.isPPRenewalAlertEnabled = false;
        this.renewalPPText= this.ppDdaBannerMessage
      }
    })
  }

  sendTranslateEventToGA(language: string) {
    if (window && gtag) {
      gtag('event', 'browserTranslate', {
        'event_category': 'Browser Translate',
        'event_label': 'Browser Translate Trigger',
        'value': language
      });
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  showPPBannerMessage() {
    const url = this.router.url;
    let showPPBanner = false
    showPPBanner = url.includes("/api/prov/atglance") ? true : false;
    this.isPartner = isPartner(this.userType);
    return and(isPartner, showPPBanner);;
  }

  enableBannerMessage() {
    const url = this.router.url;
    this.isPartner = isPartner(this.userType);
    if (!this.isPartner) {
      const onHomeScreen = sessionStorage.getItem('homeScreen') === 'true';
      const homeOrSignInScreen = url.includes("/signin") || onHomeScreen ? true : false;
      return homeOrSignInScreen;
    } else {
     return this.showPPBannerMessage();
    }
  }

  isLoggedIn() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const userName = sessionStorage.getItem('userName');
    const getCurrentUser = !isNil(currentUser) || !isNil(userName);

    const token = this.authService.tokenId;
    const hasToken = !isNil(token);
    return and(hasToken, getCurrentUser);
  }
  
  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  onToggle(open) {
    this.sidebarOpened = open;
  }

  onSignout(_) {
    const appNum = sessionStorage.getItem('appNum');
    if (!appNum && !this.location.path().includes(appNum)) {
      this.isAppNum = false;
    }
    this.isOnApp = false;
    if (this.isAppNum === false) {
      this.idle.signOut(false);
    }
  }

  onActivate() {
    this.top.element().scrollIntoView();
  }

  onProgressBarOpen() {
    this.isMobileProgBarOpen = !this.isMobileProgBarOpen;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }

  isSecondTitle(): boolean {
    if (sessionStorage.getItem('typesOfCoverage') === 'yes') {
      this.titleService.setTitle('Getting Health Care with TennCare');
    };
    return (sessionStorage.getItem('typesOfCoverage') && sessionStorage.getItem('typesOfCoverage') === 'yes')
  }
}