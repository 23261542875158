import { Component, AfterContentInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { equals, join, isNil, gt, isEmpty } from 'ramda';
import { setValue } from '@app/helpers/form.helpers';
import { setRequired } from '@app/helpers/form-control.helpers';
import { isNo, isYes, setValueNo, setValueYes } from '@app/helpers/tayn.helpers';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { toggles, swapControls, swapControl } from '@app/helpers/forms.helpers';
import { isOther, isSelf, isAssistingPerson, permissionCodes } from '@app/helpers/models.helpers';
import { codeEqual } from '@app/helpers/code.helpers';
import { minus9Days } from "@app/helpers/date.helpers";
import { getDataValue } from '@app/helpers/object.helpers';
import { ApiService } from '../../../../gateway/services/api.service';
import { ProcessService } from '../../../../services/process.service';
import { FormBuilder } from '@angular/forms';
import { ModalService } from '../../../../services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { formatTableData } from '@app/helpers/tables.helpers';
import { SigninService } from '@app/gateway/signin/signin.service';
import { isApplyForBenefits, isChangeReport, isRedeMode, isRmbRmc } from '@app/helpers/mode.helpers';
import Vs from '@app/services/validators/validators.service';
import { Title } from '@angular/platform-browser';
import { ServiceConfigService } from '@app/services/service-config.service';
import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';

@Component({
  templateUrl: './household.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})




export class HouseholdComponent extends SectionFormDirective implements AfterContentInit, OnDestroy {
  fullName;
  partnerName: string;
  showCommonCtrls = false;
  modifiedPAddress = false;
  showAssistingProof = false;
  showAssistingNotices = false;
  caseheadPopoverText;
  assistingPersonPop1;
  assistingPersonPop2;
  languagePopup;
  minimumDate = minus9Days();
  today: any = new Date();
  isFullCoverageApp = false;
  appType = sessionStorage.getItem('appType');
  user = sessionStorage.getItem('userType');
  isAHS = sessionStorage.getItem('isKepro');
  isPpRmbRmc = (this.appType === 'RB' || this.appType === 'RC') && this.user === 'RP';
  toolTip: boolean;
  letterLanguage;
  isMedicAidApp = this.user === 'RP' && this.appType === 'AFB' && !this.isAHS;
  addressSub: Subscription;
  defaultLanguage;
  translatedLang;
  appKey;
  appKey2;
  applicantBehalf1;
  isRmbRmc;
  isAssistingPersonNameSameAsHoh = false;
  isFinReassessment = false;
  isKepro = false;
  firstName;
  lastName;
  userData;
  ismode = sessionStorage.getItem('mode');
  viewTCC = JSON.parse(sessionStorage.getItem('ViewTCC'));
  currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
  showWellpoint: boolean = false;

  //For AHS AFB, RMC, RMC
  isAhsRmbOrRmc = (this.isAHS && this.user === 'RP') &&
    ( this.appType === 'REDETERMINATION' || this.appType === 'RB' ||  this.appType === 'CHANGE_REPORT' || this.appType === 'RC' || this.appType === 'AFB') &&
    (this.ismode === 'REDETERMINATION' || this.ismode === 'NEW' || this.ismode === 'CHANGE_REPORT');

  isAhsAfb = (this.isAHS && this.user === 'RP') && this.appType === 'AFB' && this.ismode === 'NEW';
  isAhsRmb = (this.isAHS && this.user === 'RP') && (this.appType === 'RB' || this.appType === 'REDETERMINATION') && this.ismode === 'REDETERMINATION';
  isAhsRmc = (this.isAHS && this.user === 'RP') && (this.appType === 'RC' || this.appType === 'CHANGE_REPORT') && this.ismode === 'CHANGE_REPORT';
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));

  ppViewTccHoHrmbrmc = ((this.appType === 'RC' || this.appType === 'RB') && this.user === 'RP' && this.viewTCC && !this.isFinReassessment);
  constructor(private modalService: ModalService, builder: FormBuilder, public processService: ProcessService, public apiService: ApiService,
    public translateService: TranslateService, public signinService: SigninService, private titleService: Title, private readonly serviceConfigService: ServiceConfigService, private cdr: ChangeDetectorRef) {
    super(builder);
    this.addressSub = this.processService.processAddressValidate.subscribe((action) => this.addressValidate(action));
    this.titleService.setTitle('Household Information');
  }

  addressValidate(action) {

    const appNum = this.applicationNumber;
    const formData = this.formGroup.value.data;
    const physical = getDataValue('physicalAddress.address', formData);
    const physicalFormat = getDataValue('physicalAddress.addressFormat', formData);
    const isSaveExit = equals(action, 'fromSaveExit') ? true : false;

    const mailing = getDataValue('mailingAddress.address', formData);
    const mailingFormat = getDataValue('mailingAddress.addressFormat', formData);

    const assisting = getDataValue('assistingPerson.address', formData);
    const assistingFormat = getDataValue('assistingPerson.addressFormat', formData);

    const postObject = {
      addressList: [
        { addressType: 'PHYSICAL', originalAddress: physical },
        { addressType: 'MAILING', originalAddress: mailing },
        { addressType: 'ASSISTING', originalAddress: assisting }
      ]
    };

    if (isNil(physical) || codeEqual('MI', physicalFormat)) {
      postObject.addressList.splice(0, 1);
    }

    if (isNil(mailing) || codeEqual('MI', mailingFormat)) {
      equals(postObject.addressList.length, 2) ? postObject.addressList.splice(0, 1) : postObject.addressList.splice(1, 1);
    }

    if (isNil(assisting) || codeEqual('MI', assistingFormat)) {
      equals(postObject.addressList.length, 1) ? postObject.addressList.splice(0, 1) : gt(postObject.addressList.length, 2) ? postObject.addressList.splice(2, 1) : postObject.addressList.splice(1, 1);
    }

    if (isEmpty(postObject.addressList)) {
      this.processService.addressValidated = true;
    } else {
      this.apiService.post('applications/' + appNum + '/sections/HHAPL/address', postObject, {headers: new HttpHeaders({'pageId': 'HHAPL'})}, true).subscribe((res) => {
        if (!isNil(res)) {
          this.modalService.openAddressModal(res, isSaveExit).subscribe(result => {
            if (result !== true) {
              if (result) {
                const phyIndex = result.findIndex(i => i.addressType === 'PHYSICAL');
                const mailIndex = result.findIndex(i => i.addressType === 'MAILING');
                const assisIndex = result.findIndex(i => i.addressType === 'ASSISTING');
                if (phyIndex === 0) {
                  const addressLine1 = this.getControl('data.physicalAddress.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.physicalAddress.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.physicalAddress.address.city', this.formGroup);
                  const state = this.getControl('data.physicalAddress.address.state', this.formGroup);
                  const zip = this.getControl('data.physicalAddress.address.zip', this.formGroup);

                  if (result[0].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[0].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[0].suggestedAddress.addressLine2);
                    city.setValue(result[0].suggestedAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[0].suggestedAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[0].suggestedAddress.zip);
                  } else if (result[0].selectedAddress === 'Original') {
                    addressLine1.setValue(result[0].originalAddress.addressLine1);
                    addressLine2.setValue(result[0].originalAddress.addressLine2);
                    city.setValue(result[0].originalAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[0].originalAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[0].originalAddress.zip);
                  }
                };
                if (mailIndex === 0 || mailIndex === 1) {
                  const idx = mailIndex;
                  const addressLine1 = this.getControl('data.mailingAddress.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.mailingAddress.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.mailingAddress.address.city', this.formGroup);
                  const state = this.getControl('data.mailingAddress.address.state', this.formGroup);
                  const zip = this.getControl('data.mailingAddress.address.zip', this.formGroup);

                  if (result[idx].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[idx].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[idx].suggestedAddress.addressLine2);
                    city.setValue(result[idx].suggestedAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].suggestedAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].suggestedAddress.zip);
                  } else if (result[idx].selectedAddress === 'Original') {
                    addressLine1.setValue(result[idx].originalAddress.addressLine1);
                    addressLine2.setValue(result[idx].originalAddress.addressLine2);
                    city.setValue(result[idx].originalAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].originalAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].originalAddress.zip);
                  }
                };

                if (assisIndex === 1 || assisIndex === 2) {
                  const idx = assisIndex;
                  const addressLine1 = this.getControl('data.assistingPerson.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.assistingPerson.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.assistingPerson.address.city', this.formGroup);
                  const state = this.getControl('data.assistingPerson.address.state', this.formGroup);
                  const zip = this.getControl('data.assistingPerson.address.zip', this.formGroup);

                  if (result[idx].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[idx].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[idx].suggestedAddress.addressLine2);
                    city.setValue(result[idx].suggestedAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].suggestedAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].suggestedAddress.zip);
                  } else if (result[idx].selectedAddress === 'Original') {
                    addressLine1.setValue(result[idx].originalAddress.addressLine1);
                    addressLine2.setValue(result[idx].originalAddress.addressLine2);
                    city.setValue(result[idx].originalAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].originalAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].originalAddress.zip);
                  }
                };
                this.processService.addressValidated = true;
              }
            }
          }, () => {
            this.processService.addressValidated = true;
          })
        } else {
          this.processService.addressValidated = true;
        }
      })
    }

  }

  isDateOfApplicationEnabled() {
    const val = this.getControl('data.physicalAddress.applicationSource');
    if (val.value != undefined && val.value != null && val.value.code != 'T')
      return true;
    return false;
  }

  showAssistPerson() {
    this.modalService.openAssistPersonTypes();
  }

  onChange(event, val?) {
    const name = this.getControl('data.assistingPerson.name');
    const resp = this.getControl('data.assistingPerson.responsibilities');
    const applicantBehalf = this.getControl('data.assistingPerson.responsibilities.applicantBehalf')
    const submitRenewal = this.getControl('data.assistingPerson.responsibilities.submitRenewal');
    const otherLetter = this.getControl('data.assistingPerson.responsibilities.letterOtherCommunication');
    const applicantAckSignSw = this.getControl('data.assistingPerson.applicantAckSignSw');
    const applicantSignName = this.getControl('data.assistingPerson.applicantSignName');
    const setVal = (c, v) => setValue(c, v);
    const setAppBehalf = () => setVal("A", applicantBehalf);
    const setSubRenewal = () => setVal("S", submitRenewal);
    const setOtherLtr = () => setVal("L", otherLetter);

    const resetAppBehalf = () => applicantBehalf.reset();
    const resetSubmitRen = () => submitRenewal.reset();
    const resetOther = () => otherLetter.reset();
    if (event.target ?.checked === true && val) {
      switch (val) {
        case 'S':
          setAppBehalf();
          break;
        case 'L':
          setAppBehalf();
          setSubRenewal();
          break;
        case 'Y':
          setAppBehalf();
          setSubRenewal();
          setOtherLtr();
          break;
      }
      resp.setErrors(null);
      applicantBehalf.setValidators(null);
      applicantBehalf.setErrors(null);
    } else if (event.target ?.checked === false && val) {
      switch (val) {
        case 'S':
          resetAppBehalf();
          break;
        case 'L':
          resetAppBehalf();
          resetSubmitRen();
          break;
        case 'Y':
          resetAppBehalf();
          resetSubmitRen();
          resetOther();
          break;
      }
      resp.setErrors({ required: true });
    } else if (event.code === 'AR' || event.code === 'OH') {

      applicantBehalf.setValidators(Vs.required);
      if(event.code === 'AR'){
        applicantAckSignSw.enable();
        applicantAckSignSw.setValue('');
        applicantAckSignSw.setValidators( Vs.required);
        applicantAckSignSw.updateValueAndValidity();
        applicantSignName.enable();
        applicantSignName.setValue('');
        applicantSignName.setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
      }

    } else if(event.code === 'Y' && val === 'AssistPerson' && this.isKepro){
      name.patchValue({firstName: this.firstName, lastName:this.lastName })
    } else {

      //MP condition
      const condition1 = (this.user !== 'RP' || this.isAhsRmbOrRmc || this.ppViewTccHoHrmbrmc) && (this.getValue('data.assistingOnBehalf').value === 'Assisting Person' || this.getValue('data.assistingOnBehalf').value === 'Persona que Ayuda' || this.isY('data.addAssistingPersonToApp'));
      //PP FRM condition
      const condition2 = ((this.isY('data.ppAddAssistingPersonToApp') || this.isY('data.ppAssistingOnBehalf'))) && (this.user === 'RP' && (this.appType === 'RB' || this.appType === 'RC' || this.appType === 'REDETERMINATION' || this.isFinReassessment) && !this.isAHS);

      //PP FC condition
      const condition3 = (this.isY('data.ppAddAssistingPersonToApp') || this.isY('data.ppAssistingOnBehalf')) && (this.user === 'RP' && this.appType === 'AFB' && !this.isAHS);
      const relationship = this.getControl('data.assistingPerson.relationship');

      if( condition1 || condition2 || condition3) {
        relationship.enable();
        relationship.updateValueAndValidity();
      } else{
        relationship.disable();
        relationship.updateValueAndValidity();
      }
      applicantBehalf.clearValidators();
      applicantAckSignSw.disable();
      applicantAckSignSw.updateValueAndValidity();
      applicantAckSignSw.clearValidators();
      applicantSignName.disable();
      applicantSignName.clearValidators();

    }
  }


  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  getLangData(data) {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (this.translatedLang === 'sp') {
        element.description = element.spDescription;
        element.value = element.spDescription;
      }
    }
    return data;
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  ngAfterContentInit() {
    this.wellpointData();
    const isAFB = isApplyForBenefits(this.applicationType);
    this.isFullCoverageApp = (isAFB && this.user === 'RP') ? true : false;
    this.isRmbRmc = isRmbRmc(this.mode);
    this.applicantBehalf1 = isRedeMode(this.mode) ? 'APPLICANT_BEHALF_1' : (isChangeReport(this.mode) ? 'APPLICANT_BEHALF_1' : 'APPLICANT_BEHALF_3');
    this.appKey = isRedeMode(this.mode) && !this.isPreterm ? 'RENEWAL_NAME' : isRedeMode(this.mode) && this.isPreterm ? 'QFR' : (isChangeReport(this.mode) ? 'CHANGE_TEXT' : 'APPLICATION_TEXT');
    this.appKey2 = isRedeMode(this.mode) ? 'RENEWAL_NAME' : (isChangeReport(this.mode) ? 'CHANGE_TEXT2' : 'APPLICATION_TEXT');
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.languageCheck(this.defaultLanguage);
    this.translatedLang = this.defaultLanguage
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang
      this.languageCheck(res.lang);
    })

    if(this.user !== 'RP' || this.isAhsRmbOrRmc || this.ppViewTccHoHrmbrmc){
      const ppAssistingOnBehalf = this.getControl('data.ppAssistingOnBehalf');
      const ppAddAssistingPersonToApp = this.getControl('data.ppAddAssistingPersonToApp');
      ppAddAssistingPersonToApp.disable();
      ppAddAssistingPersonToApp.clearValidators();
      ppAddAssistingPersonToApp.updateValueAndValidity();
      ppAssistingOnBehalf.disable();
      ppAssistingOnBehalf.clearValidators();
      ppAssistingOnBehalf.updateValueAndValidity();
    }

    if(!this.isAHS){
      const applicationDt = this.getControl('data.applicationDt');
      applicationDt.disable();
      applicationDt.clearValidators();
      applicationDt.updateValueAndValidity();
    }

    if(this.user === 'RP' && (this.appType === 'AFB' || this.isFinReassessment) && !this.isAHS){
      const assistingOnBehalf = this.getControl('data.assistingOnBehalf');
      const addAssistingPersonToApp = this.getControl('data.addAssistingPersonToApp');
      addAssistingPersonToApp.disable();
      addAssistingPersonToApp.clearValidators();
      addAssistingPersonToApp.updateValueAndValidity();
      assistingOnBehalf.disable();
      assistingOnBehalf.clearValidators();
      assistingOnBehalf.updateValueAndValidity();
    }
    // if((this.getValue('data.assistingOnBehalf').value != 'Assisting Person' || this.getValue('data.assistingOnBehalf').value != 'Persona que Ayuda') && this.isY('data.addAssistingPersonToApp')){
    //   this.getControl('data.assistingPerson.ackSignSw').disable();
    //   this.getControl('data.assistingPerson.ackSignSw').clearValidators();
    //   this.getControl('data.assistingPerson.assistingPersonSignName').disable();
    //   this.getControl('data.assistingPerson.assistingPersonSignName').clearValidators();
    //   this.getControl('data.assistingPerson.applicantAckSignSw').disable();
    //   this.getControl('data.assistingPerson.applicantAckSignSw').clearValidators();
    //   this.getControl('data.assistingPerson.applicantSignName').disable();
    //   this.getControl('data.assistingPerson.applicantSignName').clearValidators();
    // }
    const dateOfApplication = this.getControl('data.dateOfApplication');
    if (!this.isMedicAidApp) {
      dateOfApplication.disable();
    }
    const primaryLanguage = this.getControl('data.languagePreference.primaryLanguage');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (primaryLanguage.value) {
        const tableVar = this.tables.PRIMARYLANGUAGE.filter((item) => item.code === primaryLanguage.value.code);
        this.tableData(tableVar);
        primaryLanguage.patchValue(tableVar[0]);
      }
    }
    const letterLanguage = this.getControl('data.languagePreference.letterLanguage');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (letterLanguage.value) {
        const tableVar = this.tables.WRITTENLANGUAGE.filter((item) => item.code === letterLanguage.value.code);
        this.tableData(tableVar);
        letterLanguage.patchValue(tableVar[0]);
      }
    }
    const otherLanguageName = this.getControl('data.languagePreference.otherLanguageName');
    const addMailingInfo = this.getControl('extras.helpers.addMailingInfo');
    const relationship = this.getControl('data.assistingPerson.relationship');
    if (!this.isMedicAidApp) {
      if (relationship.value) {

        const tableVar = this.tables.AUTHREPRELATIONSHIP.filter((item) => item.code === relationship.value.code);
        this.tableData(tableVar);
        relationship.patchValue(tableVar[0]);
      }
    }
    //  else {
    //   relationship.disable();
    //   relationship.updateValueAndValidity();
    // }


    const assistingOnBehalf = this.getControl('data.assistingOnBehalf');
    const ppAddAssistingPersonToApp = this.getControl('data.ppAddAssistingPersonToApp');
    const addAssistingPersonToApp = this.getControl('data.addAssistingPersonToApp');
    // if (!this.isMedicAidApp && isNil(this.isFinReassessment && !this.isKepro)) {
    //   if (ppAddAssistingPersonToApp.value) {
    //     const tableVar = this.tables.MPASSTPER.filter((item) => item.code === ppAddAssistingPersonToApp.value.code);
    //     // this.tableData(tableVar);
    //     ppAddAssistingPersonToApp.patchValue(tableVar[0]);
    //   }
    // }

    if (!this.isMedicAidApp && isNil(this.isFinReassessment && !this.isKepro)) {
      if (assistingOnBehalf.value) {
        const tableVar = this.tables.MPASSTPER.filter((item) => item.code === assistingOnBehalf.value.code);
        // this.tableData(tableVar);
        assistingOnBehalf.patchValue(tableVar[0]);
      }
    }

    const homeless = this.getControl('data.homeless');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (homeless.value) {
        const tableVar = this.tables.YESNO.filter((item) => item.code === homeless.value.code);
        this.tableData(tableVar);
        homeless.patchValue(tableVar[0]);

      }
    }

    const sahPrgSw = this.getControl('data.sahPrgSw');
    if (sahPrgSw.value) {
      if  (sahPrgSw.value?.code === 'Y') {
        this.getControl('data.physicalAddress.address').disable();
      }
      const tableVar = this.tables.YESNO.filter((item) => item.code === sahPrgSw.value.code);
      this.tableData(tableVar);
      sahPrgSw.patchValue(tableVar[0]);
    }
    const sahCountyCd = this.getControl('data.sahCountyCd');
    if (sahCountyCd.value) {
      const tableVar = this.tables.COUNTY.filter((item) => item.code === sahCountyCd.value.code);
      this.tableData(tableVar);
      sahCountyCd.patchValue(tableVar[0]);
    }

    const addToApplication = this.getControl('data.addAssistingPersonToApp');
    const ppAssistingOnBehalf = this.getControl('data.ppAssistingOnBehalf');

    if (!this.isMedicAidApp && !this.isFinReassessment) {
      if (addToApplication.value) {
        if  (addToApplication.value?.code === 'Y') {
          relationship.enable();
        }
        const tableVar = this.tables.YESNO.filter((item) => item.code === addToApplication.value.code);
        this.tableData(tableVar);
        addToApplication.patchValue(tableVar[0]);
      }
    }

    if ((this.user !== 'RP' || this.isAhsRmbOrRmc || this.ppViewTccHoHrmbrmc) ) {
      addToApplication.clearValidators();
      addToApplication.updateValueAndValidity();
    }

    if ((this.user === 'RP' && (this.appType === 'AFB' || this.isFinReassessment) && !this.isAHS)) {
      ppAssistingOnBehalf.clearValidators();
      ppAssistingOnBehalf.updateValueAndValidity();
      if(ppAssistingOnBehalf.value){
        if  (ppAssistingOnBehalf.value?.code === 'Y') {
          relationship.enable();
        }
      }
    }


    if( ((this.user !=='RP' || this.isAhsRmbOrRmc || this.ppViewTccHoHrmbrmc) && (assistingOnBehalf.value?.code === 'A' || addAssistingPersonToApp.value?.code === 'Y'))
      || ((this.user === 'RP' && this.isFinReassessment) && (ppAssistingOnBehalf.value?.code === 'Y' || ppAddAssistingPersonToApp.value?.code === 'Y'))
      || ((this.user === 'RP' && this.appType === 'AFB' && !this.isAHS) && (ppAssistingOnBehalf.value?.code === 'Y' || ppAddAssistingPersonToApp.value?.code === 'Y')) ) {
      this.getControl('data.assistingPerson.ackSignSw').enable();
      this.getControl('data.assistingPerson.assistingPersonSignName').enable();
      if(assistingOnBehalf.value?.code === 'A') {
        this.getControl('data.assistingPerson.ackSignSw').setValidators( Vs.required);
        this.getControl('data.assistingPerson.ackSignSw').updateValueAndValidity();
        this.getControl('data.assistingPerson.assistingPersonSignName').setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
        this.getControl('data.assistingPerson.assistingPersonSignName').updateValueAndValidity();
      }

    }

    if( ((this.user !=='RP' || this.isAhsRmbOrRmc || this.ppViewTccHoHrmbrmc) && (assistingOnBehalf.value?.code === 'S' && addAssistingPersonToApp.value?.code === 'N'))
      || ((this.user === 'RP' && this.isFinReassessment) && (ppAssistingOnBehalf.value?.code === 'N' && ppAddAssistingPersonToApp.value?.code === 'N'))
      || ((this.user === 'RP' && this.appType === 'AFB' && !this.isAHS) && (ppAssistingOnBehalf.value?.code === 'N' && ppAddAssistingPersonToApp.value?.code === 'N')) ){
      this.getControl('data.assistingPerson.ackSignSw').disable();
      this.getControl('data.assistingPerson.assistingPersonSignName').disable();
      this.getControl('data.assistingPerson.applicantAckSignSw').disable();
      this.getControl('data.assistingPerson.applicantSignName').disable();
    }


    const helpDuration = this.getControl('data.assistingPerson.helpDuration');
    const name = this.getControl('data.assistingPerson.name');
    const organizationName = this.getControl('data.assistingPerson.organizationName');
    const idNumber = this.getControl('data.assistingPerson.idNumber');
    const applicationSource = this.getControl('data.physicalAddress.applicationSource');

    if(!applicationSource.value && this.isKepro){
      const tableVar = this.tables.APRNSOURCE;
      this.tableData(tableVar);
      applicationSource.patchValue(tableVar[3]);
      applicationSource.updateValueAndValidity();
    }


    this.registerToggle(applicationSource, (code) => sessionStorage.setItem('appSource', code?.value));

    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (applicationSource.value) {
        const tableVar = this.tables.APRNSOURCE.filter((item) => item.code === applicationSource.value.code);
        this.tableData(tableVar);
        applicationSource.patchValue(tableVar[0]);
      }
    }

    const bestTimeP = this.getControl('data.physicalAddress.bestTime');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (bestTimeP.value) {
        const tableVar = this.tables.CONTACTTIME.filter((item) => item.code === bestTimeP.value.code);
        this.tableData(tableVar);
        bestTimeP.patchValue(tableVar[0]);

      }
    }
    const addressFormatP = this.getControl('data.physicalAddress.addressFormat');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (addressFormatP.value) {
        const tableVar = this.tables.ADDRESSFORMATTYPES.filter((item) => item.code === addressFormatP.value.code);
        this.tableData(tableVar);
        addressFormatP.patchValue(tableVar[0]);

      }
    }
    const physicalAddress = this.getControl('data.physicalAddress.address');
    const physicalAddressState = this.getControl('data.physicalAddress.address.state');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (physicalAddressState.value) {
        const tableVar = this.tables.STATE.filter((item) => item.code === physicalAddressState.value.code);
        this.tableData(tableVar);
        physicalAddressState.patchValue(tableVar[0]);

      }
    }
    const physicalAddressCounty = this.getControl('data.physicalAddress.address.county');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (physicalAddressCounty.value) {
        const tableVar = this.tables.COUNTY.filter((item) => item.code === physicalAddressCounty.value.code);
        this.tableData(tableVar);
        physicalAddressCounty.patchValue(tableVar[0]);
      }
    }
    const assistPersonhelpDuration = this.getControl('data.assistingPerson.helpDuration');
    if (assistPersonhelpDuration.value) {
      const tableVar = this.tables.AUTHREPTIME.filter((item) => item.code === assistPersonhelpDuration.value.code);
      this.tableData(tableVar);
      assistPersonhelpDuration.patchValue(tableVar[0]);
    }

    const physicalAddressaddressAPO = this.getControl('data.physicalAddress.address.addrApoFpo');
    this.setDropdownValues(physicalAddressaddressAPO, this.tables.MILITARYPOCODES)


    const physicalAddressaddressAaAe = this.getControl('data.physicalAddress.address.addrAaAeAp');
    this.setDropdownValues(physicalAddressaddressAaAe, this.tables.STATEMILITARY)

    const mailingAddressaddressAPO = this.getControl('data.mailingAddress.address.addrApoFpo');
    this.setDropdownValues(mailingAddressaddressAPO, this.tables.MILITARYPOCODES)


    const mailingAddressaddressAaAe = this.getControl('data.mailingAddress.address.addrAaAeAp');
    this.setDropdownValues(mailingAddressaddressAaAe, this.tables.STATEMILITARY)

    const assistingAddressaddressAPO = this.getControl('data.assistingPerson.address.addrApoFpo');
    this.setDropdownValues(assistingAddressaddressAPO, this.tables.MILITARYPOCODES)


    const assistingAddressaddressAaAe = this.getControl('data.assistingPerson.address.addrAaAeAp');
    this.setDropdownValues(assistingAddressaddressAaAe, this.tables.STATEMILITARY)


    const addressFormatM = this.getControl('data.mailingAddress.addressFormat');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (addressFormatM.value) {
        const tableVar = this.tables.ADDRESSFORMATTYPES.filter((item) => item.code === addressFormatM.value.code);
        this.tableData(tableVar);
        addressFormatM.patchValue(tableVar[0]);

      }
    }
    const mailingAddress = this.getControl('data.mailingAddress.address');
    const mailingAddressState = this.getControl('data.mailingAddress.address.state');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (mailingAddressState.value) {
        const tableVar = this.tables.STATE.filter((item) => item.code === mailingAddressState.value.code);
        this.tableData(tableVar);
        mailingAddressState.patchValue(tableVar[0]);

      }
    }
    const mailingAddressCounty = this.getControl('data.mailingAddress.address.county');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (mailingAddressCounty.value) {
        const tableVar = this.tables.COUNTY.filter((item) => item.code === mailingAddressCounty.value.code);
        this.tableData(tableVar);
        mailingAddressCounty.patchValue(tableVar[0]);
      }
    }


    const addressFormatA = this.getControl('data.assistingPerson.addressFormat');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (addressFormatA.value) {
        const tableVar = this.tables.ADDRESSFORMATTYPES.filter((item) => item.code === addressFormatA.value.code);
        this.tableData(tableVar);
        addressFormatA.patchValue(tableVar[0]);

      }
    }
    const assistAddress = this.getControl('data.assistingPerson.address');
    const assistingAddressState = this.getControl('data.assistingPerson.address.state');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (assistingAddressState.value) {
        const tableVar = this.tables.STATE.filter((item) => item.code === assistingAddressState.value.code);
        this.tableData(tableVar);
        assistingAddressState.patchValue(tableVar[0]);

      }
    }
    const assistingAddressCounty = this.getControl('data.assistingPerson.address.county');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (assistingAddressCounty.value) {
        const tableVar = this.tables.COUNTY.filter((item) => item.code === assistingAddressCounty.value.code);
        this.tableData(tableVar);
        assistingAddressCounty.patchValue(tableVar[0]);
      }
    }

    const bestTimeA = this.getControl('data.assistingPerson.bestTime');
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (bestTimeA.value) {
        const tableVar = this.tables.CONTACTTIME.filter((item) => item.code === bestTimeA.value.code);
        this.tableData(tableVar);
        bestTimeA.patchValue(tableVar[0]);

      }
    }

    const email = this.getControl('data.assistingPerson.email');
    const bestTime = this.getControl('data.assistingPerson.bestTime');
    const contactPhones = this.getControl('data.assistingPerson.contactPhones');
    const casehead = this.getControl('data.casehead');
    const responsibilities = this.getControl('data.assistingPerson.responsibilities');
    const applicantBehalf = responsibilities.get('applicantBehalf');
    const submitRenewal = responsibilities.get('submitRenewal');
    const otherLetter = responsibilities.get('letterOtherCommunication');
    const yourBehalf = responsibilities.get('yourBehalf');

    const assistControls = [
      name,
      organizationName,
      idNumber,
      addressFormatA,
      assistAddress,
      email,
      bestTime,
      contactPhones,
      relationship,
      responsibilities
    ];

    const isHomeless = [mailingAddress, addressFormatM];
    const notHomeless = [physicalAddress, addressFormatP, addMailingInfo];
    const permissionsControls = [applicantBehalf, submitRenewal, otherLetter, yourBehalf]

    this.registerToggle(
      addToApplication,
      toggles([
        swapControls(assistControls, isYes),
        swapControl(helpDuration, isYes)
      ])
    );

    if(this.user === 'RP' && (this.appType === 'AFB' || this.isFinReassessment) && !this.isAHS){
      this.registerToggle(
        ppAssistingOnBehalf,
        toggles([
          swapControls(assistControls, isYes),
          swapControl(helpDuration, isYes)
        ])
      );
    }

    if(this.user === 'RP' && (this.appType === 'AFB' || this.isFinReassessment) && !this.isAHS){
      this.registerToggle(
        ppAddAssistingPersonToApp,
        toggles([
          swapControls(assistControls, isYes),
          swapControl(helpDuration, isYes)
        ])
      );
    }


    physicalAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
    });
    mailingAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
    });
    assistAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
    });

    this.registerToggle(relationship,
      toggles([
        swapControls(permissionsControls, permissionCodes)
      ])
    );


    if (this.isMedicAidApp || this.isFinReassessment) {
      this.registerToggle(ppAddAssistingPersonToApp,
        toggles([
          swapControl(ppAssistingOnBehalf, isNo),
          swapControls(assistControls, isYes)
        ])
      );
    } else {
      this.registerToggle(assistingOnBehalf,
        toggles([
          swapControl(addToApplication, isSelf),
          swapControls(assistControls, isAssistingPerson)
        ])
      );
    }

    this.registerToggle(
      homeless,
      toggles([
        swapControls(notHomeless, isNo),
        swapControls(isHomeless, isYes)
      ])
    );
    this.registerToggle(primaryLanguage, swapControl(otherLanguageName, isOther));

    this.registerToggle(addMailingInfo, swapControls([mailingAddress, addressFormatM], isNo));

    if (this.individuals.length) {
      if (this.individuals[0].name.firstName) {
        const { firstName, middleInitial, lastName, suffix } = this.individuals[0].name;
        this.fullName = join(' ', [firstName, middleInitial, lastName, suffix ? suffix.value : ''])
      }
    };

    const createName = () => {
      const { firstName, middleInitial, lastName, suffix } = casehead.value;
      this.fullName = join(' ', [firstName, middleInitial, lastName, suffix ? suffix.value : '']);
    }

    if (casehead.value) createName();

    this.registerToggle(casehead, () => createName());
    if (!mailingAddress.value.zip && !physicalAddress.value.zip) {
      addMailingInfo.setValue(({ code: '', value: '' }));
      setRequired(addMailingInfo);
    } else if (!mailingAddress.value.zip) {
      setValueYes(addMailingInfo);
    } else if (mailingAddress.value.zip) {
      setValueNo(addMailingInfo)
    }

    //Why specifically to MP and FRM? This is not setting the value for addmailinfo in FC
    // if (!this.isMedicAidApp) {
    if (addMailingInfo.value.code !== '') {
      const tableVar = this.tables.YESNO.filter((item) => item.code === addMailingInfo.value.code);
      if (tableVar.length > 0) {
        tableVar[0].value = addMailingInfo.value.value;
        addMailingInfo.setValue(tableVar[0]);
        dateOfApplication.disable();
      }
    }
    // }


    if (this.appType != 'PRG' && this.appType != 'HPE' && this.appType != 'BCC') {
      dateOfApplication.disable();
    }


    //MP help duration disable
    if (this.getValue('data.assistingOnBehalf').value === 'Assisting Person' || this.getValue('data.assistingOnBehalf').value === 'Persona que Ayuda') {
      helpDuration.clearValidators();
    }

    if( ((this.getValue('data.assistingOnBehalf').code === 'S') && this.isY('data.addAssistingPersonToApp')
      && (this.user !== 'RP' || this.isAhsRmbOrRmc || this.ppViewTccHoHrmbrmc))
      || ((this.user === 'RP' && this.appType === 'AFB' && !this.isAHS) && (this.isY('data.ppAddAssistingPersonToApp') || this.isY('data.ppAssistingOnBehalf')))
      || ((this.user === 'RP' && this.isFinReassessment) && (this.isY('data.ppAddAssistingPersonToApp') || this.isY('data.ppAssistingOnBehalf')))
    ){
      helpDuration.enable();
    }


    //PP FRM, FC help duration disable
    // if (this.getValue('data.ppAddAssistingPersonToApp').value === 'Yes' || this.getValue('data.ppAddAssistingPersonToApp').value === 'Si') {
    //   helpDuration.clearValidators();
    // }


    if (this.isMedicAidApp && this.isFinReassessment) {
      this.registerToggle(ppAddAssistingPersonToApp,
        toggles([
          swapControl(helpDuration, isYes),
          swapControls(assistControls, isYes)
        ])
      );
      this.toolTip = false;
    } else {
      this.toolTip = true;
    }

    this.signinService.getAccountData(sessionStorage.getItem('userName')).subscribe((data) => {
      if (!data || data != null){
        this.userData = data;
        this.partnerName = `${data['firstName']} ${data['lastName']}`;
        this.firstName = data['firstName'];
        this.lastName= data['lastName'];
        this.cdr.detectChanges();
      }
    })

    if (homeless.value && homeless.value.code === 'Y') {
      addMailingInfo.disable();
    }

    const boxApplicantBehalf = this.getControl('data.assistingPerson.responsibilities.applicantBehalf');
    const boxLetterOtherCommunication = this.getControl('data.assistingPerson.responsibilities.letterOtherCommunication');
    const boxsubmitRenewal = this.getControl('data.assistingPerson.responsibilities.submitRenewal');
    const boxyourBehalf = this.getControl('data.assistingPerson.responsibilities.yourBehalf');
    

    if(boxApplicantBehalf?.value === 'A' || boxLetterOtherCommunication?.value === 'L' || boxsubmitRenewal?.value  === 'S' ||  boxyourBehalf?.value  === 'Y'){
      boxApplicantBehalf.enable();
      boxApplicantBehalf.updateValueAndValidity();
      boxLetterOtherCommunication.enable();
      boxLetterOtherCommunication.updateValueAndValidity();
      boxsubmitRenewal.enable();
      boxsubmitRenewal.updateValueAndValidity();
      boxyourBehalf.enable();
      boxyourBehalf.updateValueAndValidity();
    }

    const applicantAckSignSw = this.getControl('data.assistingPerson.applicantAckSignSw');
    const applicantSignName = this.getControl('data.assistingPerson.applicantSignName');
    if(relationship?.value?.code === 'AR' && (this.isY('data.addAssistingPersonToApp') ||
      (this.getValue('data.assistingOnBehalf')?.value === 'Assisting Person' || this.getValue('data.assistingOnBehalf')?.value === 'Persona que Ayuda')
      || this.isY('data.ppAddAssistingPersonToApp') || this.isY('data.ppAssistingOnBehalf'))){
      applicantAckSignSw.enable();
      applicantAckSignSw.updateValueAndValidity();
      applicantSignName.enable();
      applicantSignName.setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
      applicantAckSignSw.updateValueAndValidity();
    }else{
      applicantAckSignSw.disable();
      applicantAckSignSw.disable();
      applicantAckSignSw.updateValueAndValidity();
      applicantSignName.clearValidators();
      applicantSignName.clearValidators();
      applicantSignName.updateValueAndValidity();
    }
  }
  tableData(tableVar) {
    if (!this.isPpRmbRmc && !this.isAHS) {
      if (this.translatedLang === 'en' && tableVar.length > 0) {
        tableVar[0].value = tableVar[0].description || tableVar[0].value;
      } else if (this.translatedLang === 'sp' && tableVar.length > 0) {
        tableVar[0].value = tableVar[0].spDescription || tableVar[0].spValue;
      }
    }
    return tableVar;
  }

  homelessOnChange(event: any) {
    const sahProgram = this.getControl('data.sahPrgSw');
    if(event.target.value === 'No' && sahProgram.value !== 'Yes'){
      const addMailingInfo = this.getControl('extras.helpers.addMailingInfo');
      addMailingInfo.setValue(({ code: '', value: '' }));
      setRequired(addMailingInfo);
    }
  }

  safeAtHomeOnChange(event: any) {
    if(event.target.value === 'Yes' || event.target.value === 'Si'){
      this.getControl('data.sahMailId').setValidators([Vs.required, Vs.safeAtHomeMailID]);;
      this.getControl('data.sahMailId').updateValueAndValidity();
      this.getControl('data.sahCountyCd').setValidators(Vs.required);
      this.getControl('data.sahCountyCd').updateValueAndValidity();
      const addMailingInfo = this.getControl('extras.helpers.addMailingInfo');
      addMailingInfo.setValue(({ code: '', value: '' }));
      // this.getControl('data.physicalAddress.addressFormat').setValue(({ code: '', value: '' }));
      this.getControl('data.physicalAddress.address').clearValidators();
      this.getControl('data.physicalAddress.address').reset();
      this.getControl('data.physicalAddress.address').disable();
      this.getControl('data.physicalAddress.address').updateValueAndValidity();
    }
    else{
      this.getControl('data.sahMailId').setValidators(null);
      this.getControl('data.sahMailId').setValue('');
      this.getControl('data.sahMailId').updateValueAndValidity();
      this.getControl('data.sahCountyCd').setValidators(null);
      this.getControl('data.sahCountyCd').setValue('');
      this.getControl('data.sahCountyCd').updateValueAndValidity();
      this.getControl('data.physicalAddress.address').enable();
      const homeless = this.getControl('data.homeless');

      if (homeless.value && homeless.value.code === 'Y') {
        this.getControl('data.mailingAddress').enable();
        this.getControl('data.physicalAddress.address').disable();
      }
      if (homeless.value && homeless.value.code === 'N') {
        this.getControl('data.physicalAddress.address').enable();
        this.getControl('data.physicalAddress.addressFormat').enable();
      }
    }
  }
  // MP First question change event
  onAssistingPersonBehalfSelected(event: any) {
    if (event.target.value === 'Assisting Person' ||  event.target.value === 'Persona que Ayuda') {
      this.getControl('data.assistingPerson.ackSignSw').setValue('');
      this.getControl('data.assistingPerson.ackSignSw').setValidators(Vs.required);
      this.getControl('data.assistingPerson.assistingPersonSignName').setValue('');
      this.getControl('data.assistingPerson.assistingPersonSignName').setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
      this.getControl('data.assistingPerson.ackSignSw').enable();
      this.getControl('data.assistingPerson.assistingPersonSignName').enable();
      this.getControl('data.assistingPerson.relationship').enable();

      if((this.getValue('data.assistingPerson.relationship')?.code === 'AR') ){
        this.getControl('data.assistingPerson.applicantAckSignSw').enable();
        this.getControl('data.assistingPerson.applicantAckSignSw').setValue('');
        this.getControl('data.assistingPerson.applicantAckSignSw').setValidators(Vs.required);
        this.getControl('data.assistingPerson.applicantSignName').enable();
        this.getControl('data.assistingPerson.applicantSignName').setValue('');
        this.getControl('data.assistingPerson.applicantSignName').setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
      }

    } else {
      this.getControl('data.assistingPerson.ackSignSw').disable();
      this.getControl('data.assistingPerson.ackSignSw').clearValidators();
      this.getControl('data.assistingPerson.assistingPersonSignName').disable();
      this.getControl('data.assistingPerson.assistingPersonSignName').clearValidators();
      this.getControl('data.addAssistingPersonToApp').reset();
      this.getControl('data.addAssistingPersonToApp').setValidators(Vs.required);
      this.getControl('data.addAssistingPersonToApp').updateValueAndValidity();

      this.getControl('data.assistingPerson.applicantAckSignSw').disable();
      this.getControl('data.assistingPerson.applicantAckSignSw').clearValidators();
      this.getControl('data.assistingPerson.applicantSignName').disable();
      this.getControl('data.assistingPerson.applicantSignName').clearValidators();
    }
  }

  // MP Second question change event
  onAssistingPersonSelected(event:any){
    if (event.target.value === 'Yes' || event.target.value === 'Si') {
      this.getControl('data.assistingPerson.ackSignSw').setValue('');
      this.getControl('data.assistingPerson.assistingPersonSignName').setValue('');
      this.getControl('data.assistingPerson.ackSignSw').enable();
      this.getControl('data.assistingPerson.ackSignSw').setValidators(Vs.required);
      this.getControl('data.assistingPerson.assistingPersonSignName').enable();
      this.getControl('data.assistingPerson.assistingPersonSignName').setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
      this.getControl('data.assistingPerson.relationship').enable();


      if((this.getValue('data.assistingPerson.relationship')?.code === 'AR') ){
        this.getControl('data.assistingPerson.applicantAckSignSw').enable();
        this.getControl('data.assistingPerson.applicantAckSignSw').setValue('');
        this.getControl('data.assistingPerson.applicantAckSignSw').setValidators(Vs.required);
        this.getControl('data.assistingPerson.applicantSignName').enable();
        this.getControl('data.assistingPerson.applicantSignName').setValue('');
        this.getControl('data.assistingPerson.applicantSignName').setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
      }
    }
    else {
      this.getControl('data.assistingPerson.ackSignSw').disable();
      this.getControl('data.assistingPerson.ackSignSw').clearValidators();
      this.getControl('data.assistingPerson.assistingPersonSignName').disable();
      this.getControl('data.assistingPerson.assistingPersonSignName').clearValidators();

      this.getControl('data.assistingPerson.applicantAckSignSw').disable();
      this.getControl('data.assistingPerson.applicantAckSignSw').clearValidators();
      this.getControl('data.assistingPerson.applicantSignName').disable();
      this.getControl('data.assistingPerson.applicantSignName').clearValidators();
    }
  }


  // PP First question change event
  ppOnAssistingPersonSelected(event:any){
    if (event.target.value === 'Yes' || event.target.value === 'Si') {
      //FRM and FC code for partner's name
      this.getControl('data.assistingPerson.name').patchValue({ firstName: this.userData.firstName, lastName: this.userData.lastName });
      this.getControl('data.assistingPerson.ackSignSw').setValue('');
      this.getControl('data.assistingPerson.assistingPersonSignName').setValue('');
      this.getControl('data.assistingPerson.ackSignSw').enable();
      this.getControl('data.assistingPerson.assistingPersonSignName').enable();
      this.getControl('data.assistingPerson.relationship').enable();
      this.getControl('data.ppAssistingOnBehalf').disable();
      this.getControl('data.ppAssistingOnBehalf').updateValueAndValidity();
      this.getControl('data.assistingPerson.helpDuration').enable();
      this.getControl('data.assistingPerson.helpDuration').updateValueAndValidity();
    }
    else{
      this.getControl('data.assistingPerson.ackSignSw').disable();
      this.getControl('data.assistingPerson.assistingPersonSignName').disable();
      this.getControl('data.ppAssistingOnBehalf').reset();
      this.getControl('data.ppAssistingOnBehalf').enable();
      this.getControl('data.ppAssistingOnBehalf').setValidators(Vs.required);
      this.getControl('data.ppAssistingOnBehalf').updateValueAndValidity();

      this.getControl('data.assistingPerson.applicantAckSignSw').disable();
      this.getControl('data.assistingPerson.applicantAckSignSw').clearValidators();
      this.getControl('data.assistingPerson.applicantSignName').disable();
      this.getControl('data.assistingPerson.applicantSignName').clearValidators();
    }
  }

  // PP FC and FRM Second question change event
  ppOnAssistingPersonBehalfSelectedFCFRM(event: any) {
    if (event.target.value === 'Yes' || event.target.value === 'Si') {
      this.getControl('data.assistingPerson.ackSignSw').setValue('');
      this.getControl('data.assistingPerson.assistingPersonSignName').setValue('');
      this.getControl('data.assistingPerson.ackSignSw').enable();
      this.getControl('data.assistingPerson.assistingPersonSignName').enable();
      this.getControl('data.assistingPerson.relationship').enable();

    } else {
      this.getControl('data.assistingPerson.ackSignSw').disable();
      this.getControl('data.assistingPerson.assistingPersonSignName').disable();
      this.getControl('data.assistingOnBehalf').disable();
      this.getControl('data.addAssistingPersonToApp').disable();

      this.getControl('data.assistingPerson.applicantAckSignSw').disable();
      this.getControl('data.assistingPerson.applicantAckSignSw').clearValidators();
      this.getControl('data.assistingPerson.applicantSignName').disable();
      this.getControl('data.assistingPerson.applicantSignName').clearValidators();
    }
  }



  validateAssistingPersonName() {
    const casehead = this.getControl('data.casehead');
    const name = this.getControl('data.assistingPerson.name');
    if (casehead ?.value ?.firstName ?.toLowerCase() === name ?.value ?.firstName ?.toLowerCase() && casehead ?.value ?.middleInitial ?.toLowerCase() === name ?.value ?.middleInitial ?.toLowerCase() &&
      casehead ?.value ?.lastName ?.toLowerCase() === name ?.value ?.lastName ?.toLowerCase() && casehead ?.value ?.suffix ?.toLowerCase() === name ?.value ?.suffix ?.toLowerCase()) {
      this.isAssistingPersonNameSameAsHoh = true;
    } else {
      this.isAssistingPersonNameSameAsHoh = false;
    }
    if (this.isAssistingPersonNameSameAsHoh) {
      this.getControl('data.hiddenField').setValidators(Vs.required);
      this.getControl('data.hiddenField').updateValueAndValidity();
    } else {
      this.getControl('data.hiddenField').clearValidators();
      this.getControl('data.hiddenField').updateValueAndValidity();
    }
    return this.isAssistingPersonNameSameAsHoh;
  }

  ngOnDestroy() {
    this.addressSub.unsubscribe();
  }
  setDropdownValues(control, table) {
    if (!this.isMedicAidApp && !this.isFinReassessment && !this.isKepro) {
      if (control.value) {
        const tableVar = table ?.filter((item) => item.code === control.value.code);
        this.tableData(tableVar);
        control.patchValue(tableVar[0]);
      }
    }
  }

  wellpointData(): void {
    const fDate = new Date(this.serviceConfigService.getServerConfig()?.wellpointEffDate);
    const cDate = new Date(this.currentDate);
    if (cDate >= fDate) {
      this.showWellpoint = true;
    }
  }

}