import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  constructor() {}

  public setCurrentUser(userId: string) { 
    gtag('set', {'user_id': userId});
  };

  public setEbmsFlag () {
    gtag('event', 'ebmsUser', {
      'event_category': 'ebmsUser',
      'event_label': 'ebmsUser',
    });
  }
}