import { Component } from '@angular/core';
import { BaseModalDirective } from '../base-modal';

@Component({
  selector: 'nh-health-plan-cancel-modal',
  templateUrl: './health-plan-cancel-modal.component.html'
})
export class HealthPlanCancelModalComponent extends BaseModalDirective {

}
