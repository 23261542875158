<nh-form [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'APPEAL_DEMOGRAPHICS_TEXT1' | translate }}</h5>
    <hr>
    <div class="appellant-info">
      <p class="static-text case_num"><strong>{{'COVERAGE_CASE_NUMBER' | translate }}: </strong>{{ caseNumber }}</p>
      <p class="static-text"><strong>{{'PRIMARY_APPELLANT' | translate }} </strong>{{ appellantName }}</p>
    </div>
    <br>
    <h5 class="section-header">{{'APPELLANT_ADDRESS' | translate }}</h5>
    <hr>
    <div>
      <div class="appeal_address_info" style="width: -webkit-fill-available;"
        *ngIf="exisitngAppellantAddress && !showAppellentAddresForm">
        <div style="width: -webkit-fill-available;" class="col-md-4">
          <div>{{exisitngAppellantAddress?.addressLine1 + ', '}}<br><span
              *ngIf="exisitngAppellantAddress?.addressLine2">{{exisitngAppellantAddress?.addressLine2 + ', '}}</span></div>
          <div *ngIf="!exisitngAppellantAddress?.zip4">
            <div class="row">
              <div *ngIf="this.addressFormatValue?.code === 'US'" class="pr-0 city_address_info">
                {{exisitngAppellantAddress?.city+", " + exisitngAppellantAddress?.state?.code + " "+
                AppellantZip}}<span *ngIf="exisitngAppellantAddress?.county">{{ ", " + exisitngAppellantAddress?.county?.value}}</span>
                <span class="pr-0 city_address_info ng-star-inserted">
                  <a class="p-0 pb-1 btn btn-link btn-lg hover-red" (click)="onEdit()">
                    <span class="pr-2" style="font: normal normal 400 18px 'Open Sans';">{{'EDIT' | translate }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2.5 19.5 19.49">
                      <g id="_icons_create-outline" data-name=" icons create-outline">
                        <path id="Combined_Shape-287" data-name="Combined Shape-287" class="cls-1"
                          d="M2.63,19.49A2.62,2.62,0,0,1,0,16.91V5.62A2.63,2.63,0,0,1,2.58,3h7.9a.75.75,0,0,1,.75.75.74.74,0,0,1-.74.75H2.63A1.12,1.12,0,0,0,1.5,5.6V16.87A1.12,1.12,0,0,0,2.61,18H13.88A1.12,1.12,0,0,0,15,16.89V8.24a.76.76,0,0,1,.74-.76.75.75,0,0,1,.76.75v8.64a2.62,2.62,0,0,1-2.59,2.62Zm4.92-7.55a.17.17,0,0,1,0-.18l.39-1.17A.42.42,0,0,1,8,10.41L16.47,2a.43.43,0,0,1,.6,0h0l.47.47a.42.42,0,0,1,0,.59L9.09,11.49a.5.5,0,0,1-.19.1L7.74,12H7.68a.21.21,0,0,1-.13-.05ZM18.17,1.85l-.53-.53a.39.39,0,0,1,0-.53h0l.58-.58a.76.76,0,0,1,1.07,0,.75.75,0,0,1,0,1.06l-.56.57a.4.4,0,0,1-.54,0h0Z" />
                      </g>
                    </svg>
                  </a>
                </span>
              </div>
              <div *ngIf="this.addressFormatValue?.code === 'MI'" class="pr-0 city_address_info">
                {{exisitngAppellantAddress?.addrApoFpo?.value + ","}}<span>{{ exisitngAppellantAddress?.addrAaAeAp?.value + ", "+ AppellantZip}}</span>
                <span class="pr-0 city_address_info ng-star-inserted">
                  <a class="p-0 pb-1 btn btn-link btn-lg hover-red" (click)="onEdit()">
                    <span class="pr-2" style="font: normal normal 400 18px 'Open Sans';">{{'EDIT' | translate }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2.5 19.5 19.49">
                      <g id="_icons_create-outline" data-name=" icons create-outline">
                        <path id="Combined_Shape-287" data-name="Combined Shape-287" class="cls-1"
                          d="M2.63,19.49A2.62,2.62,0,0,1,0,16.91V5.62A2.63,2.63,0,0,1,2.58,3h7.9a.75.75,0,0,1,.75.75.74.74,0,0,1-.74.75H2.63A1.12,1.12,0,0,0,1.5,5.6V16.87A1.12,1.12,0,0,0,2.61,18H13.88A1.12,1.12,0,0,0,15,16.89V8.24a.76.76,0,0,1,.74-.76.75.75,0,0,1,.76.75v8.64a2.62,2.62,0,0,1-2.59,2.62Zm4.92-7.55a.17.17,0,0,1,0-.18l.39-1.17A.42.42,0,0,1,8,10.41L16.47,2a.43.43,0,0,1,.6,0h0l.47.47a.42.42,0,0,1,0,.59L9.09,11.49a.5.5,0,0,1-.19.1L7.74,12H7.68a.21.21,0,0,1-.13-.05ZM18.17,1.85l-.53-.53a.39.39,0,0,1,0-.53h0l.58-.58a.76.76,0,0,1,1.07,0,.75.75,0,0,1,0,1.06l-.56.57a.4.4,0,0,1-.54,0h0Z" />
                      </g>
                    </svg>
                  </a>
                </span>
              </div>

            </div>
          </div>
        </div>

      </div>
      <br>
      <div [formGroup]="getControl('data.appealAddress')" *ngIf="showAppellentAddresForm || !exisitngAppellantAddress"> 
        <nh-field name="addressFormat" label="{{'ADDRESS_FORMAT' | translate}}" class="label-section">
          <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
          <select nh name="addressFormat" [items]="tableCodeValue('ADDRESSFORMATTYPES', 'data.appealAddress.addressFormat')"
              aria-label="addressFormat" formControlName="addressFormat" (change)="addressFormatChange('onChange')"></select>
      </nh-field>
      <nh-name-address [addressname] = "'appeal-demographics'" [addressFormat]="getControl('data.appealAddress.addressFormat')"
      [address]="getControl('data.appealAddress.address')" [stateDefault]="false" [isAssistingPerson]="false" [tables]="tables">
      </nh-name-address>
      <div class="row">
        <div class="col-mr-3 pl-3 pr-3 md-1 py-sm-1">
      <button id="continue" class="btn btn-block btn-secondary mb-1" type="button" id="dialog" (click)="onSave()"  [disabled]="!validAddress">{{'SAVE' | translate
      }}</button>
      </div>
      <div class="col-mr-3 pl-3 pr-3 md-1 py-sm-1">
      <button class="btn btn-sm btn-secondary mb-1" type="button" id="dialog" (click)="onCancel()">{{'CANCEL' | translate
      }}</button>
    </div>
    </div>
      </div>
    </div>
    <br>
    <h5 class="section-header">{{'CONTACT_INFO' | translate }}</h5>
    <hr>
    <div [formGroup]="getControl('data.appellantDemographics')">
    <nh-field label="{{'APPEAL_DEMOGRAPHICS_TEXT2' | translate }}" class="label-section">
      <select nh name="futureAddressSw" [items]="tableCodeValue('YESNO', 'data.appellantDemographics.futureAddressSw')"
        aria-label="futureAddressSw" formControlName="futureAddressSw"></select>
    </nh-field>
    <nh-field name="languagePreference" label="{{'APPEAL_DEMOGRAPHICS_TEXT3' | translate}}" [labelSize]="false"><select
        nh name="languagePreference" [items]="tableCodeValue('WRITTENLANGUAGE', 'data.appellantDemographics.languagePreference')" aria-label="languagePreference"
        formControlName="languagePreference" (change)="languagePref()"></select>
    </nh-field>
    <nh-field [mandatory]="interpreterMandatory" label="{{'APPEAL_DEMOGRAPHICS_TEXT5' | translate}}" class="label-section">
      <select aria-label='appealHearInterpreterSw' nh name="appealHearInterpreterSw" formControlName="appealHearInterpreterSw" [items]="tableCodeValue('YESNO', 'data.appellantDemographics.appealHearInterpreterSw')">
      </select>
    </nh-field>
    <nh-field [mandatory]="false" class="label-section" label="{{'EMAIL_ADDRESS' | translate}}"><input type="email"
        name="emailAddress" class="form-control" aria-label="emailAddress" formControlName="emailAddress">
    </nh-field>

    <nh-field [mandatory]="true" label="{{'BEST_TIME_TO_CALL' | translate}}" class="label-section"><select nh
        name="bestTime" [items]="tableCodeValue('CONTACTTIME', 'data.appellantDemographics.bestTime')" aria-label="bestTime" formControlName="bestTime"></select>
    </nh-field>
    <div [formGroup]="getControl('data.appellantDemographics.appellantcontactInfo')">
      <div class="row">
        <div class="col">
          <nh-field [mandatory]="true" label="{{'PRIMARY_PHONE' | translate}}">
            <input type="text" name="home" formControlName="home" class="form-control" mask="000-000-0000"
              placeholder="XXX-XXX-XXXX">
          </nh-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <nh-field [mandatory]="false" label="{{'SECONDARY_PHONE' | translate}}">
            <input type="text" name="message" formControlName="message" class="form-control" mask="000-000-0000"
              placeholder="XXX-XXX-XXXX">
          </nh-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <nh-field [mandatory]="false" label="{{'EXTENSION' | translate}}">
            <input type="text" name="extension" aria-label="extension" formControlName="extension" maxlength="6" mask="0*" class="form-control">
          </nh-field>
        </div>
      </div>
    </div>
  </div>
  </section>
</nh-form>