import { Component, OnInit, Input, ViewEncapsulation, HostListener, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '@app/services/state/state.service';
import { ApiService } from '../services/api.service';
import { CreateAccountService } from '../services/createAccount.service';
import { isEmpty } from 'ramda';
import { HttpHeaders } from '@angular/common/http';
import { ServiceConfigService } from '@app/services/service-config.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from './user-profile.service';
import { IdleService } from '@app/services/idle/idle.service';
import { ChangeEmailService } from './change-email/change-email.service';
import { ChangePasswordService } from './change-password/change-password.service';
import { ChangeSecurityQuestionsService } from './change-security-questions/change-security-questions.service';
import { LinkCaseService } from './link-case/link-case.service';
import { CommunicationPreferencesService } from './communication-preferences/communication-preferences.service';
import { JoyrideService } from 'ngx-joyride';
import { ModalService } from '@app/services/modal/modal.service';
import { HeaderComponent } from '@app/components/header/header.component';
import { ProcessService } from '@app/services/process.service';
import { SigninService } from '../signin/signin.service';
import { AppealsService } from './my-appeals/appeals.service';
import { Subscription } from 'rxjs';
declare const $: any;

@Component({
  selector: 'nh-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() routerLinkActive;

  userDetails: any;
  isManageAccountsActive = false;
  isMyLink = false;
  applicationNum;
  isAppNum = false;
  hoh = false;
  isFromPartnerPortal;
  renewalDisable: boolean = true;
  qfrDisable: boolean = true;
  hasIndiv: boolean;
  isMenuOpened: boolean = false;
  hoveredOnRenew: boolean;
  hoveredOnQFR: boolean;
  hoveredOnCommunicate: boolean = false;
  hoveredOnReport: boolean;
  hoveredOnAFB: boolean;
  updateEmailLinkActive = false;
  updateEmailFrom;
  changePasswordLinkActive = false;
  changePassword;
  updateSecurityQuestionsLinkActive = false;
  mySubmissions = false;
  healthPlanDetailsTitle=false;
  updateSecurityQuestions;
  successMessage;
  isManageAccountActive = false;
  isLinkYourCaseActive = false;
  caseLinkfrom;
  isBenefitDashboardActive = false;
  isCoverageDetailsActive = false;
  iscommunicationPrefActive = false;
  myDocumentsTitle = false;
  showLeftPanel = true;
  myLetters = false;
  autoRenewal = false;
  disableRenewal = true;
  showTutorialInMobileView = false;
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  dashboardTutorialActive = false;
  showDashboardTutorialInMobileView = false;
  disableRmc = false;
  translatedLang;
  enableChangeReport = false;
  indivId;
  showHealthPlanDetails: boolean;
  hoveredOnHealthPlan: boolean;
  mcoMasterSwitch:boolean;
  appealsMasterSwitch:boolean;
  showMyAppeals:boolean;
  showQFR: boolean = false;
  appealContinuanceTitle=false;
  appealWithdrawalTitle=false;
  appealDashboardTitle = false;
  showAppeals:boolean;
  step4;
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
  showQFRLink: Subscription;
  showAppealsLink: Subscription;
  showHealthPlanLink: Subscription;
  readTCC = sessionStorage.getItem('ReadTCC') === 'true' ? true : false;
  afbLinkToggle : boolean = false;
  kbSwitch: boolean = false;
  isLoggedUserHoh: boolean = false;
  isReconDate: boolean = false;
  isCaseTerminated: boolean = false;
  dcsOrSSIMultipleCase: boolean = false;
  isCaseDenied: boolean = false;
  constructor(private apiservice: ApiService,
    private userProfileService: UserProfileService,
    public createAccount: CreateAccountService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private serverConfig: ServiceConfigService,
    public translateService: TranslateService,
    private idleService: IdleService,
    public changeEmailService: ChangeEmailService,
    private changePasswordService: ChangePasswordService,
    private changeSecurityQuestionsService: ChangeSecurityQuestionsService,
    public linkCaseService: LinkCaseService,
    public communicationPreferencesSvc: CommunicationPreferencesService,
    public apiService: ApiService,
    public readonly joyrideService: JoyrideService,
    private modalService: ModalService,
    private headerComponent: HeaderComponent,
    public processService: ProcessService,
    public signInService: SigninService,
    public appealsService: AppealsService
  ) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  @ViewChild('step1') private step1: ElementRef;
  @ViewChild('step2') private step2: ElementRef;
  @ViewChild('step3') private step3: ElementRef;


  ngOnInit(): void {
    this.mcoMasterSwitch = this.serverConfig.getServerConfig().mcoMasterSwitch;
    this.appealsMasterSwitch = this.serverConfig.getServerConfig().appealsMasterSwitch;
    this.afbLinkToggle = this.serverConfig.getServerConfig().AFB_LINK_SWITCH;
    const hidePanel = sessionStorage.getItem('hidePanel');
    this.showLeftPanel = hidePanel === 'true' ? false : true;
    const indiv = sessionStorage.getItem('individualId');
    this.signInService.getIndivId.next(indiv);
    this.hasIndiv = indiv && indiv != 'null' && indiv != '0' ? true : false;
    this.applicationNum = sessionStorage.getItem('appNum');
    this.isAppNum = this.applicationNum === 'null' && this.applicationNum === '0' && this.applicationNum === 0 ? false : true;
    const indivId = sessionStorage.getItem('individualId');
    const userType = sessionStorage.getItem('userType');
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.isFromPartnerPortal = userType === 'RP' && !isEmpty(indivId);
    this.readTCC = sessionStorage.getItem('ReadTCC') === 'true' ? true : false;
    const hoh = JSON.parse(sessionStorage.getItem('hoh'));
    this.hoh = hoh == null ? this.isFromPartnerPortal : hoh == true ? true : false;
    const userName = {
      'userName': JSON.parse(sessionStorage.getItem('currentUser')) ?.userId ?
        JSON.parse(sessionStorage.getItem('currentUser')).userId : sessionStorage.getItem('userName')
    }
    this.userProfileService.reconDate.subscribe((bool) => {
      if(bool === true){
        this.isReconDate = bool;
      }
    })

    this.userProfileService.isCaseTerminated.subscribe((bool) => {
      if (bool === true) {
        this.isCaseTerminated = bool;
      }
    })

    this.userProfileService.isCaseDenied.subscribe((bool) => {
      if (bool === true) {
        this.isCaseDenied = bool;
      }
    })

    this.userProfileService.isHoh.subscribe((bool) => {
      if (bool === true) {
        this.isLoggedUserHoh = bool
      }
    })
    this.userProfileService.getKbData.subscribe((bool) => {
      if (bool === true) {
        this.kbSwitch = bool;
      }
    })

    this.userProfileService.dcsOrSSIMultipleCase.subscribe((bool) => {
      if (bool === true) {
        this.dcsOrSSIMultipleCase = bool;
      }
    })
    this.userProfileService.hideRenew$.subscribe((bool) => {
      this.renewalDisable = bool && this.hasIndiv && !this.readTCC;
      if (this.userStatus && this.renewalDisable) {
        sessionStorage.setItem('rmbLinkStatus', JSON.stringify(this.renewalDisable));
      } else {
        sessionStorage.removeItem('rmbLinkStatus');
      }
    });
    this.userProfileService.enableChangeReport.subscribe(res => {
      this.enableChangeReport = res;
    })
    this.userProfileService.disableChangeReport$.subscribe((value) => {
      this.disableRmc = !this.readTCC ? value : false
      if (this.userStatus && this.disableRmc) {
        sessionStorage.setItem('rmcLinkStatus', JSON.stringify(this.disableRmc));
      } else {
        sessionStorage.removeItem('rmcLinkStatus');
      }
    })
    this.route.queryParams.subscribe((res) => {
      if (!isEmpty(res)) {
        this.successMessage = res
      }
    })

    this.showQFRLink = this.userProfileService.showHideQFR.subscribe((bool) => {
      if(bool === true && !this.readTCC){
        this.showQFR = true;
      } 
    });

    this.showAppealsLink = this.userProfileService.showHideMyAppeals.subscribe((bool) => {
      if(bool === true){
        this.showAppeals = true;
      } 
    });

    this.showHealthPlanLink = this.userProfileService.enableAppealOrHealthPlan.subscribe((bool) => {
    if (this.appealsMasterSwitch) {
      if (currentUser?.accountTypeCd === 'CL' && bool === true && !this.showAppeals) {
        if (sessionStorage.getItem('individualId') === 'null' || sessionStorage.getItem('individualId') === '0') {
          this.showMyAppeals = false;
        }
        else {
          this.showMyAppeals = true;
        }
      } else {
        this.showMyAppeals = false;
      }
    }
    if(this.mcoMasterSwitch){
      if (currentUser?.accountTypeCd === 'CL'  && bool === true) {
        if(sessionStorage.getItem('individualId') === 'null' || sessionStorage.getItem('individualId') === '0'){
          this.showHealthPlanDetails = false;
        }
        else{
          this.showHealthPlanDetails = true;
        }    
      }else{
        this.showHealthPlanDetails = false;   
      }
    }
  });

    const children = this.route.snapshot.children[0];
    if (children && children.url && children.url[0]) {
      const path = children.url[0].path;
      if (path === "update-security-questions" || path === "update-email") {
        this.isManageAccountsActive = true;
        this.isMyLink = true;
      }
      if (path === "update-email") {
        this.updateEmailLinkActive = true;
        this.updateEmailFrom = this.changeEmailService.updateEmailFrom;
      }
      if (path === "change-password") {
        this.changePasswordLinkActive = true;
        this.changePassword = this.changePasswordService.changePassword;
      }
      if (path === "update-security-questions") {
        this.updateSecurityQuestionsLinkActive = true;
        this.updateSecurityQuestions = this.changeSecurityQuestionsService.updateSecurityQuestions;
      }
      if (path === "manage-account") {
        this.isManageAccountActive = true;
        this.createAccount.manageAccountFormUpdateState = false;
      }
      if (path === "case-link") {
        this.isLinkYourCaseActive = true;
        this.caseLinkfrom = this.linkCaseService.caseLinkfrom;
        this.linkCaseService.isError = false;
      }
      if (path === "benefit-dashboard") {
        this.isBenefitDashboardActive = true;
      }
      if (path === "communication-preferences") {
        this.iscommunicationPrefActive = true;
      }
      if (path === "my-documents") {
        this.myDocumentsTitle = true;
      }
      if (path === 'my-letters') {
        this.myLetters = true;
      }
      if (path === 'auto-renewal') {
        this.autoRenewal = true;
      }

      if (path === "coverage-details") {
        this.isCoverageDetailsActive = true;
      }
      if (path === 'my-submissions') {
        this.mySubmissions = true;
      }
      if (path === 'healthplan-details') {
        this.healthPlanDetailsTitle = true;
      }
      if (path === 'appeal-continuance') {
        this.appealContinuanceTitle = true;
      }
      if (path === 'appeal-withdrawal') {
        this.appealWithdrawalTitle = true;
      }
      if(path === 'my-appeals'){
        this.appealDashboardTitle = true; 
      }
    };

    
    if (!sessionStorage.getItem('backOrRefresh')) {
    this.route.queryParams.pipe((res) => {
      if (res['tccToken']) {
        sessionStorage.setItem('TCAM', res['tccToken']);
      }
      const contentHeader = new HttpHeaders({'pageId':'TCMMA'})
      return this.apiservice.post('indi/users/manageMyAccount', userName,{headers: contentHeader})
    }).subscribe((res) => {
      this.userDetails = res;
      this.userProfileService.setEmailId(res['email']); 
      this.createAccount.setUserObj(res);
    })
  }
 
    const firstTimeUser = sessionStorage.getItem('firstTimeAccessSw');
    this.isMobileView = this.screenWidth <= this.mobileWidth;
    if (firstTimeUser && firstTimeUser === "Y" && this.isMobileView) {
      this.showTutorialInMobileView = true;
    }
    this.translatedLang = this.translateService.getDefaultLang();
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
    });
  }

  ngAfterViewInit(): void {
    this.userProfileService.sendStep1Info(this.step1);
    this.userProfileService.sendStep2Info(this.step2);
    this.userProfileService.sendStep3Info(this.step3);
    if (this.updateSecurityQuestionsLinkActive) {
      this.updateSecurityQuestions = this.changeSecurityQuestionsService?.updateSecurityQuestions;
    }
  }

  checkReportChangeEnabled() {
    if(this.enableChangeReport)
      return false;    
    else
      return !this.disableRmc;
  }
  
  applyNow() {
    sessionStorage.setItem('appType', 'AFB');
    this.applicationNum = sessionStorage.getItem('appNum');
    this.isAppNum = this.applicationNum == 'null' || this.applicationNum === '0' || this.applicationNum === 0 ? false : true;
    if (this.isAppNum) {
      this.router.navigate(['/start', 'welcomeback'])
    } else {
      sessionStorage.setItem('isNewApp', 'true');
      this.stateService.continueApplication(true);
      this.router.navigateByUrl('/application/' + 'NEW');
    }
  }

  backToPartnerPortal() {
    sessionStorage.removeItem('hidePanel');
    sessionStorage.removeItem('individualId');
    this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
  }

  tccCall(): void {
    const userName = {
      'userId': JSON.parse(sessionStorage.getItem('currentUser')) ?.userId,
      'tcam': this.idleService.getCookie('TCAM')
    }
    this.userProfileService.getTccAppData('/accounts/getEncryptedDetails', userName).subscribe((res) => {
      window.location.href = `https://devr2-cp.teds.tn.gov/access?tccToken=${res['encryptionText']}`;
    })
  }

  menuClick(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }

  updateEmail1() {
    this.changeEmailService.updateEmail();
  }

  isPasswordFormValid() {
    return this.changePasswordService.isFormValid();
  }

  updatePassword(): void {
    this.changePasswordService.updatePassword();
  }

  saveSecurityQuestion(): void {
    this.changeSecurityQuestionsService.saveSecurityQuestion();
  }

  disableQAM() {
    return !this.updateEmailLinkActive && !this.changePasswordLinkActive && !this.updateSecurityQuestionsLinkActive &&
      !this.successMessage ?.updatedEmail && !this.successMessage ?.updatedPassword && !this.successMessage ?.updatedSecurityQuestions;
  }

  onPrevious() {
    this.router.navigateByUrl('user-profile/benefit-dashboard');
  }

  validateUser() {
    const data = {
      showTutorial: "N",
    };
    this.userProfileService.postTutorial(data).subscribe();
  }

  openTutorial() {
    this.modalService
      .openCoverageTutorial()
      .subscribe(this.startTutorial.bind(this));
  }

  startTutorial() {
    const closeTutorial = sessionStorage.getItem('closeTutorial');
    if (closeTutorial !== "true") {
    this.userProfileService.step4Info.subscribe(data => {
      this.step4 = data;
    });
    const customTexts = this.translatedLang === 'en' ? { done: "Finish" } : {
      done: "Terminar",
      prev: 'Anterior',
      next: 'Siguiente'
    }
    if (!this.isMobileView) {
      this.joyrideService.startTour({
        steps: ["Step1", "Step2", "Step3", "Step4", "Step5"],
        showPrevButton: false,
        showCounter: false,
        customTexts,
      }).subscribe(data => {
          if(data.name === 'Step1'){
            this.step1.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
          } 
          if(data.name === 'Step2'){
            this.step2.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
          }
          if(data.name === 'Step3'){
            this.step3.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
          }
          if (data.name === 'Step4') {
            this.step4.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
          }
         })
    } else {
      this.joyrideService.startTour({
        steps: ["Step6", "Step7", "Step8", "Step4", "Step9"],
        showPrevButton: false,
        showCounter: false,
        customTexts,
      });
    }
  }
  else if(closeTutorial === 'true'){
    sessionStorage.removeItem('closeTutorial');
  }
  }

  dashboardTutorial() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
    if (this.isMobileView) {
      this.showTutorialInMobileView = true;

      //Service Call to validate
      this.processService.getIsFromNextFAQ().subscribe((result) => {
        if (result) {
          this.headerComponent.dropdownOpened = true;
        }
      });
    } else {
      const url = this.router.url;
      if(!url.includes('user-profile/benefit-dashboard')) {
      this.router.navigateByUrl("user-profile/benefit-dashboard");
      }
      this.showTutorialInMobileView = false;
      this.headerComponent.dropdownOpened = false;
    }
    this.validateUser();
    this.openTutorial();
  }

  toAppealsDashboard(){
    this.router.navigateByUrl('user-profile/my-appeals');
  }

  continuance(){
    this.appealsService.showMessage = false;
    this.appealsService.showMessageWithdrawal = false;
  }

  get userStatus(): boolean {
    const hohWithoutReconDate = this.isLoggedUserHoh && !this.isReconDate && !this.isCaseTerminated && !this.dcsOrSSIMultipleCase && !this.isCaseDenied;
    const KbSwitchWithoutHohOrReconDate = this.kbSwitch && !this.isLoggedUserHoh && !this.isReconDate && !this.isCaseTerminated && !this.dcsOrSSIMultipleCase && !this.isCaseDenied;
    const hohWithReconDate = this.isLoggedUserHoh && this.isReconDate && !this.dcsOrSSIMultipleCase;
    const status = hohWithoutReconDate || KbSwitchWithoutHohOrReconDate || hohWithReconDate;
    return this.afbLinkToggle && status;
  }

  get isLinkDisable(): boolean {
      return (this.afbLinkToggle && this.userStatus && (this.renewalDisable || this.disableRmc))
  }

  ngOnDestroy() {
    this.showQFRLink.unsubscribe();
    this.showAppealsLink.unsubscribe();
    this.showHealthPlanLink.unsubscribe();
  }
}
