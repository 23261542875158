import { EasyRMBStartComponent } from './easyRmb.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';


const formValidation = (builder, recordData) => {
  const rmbData = lookIn(recordData);
  const modules = rmbData(['modules']);
  const helpers = builder.group({
    hoh: null,
    peopleInYourHome: null,
    householdInfo: null,
    taxInformation: null,
    incomeEmployment: null,
    otherBenefits: null,
    expenses: null,
    resources: null
  });
  if (modules) {
    const data = builder.group({
      modules: builder.array(modules)
  });
    return { helpers, data };
  }

  return { data: null };
};

export default sectionConfiguration(EasyRMBStartComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showSubmit: false,
  showComments: false,
  showSaveAndExit: false,
});
