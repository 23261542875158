import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'nh-submission-search',
	templateUrl: './submission-search.component.html',
	styleUrls: ['./submission-search.component.scss']
})

export class ProviderSubmissionSearchComponent implements OnInit {

	constructor () {}

	ngOnInit() {
		sessionStorage.removeItem('submitSuccess');
	}
}
