import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faqData, faqRPData } from './faq-data';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '@app/services/metaservice';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {
  data = [];
  userType = '';

  constructor(public activatedRoute: ActivatedRoute, public translateService: TranslateService, private metaService: MetaService, private titleService: Title
    ) {
    this.titleService.setTitle('FAQ');
  }

  ngOnInit(): void {
    this.metaService.createCanonicalURL();
    this.activatedRoute.queryParams.subscribe(res => this.onLoad(res));
    if(sessionStorage.getItem('userType')) {
      this.userType = sessionStorage.getItem('userType');
    }
    this.setData();
    sessionStorage.removeItem('submitSuccess');
  }

  onLoad(response) {
    if (response.lang) {
      this.translateService.setDefaultLang(response.lang);
    }
    if(response.usertype) {
      this.userType = response.usertype;
      this.setData();
    }
  }

  setData() {
    if (this.userType && this.userType === 'RP') {
      this.data = [...faqRPData];
    } else {
      this.data = [...faqData];
    }
  }

  showHide(num) {
    num.show = !num.show
  }

  cardClickHandler(event: any,item: any) {
    const target = event.target as HTMLElement;
    if(target.tagName.toLowerCase() !== 'a') {
      item.show = !item.show;
    }
   
  }

}
