<nh-spinner></nh-spinner>
<div *ngIf="isMasterBannerSwitchEnabled">
  <nh-alert type="sitewide" [isOpen]="isMobileView" *ngIf="!isPartner && isSiteWideAlertEnabled && enableBannerMessage()">
    <div class="row">
      <div class="icon">
        <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
      </div>
      <div class="message">
        <span>{{siteWideText}}</span>
      </div>
    </div>
  </nh-alert>
  <nh-alert type="sitewide" [isOpen]="isMobileView" *ngIf="siteWideMasterSwitch">
    <div class="row">
      <div class="icon">
        <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
      </div>
      <div class="message">
        <span>{{siteWideText}}</span>
      </div>
    </div>
  </nh-alert>
  <nh-alert type="sitewide" [isOpen]="isMobileView" *ngIf="!isPartner && !isLoggedIn() && enableBannerMessage() && renewalText">
    <div class="row">
      <div class="icon">
        <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
      </div>
      <div class="message">
        <span>{{renewalText}}</span>
      </div>
    </div>    
  </nh-alert>
  <nh-alert type="sitewide" [isOpen]="isMobileView" *ngIf="(isPPRenewalAlertEnabled || isPPDDARenewalAlertEnabled) && renewalPPText">
    <div class="row">
      <div class="icon">
        <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
      </div>
      <div class="message">
        <span>{{renewalPPText}}</span>
      </div>
    </div>
  </nh-alert>

  <nh-alert type="sitewide" [isOpen]="isMobileView" *ngIf="isPPDentalBannerSwitchEnabled">
    <div class="row">
      <div class="icon">
        <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
      </div>
      <div class="message">
        <span [innerHTML]="dentalBenefitsPPText"></span>
      </div>
    </div>
  </nh-alert>

  <nh-alert type="sitewide" [isOpen]="isMobileView" *ngIf="!isPartner && isDentalBannerSwitchEnabled && enableBannerMessage()">
    <div class="row">
    <div class="icon">
      <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
    </div>
    <div class="message">
      <span [innerHTML]="dentalBenefitsText"></span>
    </div>
  </div>
</nh-alert>
</div>
<ng-sidebar-container class="reset-height">
  <div ng-sidebar-content nh-container #top [class.sp]="translateService.getDefaultLang() === 'sp'">
    <nh-header (toggle)="onToggle($event)" (signout)="onSignout($event)"></nh-header>
      <div class="env-message" *ngIf="isEnvironmentAlertEnabled">{{environmentAlertText}} - {{environmentName}}</div>
      <div *ngIf="isMasterBannerSwitchEnabled">
      <nh-alert type="sitewide" [isOpen]="!isMobileView" *ngIf="!isPartner && isSiteWideAlertEnabled && enableBannerMessage()">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{siteWideText}}</span>
          </div>
        </div>
      </nh-alert>
      <nh-alert type="sitewide" [isOpen]="!isMobileView" *ngIf="siteWideMasterSwitch">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{siteWideText}}</span>
          </div>
        </div>
      </nh-alert>
      <nh-alert type="sitewide" [isOpen]="!isMobileView" *ngIf="!isPartner && !isLoggedIn() && enableBannerMessage() && renewalText">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{renewalText}}</span>
          </div>
        </div>
      </nh-alert>
      <nh-alert type="sitewide" [isOpen]="!isMobileView" *ngIf="(isPPRenewalAlertEnabled || isPPDDARenewalAlertEnabled) && renewalPPText">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{renewalPPText}}</span>
          </div>
        </div>
      </nh-alert>

      <nh-alert type="sitewide" [isOpen]="!isMobileView" *ngIf="isPPDentalBannerSwitchEnabled">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
          </div>
          <div class="message">
            <span [innerHTML]="dentalBenefitsPPText"></span>
          </div>
        </div>
      </nh-alert>

      <nh-alert type="sitewide" [isOpen]="!isMobileView" *ngIf="!isPartner && !isLoggedIn() && isDentalBannerSwitchEnabled && enableBannerMessage()">
        <div class="row">
        <div class="icon">
          <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline-white.svg" alt="warning" />
        </div>
        <div class="message">
          <span [innerHTML]="dentalBenefitsText"></span>
        </div>
      </div>
    </nh-alert>
    </div>
    <div *ngIf="isOnApp" class="bg-white mgn-tp">
      <div class="container d-lg-none">
        <div>
          <div class="sub-text-prog">{{'TRACKER' | translate}}</div>
          <div class="app-tracker row">
            <div class="col-10" *ngIf="appType !== 'APL'" (click)="onProgressBarOpen()"><strong>{{'APPLICATION_PROGRESS' | translate}}</strong></div>
            <div class="col-10" *ngIf="appType === 'APL'" (click)="onProgressBarOpen()"><strong>{{'APPEAL_PROGRESS' | translate}}</strong></div>
            <div class="col-2 arrow-style">
              <img (click)="onProgressBarOpen()" *ngIf="!isMobileProgBarOpen" src="../assets/images/⚛️ icons-caret-down-outline.svg" alt="down-arrow">
              <img (click)="onProgressBarOpen()" *ngIf="isMobileProgBarOpen" src="../assets/images/⚛️ icons-caret-up-outline.svg" alt="up-arrow">
            </div>
          </div>
          <br />
        </div>
        <div class="mobile-progress-bar container">
          <nh-stepper *ngIf="isMobileProgBarOpen" class="d-lg-none"></nh-stepper>
        </div>
      </div>
    </div>
    <div [ngClass]="{'container': isOnApp}">
      <nh-notifications [messages]="notifications"></nh-notifications>
      <nh-stepper *ngIf="isOnApp" class="d-none d-lg-block"></nh-stepper>
      <nh-page-title *ngIf="isOnApp && !isSecondTitle()" [title]="title$ | async | translate " [isSummary]="isSummary$ | async" [isSubTitleEnabled]="isSubTitleEnabled$ | async"></nh-page-title>
      <nh-page-title *ngIf="isOnApp && isSecondTitle()" [title]="secondTitle$ | async | translate "  [isSummary]="isSummary$ | async"></nh-page-title>
      <nh-chatbot></nh-chatbot>
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
    <nh-footer *ngIf="!mobileUser"></nh-footer>
  </div>
</ng-sidebar-container>
<nh-scroll-top *ngIf="isMobileView"></nh-scroll-top>