import { contains } from 'ramda';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '@app/services/application/application.service';
import { StateService } from '@app/services/state/state.service';
import { StorageService } from '@app/services/storage/storage.service';
import { UserProfileService } from '../user-profile.service';
import { ApiService } from "../../services/api.service";
import { isPartner } from '@app/helpers/mode.helpers';
import { Title } from '@angular/platform-browser';
import { HttpHeaders  } from '@angular/common/http';

@Component({
  selector: 'nh-report-change',
  templateUrl: './report-change.component.html',
  styleUrls: ['./report-change.component.scss']
})

export class ReportChangeComponent implements OnInit {
  constructor(
    private userProfileService: UserProfileService, 
    private router: Router, 
    private appService: ApplicationService, 
    private stateService: StateService,
    protected storageService: StorageService,
    private apiService: ApiService,
    private titleService: Title
  ) { 
    this.titleService.setTitle('Report a Change');
  }

  indivId;
  coverageDetails;
  caseSummary = [];
  applicationNum;
  userName;
  indivEncId;
  isPartner;
  response;
  caseDetails = [];

  ngOnInit(): void {
    this.userName = sessionStorage.getItem("userName");
    this.indivEncId = sessionStorage.getItem("individualId");
    const userType = sessionStorage.getItem('userType');
    this.isPartner = isPartner(userType);
    if (this.userName) {
      const url = 'publ/signin/validate/info?userId=' + this.userName;
      this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCRMC'})}).subscribe((res) => {
      if (res && !this.isPartner) {
        this.indivId = res['individualId'].toString();
      } else {
        this.indivId = sessionStorage.getItem("individualId");
      }
      this.userProfileService.getBenefitDashboard(this.indivEncId).subscribe((response: any) => {
        this.response = { ...response };
        this.coverageDetails = response.cmbSummaryResponse;
        this.caseSummary = this.coverageDetails?.caseSummaryResponse;
        this.caseDetails = this.caseSummary?.filter(f => f.applicationStatusDesc !== 'TN');
        this.showMsg();
        this.caseSummary?.forEach((i) => {
          const searchedIndv = i.indvSummaryResponse.filter(indv => indv.indvId === this.indivId);
          i['isKbA'] = searchedIndv?.filter(data => (( ((data.programStatus?.length && data.programStatus[0]?.coeCode === 'K01') && (this.getAge(data.dob)<=18)) || ((data.programStatus?.length && data.programStatus[0]?.coeCode === 'K03') && (this.getAge(data.dob)<=18)) )
                                                     && data.programStatus?.length && data.programStatus[0]?.edgStatus !== 'DN')).length > 0;
          if(i['isKbA']){
            i.hoHID = this.indivId;
          }
          const eligibleCodes = ["SSI", "SST"];
          searchedIndv.find(record => {
            return i['isReportSsiEligble'] = contains(record.programStatus?.length && record.programStatus[0]?.coeCode, eligibleCodes);
          });
          const eligibleDCSCodes = ["D02"];
          const eligibleappModeCd = ["DCS"];
          searchedIndv.find(record => {
            return i['isDcsEligible'] = contains(record.programStatus?.length && record.programStatus[0]?.coeCode, eligibleDCSCodes) && contains(i.appModeCd, eligibleappModeCd);
          })
        })
      });
    });
  }
  };

  showMsg() {
    this.caseSummary?.forEach(item => {
      const deniedIndv = item.indvSummaryResponse.filter(member => {
        return member.programStatus?.length ? member.programStatus[0]?.edgStatus === 'DN' : null;
      })
      const kList = ['K01', 'K02', 'K03', 'K04'];

      if (deniedIndv.length > 0) {
        let currentCoeCode
        if(deniedIndv[0]?.programStatus?.length) {
          currentCoeCode = deniedIndv[0]?.programStatus[0]?.coeCode;
        }
        const showKtMsg = contains(currentCoeCode, kList);
        sessionStorage.setItem('showKtMsg', showKtMsg);
      }
    })
  }

  onReportChange(caseNum, applicationType) {
    this.stateService.continueApplication(true);
    this.storageService.setCaseNumber(caseNum);
    this.storageService.setApplicationType(applicationType);
    this.appService.getHeadOfHouseholdInfo(0,'APL').subscribe((res: any) => {
      this.applicationNum = res?.applicationNumber;
      if (this.applicationNum) {
          this.appService.deleteApp(this.applicationNum, 'TCRMC').subscribe(() => {});
      }
          this.stateService.resetStates();
    this.appService.getHeadOfHouseholdInfo(caseNum, 'CHANGE_REPORT').subscribe((res: any) => {
    const navigate = () => this.router.navigateByUrl('/application/' + 'CHANGE_REPORT');
      if (res) {
        this.applicationNum = res?.applicationNumber;
        if (this.applicationNum && this.applicationNum !== 0) {
          this.appService.deleteApp(this.applicationNum, 'TCRMC').subscribe(() => navigate());
        } else {
          navigate();
        }
      } else {
        navigate();
      }
    });
  })
  }

  getAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }    
    return age;
  }
}
