import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { isHeadOfHousehold } from '@app/helpers/individual.helpers';

import { Record, Identifier } from '@app/models/record.model';
import { emptyRecord } from '@app/helpers/models.helpers';
import { TranslateService } from '@ngx-translate/core';
import { contains } from 'ramda';

@Component({
  selector: 'nh-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() columns: any[];
  @Input() rows: any[];
  @Input() limit = 6;
  @Input() mode = '';
  @Input() sectionId = '';
  @Input() isRenewMyBenefits: boolean;
  @Input() individuals: any;
  @Input() isFooterDisable: boolean;
  @ViewChild('onFocus', { static: false })
  elementRef: ElementRef;
  flexSection;
  showKbMedicalBills = false;
  kbMedicalBillsRow = false;
  hasPhonenumbers = false;
  isAFB = false;
  applicationType = sessionStorage.getItem('appType');
  appealActionHeader = '';
  appealRows;
  enableEditReview = false;
  @Output() edit: EventEmitter<Record> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<Identifier> = new EventEmitter();

  items = [{ code: 'Y', value: 'Yes' }, { code: 'N', value: 'No' }];
  // Health Coverage Summary translations
  policyHeaderEn = "Policy Holder";
  policyHeaderSp = "Titular de la póliza";
  whoIsCoveredEn = "Who is Covered";
  whoIsCoveredSp = "Quien esta cubierta";
  insuranceCompanyPolicyEn = "Insurance Company/ <br> Policy Number";
  insuranceCompanyPolicySp = "Compañía de seguros / Número de póliza";
  monthlyPremiumEn = "Monthly Premium";
  monthlyPremiumSp = "Prima mensual";

  // People Summary translations
  nameSsnEn = "Name / SSN";
  nameSsnSp = "Nombre / SSN";
  dobEn = "Date of Birth";
  dobSp = "Fecha de nacimiento";
  applyForCoverageEn = "Applying for<br> Coverage";
  applyForCoverageSp = "Solicitud de cobertura";
  pregnantEn = "Pregnant";
  pregnantSp = "Embarazada";

  // Tax Summary translations.
  houseHoldMemberEn = "Household Member";
  houseHoldMemberSp = "Miembro del hogar";
  taxFilingStatusEn = "Tax Filing<br> Status";
  taxFilingStatusSp = "Estado para la declaración de impuestos";
  taxDependentOutsideHouseholdEn = "Tax Dependent<br> Outside Household";
  taxDependentOutsideHouseholdSp = "Dependiente fiscal fuera del hogar";
  jointFilerOutsideHouseholdEn = "Joint Filer<br> Outside Household";
  jointFilerOutsideHouseholdSp = "Contribuyente conjunto que vive fuera del hogar";
  deductionTypeEn = "Deduction Type";
  deductionTypeSp = "Tipo de deducción";

  //Employment Summary translations
  nameEn = "Name";
  nameSp = "Nombre";
  employerEn = "Employer";
  employerSp = "Empleador";
  startDateEn = "Start Date";
  startDateSp = "Fecha de inicio";
  howOftenEn = "How Often";
  howOftenSp = "Frecuencia";
  amountEn = "Amount";
  amountSp = "Cantidad"

  //Self-Employment Summary translations
  nameSeEn = "Name";
  nameSeSp = "Nombre";
  bussinessDescriptionEn = "Business Description";
  bussinessDescriptionSp = "Descripción del negocio";
  typeOfBussinessEn = "Type of Business";
  typeOfBussinessSp = "Tipo de negocio";
  netIncomeEn = "Net Income";
  netIncomeSp = "Ingresos netos"

  //Other Income Summary translations
  nameOiEn = "Name";
  nameOiSp = "Nombre";
  otheIncomeTypeEn = "Other Income Type";
  otheIncomeTypeSp = "Otra tipo de ingresos";
  howMuchEn = "How Much";
  howMuchSp = "Cuánto";
  howOftenOiEn = "How Often";
  howOftenOiSp = "Frecuencia"

  //Katie Beckett Summary translations
  childEn = "Child";
  childSp = "Niño";
  reasonForApplyingKbEn = "Reason for Applying <br> to Katie Beckett";
  reasonForApplyingKbSp = "Razón para solicitar Katie Beckett";
  primaryContactEn = "Primary Contact";
  primaryContactSp = "Contacto primario"

  //Shelter or Utility & Dependent Care Expenses Summary translations
  nameSuEn = "Name";
  nameSuSp = "Nombre";
  expenseTypeEn = "Expense Type";
  expenseTypeSp = "Tipo de gasto";
  howMuchSuEn = "How Much";
  howMuchSuSP = "Cuánto";
  howOftenSuEn = "How Often";
  howOftenSuSp = "Frecuencia";
  personReceivingCareEn = "Person Receiving Care";
  personReceivingCareSp = "Persona que Recibió la Atención"

  //Access to other coverage Summary
  contactNameEn = "Contact Name";
  contactNameSp = "Nombre del contacto";
  MinimumRequirementMetEn = "Minimum Requirement Met?";
  MinimumRequirementMetSp = "¿Se cumple el requisito mínimo?";

  //Medical Bills Summary Translations
  nameMbEn = "Name";
  nameMbSp = "Nombre";
  persoWhoReceivedCareEn = "Person Who <br>Received Care";
  persoWhoReceivedCareSp = "Persona que recibió la atención";
  medicalBillTypeEn = "Medical Bill Type";
  medicalBillTypeSp = "Tipo de Factura Médica";
  amountPaidEn = "Total Amount";
  amountPaidSp = "Cantidad total";
  amountUnpaidEn = "Amount you have already paid";
  amountUnpaidSp = "Cantidad que ya pagó";
  kbMedicalBillsSwen = "Medical Bills in the <br>last 3 months";
  kbMedicalBillsSwsp = "Facturas médicas en los <br>últimos 3 meses";

  //Financial Resources Summary
  ownerEn = "Owner";
  ownerSp = "Propietario";
  typeEn = "Type";
  typeSp = "Tipo";
  bankOrCompanyNameEn = "Bank or <br/> Company Name";
  bankOrCompanyNameSp = "Nombre del banco o compañía";
  totalValueEn = "Total Value";
  totalValueSp = "Valor total";

  //Vehicle Summary
  vehicleEn = "Vehicle";
  vehicleSp = "Vehículo";
  amountOwedEn = "Amount Owed";
  amountOwedSp = "Cantidad adeudada"

  //Property Summary
  propertyTypeEn = "Property Type";
  propertyTypeSp = "Tipo de propiedad";
  propertyValueEn = "Property Value";
  propertyValueSp = "Valor de la propiedad"

  //Burial Summary
  burrialTypeEn = "Burial Type";
  burrialTypeSp = "Tipo de Entierro"

  //Life Insurance Summary
  policyTypeEn = "Policy Type";
  policyTypeSp = "Tipo de póliza";
  faveValueEn = "Face Value";
  faveValueSp = "Valor nominal";
  insuranceNumberEn = "Insurance </br> Company/ Policy Number";
  insuranceNumberSp = "Aseguradora/Número de póliza"

  //Other Resources Summary
  valueEn = "Value";
  valueSp = "Valor"

  //Transferred Resources Summary
  originalOwnerEn = "Original Owner";
  originalOwnerSp = "Propietario original";
  amountReceivedEn = "Amount Received";
  amountReceivedSp = "Cantidad recibida";
  transferDateEn = "Transfer Date";
  transferDateSp = "Fecha de transferencia";
  name = [];
  taxPopUpOne;
  taxPopUpOneEnOne = "You already told us about tax information for "
  taxPopUpOneSpOne = "Ya nos dio la información fiscal para "
  taxPopUpTwo;
  taxPopUpOneEnTwo = ". Click Edit for the tax filer if you want to make changes.";
  taxPopUpOneSpTwo = ". Haga clic en Editar para el declarante de impuestos si quiere hacer cambios."
  headOfHouseHoldContent;

  //Appeals Representative Details Summary
  representativeEn = "Name";
  representativeSp = "Nombre";
  phoneNumberEn = "Phone Number";
  phoneNumberSp = "Número de Teléfono";
  relationshipEn = "Relationship";
  relationshipSp = "Relación";
  addressEn = "Address";
  addressSp = "Dirección";

  options;
  constructor(public translateService: TranslateService) { }


  ngAfterViewInit() {
    if (!this.sectionId && this.rows && this.rows.length > 0) {
      this.flexSection = this.rows[0].identifier.sectionId;
    }
    if (this.sectionId !== 'HHSUR' && this.elementRef) {
      this.elementRef.nativeElement.focus();
    }
  }

  ngOnInit() {
    const defaultLanguage = this.translateService.getDefaultLang();
    if (defaultLanguage === 'en') {
      this.taxPopUpOne = this.taxPopUpOneEnOne;
      this.taxPopUpTwo = this.taxPopUpOneEnTwo;
    } else {
      this.taxPopUpOne = this.taxPopUpOneSpOne;
      this.taxPopUpTwo = this.taxPopUpOneSpTwo;
    }
    const eligibleSectionId = ["XPUTL", "XPDEP", "XPOTH", "XPSUP"];
    this.isAFB = this.applicationType === 'AFB';
    if (this.sectionId === "INMED" && this.isAFB) {
      for (let i = 0; this.rows.length > i; i++) {
        const data = this.rows[i].data;
        this.showKbMedicalBills = data.kbMedicalBillsSw?.code ? true : false;
        const obj = {
          'grow': 5,
          'name': "Medical Bills in the <br>last 3 months",
          'prop': "data.kbMedicalBillsSw.value"
        }
        for (let i = 0; this.columns.length > i; i++) {
          if (this.columns[i].name === obj.name || this.columns[i].name === this.kbMedicalBillsSwsp) {
            this.kbMedicalBillsRow = true;
          }
        }
        if (this.showKbMedicalBills && !this.kbMedicalBillsRow) {
          this.columns.splice(1, 0, obj);
        }
        if (data.kbMedicalBillsSw?.code === 'N') {
          data.medicalBillType = { "value": "N/A" };
          data.careReceivingIndvNumber = "N/A"
          let index = this.columns.findIndex(i => i.prop == 'data.medicalBillOweAmt');
          let ind = this.columns.findIndex(i => i.prop == 'data.medicalBillUnpaidAmt');
          if (data.medicalBillOweAmt === 'N/A' && index != -1) {
            delete this.columns[index].pipe;
          }
          else {
            this.columns[index].pipe = 'currency';
          }
          if (data.medicalBillUnpaidAmt === 'N/A' && ind != -1) {
            delete this.columns[ind].pipe;
          } else {
            this.columns[ind].pipe = 'currency';
          }
        }
      }
    }
    if (this.sectionId === "ARPDT") {
      this.appealActionHeader = 'AppealsAction'
      let authElig;
      this.appealRows = [];
      this.rows.forEach(opt => {
        if (opt.data.contactDetails?.cell) {
          opt.data.contactDetails.cell = `(${opt.data.contactDetails.cell.substr(0, 3)}) ${opt.data.contactDetails.cell.substr(3, 3)}-${opt.data.contactDetails.cell.substr(6)}`;
        }
        if (opt.data.representativeAddress?.address?.zip && opt.data.representativeAddress?.address?.zip.length > 5) {
          opt.data.representativeAddress.address.zip = `${opt.data.representativeAddress.address.zip.substr(0, 5)}-${opt.data.representativeAddress.address.zip.substr(5,4)}`;
        }
        if (opt.data.eligibleRepresentative?.eligRepresentAddress?.address?.zip && opt.data.eligibleRepresentative?.eligRepresentAddress?.address?.zip.length > 5) {
          opt.data.eligibleRepresentative.eligRepresentAddress.address.zip = `${opt.data.eligibleRepresentative?.eligRepresentAddress?.address.zip.substr(0, 5)}-${opt.data.eligibleRepresentative?.eligRepresentAddress?.address.zip.substr(5,4)}`;
        }
        if (opt.data.contactDetails?.work) {
          opt.data.contactDetails.work = `(${opt.data.contactDetails.work.substr(0, 3)}) ${opt.data.contactDetails.work.substr(3, 3)}-${opt.data.contactDetails.work.substr(6)}`;
        }
        if (opt.data.contactDetails?.cell && opt.data.contactDetails?.work) {
          this.hasPhonenumbers = true;
        }
        if(opt.data.eligibleRepresentative?.eligRepresentPhoneNum){
          opt.data.eligibleRepresentative.eligRepresentPhoneNum = `(${opt.data.eligibleRepresentative?.eligRepresentPhoneNum.substr(0, 3)}) ${opt.data.eligibleRepresentative?.eligRepresentPhoneNum.substr(3, 3)}-${opt.data.eligibleRepresentative?.eligRepresentPhoneNum.substr(6)}`;
        }
        this.appealRows.push(opt);
      });
      for (let i=0; this.rows.length > i; i++) {
        const appealAuthRepData = this.rows[i]['data']['eligibleRepresentative'];
        authElig = appealAuthRepData?.eligAuthSelected === true ? appealAuthRepData : null;
      }
      if (authElig) {
      this.appealRows.push({'data': 
        {
          'name': {'firstName': authElig.eligRepresentativeName, 'lastName': ""},
          'contactDetails': authElig.eligRepresentPhoneNum ? {'cell': authElig.eligRepresentPhoneNum} : {'cell': "N/A"},
          'appeallantRelationship': {'code': null, 'spValue': "Representante autorizado elegible", 'value': "Eligible authorized Representative"},
          'representativeAddress': authElig.eligRepresentAddress?.address ? authElig.eligRepresentAddress : {'address' : {'addressLine1': "N/A",'addressLine2': "", 'city': "", 'state': {'code': '', 'value': ''}, 'zip': '', 'county': {'code': '', 'value': ''}}},
          'disableEdit': true,
        } 
      })
    }
    for (let i=0; this.appealRows.length > i; i++) {
      const appealAuthRepData = this.appealRows[i]['data']['addReprentative'];
      if(appealAuthRepData?.code === 'N') {
        this.appealRows.splice(i,1)
      }
    }
    }
    if (defaultLanguage !== 'en') {
      if (this.sectionId === "HHHLC") {
        this.healthCoverage(defaultLanguage);
      } else if (this.sectionId === "HHIND") {
        this.peopleSummary(defaultLanguage);
      } else if (this.sectionId === "HHTJF") {
        this.taxSummary(defaultLanguage);
      } else if (this.sectionId === "ICEMI") {
        this.employmentSummary(defaultLanguage);
      } else if (this.sectionId === "ICSEM") {
        this.selfEmploymentSummary(defaultLanguage);
      } else if (this.sectionId === "ICOTH") {
        this.otherIncomeSummary(defaultLanguage);
      } else if (this.sectionId === "OTKBR") {
        this.katieBeckettSummary(defaultLanguage);
      } else if (contains(this.sectionId, eligibleSectionId)) {
        this.shelterOrUtilityExpenseSummary(defaultLanguage);
      } else if (contains(this.sectionId, eligibleSectionId)) {
        this.shelterOrUtilityExpenseSummary(defaultLanguage);
      } else if (this.sectionId === "INMED") {
        this.medicalBillsSummary(defaultLanguage);
      } else if (this.sectionId === "ASLIQ") {
        this.financialResourcesSummary(defaultLanguage);
      } else if (this.sectionId === "ASVEH" || this.sectionId === "ASBUR") {
        this.vehicleAndBurialSummary(defaultLanguage);
      } else if (this.sectionId === "ASPRP") {
        this.propertySummary(defaultLanguage);
      } else if (this.sectionId === "ASLIF") {
        this.lifeInsuranceSummary(defaultLanguage);
      } else if (this.sectionId === "OTHRS") {
        this.otherResourcesSummary(defaultLanguage);
      } else if (this.sectionId === "ASCLD") {
        this.transferredResourcesSummary(defaultLanguage);
      } else if (this.sectionId === "ARPDT") {
        this.appealRepresentativeDetailsSummary(defaultLanguage);
      }
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      if (this.sectionId === "HHHLC") {
        this.healthCoverage(res.lang);
      } else if (this.sectionId === "HHIND") {
        this.peopleSummary(res.lang);
      } else if (this.sectionId === "HHTJF") {
        this.taxSummary(res.lang);
      } else if (this.sectionId === "ICEMI") {
        this.employmentSummary(res.lang);
      } else if (this.sectionId === "ICSEM") {
        this.selfEmploymentSummary(res.lang);
      } else if (this.sectionId === "ICOTH") {
        this.otherIncomeSummary(res.lang);
      } else if (this.sectionId === "OTKBR") {
        this.katieBeckettSummary(res.lang);
      } else if (contains(this.sectionId, eligibleSectionId)) {
        this.shelterOrUtilityExpenseSummary(res.lang);
      } else if (this.sectionId === "INMED") {
        this.medicalBillsSummary(res.lang);
      } else if (this.sectionId === "ASLIQ") {
        this.financialResourcesSummary(res.lang);
      } else if (this.sectionId === "ASVEH" || this.sectionId === "ASBUR") {
        this.vehicleAndBurialSummary(res.lang);
      } else if (this.sectionId === "ASPRP") {
        this.propertySummary(res.lang);
      } else if (this.sectionId === "ASLIF") {
        this.lifeInsuranceSummary(res.lang);
      } else if (this.sectionId === "OTHRS") {
        this.otherResourcesSummary(res.lang);
      } else if (this.sectionId === "ASCLD") {
        this.transferredResourcesSummary(res.lang);
      } else if (this.sectionId === "ARPDT") {
        this.appealRepresentativeDetailsSummary(res.lang);
      }
    });
    if (this.sectionId === 'HHSUR') {
      this.options = this.rows.map(() => { });
    }

    if (this.sectionId === 'HHTJF') {
      for (let i = 0; this.rows.length > i; i++) {
        const fullName = this.individuals.filter(value => value.identifier === this.rows[i].identifier.individualId)[0]?.name?.fullName;
        this.name.push(fullName)
      }
    }
    else if (this.sectionId === "HHIND") {
      this.setHoHPopContent(defaultLanguage);
    }
   
    if ((this.rows?.length > 1 && this.sectionId === 'HHTJF')) {   
      const fileterdRows =  this.rows.filter(element => element['data']['taxFilingStatus'])
      this.rows = fileterdRows;
      const taxFiler = this.rows.filter(element => element['data']['taxFilingStatus'] === 'Filer')
      console.log(taxFiler)
      if(fileterdRows.length > 1 && taxFiler.length){
      this.enableEditReview = true; 
      }
    } 
  }
  
  appealRepresentativeDetailsSummary(lang) {
    if (lang !== 'en') { 
      console.log(this.columns)
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.representativeEn) {
          this.columns[i].name = this.representativeSp
        } else if (this.columns[i].name === this.phoneNumberEn) {
          this.columns[i].name = this.phoneNumberSp
        } else if (this.columns[i].name === this.relationshipEn) {
          this.columns[i].name = this.relationshipSp
        } else if (this.columns[i].name === this.addressEn) {
          this.columns[i].name = this.addressSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.representativeSp) {
          this.columns[i].name = this.representativeEn
        } else if (this.columns[i].name === this.phoneNumberSp) {
          this.columns[i].name = this.phoneNumberEn
        } else if (this.columns[i].name === this.relationshipSp) {
          this.columns[i].name = this.relationshipEn
        } else if (this.columns[i].name === this.addressSp) {
          this.columns[i].name = this.addressEn
        }
      }
    }
    return this.columns;
  }

  transferredResourcesSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.originalOwnerEn) {
          this.columns[i].name = this.originalOwnerSp
        } else if (this.columns[i].name === this.typeEn) {
          this.columns[i].name = this.typeSp
        } else if (this.columns[i].name === this.valueEn) {
          this.columns[i].name = this.valueSp
        } else if (this.columns[i].name === this.amountReceivedEn) {
          this.columns[i].name = this.amountReceivedSp
        } else if (this.columns[i].name === this.transferDateEn) {
          this.columns[i].name = this.transferDateSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.originalOwnerSp) {
          this.columns[i].name = this.originalOwnerEn
        } else if (this.columns[i].name === this.typeSp) {
          this.columns[i].name = this.typeEn
        } else if (this.columns[i].name === this.valueSp) {
          this.columns[i].name = this.valueEn
        } else if (this.columns[i].name === this.amountReceivedSp) {
          this.columns[i].name = this.amountReceivedEn
        } else if (this.columns[i].name === this.transferDateSp) {
          this.columns[i].name = this.transferDateEn
        }
      }
    }
    return this.columns;
  }

  otherResourcesSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerEn) {
          this.columns[i].name = this.ownerSp
        } else if (this.columns[i].name === this.typeEn) {
          this.columns[i].name = this.typeSp
        } else if (this.columns[i].name === this.valueEn) {
          this.columns[i].name = this.valueSp
        } else if (this.columns[i].name === this.amountOwedEn) {
          this.columns[i].name = this.amountOwedSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerSp) {
          this.columns[i].name = this.ownerEn
        } else if (this.columns[i].name === this.typeSp) {
          this.columns[i].name = this.typeEn
        } else if (this.columns[i].name === this.valueSp) {
          this.columns[i].name = this.valueEn
        } else if (this.columns[i].name === this.amountOwedSp) {
          this.columns[i].name = this.amountOwedEn
        }
      }
    }
    return this.columns;
  }

  lifeInsuranceSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerEn) {
          this.columns[i].name = this.ownerSp
        } else if (this.columns[i].name === this.policyTypeEn) {
          this.columns[i].name = this.policyTypeSp
        } else if (this.columns[i].name === this.faveValueEn) {
          this.columns[i].name = this.faveValueSp
        } else if (this.columns[i].name === this.insuranceNumberEn) {
          this.columns[i].name = this.insuranceNumberSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerSp) {
          this.columns[i].name = this.ownerEn
        } else if (this.columns[i].name === this.policyTypeSp) {
          this.columns[i].name = this.policyTypeEn
        } else if (this.columns[i].name === this.faveValueSp) {
          this.columns[i].name = this.faveValueEn
        } else if (this.columns[i].name === this.insuranceNumberSp) {
          this.columns[i].name = this.insuranceNumberEn
        }
      }
    }
    return this.columns;
  }

  propertySummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerEn) {
          this.columns[i].name = this.ownerSp
        } else if (this.columns[i].name === this.propertyTypeEn) {
          this.columns[i].name = this.propertyTypeSp
        } else if (this.columns[i].name === this.amountOwedEn) {
          this.columns[i].name = this.amountOwedSp
        } else if (this.columns[i].name === this.propertyValueEn) {
          this.columns[i].name = this.propertyValueSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerSp) {
          this.columns[i].name = this.ownerEn
        } else if (this.columns[i].name === this.propertyTypeSp) {
          this.columns[i].name = this.propertyTypeEn
        } else if (this.columns[i].name === this.amountOwedSp) {
          this.columns[i].name = this.amountOwedEn
        } else if (this.columns[i].name === this.propertyValueSp) {
          this.columns[i].name = this.propertyValueEn
        }
      }
    }
    return this.columns;
  }

  vehicleAndBurialSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerEn) {
          this.columns[i].name = this.ownerSp
        } else if (this.columns[i].name === this.vehicleEn) {
          this.columns[i].name = this.vehicleSp
        } else if (this.columns[i].name === this.totalValueEn) {
          this.columns[i].name = this.totalValueSp
        } else if (this.columns[i].name === this.amountOwedEn) {
          this.columns[i].name = this.amountOwedSp
        } else if (this.columns[i].name === this.burrialTypeEn) {
          this.columns[i].name = this.burrialTypeSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerSp) {
          this.columns[i].name = this.ownerEn
        } else if (this.columns[i].name === this.vehicleSp) {
          this.columns[i].name = this.vehicleEn
        } else if (this.columns[i].name === this.totalValueSp) {
          this.columns[i].name = this.totalValueEn
        } else if (this.columns[i].name === this.amountOwedSp) {
          this.columns[i].name = this.amountOwedEn
        } else if (this.columns[i].name === this.burrialTypeSp) {
          this.columns[i].name = this.burrialTypeEn
        }
      }
    }
    return this.columns;
  }

  financialResourcesSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerEn) {
          this.columns[i].name = this.ownerSp
        } else if (this.columns[i].name === this.typeEn) {
          this.columns[i].name = this.typeSp
        } else if (this.columns[i].name === this.bankOrCompanyNameEn) {
          this.columns[i].name = this.bankOrCompanyNameSp
        } else if (this.columns[i].name === this.totalValueEn) {
          this.columns[i].name = this.totalValueSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.ownerSp) {
          this.columns[i].name = this.ownerEn
        } else if (this.columns[i].name === this.typeSp) {
          this.columns[i].name = this.typeEn
        } else if (this.columns[i].name === this.bankOrCompanyNameSp) {
          this.columns[i].name = this.bankOrCompanyNameEn
        } else if (this.columns[i].name === this.totalValueSp) {
          this.columns[i].name = this.totalValueEn
        }
      }
    }
    return this.columns;
  }

  medicalBillsSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameMbEn) {
          this.columns[i].name = this.nameMbSp
        } else if (this.columns[i].name === this.persoWhoReceivedCareEn) {
          this.columns[i].name = this.persoWhoReceivedCareSp
        } else if (this.columns[i].name === this.medicalBillTypeEn) {
          this.columns[i].name = this.medicalBillTypeSp
        } else if (this.columns[i].name === this.amountPaidEn) {
          this.columns[i].name = this.amountPaidSp
        } else if (this.columns[i].name === this.amountUnpaidEn) {
          this.columns[i].name = this.amountUnpaidSp
        } else if (this.columns[i].name === this.kbMedicalBillsSwen) {
          this.columns[i].name = this.kbMedicalBillsSwsp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameMbSp) {
          this.columns[i].name = this.nameMbEn
        } else if (this.columns[i].name === this.persoWhoReceivedCareSp) {
          this.columns[i].name = this.persoWhoReceivedCareEn
        } else if (this.columns[i].name === this.medicalBillTypeSp) {
          this.columns[i].name = this.medicalBillTypeEn
        } else if (this.columns[i].name === this.amountPaidSp) {
          this.columns[i].name = this.amountPaidEn
        } else if (this.columns[i].name === this.amountUnpaidSp) {
          this.columns[i].name = this.amountUnpaidEn
        } else if (this.columns[i].name === this.kbMedicalBillsSwsp) {
          this.columns[i].name = this.kbMedicalBillsSwen
        }
      }
    }
    return this.columns;
  }

  shelterOrUtilityExpenseSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameSuEn) {
          this.columns[i].name = this.nameSuSp
        } else if (this.columns[i].name === this.expenseTypeEn) {
          this.columns[i].name = this.expenseTypeSp
        } else if (this.columns[i].name === this.howMuchSuEn) {
          this.columns[i].name = this.howMuchSuSP
        } else if (this.columns[i].name === this.howOftenSuEn) {
          this.columns[i].name = this.howOftenSuSp
        } else if (this.columns[i].name === this.personReceivingCareEn) {
          this.columns[i].name = this.personReceivingCareSp
        } else if (this.columns[i].name === this.contactNameEn) {
          this.columns[i].name = this.contactNameSp
        } else if (this.columns[i].name === this.MinimumRequirementMetEn) {
          this.columns[i].name = this.MinimumRequirementMetSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameSuSp) {
          this.columns[i].name = this.nameSuEn
        } else if (this.columns[i].name === this.expenseTypeSp) {
          this.columns[i].name = this.expenseTypeEn
        } else if (this.columns[i].name === this.howMuchSuSP) {
          this.columns[i].name = this.howMuchSuEn
        } else if (this.columns[i].name === this.howOftenSuSp) {
          this.columns[i].name = this.howOftenSuEn
        } else if (this.columns[i].name === this.personReceivingCareSp) {
          this.columns[i].name = this.personReceivingCareEn
        } else if (this.columns[i].name === this.contactNameSp) {
          this.columns[i].name = this.contactNameEn
        } else if (this.columns[i].name === this.MinimumRequirementMetSp) {
          this.columns[i].name = this.MinimumRequirementMetEn
        }
      }
    }
    return this.columns;
  }

  katieBeckettSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.childEn) {
          this.columns[i].name = this.childSp
        } else if (this.columns[i].name === this.reasonForApplyingKbEn) {
          this.columns[i].name = this.reasonForApplyingKbSp
        } else if (this.columns[i].name === this.primaryContactEn) {
          this.columns[i].name = this.primaryContactSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.childSp) {
          this.columns[i].name = this.childEn
        } else if (this.columns[i].name === this.reasonForApplyingKbSp) {
          this.columns[i].name = this.reasonForApplyingKbEn
        } else if (this.columns[i].name === this.primaryContactSp) {
          this.columns[i].name = this.primaryContactEn
        }
      }
    }
    return this.columns;
  }

  otherIncomeSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameOiEn) {
          this.columns[i].name = this.nameOiSp
        } else if (this.columns[i].name === this.otheIncomeTypeEn) {
          this.columns[i].name = this.otheIncomeTypeSp
        } else if (this.columns[i].name === this.howMuchEn) {
          this.columns[i].name = this.howMuchSp
        } else if (this.columns[i].name === this.howOftenOiEn) {
          this.columns[i].name = this.howOftenOiSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameOiSp) {
          this.columns[i].name = this.nameOiEn
        } else if (this.columns[i].name === this.otheIncomeTypeSp) {
          this.columns[i].name = this.otheIncomeTypeEn
        } else if (this.columns[i].name === this.howMuchSp) {
          this.columns[i].name = this.howMuchEn
        } else if (this.columns[i].name === this.howOftenOiSp) {
          this.columns[i].name = this.howOftenOiEn
        }
      }
    }
    return this.columns;
  }

  selfEmploymentSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameSeEn) {
          this.columns[i].name = this.nameSeSp
        } else if (this.columns[i].name === this.bussinessDescriptionEn) {
          this.columns[i].name = this.bussinessDescriptionSp
        } else if (this.columns[i].name === this.typeOfBussinessEn) {
          this.columns[i].name = this.typeOfBussinessSp
        } else if (this.columns[i].name === this.netIncomeEn) {
          this.columns[i].name = this.netIncomeSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameSeSp) {
          this.columns[i].name = this.nameSeEn
        } else if (this.columns[i].name === this.bussinessDescriptionSp) {
          this.columns[i].name = this.bussinessDescriptionEn
        } else if (this.columns[i].name === this.typeOfBussinessSp) {
          this.columns[i].name = this.typeOfBussinessEn
        } else if (this.columns[i].name === this.netIncomeSp) {
          this.columns[i].name = this.netIncomeEn
        }
      }
    }
    return this.columns;
  }

  employmentSummary(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.rows.length > i; i++) {
        if (this.rows[i].data.wage.paymentPeriod?.spValue) {
          const employmentValue = this.rows[i].data.wage.paymentPeriod.value
          if(this.rows[i].data.wage.paymentPeriod?.spValue !== employmentValue){
            this.rows[i].data.wage.paymentPeriod.enValue = employmentValue
          }
          this.rows[i].data.wage.paymentPeriod.value = this.rows[i].data.wage.paymentPeriod?.spValue
          }
      }
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameEn) {
          this.columns[i].name = this.nameSp
        } else if (this.columns[i].name === this.employerEn) {
          this.columns[i].name = this.employerSp
        } else if (this.columns[i].name === this.startDateEn) {
          this.columns[i].name = this.startDateSp
        } else if (this.columns[i].name === this.howOftenEn) {
          this.columns[i].name = this.howOftenSp
        } else if (this.columns[i].name === this.amountEn) {
          this.columns[i].name = this.amountSp
        }
      }
    } else {
      for (let i = 0; this.rows.length > i; i++) {  
        if (this.rows[i].data.wage.paymentPeriod?.enValue) {
          this.rows[i].data.wage.paymentPeriod.value = this.rows[i].data.wage.paymentPeriod?.enValue
          }
      }
    for (let i = 0; this.columns.length > i; i++) {
      if (this.columns[i].name === this.nameSp) {
          this.columns[i].name = this.nameEn
        } else if (this.columns[i].name === this.employerSp) {
          this.columns[i].name = this.employerEn
        } else if (this.columns[i].name === this.startDateSp) {
          this.columns[i].name = this.startDateEn
        } else if (this.columns[i].name === this.howOftenSp) {
          this.columns[i].name = this.howOftenEn
        } else if (this.columns[i].name === this.amountSp) {
          this.columns[i].name = this.amountEn
        }
      }
    }
    return this.columns;
  }

  taxSummary(lang) {
    if (lang !== 'en') {
      this.taxPopUpOne = this.taxPopUpOneSpOne;
      this.taxPopUpTwo = this.taxPopUpOneSpTwo;
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.houseHoldMemberEn) {
          this.columns[i].name = this.houseHoldMemberSp
        } else if (this.columns[i].name === this.taxFilingStatusEn) {
          this.columns[i].name = this.taxFilingStatusSp
        } else if (this.columns[i].name === this.taxDependentOutsideHouseholdEn) {
          this.columns[i].name = this.taxDependentOutsideHouseholdSp
        } else if (this.columns[i].name === this.jointFilerOutsideHouseholdEn) {
          this.columns[i].name = this.jointFilerOutsideHouseholdSp
        } else if (this.columns[i].name === this.deductionTypeEn) {
          this.columns[i].name = this.deductionTypeSp
        }
      }
    } else {
      this.taxPopUpOne = this.taxPopUpOneEnOne;
      this.taxPopUpTwo = this.taxPopUpOneEnTwo;
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.houseHoldMemberSp) {
          this.columns[i].name = this.houseHoldMemberEn
        } else if (this.columns[i].name === this.taxFilingStatusSp) {
          this.columns[i].name = this.taxFilingStatusEn
        } else if (this.columns[i].name === this.taxDependentOutsideHouseholdSp) {
          this.columns[i].name = this.taxDependentOutsideHouseholdEn
        } else if (this.columns[i].name === this.jointFilerOutsideHouseholdSp) {
          this.columns[i].name = this.jointFilerOutsideHouseholdEn
        } else if (this.columns[i].name === this.deductionTypeSp) {
          this.columns[i].name = this.deductionTypeEn
        }
      }
    }
    return this.columns;
  }

  peopleSummary(lang) {
    this.setHoHPopContent(lang)
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameSsnEn) {
          this.columns[i].name = this.nameSsnSp
        } else if (this.columns[i].name === this.dobEn) {
          this.columns[i].name = this.dobSp
        } else if (this.columns[i].name === this.applyForCoverageEn) {
          this.columns[i].name = this.applyForCoverageSp
        } else if (this.columns[i].name === this.pregnantEn) {
          this.columns[i].name = this.pregnantSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.nameSsnSp) {
          this.columns[i].name = this.nameSsnEn
        } else if (this.columns[i].name === this.dobSp) {
          this.columns[i].name = this.dobEn
        } else if (this.columns[i].name === this.applyForCoverageSp) {
          this.columns[i].name = this.applyForCoverageEn
        } else if (this.columns[i].name === this.pregnantSp) {
          this.columns[i].name = this.pregnantEn
        }
      }
    }
    return this.columns;
  }

  healthCoverage(lang) {
    if (lang !== 'en') {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.policyHeaderEn) {
          this.columns[i].name = this.policyHeaderSp
        } else if (this.columns[i].name === this.whoIsCoveredEn) {
          this.columns[i].name = this.whoIsCoveredSp
        } else if (this.columns[i].name === this.insuranceCompanyPolicyEn) {
          this.columns[i].name = this.insuranceCompanyPolicySp
        } else if (this.columns[i].name === this.monthlyPremiumEn) {
          this.columns[i].name = this.monthlyPremiumSp
        }
      }
    } else {
      for (let i = 0; this.columns.length > i; i++) {
        if (this.columns[i].name === this.policyHeaderSp) {
          this.columns[i].name = this.policyHeaderEn
        } else if (this.columns[i].name === this.whoIsCoveredSp) {
          this.columns[i].name = this.whoIsCoveredEn
        } else if (this.columns[i].name === this.insuranceCompanyPolicySp) {
          this.columns[i].name = this.insuranceCompanyPolicyEn
        } else if (this.columns[i].name === this.monthlyPremiumSp) {
          this.columns[i].name = this.monthlyPremiumEn
        }
      }
    }
    return this.columns;
  }

  setHoHPopContent(lang) {
    const hoh_detail = this.rows.find(ele => ele.data.headOfHousehold);
    if (hoh_detail) {
      this.headOfHouseHoldContent = `${hoh_detail.data.name.fullName} is the Head of Household. To make changes to ${hoh_detail.data.name.fullName}'s name or address, click the green check mark at the top of your screen for Head of Household.`;
      if (lang !== 'en') {
        this.headOfHouseHoldContent = `${hoh_detail.data.name.fullName} es el Jefe de Familia. Para hacer cambios al nombre o la dirección de ${hoh_detail.data.name.fullName}, haga clic en la marca de verificación verde en la parte superior de la pantalla de Jefe de Familia.`;
      }
    }
  }

  onEdit(record: Record) {
    this.edit.emit(record);
  }

  onDelete(record: Record) {
    if (this.sectionId === "PRASM") {
      this.delete.emit({ record: record });
    } else {
      this.delete.emit({ record: record });
    }
  }

  
  onDeleteAplRow(record: Record) {
    if(record.data.disableEdit) {
      record.data['appeallantRelationship'] = {'code': "ER",'value': "ER"};
      record.data['eligibleRepresentative'] = {'eligAuthSelected': false};
      record['identifier'] = {'sectionId': 'ARPDT'};
      record['exportedData'] = null;
      record['exportingStatus'] = "ADDED"
    }
      this.delete.emit({ record: record });
  }

  canDelete(row = emptyRecord({})) {
    return !isHeadOfHousehold(row.data);
  }

  onChange() {
    if (this.sectionId === 'HHSUR') {
      this.change.emit(this.options);
    }
  }
}
