<nh-alert type="danger" [isOpen]="isInvalidFileSize">
  <div class="row">
    <div class="icon">
      <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span>{{ 'FILE_SIZE' | translate}}</span>
    </div>
  </div>
</nh-alert>
<nh-alert type="danger" [isOpen]="showErrorMessage">
  <div class="row">
    <div class="icon">
      <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span>{{ 'ERROR_FILE_UPLOAD' | translate}}</span>
    </div>
  </div>
</nh-alert>
<nh-alert type="danger" [isOpen]="isPasswordProtected">
  <div class="row">
    <div class="icon">
      <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span>{{ 'FILE_PWD_PROTECTED' | translate}}</span>
    </div>
  </div>
</nh-alert>
<nh-alert type="danger" [isOpen]="isInvalidFileType">
  <div class="row">
    <div class="icon">
      <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span>{{ 'FILE_TYPE' | translate}}</span>
    </div>
  </div>
</nh-alert>
<div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
  [uploader]="uploader" (onFileDrop)="onFileSelected($event)" class="file-upload">
  <!-- <i class="fa fa-upload"></i> -->
  <img aria-label="arrowUpImage" src="../../../assets/images/⚛️ icons-arrow-up-circle-outline.svg">
  <div class="d-block d-lg-none hover-red">
      <a class="btn btn-link hover-red">
        <span class="pr-2"  (click)="fileInput.click()">Upload File</span>
      </a>
  </div>
  <div class="d-none d-lg-block">
      <a class="btn btn-link drag-drop-style">
        <span class="pr-2">{{'DRAG_AND_DROP_FILES' | translate }}</span>
      </a>
  </div>
</div>

<br>

<div>
  <button class="d-none d-lg-block btn btn-block btn-secondary" (click)="fileInput.click()" type="button" id="loadFileXml"
      value="loadXml"> {{'CHOOSE_FILE' | translate }} </button>
</div>
<div class="small light-text pt-2">{{'UPLOAD_DOC_TEXT5' | translate }}: .tiff, .bmp, .jpg, .pdf </div>
<input #fileInput [uploader]="uploader" style="display: none;" id="file" name="file" ng2FileSelect type="file"
  (onFileSelected)="onFileSelected($event)" />
  