import { getDataValue } from '@app/helpers/object.helpers';
import { ApiService } from '../../../../gateway/services/api.service';
import { ProcessService } from '../../../../services/process.service';
import { FormBuilder } from '@angular/forms';
import { ModalService } from '../../../../services/modal/modal.service';
import { Component, AfterContentInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import { equals, join, isNil, gt, isEmpty } from 'ramda';
//import { setValue } from '@app/helpers/form.helpers';
import { setRequired } from '@app/helpers/form-control.helpers';
import { isNo, isYes, setValueNo, setValueYes } from '@app/helpers/tayn.helpers';
//import { valueChanges } from '@app/helpers/form-group.helpers';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { toggles, swapControls, swapControl } from '@app/helpers/forms.helpers';
import { isOther } from '@app/helpers/models.helpers';
import { codeEqual } from '@app/helpers/code.helpers';
import { minus9Days } from "@app/helpers/date.helpers";
import { SigninService } from '@app/gateway/signin/signin.service';
import { Subscription } from 'rxjs';
import Vs from '@app/services/validators/validators.service';
import { Title } from '@angular/platform-browser';
import { setValue } from '@app/helpers/form.helpers';
import { ServiceConfigService } from '@app/services/service-config.service';
import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';

@Component({
  templateUrl: './household-presumptive.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HouseholdPresumptiveComponent extends SectionFormDirective implements AfterContentInit, OnDestroy {
  fullName;
  showCommonCtrls = false;
  modifiedPAddress = false;
  showAssistingProof = false;
  showAssistingNotices = false;
  caseheadPopoverText = `Choose "military" only if you have a military address`;
  assistingPersonPop1 = `An Assisting Person can be someone who is helping you complete this application or it can be someone that, with your permission, can make decisions and talk to us on behalf of your household. If you want an Assisting Person to help you, you can tell us what you want your Assisting Person to help with.`
  assistingPersonPop2 = `An Assisting Person would be someone like an Authorized Representative, Conservator, Legal Guardian, Power of Attorney for anyone else who you would like to help you manage your coverage or receive your notices.`;
  languagePopup = `Right now, we can only send letters in English or Spanish. Interpreter services are free to get help in other languages by calling 855-259-0701.`;
  minimumDate = minus9Days();
  today: any = new Date();
  userData: any;
  partnerName: any;
  addressSub: Subscription;
  appType = sessionStorage.getItem('appType');
  isAssistingPersonNameSameAsHoh = false;
  currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
  showWellpoint: boolean = false;

  constructor(private titleService: Title, private modalService: ModalService, builder: FormBuilder, public processService: ProcessService, public apiService: ApiService, public signinService: SigninService, private readonly serviceConfigService: ServiceConfigService
  ) {
    super(builder);
    this.titleService.setTitle('Presumptive Household');
    this.addressSub = this.processService.processAddressValidate.subscribe((action) => this.addressValidate(action));
  }
  user = sessionStorage.getItem('userType');

  isPartnerPortal = this.user === 'RP' ? true : false
  addressValidate(action) {
    const appNum = this.applicationNumber;
    const formData = this.formGroup.value.data;
    const physical = getDataValue('physicalAddress.address', formData);
    const physicalFormat = getDataValue('physicalAddress.addressFormat', formData);
    const isSaveExit = equals(action, 'fromSaveExit') ? true : false;

    const mailing = getDataValue('mailingAddress.address', formData);
    const mailingFormat = getDataValue('mailingAddress.addressFormat', formData);

    const assisting = getDataValue('assistingPerson.address', formData);
    const assistingFormat = getDataValue('assistingPerson.addressFormat', formData);

    const postObject = {
      addressList: [
        { addressType: 'PHYSICAL', originalAddress: physical },
        { addressType: 'MAILING', originalAddress: mailing },
        { addressType: 'ASSISTING', originalAddress: assisting }
      ]
    };

    if (isNil(physical) || codeEqual('MI', physicalFormat)) {
      postObject.addressList.splice(0, 1);
    }

    if (isNil(mailing) || codeEqual('MI', mailingFormat)) {
      equals(postObject.addressList.length, 2) ? postObject.addressList.splice(0, 1) : postObject.addressList.splice(1, 1);
    }

    if (isNil(assisting) || codeEqual('MI', assistingFormat)) {
      equals(postObject.addressList.length, 1) ? postObject.addressList.splice(0, 1) : gt(postObject.addressList.length, 2) ? postObject.addressList.splice(2, 1) : postObject.addressList.splice(1, 1);
    }

    if (isEmpty(postObject.addressList)) {
      this.processService.addressValidated = true;
    } else {
      this.apiService.post('applications/' + appNum + '/sections/HHAPL/address', postObject, {headers: new HttpHeaders({'pageId': 'HHAPL'})}, true).subscribe((res) => {
        if (!isNil(res)) {
          this.modalService.openAddressModal(res, isSaveExit).subscribe(result => {
            if (result !== true) {
              if (result) {
                const phyIndex = result.findIndex(i => i.addressType === 'PHYSICAL');
                const mailIndex = result.findIndex(i => i.addressType === 'MAILING');
                const assisIndex = result.findIndex(i => i.addressType === 'ASSISTING');
                if (phyIndex === 0) {
                  const addressLine1 = this.getControl('data.physicalAddress.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.physicalAddress.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.physicalAddress.address.city', this.formGroup);
                  const state = this.getControl('data.physicalAddress.address.state', this.formGroup);
                  const zip = this.getControl('data.physicalAddress.address.zip', this.formGroup);

                  if (result[0].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[0].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[0].suggestedAddress.addressLine2);
                    city.setValue(result[0].suggestedAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[0].suggestedAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[0].suggestedAddress.zip);
                  } else if (result[0].selectedAddress === 'Original') {
                    addressLine1.setValue(result[0].originalAddress.addressLine1);
                    addressLine2.setValue(result[0].originalAddress.addressLine2);
                    city.setValue(result[0].originalAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[0].originalAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[0].originalAddress.zip);
                  }
                };
                if (mailIndex === 0 || mailIndex === 1) {
                  const idx = mailIndex;
                  const addressLine1 = this.getControl('data.mailingAddress.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.mailingAddress.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.mailingAddress.address.city', this.formGroup);
                  const state = this.getControl('data.mailingAddress.address.state', this.formGroup);
                  const zip = this.getControl('data.mailingAddress.address.zip', this.formGroup);

                  if (result[idx].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[idx].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[idx].suggestedAddress.addressLine2);
                    city.setValue(result[idx].suggestedAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].suggestedAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].suggestedAddress.zip);
                  } else if (result[idx].selectedAddress === 'Original') {
                    addressLine1.setValue(result[idx].originalAddress.addressLine1);
                    addressLine2.setValue(result[idx].originalAddress.addressLine2);
                    city.setValue(result[idx].originalAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].originalAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].originalAddress.zip);
                  }
                };

                if (assisIndex === 1 || assisIndex === 2) {
                  const idx = assisIndex;
                  const addressLine1 = this.getControl('data.assistingPerson.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.assistingPerson.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.assistingPerson.address.city', this.formGroup);
                  const state = this.getControl('data.assistingPerson.address.state', this.formGroup);
                  const zip = this.getControl('data.assistingPerson.address.zip', this.formGroup);

                  if (result[idx].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[idx].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[idx].suggestedAddress.addressLine2);
                    city.setValue(result[idx].suggestedAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].suggestedAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].suggestedAddress.zip);
                  } else if (result[idx].selectedAddress === 'Original') {
                    addressLine1.setValue(result[idx].originalAddress.addressLine1);
                    addressLine2.setValue(result[idx].originalAddress.addressLine2);
                    city.setValue(result[idx].originalAddress.city);
                    const selectedState = this.tables.STATE.filter(s => s.code == result[idx].originalAddress.state.code)[0];
                    state.setValue(selectedState);
                    zip.setValue(result[idx].originalAddress.zip);
                  }
                };
                this.processService.addressValidated = true;
              }
            }
          })
        } else {
          this.processService.addressValidated = true;
        }
      })
    }

  }

  showAssistPerson() {
    this.modalService.openAssistPersonTypes();
  }

  safeAtHomeOnChange(event: any) {
    if(event.target.value === 'Yes'){
        this.getControl('data.sahMailId').setValidators([Vs.required, Vs.safeAtHomeMailID]);;
        this.getControl('data.sahMailId').updateValueAndValidity();
        this.getControl('data.sahCountyCd').setValidators(Vs.required);
        this.getControl('data.sahCountyCd').updateValueAndValidity();
        const addMailingInfo = this.getControl('extras.helpers.addMailingInfo');
        addMailingInfo.setValue(({ code: '', value: '' }));
        this.getControl('data.physicalAddress.address').clearValidators();
        this.getControl('data.physicalAddress.address').reset();
        this.getControl('data.physicalAddress.address').disable();
        this.getControl('data.physicalAddress.address').updateValueAndValidity();
        this.getControl('data.physicalAddress.addressFormat').reset();
        this.getControl('data.physicalAddress.addressFormat').updateValueAndValidity();

      }
    else{
        this.getControl('data.sahMailId').setValidators(null);
        this.getControl('data.sahMailId').setValue('');
        this.getControl('data.sahMailId').updateValueAndValidity();
        this.getControl('data.sahCountyCd').setValidators(null);
        this.getControl('data.sahCountyCd').setValue('');
        this.getControl('data.sahCountyCd').updateValueAndValidity();
        this.getControl('data.physicalAddress').enable();
        const homeless = this.getControl('data.homeless');

        if (homeless.value && homeless.value.code === 'Y') {
          this.getControl('data.mailingAddress').enable();
          this.getControl('data.physicalAddress.address').reset();
          this.getControl('data.physicalAddress.address').disable();
          this.getControl('data.physicalAddress.addressFormat').reset();
          this.getControl('data.physicalAddress.addressFormat').disable();
        }
    }
  }

  onChange(event, val?) {

    const resp = this.getControl('data.assistingPerson.responsibilities');
    const applicantBehalf = this.getControl('data.assistingPerson.responsibilities.applicantBehalf')
    const submitRenewal = this.getControl('data.assistingPerson.responsibilities.submitRenewal');
    const otherLetter = this.getControl('data.assistingPerson.responsibilities.letterOtherCommunication');
    const setVal = (c, v) => setValue(c, v);
    const setAppBehalf = () => setVal("A", applicantBehalf);
    const setSubRenewal = () => setVal("S", submitRenewal);
    const setOtherLtr = () => setVal("L", otherLetter);

    const resetAppBehalf = () => applicantBehalf.reset();
    const resetSubmitRen = () => submitRenewal.reset();
    const resetOther = () => otherLetter.reset();
    if (event.target ?.checked === true && val) {
      switch (val) {
        case 'S':
          setAppBehalf();
          break;
        case 'L':
          setAppBehalf();
          setSubRenewal();
          break;
        case 'Y':
          setAppBehalf();
          setSubRenewal();
          setOtherLtr();
          break;
      }
      resp.setErrors(null);
      applicantBehalf.setValidators(null);
      applicantBehalf.setErrors(null);
    } else if (event.target ?.checked === false && val) {
      switch (val) {
        case 'S':
          resetAppBehalf();
          break;
        case 'L':
          resetAppBehalf();
          resetSubmitRen();
          break;
        case 'Y':
          resetAppBehalf();
          resetSubmitRen();
          resetOther();
          break;
      }
      resp.setErrors({ required: true });
    // } else if (event.code === 'AR' || event.code === 'OH') {
    //   applicantBehalf.setValidators(Vs.required);
    // } else if(event.code === 'Y' && val === 'AssistPerson' && this.isKepro){
    //   name.patchValue({firstName: this.firstName, lastName:this.lastName })
    // } else {

    //   //MP condition
    //   const condition1 = (this.user !== 'RP') && (this.getValue('data.assistingOnBehalf').value === 'Assisting Person' || this.isY('data.addAssistingPersonToApp'));

    //  //PP FRM condition
    //    const condition2 = ((this.isY('data.ppAddAssistingPersonToApp') || this.getValue('data.ppAssistingOnBehalf').value === 'Assisting Person')) && (this.user === 'RP' && (this.appType === 'RB' || this.appType === 'RC' || this.appType === 'REDETERMINATION' || this.isFinReassessment));

    //    //PP FC condition
    //    const condition3 = (this.isY('data.ppAddAssistingPersonToApp') || this.isY('data.ppAssistingOnBehalf')) && (this.user === 'RP' && this.appType === 'AFB');
    //   const relationship = this.getControl('data.assistingPerson.relationship');
      
    //     if( condition1 || condition2 || condition3) {
    //       relationship.enable();
    //       relationship.updateValueAndValidity();
    //     } else{
    //       relationship.disable();
    //       relationship.updateValueAndValidity();
    //     }
      applicantBehalf.clearValidators();

    }
  }

  ngAfterContentInit() {
    this.wellpointData();
    const primaryLanguage = this.getControl('data.languagePreference.primaryLanguage');
    const otherLanguageName = this.getControl('data.languagePreference.otherLanguageName');
    const addMailingInfo = this.getControl('extras.helpers.addMailingInfo');
    const dateOfApplication = this.getControl('data.dateOfApplication');
    const healthPlan = this.getControl('data.healthPlan');
    const relationship = this.getControl('data.assistingPerson.relationship');
    const ppAssistingOnBehalf = this.getControl('data.ppAssistingOnBehalf');
    const homeless = this.getControl('data.homeless');
    const ppAddAssistingPersonToApp = this.getControl('data.ppAddAssistingPersonToApp');
    const helpDuration = this.getControl('data.assistingPerson.helpDuration');
    const name = this.getControl('data.assistingPerson.name');
    const organizationName = this.getControl('data.assistingPerson.organizationName');
    const idNumber = this.getControl('data.assistingPerson.idNumber');

    const addressFormatP = this.getControl('data.physicalAddress.addressFormat');
    const physicalAddress = this.getControl('data.physicalAddress.address');

    const addressFormatM = this.getControl('data.mailingAddress.addressFormat');
    const mailingAddress = this.getControl('data.mailingAddress.address');

    const addressFormatA = this.getControl('data.assistingPerson.addressFormat');
    const assistAddress = this.getControl('data.assistingPerson.address');
    const assistPersonhelpDuration = this.getControl('data.assistingPerson.helpDuration');
    const assistingOnBehalf1 = this.getControl('data.ppAssistingOnBehalf');
    
    if (assistPersonhelpDuration.value) {
      const tableVar = this.tables.AUTHREPTIME.filter((item) => item.code === assistPersonhelpDuration.value.code);
      this.tableData(tableVar);
      assistPersonhelpDuration.patchValue(tableVar[0]);
    }
    if (assistingOnBehalf1.value) {
      const tableVar = this.tables.YESNO.filter((item) => item.code === assistingOnBehalf1.value.code);
      this.tableData(tableVar);
      assistingOnBehalf1.patchValue(tableVar[0]);
    }
 
    const email = this.getControl('data.assistingPerson.email');
    const bestTime = this.getControl('data.assistingPerson.bestTime');
    const contactPhones = this.getControl('data.assistingPerson.contactPhones');
    const casehead = this.getControl('data.casehead');

    const responsibilities = this.getControl('data.assistingPerson.responsibilities');
    const applicantBehalf = responsibilities.get('applicantBehalf');
    const submitRenewal = responsibilities.get('submitRenewal');
    const otherLetter = responsibilities.get('letterOtherCommunication');
    const yourBehalf = responsibilities.get('yourBehalf');

    const assistControls = [
      name,
      organizationName,
      idNumber,
      addressFormatA,
      assistAddress,
      email,
      bestTime,
      contactPhones,
      relationship,
      responsibilities,
      helpDuration
    ];

    const isHomeless = [mailingAddress, addressFormatM];
    const notHomeless = [physicalAddress, addressFormatP, addMailingInfo];


    // const setVal = (c, v) => setValue(c, v);
    // const setAppBehalf = () => setVal("A", applicantBehalf);
    // const setSubRenewal = () => setVal("S", submitRenewal);
    // const setOtherLtr = () => setVal("L", otherLetter);

    // const resetAppBehalf = () => applicantBehalf.reset();
    // const resetSubmitRen = () => submitRenewal.reset();
    // const resetOther = () => otherLetter.reset();

    // valueChanges(submitRenewal).subscribe(val => equals(val, "S") ? setAppBehalf() : resetAppBehalf());
    // valueChanges(otherLetter).subscribe(val => equals(val, "L") ? (setAppBehalf(), setSubRenewal(), this.showAssistingNotices = true) : (resetAppBehalf(), resetSubmitRen(), this.showAssistingNotices = false));
    // valueChanges(yourBehalf).subscribe(val => equals(val, "Y") ? (setAppBehalf(), setSubRenewal(), setOtherLtr()) : (resetAppBehalf(), resetSubmitRen(), resetOther()));

    physicalAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
    });
    mailingAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
    });
    assistAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
    });


    this.registerToggle(ppAddAssistingPersonToApp,
      toggles([
        swapControl(ppAssistingOnBehalf, isNo),
        swapControls(assistControls, isYes)
      ])
    );
    // this.registerToggle(assistingPerson,
    //   toggles([
    //     swapControl(addToApplication, this.isY),
    //     swapControls(assistControls, this.isN)
    //   ])
    // );

    this.registerToggle(
      homeless,
      toggles([
        swapControls(notHomeless, isNo),
        swapControls(isHomeless, isYes)
      ])
    );
    this.registerToggle(primaryLanguage, swapControl(otherLanguageName, isOther));
    this.registerToggle(
      ppAssistingOnBehalf,
      toggles([
        swapControls(assistControls, isYes)
      ])
    );
    this.registerToggle(addMailingInfo, swapControls([mailingAddress, addressFormatM], isNo));

    if (this.individuals.length) {
      if (this.individuals[0].name.firstName) {
        const { firstName, middleInitial, lastName, suffix } = this.individuals[0].name;
        this.fullName = join(' ', [firstName, middleInitial, lastName, suffix ? suffix.value : ''])
      }
    };

    const createName = () => {
      const { firstName, middleInitial, lastName, suffix } = casehead.value;
      this.fullName = join(' ', [firstName, middleInitial, lastName, suffix ? suffix.value : '']);
    }

    this.registerToggle(casehead, () => createName());
    if (!mailingAddress.value.zip && !physicalAddress.value.zip) {
      addMailingInfo.setValue(({ code: '', value: '' }));
      setRequired(addMailingInfo);
    } else if (!mailingAddress.value.zip) {
      setValueYes(addMailingInfo);
    } else if (mailingAddress.value.zip) {
      setValueNo(addMailingInfo)
    }

    if (addMailingInfo.value.code !== '') {
      const tableVar = this.tables.YESNO.filter((item) => item.code === addMailingInfo.value.code);
      if (tableVar.length > 0) {
        tableVar[0].value = addMailingInfo.value.value;
        addMailingInfo.setValue(tableVar[0]);
      }
    }

    if (this.isPartnerPortal) {
      addressFormatP.clearValidators();
    } else {
      dateOfApplication.clearValidators();
      healthPlan.clearValidators();
    }

    this.signinService.getAccountData(sessionStorage.getItem('userName')).subscribe((data) => {
      this.userData = data;
      this.partnerName = `${data['firstName']} ${data['lastName']}`;
    })

    if(ppAddAssistingPersonToApp.value?.code === 'Y') {
      name.enable();
      assistAddress.enable();
      organizationName.enable();
      email.enable();
      bestTime.enable();
      contactPhones.enable();
    }

    if(ppAssistingOnBehalf.value?.code === 'Y' || ppAddAssistingPersonToApp.value?.code === 'Y') {
    this.getControl('data.assistingPerson.ackSignSw').enable();
    this.getControl('data.assistingPerson.assistingPersonSignName').enable();

    }
    if(ppAssistingOnBehalf.value?.code === 'N' && ppAddAssistingPersonToApp.value?.code === 'N') {
      this.getControl('data.assistingPerson.ackSignSw').disable();
      this.getControl('data.assistingPerson.assistingPersonSignName').disable();
    }

    // this.getControl('data.assistingPerson.ackSignSw').disable();
    // this.getControl('data.assistingPerson.assistingPersonSignName').disable();

    if(casehead.value) { 
      const { firstName, middleInitial, lastName, suffix } = casehead.value;
      this.fullName = join(' ', [firstName, middleInitial, lastName, suffix ? suffix.value : '']);
    }

     if(relationship.value?.code === 'AR') {
       applicantBehalf.enable();
       submitRenewal.enable();
       otherLetter.enable();
       yourBehalf.enable();

    }

    const sahPrgSw = this.getControl('data.sahPrgSw');
    if (sahPrgSw.value) {
      if  (sahPrgSw.value?.code === 'Y') {
        this.getControl('data.physicalAddress.address').disable();
      } 
    }
    if (homeless.value) {
      if  (homeless.value?.code === 'Y') {
        this.getControl('data.physicalAddress.address').disable();
        this.getControl('data.physicalAddress.addressFormat').disable();
      }
    }

    const applicantAckSignSw = this.getControl('data.assistingPerson.applicantAckSignSw');
    const applicantSignName = this.getControl('data.assistingPerson.applicantSignName');
    if(relationship?.value?.code === 'AR'){
      applicantAckSignSw.enable();
      applicantAckSignSw.updateValueAndValidity();
      applicantSignName.enable();
      applicantSignName.setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
      applicantAckSignSw.updateValueAndValidity();
    }else{
      applicantAckSignSw.disable();
      applicantAckSignSw.disable();
      applicantAckSignSw.updateValueAndValidity();
      applicantSignName.clearValidators();
      applicantSignName.clearValidators();
      applicantSignName.updateValueAndValidity();
    }

  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  tableData(tableVar) {
      if (tableVar.length > 0) {
        tableVar[0].value = tableVar[0]?.value;
      }
    return tableVar;
  }

    onAssistingPersonPartnerSelected(event: any) {
   if (event.target.value === 'Yes') {
     this.getControl('data.assistingPerson.name').patchValue({ firstName: this.userData.firstName, lastName: this.userData.lastName });
     this.getControl('data.ppAddAssistingPersonToApp').patchValue({ code: 'Y', value: 'Yes', spValue: "Si" });
     this.getControl('data.assistingPerson.ackSignSw').setValue('');
     this.getControl('data.assistingPerson.assistingPersonSignName').setValue('');
     this.getControl('data.assistingPerson.ackSignSw').enable();
     this.getControl('data.assistingPerson.assistingPersonSignName').enable();

     this.getControl('data.assistingPerson.applicantAckSignSw').setValue('');
     this.getControl('data.assistingPerson.applicantSignName').setValue('');
     this.getControl('data.assistingPerson.applicantAckSignSw').enable();
     this.getControl('data.assistingPerson.applicantSignName').enable();
     
    } else {
     this.getControl('data.ppAddAssistingPersonToApp').patchValue({ code: 'N', value: 'No', spValue: "No" });
     this.getControl('data.assistingPerson.ackSignSw').disable();
     this.getControl('data.assistingPerson.assistingPersonSignName').disable();
     this.getControl('data.assistingPerson.applicantAckSignSw').disable();
     this.getControl('data.assistingPerson.applicantSignName').disable();
    }
 }
 onAssistingPersonPartnerSelectedBehalf(event:any){
  if (event.target.value === 'Yes') {
    this.getControl('data.assistingPerson.ackSignSw').setValue('');
    this.getControl('data.assistingPerson.assistingPersonSignName').setValue('');
    this.getControl('data.assistingPerson.ackSignSw').enable();
     this.getControl('data.assistingPerson.assistingPersonSignName').enable();

     this.getControl('data.assistingPerson.applicantAckSignSw').setValue('');
     this.getControl('data.assistingPerson.applicantSignName').setValue('');
     this.getControl('data.assistingPerson.applicantAckSignSw').enable();
     this.getControl('data.assistingPerson.applicantSignName').enable();
  }
  else{
     this.getControl('data.assistingPerson.ackSignSw').disable();
     this.getControl('data.assistingPerson.assistingPersonSignName').disable();
     this.getControl('data.assistingPerson.applicantAckSignSw').disable();
     this.getControl('data.assistingPerson.applicantSignName').disable();
  }
 } 

 Selected(event: any) {
  const responsibilities = this.getControl('data.assistingPerson.responsibilities');
  const applicantBehalf = responsibilities.get('applicantBehalf');
  const submitRenewal = responsibilities.get('submitRenewal');
  const otherLetter = responsibilities.get('letterOtherCommunication');
  const yourBehalf = responsibilities.get('yourBehalf');
  const applicantAckSignSw = this.getControl('data.assistingPerson.applicantAckSignSw');
  const applicantSignName = this.getControl('data.assistingPerson.applicantSignName');

 if (event.target.value === 'Authorized Representative') {
    applicantBehalf.enable();    
    applicantBehalf.setValidators(Vs.required);
    applicantBehalf.updateValueAndValidity();
    submitRenewal.enable();
    otherLetter.enable();
    yourBehalf.enable();

    applicantAckSignSw.enable();
    applicantAckSignSw.setValue('');
    applicantAckSignSw.setValidators( Vs.required);
    applicantAckSignSw.updateValueAndValidity();
    applicantSignName.enable();
    applicantSignName.setValue('');
    applicantSignName.setValidators([Vs.required, Vs.maxLength(30), Vs.signatureName]);
    
  }
  else {
    applicantBehalf.setValidators(null);
    applicantBehalf.setErrors(null);
    applicantAckSignSw.disable();
    applicantAckSignSw.updateValueAndValidity();
    applicantAckSignSw.clearValidators();
    applicantSignName.disable();
    applicantSignName.clearValidators();
  }
}
  // onAssistingBehalf(event: any) {
  //   const ppAssistingOnBehalf = this.getControl('data.ppAssistingOnBehalf');
  //   const addToApplication = this.getControl('data.ppAddAssistingPersonToApp');
  //   const organizationName = this.getControl('data.assistingPerson.organizationName');
  //   const idNumber = this.getControl('data.assistingPerson.idNumber');
  //   const name = this.getControl('data.assistingPerson.name');

  //   const addressFormatA = this.getControl('data.assistingPerson.addressFormat');
  //   const assistAddress = this.getControl('data.assistingPerson.address');
  //   const email = this.getControl('data.assistingPerson.email');
  //   const bestTime = this.getControl('data.assistingPerson.bestTime');
  //   const contactPhones = this.getControl('data.assistingPerson.contactPhones');
  //   const relationship = this.getControl('data.assistingPerson.relationship');
  //   const responsibilities = this.getControl('data.assistingPerson.responsibilities');
  //   const helpDuration = this.getControl('data.assistingPerson.helpDuration');
  //   const assistControls = [
  //     name,
  //     organizationName,
  //     idNumber,
  //     addressFormatA,
  //     assistAddress,
  //     email,
  //     bestTime,
  //     contactPhones,
  //     relationship,
  //     responsibilities,
  //     helpDuration
  //   ];
  //   this.registerToggle(ppAssistingOnBehalf,
  //     toggles([
  //       swapControl(addToApplication, isNo),
  //       swapControls(assistControls, isYes)
  //     ])
  //   );
  //   if (event.target.value == 'Yes') {
  //     this.getControl('data.ppAssistingOnBehalf').patchValue({
  //       code: "Y", value: "Yes", spValue: "Si"
  //     })
  //   } else if (event.target.value == 'No') {
  //     this.getControl('data.ppAssistingOnBehalf').patchValue({
  //       code: "N", value: "No", spValue: "No"
  //     })
  //   }
  // }

  validateAssistingPersonName() {
    const casehead = this.getControl('data.casehead');
    const name = this.getControl('data.assistingPerson.name');
    if (casehead.value.firstName === name.value.firstName && casehead.value.middleInitial === name.value.middleInitial && casehead.value.lastName === name.value.lastName && casehead.value.suffix === name.value.suffix) {
      this.isAssistingPersonNameSameAsHoh = true;
    } else {
      this.isAssistingPersonNameSameAsHoh = false;
    }
    if (this.isAssistingPersonNameSameAsHoh) {
      this.getControl('data.hiddenField').setValidators(Vs.required);
      this.getControl('data.hiddenField').updateValueAndValidity();
    } else {
      this.getControl('data.hiddenField').clearValidators();
      this.getControl('data.hiddenField').updateValueAndValidity();
    }
    return this.isAssistingPersonNameSameAsHoh;
  }

  wellpointData(): void {
    const fDate = new Date(this.serviceConfigService.getServerConfig()?.wellpointEffDate);
    const cDate = new Date(this.currentDate);
    if (cDate >= fDate) {
      this.showWellpoint = true;
    }
  }


  ngOnDestroy() {
    this.addressSub.unsubscribe();
  }

}