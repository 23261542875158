<div class="container mb-2">
  <div class="row">
    <div class="col-12">
      <h1 class="page-title">{{'REPORT_CHANGE' | translate }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="card pb-2" style="width:100%">
      <div class="card-body">
          <div *ngIf="response" class="d-none d-lg-block">
            <div class="row">
            <table class="table">
              <thead>
                <tr>
                  <th>{{'Case' | translate }}</th>
                  <th>{{'Action' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.caseDetails">
                  <td>{{ item.caseNum }}</td>
                  <td>
                    <button *ngIf="item.openRenewalSW === 'N' && !item.isReportSsiEligble && !item.isDcsEligible && item.hoHID === this.indivId" class="btn btn-block btn-rcsecondary" (click)="onReportChange(item.caseNum, 'RC')">{{'REPORT_CHANGE' | translate }}</button>
                    <p *ngIf="item.openRenewalSW === 'Y'">{{'REPORT_CHANGE_STATIC1' | translate }}</p>
                    <p *ngIf="(item.openRenewalSW === 'N' || item.openRenewalSW === null) && item.isReportSsiEligble">{{'REPORT_SSI_ELIGBLE' | translate }}</p>
                    <p *ngIf="(item.openRenewalSW === 'N' || item.openRenewalSW === null) && item.isDcsEligible">{{'REPORT_DCS_ELIGIBLE' | translate }}<br><br> {{'REPORT_DCS_ELIGIBLE_1' | translate }}</p>
                    <p *ngIf="item.hoHID !== this.indivId">{{'REPORT_CHANGE_STATIC2' | translate }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
          <div *ngIf="response">
            <div class="nh-table-mobile d-lg-none">
              <ng-container *ngIf="caseSummary.length">
                <div *ngFor="let item of caseDetails">
                  <div *ngIf="item?.openRenewalSW === 'N'" class="table-row mb-3 pb-3">
                    <div *ngIf="item.openRenewalSW" class="mobile-table-header">{{'Case' | translate}}</div>
                    <hr>
                    <div *ngIf="item.openRenewalSW" class="mobile-table-data">{{item.caseNum}}</div>
                    <br>
                    <div *ngIf="item.openRenewalSW" class="mobile-table-header">{{'Action' | translate }}</div>
                  </div>
                  <div class="mobile-table-data">
                    <button *ngIf="item.openRenewalSW === 'N' && !item.isReportSsiEligble && !item.isDcsEligible && item.hoHID === this.indivId" class="w-100 btn btn-block btn-rcsecondary" (click)="onReportChange(item.caseNum, 'RC')">{{'REPORT_CHANGE' | translate }}</button>
                    <p *ngIf="item.openRenewalSW === 'Y'">{{'REPORT_CHANGE_STATIC1' | translate }}</p>
                    <p *ngIf="item.openRenewalSW === 'N' && item.isReportSsiEligble">{{'REPORT_SSI_ELIGBLE' | translate }}</p>
                    <p *ngIf="item.openRenewalSW === 'N' && item.isDcsEligible">{{'REPORT_DCS_ELIGIBLE' | translate }}<br><br> {{'REPORT_DCS_ELIGIBLE_1' | translate }}</p>
                    <p *ngIf="item.hoHID !== this.indivId">{{'REPORT_CHANGE_STATIC2' | translate }}</p>
                  </div>
                  <br>
                  <hr>
                </div>
              </ng-container>
              <p *ngIf="!caseSummary.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
