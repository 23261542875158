<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <div *ngIf="invaidRelation || invaidRelationOutside">
    <nh-alert type="danger" [isOpen]="invaidRelation || invaidRelationOutside">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
        </div>
        <div class="message">
          <span>{{ invalidRel }}</span>
        </div>
      </div>
    </nh-alert>
  </div>
  <div *ngIf="invalidUnknownGenderRelation">
    <nh-alert type="danger" [isOpen]="invalidUnknownGenderRelation">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
        </div>
        <div class="message">
          <span>{{ unknownGenderRelationError }}</span>
        </div>
      </div>
    </nh-alert>
  </div>
  <div>
    <h5 class="section-header">Relationship Details</h5>
    <hr>
    <p>Please tell us how the people in the home are related to each other.</p>
    <section [formGroup]="getControl('data')">

      <div [formGroup]="getControl('data.householdRelationshipInformation')">
        <table class="table w-100 table-striped">
          <thead>
            <tr>
              <th scope="col">First Person</th>
              <th scope="col">Relationship</th>
              <th scope="col">Second Person</th>
              <th scope="col">Primary Caregiver</th>
              <th scope="col">Joint filers</th>
            </tr>
          </thead>
          <tbody formArrayName="relationshipList">
            <tr *ngFor="let row of relationshipList.controls; index as i; last as isLast" [formGroupName]="i">
              <td>
                {{ name(row.value.sourceIndividual) }},
                {{ age(row.value.sourceIndividual) }}
                {{ fetchGender(row.value.sourceIndividual) }}
              </td>
              <td>
                <nh-field [isRow]="true" [mandatory]="false" class="label-section">
                  <select aria-label="relation" nh name="relation" formControlName="relation"
                    [items]="tables[gender(getControl('data.householdRelationshipInformation.relationshipList'), i)]"
                    (change)="onRelationChange('relationshipList',row,i,true)"></select>
                  <span class="form-text invalid-name d-block" *ngIf="isDuplicateRelation(i)">One individual cannot
                    have more than one spouse</span>
                    <span class="form-text invalid-name d-block" *ngIf="isParentRelationError(i) && !isDuplicateRelation(i)">{{parentRelationError}}</span>
                    <span class="form-text invalid-name d-block" *ngIf="isYoungerParent(i) && !isParentRelationError(i) && !isDuplicateRelation(i)">{{'PARENT_GRANDPARENT_ERROR' | translate}}</span>
                </nh-field>
              </td>
              <td>
                {{ name(row.value.referenceIndividual) }},
                {{ age(row.value.referenceIndividual) }}
                {{ fetchGender(row.value.referenceIndividual) }}
              </td>
              <td>
                <nh-checkbox name="primaryCaregiver" (change)="primaryCaregivers(relationshipList)" [control]="row.controls.primaryCaregiver" [checkedValue]="val"
                  *ngIf="getPrimaryCare(row?.value?.relation?.code) && !row?.value?.isReverseFlow"
                  class="label-section">
                </nh-checkbox>
              </td>
              <td>
                <nh-checkbox name="jointFilers" [control]="row.controls.jointFilers" [checkedValue]="val"
                  *ngIf="getJointFiler(row?.value?.relation?.code) && !row?.value?.isReverseFlow" class="label-section">
                </nh-checkbox>
              </td>
            </tr>
            <tr>
              <span *ngIf="ifRequired" class="invalid-name d-block">You can only select up to two primary caretakers per child.</span>
            </tr>
          </tbody>
        </table>
        <br>
        <div hidden>
          <h5 class="section-header">Primary Caregiver</h5>
          <hr>
          <!-- <div (click)="showPrimaryCaregiverModal()" [style.color]="'#2274aa'">More about Primary Caregivers
            <span><i class="fa fa-external-link"></i></span>
          </div> -->

          <div class="row pl-1 pt-1 hover-red">
            <a class="btn btn-link hover-red" (click)="showPrimaryCaregiverModal()">
              <span class="pr-2">More about Primary Caregivers</span>
              <span><i class="fa fa-external-link"></i></span>
            </a>
          </div>

          <br>
          <div formArrayName="primaryCareList">
            <ng-container>
              <table class="form-row">
                <tr class="w-100 row">
                  <th class="col-3 col-md-3">First Person</th>
                </tr>
                <tr class="w-100" *ngFor="let primaryCareList of primaryCareList.controls; let i = index;">
                  <fieldset [formGroupName]="i" class="w-100 row">
                    <td class="col-3 col-md-3">{{ name(primaryCareList.value.indvId) }}</td>
                  </fieldset>
                </tr>
              </table>
            </ng-container>
          </div>
        </div>
      </div>
      <br>
      <div *ngIf="taxFilersEnabled">
        <h5 class="section-header">Tax Dependents in the Household</h5>
        <hr>
        <div *ngIf="error.isError" class="small alert alert-danger">
          {{ error.errorMessage }}
        </div>
        <table class="table w-100 table-striped">
          <thead>
            <tr>
              <th scope="col">Person claiming a dependent</th>
              <th scope="col">Person being claimed as a dependent</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody formArrayName="taxFilingData">
            <tr *ngFor="let row of taxFilingData.controls; index as i; last as isLast" [formGroupName]="i">
              <td>
                <nh-field [isRow]="true" [mandatory]="false" class="label-section">
                  <select aria-label="taxFilingIndividualId" nh
                    [attr.disabled]="taxFilingData.controls[i].value.taxFilingIndividualId != null ? true : null"
                    name="taxFilingIndividualId" formControlName="taxFilingIndividualId" bindValue="individualId"
                    bindLabel="name" [items]="getTaxFilers(true)" (change)="onTaxChange($event,i)"></select>
                </nh-field>
              </td>
              <td>
                <nh-field [isRow]="true" [mandatory]="false" class="label-section">
                  <select aria-label="taxDependentIndividualId" nh
                    name="taxDependentIndividualId" formControlName="taxDependentIndividualId" bindValue="individualId"
                    bindLabel="name" [items]="getTaxFilers(false)" (change)="onTaxChange($event,i)"></select>
                  <span class="invalid-name d-block" *ngIf="isDuplicateTaxDependent(i)">You have listed the
                    same tax dependent for {{taxDependentName[i]}} more than once.
                    Please update the tax dependent entry for {{taxDependentName[i]}} before continuing.</span>
                </nh-field>
              </td>
              <td>
                <div class="hover-red">
                  <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeAt(i, row)">
                    <span class="pr-2">Remove</span>
                    <nh-remove-svg></nh-remove-svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pt-1 hover-red text-left">
          <button class="btn btn-link btn-lg hover-red" (click)="addRow()">
            <span class="pr-2">Add Another</span>
            <nh-add-svg></nh-add-svg>
          </button>
        </div>

      </div>
      <br>
      <h5 class="section-header">Tax Dependents / Joint Filers Outside the Household</h5>
      <hr>
      <div *ngIf="errorRelationSPO.isError" class="small alert alert-danger">
        {{ errorRelationSPO.errorMessage }}
      </div>
      <table class="table w-100 table-striped" style="margin-bottom:5px">
        <thead>
          <tr>
            <th scope="col">Person Name</th>
            <th scope="col">Relationship</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Date of Birth</th>
            <th scope="col">Tax Dependents</th>
            <th scope="col">Joint Filer</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody formArrayName="outsideHousehold">
          <tr *ngFor="let row of outsideHousehold.controls; index as i; last as isLast" [formGroupName]="i">
            <td>
              <nh-field [isRow]="true" [mandatory]="false">
                <select aria-label="taxFilerIndividual" nh name="taxFilerIndividual" style="width:140px !important"
                  [attr.disabled]="outsideHousehold.controls[i].value.taxFilerIndividual != null ? true : null"
                  formControlName="taxFilerIndividual" bindValue="individualId" bindLabel="name"
                  [items]="getTaxFilers(true)" (change)="onTaxFilingChange('household', row, i)"></select>
              </nh-field>
            </td>
            <td>
              <select aria-label="relation" nh name="relation" formControlName="relation" style="width:140px !important; margin-bottom: 16px;"
                [items]="tables[gender(getControl('data.outsideHousehold'), i)]"
                (change)="relationChange(row, i)"></select>
            </td>
            <td>
              <nh-field [isRow]="true" [mandatory]="false">
                <input aria-label="dependentFirstName" type="text" name="dependentFirstName" maxlength="22" (change)="onRelationChange('household', row, i, false)"
                  formControlName="dependentFirstName" label="dependentFirstName" class="form-control setWidth">
              </nh-field>
            </td>
            <td>
              <nh-field [isRow]="true" [mandatory]="false">
                <input aria-label="dependentLastName" type="text" name="dependentLastName" maxlength="22" (change)="onRelationChange('household', row, i, false)"
                  formControlName="dependentLastName" label="dependentLastName" class="form-control setWidth">
              </nh-field>
            </td>
            <td style="width:165px !important">
              <nh-field [isRow]="true" [mandatory]="false">
                <nh-datepicker>
                  <input aria-label="dependentBirthDate" type="text" name="dependentBirthDate" class="form-control" (change)="onRelationChange('household', row, i, false)"
                    formControlName="dependentBirthDate" [minDate]="minimumDate" [maxDate]="today" bsDatepicker nh>
                </nh-datepicker>
              </nh-field>
            </td>
            <td>
              <nh-checkbox uniqueString="taxDependent" [disabled]="hasDependent && !row?.value?.taxDependent?.code"
                name="taxDependent" [control]="row.controls.taxDependent"
                (change)="onRelationChange('household', row, i, false)" [checkedValue]="val"
                *ngIf="getDependent(row?.value?.hasTaxDependentOutside?.code) || getJointFiler(row?.value?.relation?.code)"
                label="">
              </nh-checkbox>
            </td>
            <td>
              <nh-checkbox uniqueString="jointFiler" [disabled]="(hasJointFiler && !row?.value?.relation?.code) || errorRelationSPO.isError"
                name="jointFiler" [control]="row.controls.jointFiler"
                (change)="onRelationChange('household',row,i,false)" [checkedValue]="val"
                *ngIf="getJointFiler(row?.value?.relation?.code) && !errorRelationSPO.isError" label="">
              </nh-checkbox>
            </td>
            <td>
              <div class="pt-1 hover-red">
                <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeTaxDependentAt(i, row)">
                  <span class="pr-2">Remove</span>
                  <nh-remove-svg></nh-remove-svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="width:450px" *ngIf="isRequired">
       <span class="invalid-name d-block">You have started providing information about a tax dependent or
         joint filer outside the household. Please provide the rest of the information asked in this row of the table.</span>
     </div>
      <div class="pt-1 hover-red text-left">
        <button class="btn btn-link btn-lg hover-red" (click)="addTaxDependentsRow()">
          <span class="pr-2">Add Another</span>
          <nh-add-svg></nh-add-svg>
        </button>
      </div>
      <div>
        <input type="hidden" formControlName="hiddenField">
      </div>
      <div>
        <input type="hidden" formControlName="hiddenRelationField">
      </div>
    </section>
  </div>
</nh-form>
