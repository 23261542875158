<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title pull-left" id="my-modal-title">{{ title | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
            <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
        </button>
    </div>
    <div class="modal-body">
        <p>{{'HEALTH_PLAN_CANCEL_ERROR_TEXT_01' | translate}}</p>
        <p [innerHTML]="'HEALTH_PLAN_CANCEL_MODAL_TEXT2' | translate"></p>
    </div>
    <div class="modal-footer justify-content-start">
        <div class="mr-2 ml-2">
            <button type="button" id="dialog" class="btn btn-block btn-link" *ngIf="showConfirm"
                (click)="onConfirm()">{{ confirmButton | translate }}</button>
        </div>
    </div>
</div>