<div *ngIf="!rmcUpload && !mobileUser">
  <section>
  <h1 *ngIf="!isChangeMode && !isPresumptive && !isFinReassessment && !isAdmitDischarge && !isNewborn && !isAppeals && !isBCCEXT && !isIME" class="page-title"><span *ngIf="!isPreterm">{{ CONGRATS_STATIC_TEXT}}</span><span *ngIf="isPreterm">{{ CONGRATS_STATIC_TEXT_QFR5}}</span> {{ appKeyWord }}!
  </h1>
  <h1 *ngIf="isAdmitDischarge" class="m-3 admit-discharge-title-head">Success!</h1>
  <h1 *ngIf="isAdmitDischarge" class="m-3 admit-discharge-title-subhead">Your 2350 Form has been sent to TennCare!</h1>
  <h1 *ngIf="isNewborn" class="m-3 page-title">Success! Your Newborn Form has been sent to TennCare!</h1>
  <h1 *ngIf="isChangeMode && !isPresumptive" class="page-title">{{CONGRATS_STATIC_TEXT0}}</h1>
  <h1 *ngIf="isPresumptive" class="m-3 page-title">Success! This application has been sent to TennCare!</h1>
  <h1 *ngIf="isFinReassessment" class="m-3 page-title">Success! This Financial Application has been sent to TennCare!
  </h1>
  <h1 *ngIf="isBCCEXT" class="m-3 page-title">We have your BCC Extension Request Form!</h1>
  <h1 *ngIf="isIME" class="m-3 page-title">Success!<br>Your Incurred Medical Expense Form has been sent to TennCare!</h1>
</section>
<section class="border bg-white p-3" *ngIf="!isPresumptive && !isAdmitDischarge && !isNewborn && !isFinReassessment && !isAppeals && !isBCCEXT && !isIME">
  <section class="p-2">
    <div class="row">
      <div class="col">
        <p class="static-text" *ngIf="!isPreterm">{{ CONGRATS_STATIC_TEXT1 }} {{ appKeyWord_1 }} {{ CONGRATS_STATIC_TEXT2 }} <strong>{{ workerPortalAppNum }}</strong>.</p>
        <p class="static-text" *ngIf="isPreterm">{{ CONGRATS_STATIC_TEXT_QFR6 }} {{ appKeyWord }} {{ CONGRATS_STATIC_TEXT_QFR7 }} <strong>{{ workerPortalAppNum }}</strong>.</p>
        <p class="static-text">{{ CONGRATS_STATIC_TEXT3 }}</p>
        <p *ngIf="!isChangeMode && !isPreterm && !isRedeModeOnly" class="static-text">{{ CONGRATS_STATIC_TEXT4 }} <strong>{{
            appSubmittedDate | date: 'longDate' }}</strong>.</p>
        <p *ngIf="!isChangeMode && !isPreterm && isRedeModeOnly" class="static-text">{{ CONGRATS_STATIC_TEXT4_1 }} <strong>{{
            appSubmittedDate | date: 'longDate' }}</strong>.</p>
        <p *ngIf="!isChangeMode && isPreterm" class="static-text">{{ CONGRATS_STATIC_TEXT4_QFR }} <strong>{{
              appSubmittedDate | date: 'longDate' }}</strong>.</p>
        <p *ngIf="isChangeMode" class="static-text">{{ CONGRATS_CHAGE_MODE }} <strong>{{ appSubmittedDate
            | date:'MM/dd/yyyy' }}</strong>.</p>
      </div>
    </div>
  </section>
  <div class="p-2">
    <div *ngIf="this.isFullMedicAidApp">
      <h5 class="section-header">Confirmation of Your Submission</h5>
      <hr>
      <div class="static-text">
        <p>You can save this as a confirmation of your submission by clicking the <b>Print</b> button at the bottom of this
          screen. You can also access this information at any time via the <a href="/api/prov/submission-search">Search My Submissions </a> page.
        </p>
        <p>You can print this page and give it to the applicant to keep. It has important information that may help them
          later.</p>
      </div>
    </div>

    <div>
      <h5 class="section-header">{{ CONGRATS_STATIC_TEXT5 }}</h5>
      <hr>
      <p *ngIf="rteCaseStatus === 'AP'" class="static-text" [innerHTML]="this.CONGRATS_STATIC_TEXT6"></p>
      <p *ngIf="rteCaseStatus === 'DN'" class="static-text" [innerHTML]="this.CONGRATS_STATIC_TEXT7"></p>
      <div *ngIf="rteCaseStatus === 'PE' || !rteCaseStatus" class="static-text">
        <p *ngIf="!isPreterm && !isRMC" [innerHTML]="this.CONGRATS_STATIC_TEXT8"></p>
        <p *ngIf="!isPreterm && realTimeAppMessage !== 'RT_RMC_SUC' && isRMC" [innerHTML]="this.CONGRATS_STATIC_TEXT_NOT_REAL_TIME8"></p>
        <p *ngIf="isPreterm" [innerHTML]="this.CONGRATS_STATIC_TEXT_QFR1"></p>
        <p *ngIf="breastCervicalCancerSw === 'Y'" [innerHTML]="this.CONGRATS_STATIC_TEXT_QFR2"></p>
        <p *ngIf="isPreterm" [innerHTML]="this.CONGRATS_STATIC_TEXT_QFR3"></p>
        <p *ngIf="this.isFullMedicAidApp">It's okay if you don't have these types of documents right now. We will send
          you a letter asking for these documents if you do not have these documents with you right now.</p>
      </div>
      <div *ngIf="rteCaseStatus === 'RT'" class="static-text">
        <p *ngIf="!isPreterm && realTimeAppMessage !== 'RT_RMC_SUC' && isRMC" [innerHTML]="this.CONGRATS_STATIC_TEXT_NOT_REAL_TIME8"></p>
      </div>
      <div *ngIf="rteCaseStatus === 'RS'" class="static-text">
        <p *ngIf="!isPreterm && realTimeAppMessage === 'RT_RMC_SUC' && isRMC" [innerHTML]="this.CONGRATS_STATIC_TEXT_REAL_TIME8"></p>
      </div>
      <p *ngIf="rteCaseStatus === 'APDN'" class="static-text" [innerHTML]="this.CONGRATS_STATIC_TEXT9"></p>
    </div>

    <div *ngIf="kbDataList?.length > 0 && kbDataList[0]?.kbReferralId !== null">
      <h5 class="section-header">{{ CONGRATS_STATIC_TEXT10 }}</h5>
      <hr>
      <p>{{ CONGRATS_STATIC_TEXT11 }}</p>
      <p *ngFor="let ref of kbDataList">{{ CONGRATS_STATIC_TEXT12 }} {{ref.name}} {{ CONGRATS_STATIC_TEXT13 }}
        <strong>{{ref.kbReferralId}}.</strong><br>{{ CONGRATS_STATIC_TEXT14 }}</p>
      <p>{{ CONGRATS_STATIC_TEXT15 }} <span
          *ngFor="let ref of kbDataList; let isLast=last">{{ref.name}}{{isLast ? '' : ' or '}}</span> {{ CONGRATS_STATIC_TEXT16 }}
      <p [innerHTML]="this.CONGRATS_STATIC_TEXT17"></p>
      <p class="m-0">{{ CONGRATS_STATIC_TEXT18 }}</p>
      <ul>
        <li><strong>{{ CONGRATS_STATIC_TEXT19 }}: </strong> 901-745-7200 or 800-308-2586</li>
        <li><strong>{{ CONGRATS_STATIC_TEXT20 }}: </strong> 615-231-5047</li>
        <li><strong>{{ CONGRATS_STATIC_TEXT21 }}: </strong> 865-588-0508</li> <br>
      </ul>
    </div>

    <div class="mt-4" *ngIf="ecfIndicator === 'Y'">
      <h5 class="section-header">{{ CONGRATS_STATIC_TEXT22 }}</h5>
      <hr>
      <p>{{ CONGRATS_STATIC_TEXT23 }}</p>
      <p>{{ CONGRATS_STATIC_TEXT24 }}<a class="a-link"
          href="https://perlss.tenncare.tn.gov/externalreferral"><b>{{ CONGRATS_STATIC_TEXT25 }}</b></a>. {{ CONGRATS_STATIC_TEXT26 }}</p>
      <p class="m-0">{{ CONGRATS_STATIC_TEXT27 }}</p>
      <ul>
        <li><span class="pr-5">{{ CONGRATS_STATIC_TEXT19 }} Tennessee</span><span class="pl-3">866-372-5709</span></li>
        <li><span class="pr-5">{{ CONGRATS_STATIC_TEXT20 }} Tennessee</span>800-654-4839</li>
        <li><span class="pr-5">{{ CONGRATS_STATIC_TEXT21 }} Tennessee</span><span class="pl-4">888-531-9876</span></li> <br>
      </ul>
    </div>
    
    <div *ngIf="!isNotLinkedAcc && appType === 'AFB'">
      <nh-alert type="warning" [isOpen]="linkyourcase">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <strong>{{LINK_YOUR_CASE_HEADER}}</strong><br>
          </div>
        </div><br>
<p>{{CLICK}} <a class="page-title-small" (click)="moveToLinkMyCase()"><strong><u style="color:#174a7c">{{HERE}}</u></strong></a><span [innerHTML]="LINK_YOUR_CASE_FOR_LETTERS"></span>
            </p>
      </nh-alert>
    </div>
    
    <div *ngIf="isNotLinkedAcc">
    <nh-alert type="warning" [isOpen]="showCommPref">
      <div class="row">
        <div class="icon">
          <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <strong>{{COMM_PREF_FINISHMODULE_BANNER_TEXT1}}</strong><br>
        </div>
      </div><br>
          <p>{{CLICK}} <a class="page-title-small" (click)="moveToCommPref()"><strong><u style="color:#174a7c">{{HERE}}</u></strong></a><span [innerHTML]="COMM_PREF_FINISHMODULE_BANNER_TEXT2"></span>
          </p>
    </nh-alert>
  </div>

  <div *ngIf="isNotLinkedAcc">
    <h5 class="section-header"><strong>{{ CHANGE_HOW_LETTERS }}</strong></h5>
    <hr>
      <p>{{ CHANGE_LETTERS_STATIC_TEXT1 }} {{CLICK}} <a class="page-title-small" (click)="moveToCommPref()"><strong><u style="color:#174a7c">{{HERE}}</u></strong></a> {{ CHANGE_LETTERS_STATIC_TEXT2 }} </p>
  </div>

    <div class="mt-4">
      <h5 *ngIf="!isChangeMode" class="section-header"><strong><span *ngIf="isPreterm">{{ CONGRATS_STATIC_TEXT_QFR8 }}</span><span *ngIf="!isPreterm">{{ CONGRATS_STATIC_TEXT28 }}</span> {{appKeyWord }}</strong></h5>
      <h5 *ngIf="isChangeMode" class="section-header"><strong>{{ CONGRATS_STATIC_TEXT40 }}</strong></h5>
      <hr>
      <p *ngIf="!isPreterm" [innerHTML]="this.CONGRATS_STATIC_TEXT29"></p>
      <p *ngIf="isPreterm" [innerHTML]="this.CONGRATS_STATIC_TEXT_QFR4"></p>
      <p>{{ CONGRATS_STATIC_TEXT30 }}</p>
      <ul class="mb-3 ml-4 print-list">
        <li>{{ CONGRATS_STATIC_TEXT31 }}</li>
        <li>{{ CONGRATS_STATIC_TEXT32 }}</li>
        <li>{{ CONGRATS_STATIC_TEXT33 }}</li>

      </ul>
      <p [innerHTML]="this.CONGRATS_STATIC_TEXT34"></p>
      <div class="mb-3 mt-4">
        <button class="col-md-5 btn btn-secondary-congrats" (click)="printPdf()">{{ CONGRATS_STATIC_TEXT35 }}
          <img src="../../assets/images/printw.svg" alt="print-icon" />
        </button>
      </div>
    </div>
    <p><span *ngIf="!isPreterm">{{ CONGRATS_STATIC_TEXT36 }}</span><span *ngIf="isPreterm">{{ CONGRATS_STATIC_TEXT_QFR9 }}</span> {{appKeyWord }} {{ CONGRATS_STATIC_TEXT42 }} {{ CONGRATS_STATIC_TEXT41 }}<a class="a-link"
        href="https://tenncareconnect.tn.gov/services/jsp/services/faq.html" target="_blank"><strong>{{ CONGRATS_STATIC_TEXT37 }}</strong></a></p>
        <div *ngIf="!isKepro">
          <div *ngIf="rteCaseStatus === 'PE' || !rteCaseStatus" class="mb-3 mt-4">
            <button class="col-md-5 btn btn-secondary-congrats" (click)="goToDocuments()">{{ CONGRATS_STATIC_TEXT38 }}
              <img src="../../assets/images/cloudupload.svg" alt="upload icon" />
            </button>
          </div>
        </div>
  </div>
</section>

<br *ngIf="!isAppeals">

<div class="mr-3 pr-md-1 py-sm-1" *ngIf="!isPresumptive && !isAdmitDischarge && !isNewborn && !isFinReassessment && !isAppeals && !isBCCEXT && !isIME">
  <button id="continue" class="btn btn-secondary ml-1" (click)="navigateNext()">{{ CONGRATS_STATIC_TEXT39 }}</button>
</div>

<section class="border bg-white p-3" *ngIf="isPresumptive">
  <div class="p-3">
    <h5 class="section-header">Confirmation of Your Submission</h5>
    <hr>
    <div>
      <p>You can save this as a confirmation of your submission by clicking the <strong>Print</strong> button at the bottom
        of this screen. You can also access this information at any time via the <a href="/api/prov/submission-search">Search My Submissions </a> Page.</p>
      <p>You can print this page and give it to the applicant to keep. It has important information that may help
        them later.</p>
    </div>

    <div
      *ngIf="(isPresumptive && workerPortalAppNum && showTracking)">
      <h5 class="section-header">Track Your Application</h5>
      <hr>
      <p>Your application tracking number is <strong>{{ workerPortalAppNum }}</strong>. To check the status of your
        application, log in to your TennCare Connect account and go to the Search My Submissions.</p>
    </div>

    <div class="mt-4" *ngIf="ecfIndicator === 'Y'">
      <h5 class="section-header">About Your Self-Referral for Employment and Community First Choices</h5>
      <hr>
      <p>The Employment and Community First CHOICES program is for people of all ages who have an intellectual or
        developmental disability (I/DD) including people who have significant disabilities. Services in the program can
        help you learn skills to become employed and live as independently as possible in the community.</p>
      <p>To see if you can be served through this program, you must <a class="a-link"
        href="https://perlss.tenncare.tn.gov/externalreferral">complete the self-referral form</a>. *Clicking this
        link will take you away from TennCare Connect and you will be logged out.</p>
      <p>Do you have questions about a referral? Call the DIDD regional office in your area for free:</p>
      <ul>
        <li>West Tennesse 866-372-5709</li>
        <li>Middle Tennessee 800-654-4839</li>
        <li>East Tennessee 888-531-9876</li>
      </ul>
    </div>

    <div class="mt-4">
      <h5 class="section-header">Print the Application</h5>
      <hr>
      <p><strong>Before you print the application, remember that it includes private, personal information. </strong>
      </p>
      <p>To print, click <strong>Print</strong> button below.</p>
      <div class="mb-3 mt-4">
        <button class="col-md-5 btn btn-secondary-congrats" (click)="printPdf()">Print
          <img src="../../assets/images/printw.svg" alt="print-icon" />
        </button>
      </div>
    </div>

    <div *ngIf="isPresumptive">
      <h5 class="section-header">Presumptive Eligibility Results</h5>
      <hr>

      <p class="mt-2">The table below shows the Presumptive Eligibility decision.</p>
      <ngx-datatable class='bootstrap striped' #table [rows]='results' [columnMode]='"force"' [headerHeight]='"auto"'
        [footerHeight]='50' [rowHeight]='"auto"'>
        <ngx-datatable-column name="Name">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            {{ row?.name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Gender">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            {{ row?.gender?.value }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Date of Birth">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            {{ row?.dob }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            {{ row?.status }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Eligibility Begin Date">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            {{ row?.eligibleBeginDate }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Eligibility End Date">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            {{ row?.eligibleEndDate }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Eligibility Category">
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
            {{ row?.eligCategory?.value }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <p *ngIf="isApproved">If approved for presumptive, the health plan and TennCare Online Services will be updated
      within 2-3 business days.</p>
    <p *ngIf="isDenied">If denied for presumptive, the applicant can still apply for coverage at <a href="https://tenncareconnect.tn.gov">https://tenncareconnect.tn.gov</a></p>
    <div *ngIf="hideFullCoverage !== 'YES'">
      <h5 class="section-header">Full Coverage Application</h5>
      <hr>
      <div class="static-text">
        <p>We're reviewing your information now. If we need more information from you, you'll get a letter that tells
          you
          what's needed. The letter will also tell you when the information is due.</p>
        <p>Or, If we can make a decision with the facts we have, we'll send a letter that tells you what we decide. </p>
        <p>You can also click the <b>Upload Documents</b> button below if you want to upload documents now that we may
          ask
          you forafter we are finished reviewing your application.</p>
        <p *ngIf="breastCervicalCancerSw === 'Y' && appType !== 'BCC'" [innerHTML]="this.CONGRATS_STATIC_TEXT_QFR2"></p>
        <p>It's okay if you don't have these types of documents right now. We will send you a letter asking for these
          documents if you do not have these documents with you right now.</p>
        <div class="row mr-4">
          <button class="col-md-3 btn btn-secondary-congrats" (click)="goToDocuments()">Upload Documents
            <img src="../../assets/images/cloudupload.svg" alt="upload icon" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-5 col-md-5">
      <div class="row justify-content-start mr-4">
        <button class="col-md-3 ml-5 mb-3 btn btn-block btn-secondary" (click)="onNextClick()">Exit</button>
      </div>
    </div>
  </div>
</section>

<section class="border bg-white p-3" *ngIf="isAdmitDischarge">
  <div class="p-3">
    <p>
      The date of submission is </p>
    <p><strong> {{ appSubmittedDate | date: 'longDate' }} </strong>
    </p>
    <h5 class="section-header">Confirmation of Your Submission</h5>
    <hr>
    <div>
      <p>To save this confirmation page, click <b>Print</b> button at the bottom of this screen. From there, you can print
        this page for your records or download it to your computer. Remember, you can also
        see this submission at anytime on the <a (click)="onSearchClick()" class="view-tennCare-title">Search My Submissions</a> page.</p>
      <p>You can print this page and give it to the applicant to keep. It has important information that may help
        them later.</p>
    </div>
    <h5 class="section-header">What Happens Next</h5>
    <hr>
    <p>We're reviewing this information now. If we need more information, we'll send a letter to the patient and the
      Assisting Person (if there is one). The letter will say what information is needed and when it is due.</p>
    <p>We'll send another letter when we make our decision.</p>
    <h5 class="section-header">Print this 2350 Form (Admit/Discharge Form)</h5>
    <hr>
    <p><strong>Before you print this form, remember that it includes private, personal information.</strong></p>
    <p>To print, click <strong>Print</strong> button below.</p>
    <div class="row justify-content-start mr-4">
      <button class="btn btn-secondary-congrats" (click)="printPdf()">Print
        <img src="../../assets/images/printw.svg" alt="print-icon" />
      </button>
    </div>
    <br>
    <p>If you're having trouble printing your application, check our <a class="view-tennCare-title"
        href="https://tenncareconnect.tn.gov/services/jsp/services/faq.html" target="_blank"><strong><u>FAQ</u></strong></a> page.</p>
  </div>
</section>
<section class="border bg-white p-3" *ngIf="isNewborn">
  <div class="p-3">
    <p>
      The date of submission is </p>
    <p><strong> {{ appSubmittedDate | date: 'longDate' }} </strong>
    </p>
    <h5 class="section-header">Confirmation of Your Submission</h5>
    <hr>
    <div>
      <p>To save this confirmation page, click <b>Print</b> button at the bottom of this screen. From there, you can print
        this page for your records or download it to your computer. Remember, you can also
        see this submission at anytime on the <a (click)="onSearchClick()" class="page-title-small"><strong>My
          Submissions</strong></a> page.</p>
      <p>You can print this page and give it to the applicant to keep. It has important information that may help
        them later.</p>
    </div>
    <h5 class="section-header">What Happens Next</h5>
    <hr>
    <p>We're reviewing this information now. If we need more information, we'll send a letter to the applicant.</p>
    <p>You will also receive this letter if you listed yourself as the Assisting Person and have permission to receive the applicant's letters.</p>
    <h5 class="section-header">Print Your Newborn Form</h5>
    <hr>
    <p><strong>Before you print newborn form, remember that it includes private, personal information.</strong></p>
    <p>To print, click <strong>Print</strong> button below.</p>
    <div class="row justify-content-start mr-4">
      <button class="col-md-5 btn btn-secondary-congrats" (click)="printPdf()">Print
        <img src="../../assets/images/printw.svg" alt="print-icon" />
      </button>
    </div>
    <br>
    <p>If you're having trouble printing your application, check our <a class="page-title-small"
      href="https://tenncareconnect.tn.gov/services/jsp/services/faq.html" target="_blank"><strong><u>FAQ</u></strong></a> page.</p>
  </div>
</section>
<section class="border bg-white p-3" *ngIf="isBCCEXT">
  <div class="p-3">
    <p>
      This request was submitted to TennCare on<strong> {{ appSubmittedDate | date: 'longDate' }} </strong></p>
    <h5 class="section-header">Confirmation of Your Submission</h5>
    <hr>
    <div>
      <p>To save this confirmation page, click <b>Print</b> button at the bottom of this screen. From there, you can print
        this page for your records or download it to your computer. Remember, you can also
        see this submission at anytime on the <a (click)="onSearchClick()" class="page-title-small"><strong>My
          Submissions</strong></a> page.</p>
      <p>You can print this page and give it to the applicant to keep. It has important information that may help
        them later.</p>
    </div>
    <h5 class="section-header">Track Your Application</h5>
    <hr>
    <p>Your BCC Treatment Plan Extension Request Form tracking number is <strong>{{ workerPortalAppNum }}</strong>. </p>
    <p>To check the status of your application, log in to your TennCare Connect account and go to the My Submissions page. </p>
    <h5 class="section-header">Print this BCC Treatment Plan Extension Request Form</h5>
    <hr>
    <p><strong>Before you print this form, remember that it includes private, personal information.</strong></p>
    <p>To print, click <strong>Print</strong> button below.</p>
    <div class="row justify-content-start mr-4">
      <button class="col-md-5 btn btn-secondary-congrats" (click)="printPdf()">Print
        <img src="../../assets/images/printw.svg" alt="print-icon" />
      </button>
    </div>
    <br>
    <p>If you're having trouble printing your application, check our <a class="page-title-small"
      href="https://tenncareconnect.tn.gov/services/jsp/services/faq.html" target="_blank"><strong><u>FAQ</u></strong></a> page.</p>
    <h5 class="section-header">What Happens Next</h5>
    <hr>
    <p>We're reviewing the application now.  If we need more information to make a decision, we'll send a letter to the applicant. </p>
    <p>You will also receive this letter if you listed yourself as the Assisting Person and have permission to receive the applicant's letters.</p>
  </div>
</section>
<section class="border bg-white p-3" *ngIf="isIME">
  <div class="p-3">
    <p>
      The date of submission is </p>
    <p><strong> {{ appSubmittedDate | date: 'longDate' }} </strong>
    </p>
    <h5 class="section-header">Confirmation of Your Submission</h5>
    <hr>
    <div>
      <p>To save this confirmation page, click <b>Print</b> button below. From there, you can print
        this page for your records or download it to your computer. Remember, you can also
        see this submission at anytime on the <a (click)="onSearchClick()" class="view-tennCare-title"><strong>My Submissions</strong></a> page.</p>
      <p>You can print this page and give it to the applicant to keep. It has important information that may help
        them later.</p>
    </div>
    <h5 class="section-header">Track Your Submission</h5>
    <hr>
    <p>Your Incurred Medical Expense Form tracking number is <strong>{{ workerPortalAppNum }}</strong>. </p>
    <p>To check the status of your submission, log in to your TennCare Connect account and go to the My Submissions page.</p>
    <h5 class="section-header">Print this Incurred Medical Expense Form</h5>
    <hr>
    <p><strong>Before you print this form, remember that it includes private, personal information.</strong></p>
    <p>To print, click <strong>Print</strong> button below.</p>
    <div class="row justify-content-start mr-4">
      <button class="btn btn-secondary-congrats" (click)="printPdf()">Print
        <img src="../../assets/images/printw.svg" alt="print-icon" />
      </button>
    </div>
    <br>
    <p>If you're having trouble printing your form, check our <a class="view-tennCare-title"
        href="https://tenncareconnect.tn.gov/services/jsp/services/faq.html" target="_blank"><strong>FAQ page.</strong></a></p>
        <h5 class="section-header">What Happens Next</h5>
        <hr>
        <p>We're reviewing this information now. If we need more information, we'll send a letter to the patient and the
          Assisting Person (if there is one). The letter will say what information is needed and when it is due.</p>
        <p>We'll send another letter when we make our decision.</p>
  </div>
</section>
<br *ngIf="!isAppeals">
<div class="form-row" *ngIf="isAdmitDischarge || isNewborn || isBCCEXT || isIME">
  <div class="col-lg-5">
    <div class="justify-content-center">
      <button id="tenncare-prm-btn" class="btn btn-block btn-primary" (click)="onNextClick()">RETURN TO TENNCARE ACCESS HOME</button>
    </div>
  </div>
  <div class="col-lg-5">
    <div class="justify-content-center">
      <button id="tencare-sec-btn" class="btn btn-block btn-outline-secondary" (click)="onSearchClick()">GO TO SEARCH MY SUBMISSIONS</button>
    </div>
  </div>
</div>
<br *ngIf="!isAppeals">
<br *ngIf="!isAppeals">

<section class="border bg-white p-3" *ngIf="isFinReassessment">
  <div class="p-3">
    <h5 class="section-header">Confirmation of Your Submission</h5>
    <hr>
    <div>
      <p>To save this confirmation page, click <b>Print</b> button at the bottom of this screen. From there, you can print
        this page for your records or download it to your computer. Remember, you can also
        see this submission at anytime on the&nbsp;<a (click)="onSearchClick()" class="page-title-small">Search My
          Submissions</a>&nbsp;page.</p>
      <p>You can print this page and give it to the applicant to keep. It has important information that may help
        them later.</p>
    </div>
    <div *ngIf="workerPortalAppNum">
      <h5 class="section-header">Track Your Application</h5>
      <hr>
      <p>Your reassessment tracking number is <strong>{{ workerPortalAppNum }}</strong>.</p>
      <p>To check the status of your application, log in to your TennCare Connect account and go to the Search My
        Submissions.</p>
    </div>
    <div class="mt-4" *ngIf="ecfIndicator === 'Y'">
      <h5 class="section-header">About Your Self-Referral for Employment and Community First Choices</h5>
      <hr>
      <p>The Employment and Community First CHOICES program is for people of all ages who have an intellectual or
        developmental disability (I/DD) including people who have significant disabilities. Services in the program can
        help you learn skills to become employed and live as independently as possible in the community.</p>
      <p>To see if you can be served through this program, you must <a class="a-link"
        href="https://perlss.tenncare.tn.gov/externalreferral">complete the self-referral form</a>. *Clicking this
        link will take you away from TennCare Connect and you will be logged out.</p>
      <p>Do you have questions about a referral? Call the DIDD regional office in your area for free:</p>
      <ul>
        <li>West Tennesse 866-372-5709</li>
        <li>Middle Tennessee 800-654-4839</li>
        <li>East Tennessee 888-531-9876</li>
      </ul>
    </div>

    <div class="mt-4">
      <h5 class="section-header">Print the Financial Reassessment</h5>
      <hr>
      <p><strong>Before you print the Financial Reassessment, remember that it includes private, personal information.
        </strong>
      </p>
      <p>To print, click <strong>Print</strong> button below.</p>
      <div class="mb-3 mt-4">
        <button class="col-md-5 btn btn-secondary-congrats" (click)="printPdf()">Print
          <img src="../../assets/images/printw.svg" alt="print-icon" />
        </button>
      </div>
    </div>
    <h5 class="section-header">What Happens Next</h5>
    <hr>
    <p>We're reviewing your information now. If we need more information from you, you'll get a letter that tells you
      what's needed. The letter will also tell you when the information is due.</p>
    <p>Or, if we can make a decision with the facts we have, we'll send a letter that tells you what we decide.</p>
    <p>You can also click the <b>Upload Documents</b> button below if you want to upload documents now that we may ask
      you for after we are finished reviewing your application.</p>
    <p *ngIf="breastCervicalCancerSw === 'Y'" [innerHTML]="this.CONGRATS_STATIC_TEXT_QFR2"></p>
    <p>It's okay if you don't have these types of documents right now. We will send you a letter asking for these
      documents
      if you do not have these documents with you right now.</p>

    <div class="row mr-4">
      <button class="col-md-3 btn btn-secondary-congrats" (click)="goToDocuments()">Upload Documents
        <img src="../../assets/images/cloudupload.svg" alt="upload icon" />
      </button>
    </div>
  </div>
</section>

<div class="mr-3 pr-md-1 py-sm-1 mt-5 mb-2" *ngIf="isFinReassessment && !isKbUser">
  <button id="continue" class="btn btn-secondary ml-1" [routerLink]="['/user-profile', 'benefit-dashboard']">Next</button>
</div>

<div class="mr-3 pr-md-1 py-sm-1 mt-5 mb-2" *ngIf="isFinReassessment && isKbUser">
  <button id="continue" class="btn btn-secondary ml-1" (click)="backToMemberSearch()">Next</button>
</div>

<div *ngIf="isAppeals">
<section>
  <h1 class="page-title mt-0">{{ CONGRATS_APPEAL_STATIC_TEXT }}</h1>
</section>
<section class="border bg-white p-3">
  <section class="p-2">
    <div class="row">
      <div class="col">
        <p class="static-text" *ngIf="appealSummaryInfo.length <= 1">{{CONGRATS_APPEAL_STATIC_TEXT3}}</p>
        <p class="static-text" *ngIf="appealSummaryInfo.length > 1">{{CONGRATS_APPEAL_STATIC_TEXT24}}</p>

        <p class="static-text">{{CONGRATS_APPEAL_STATIC_TEXT4}}<strong>{{ appSubmittedDate |  date:'MM/dd/yyyy' }}.</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col">
      <table class="table w-100 table-striped">
        <thead>
          <tr>
            <th scope="col" style="text-align: center;">{{Name}}</th>
            <th scope="col" style="text-align: center;">{{APPEAL_NUMBER}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let coverage of appealSummaryInfo; index as i;">
            <td style="text-align: center;">{{coverage.memberName}}</td>
            <td style="text-align: center;">{{coverage.appealNumber}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col"></div>
    </div>
    <h5 class="section-header">{{CONGRATS_APPEAL_STATIC_TEXT5}}</h5>
    <hr>
    <p [innerHTML]="CONGRATS_APPEAL_STATIC_TEXT6"></p>
    <br>
    <p [innerHTML]="CONGRATS_APPEAL_STATIC_TEXT7"></p>
    <br>
    <p [innerHTML]="CONGRATS_APPEAL_STATIC_TEXT8"></p>
    <br>
    <p [innerHTML]="CONGRATS_APPEAL_STATIC_TEXT9"></p>
    <br>
    <p [innerHTML]="CONGRATS_APPEAL_STATIC_TEXT25"></p>
    <p class="appeal_call_info">{{CONGRATS_APPEAL_STATIC_TEXT27}}</p>
    <p [innerHTML]="CONGRATS_APPEAL_STATIC_TEXT26"></p>
    <br>
    <h5 class="section-header">{{CONGRATS_APPEAL_STATIC_TEXT12}}</h5>
    <hr>
    <p>{{CONGRATS_APPEAL_STATIC_TEXT13}}</p>
    <br>
    <p>{{CONGRATS_APPEAL_STATIC_TEXT14}}</p>
    <br>
    <ul class="mb-3 ml-4 print-list">
      <li>{{ CONGRATS_APPEAL_STATIC_TEXT15 }}</li>
      <li>{{ CONGRATS_APPEAL_STATIC_TEXT16 }}</li>
      <li>{{ CONGRATS_APPEAL_STATIC_TEXT17 }}</li>
    </ul>
    <br>
    <p>{{CONGRATS_APPEAL_STATIC_TEXT18}}<strong>{{CONGRATS_APPEAL_STATIC_TEXT19}}</strong>{{CONGRATS_APPEAL_STATIC_TEXT20}}</p>
    <div class="appeal_print_pdf">
    <div class="mb-3 mt-4">
      <button class="col-md-5 btn btn-secondary-congrats" (click)="printAppealPdf()">{{ CONGRATS_APPEAL_STATIC_TEXT21 }}
        <img src="../../assets/images/printw.svg" alt="print-icon" />
      </button>
    </div>
  </div>
    <p>{{ CONGRATS_APPEAL_STATIC_TEXT22 }}<a [routerLink]="['/', 'faq']" [queryParams]="{lang:translatedLang}" title="FAQs" target="_blank"><strong>{{ CONGRATS_APPEAL_STATIC_TEXT23 }}</strong></a></p>
  </section>

</section>
<div class="mr-3 pr-md-1 py-sm-1 mt-5 mb-2 appeal-exit" *ngIf="!isKepro">
  <button id="continue" class="btn btn-secondary ml-1" (click)="backToMyAppeals()">{{ Exit }}</button>
</div>
</div>
</div>
<div *ngIf="rmcUpload && !mobileUser">
  <section>
    <h1 class="page-title">{{ RMC_DOC_UPLOAD_HEADER }}
    </h1>
    <section class="border bg-white p-3">
      <section class="p-2">
        <nh-rmc-uploadDoc [rows]="rmcDocUploadRows" [wPappNum]="workerPortalAppNum"></nh-rmc-uploadDoc>
      </section>
    </section>
    
  </section>
  <br>
  <div class="mr-3 pr-md-1 py-sm-1" *ngIf="(!isAppeals && !isKepro)">
    <button id="continue" class="btn btn-secondary ml-1" (click)="navigateNext()">{{ CONGRATS_STATIC_TEXT39 }}</button>
  </div>
  <br>
  <br>
</div>
<div *ngIf="mobileUser">
  <section>
    <h1 class="page-title"><span *ngIf="!isPreterm">{{ CONGRATS_STATIC_TEXT}}</span><span *ngIf="isPreterm">{{ CONGRATS_STATIC_TEXT_QFR5}}</span> {{ appKeyWord }}!</h1>
  </section>
  <section class="border bg-white p-3">
    <section class="p-2">
      <div class="row">
        <div class="col">
          <p class="static-text" *ngIf="!isPreterm">{{ CONGRATS_STATIC_TEXT1 }} {{ appKeyWord_1 }} {{ CONGRATS_STATIC_TEXT2 }} <strong>{{ workerPortalAppNum }}</strong>.</p>
          <p class="static-text" *ngIf="isPreterm">{{ CONGRATS_STATIC_TEXT_QFR6 }}{{ appKeyWord }} {{ CONGRATS_STATIC_TEXT_QFR7 }} <strong>{{ workerPortalAppNum }}</strong>.</p>
          <p class="static-text">{{ CONGRATS_STATIC_TEXT3 }}</p>
          <p class="static-text">{{ CONGRATS_STATIC_TEXT4_1 }} <strong>{{
              appSubmittedDate | date: 'longDate' }}</strong>.</p>
        </div>
      </div>
    </section>
    <div class="p-2">
      <div>
        <h5 class="section-header">{{ CONGRATS_STATIC_TEXT5 }}</h5>
        <hr>
        <p [innerHTML]="CONGRATS_STATIC_TEXT6_app"></p>
      </div>
    </div>
    <div>
      <h5 class="section-header">{{ COMM_PREF_FINISHMODULE_BANNER_TEXT1 }}</h5>
      <hr>
        <p [innerHTML]="MOBILE_APP_STATIC1"></p>
    </div>
    <div>
      <h5 class="section-header">{{ CHANGE_HOW_LETTERS }}</h5>
      <hr>
        <p [innerHTML]="MOBILE_APP_STATIC2"></p>
    </div>
    <div *ngIf="kbDataList?.length > 0 && kbDataList[0]?.kbReferralId !== null">
      <h5 class="section-header">{{ CONGRATS_STATIC_TEXT10 }}</h5>
      <hr>
      <p>{{ CONGRATS_STATIC_TEXT11_1 }}</p>
      <p *ngFor="let ref of kbDataList">{{ CONGRATS_STATIC_TEXT12 }} {{ref.name}} {{ CONGRATS_STATIC_TEXT13 }}
        <strong>{{ref.kbReferralId}}.</strong><br>{{ CONGRATS_STATIC_TEXT14_1 }}</p>
      <p>{{ CONGRATS_STATIC_TEXT15_1 }} <span
          *ngFor="let ref of kbDataList; let isLast=last">{{ref.name}}{{isLast ? '' : 'or '}}</span> {{ CONGRATS_STATIC_TEXT16_1 }}
      <p [innerHTML]="this.CONGRATS_STATIC_TEXT17_1"></p>
      <p class="m-0">{{ CONGRATS_STATIC_TEXT18_1 }}</p>
      <ul>
        <li><strong>{{ CONGRATS_STATIC_TEXT19 }}: </strong> 901-745-7200 or 800-308-2586</li>
        <li><strong>{{ CONGRATS_STATIC_TEXT20_1 }}: </strong> 615-231-5047</li>
        <li><strong>{{ CONGRATS_STATIC_TEXT21 }}: </strong> 865-588-0508</li> <br>
      </ul>
    </div>
    <div>
      <h5 class="section-header">{{ MOBILE_APP_STATIC3 }}</h5>
      <hr>
        <p [innerHTML]="MOBILE_APP_STATIC4"></p>
    </div>
  </section>
  <br>
</div>