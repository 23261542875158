import { ApiService } from '@app/gateway/services/api.service'
import { Component, OnInit, DoCheck } from '@angular/core'
import { StateService } from '@app/services/state/state.service'
import { Router } from '@angular/router'
// import { ApplicationService } from '@app/services/application/application.service'
import { UserProfileService } from '../user-profile.service'
import { UserProfileComponent } from '../user-profile.component'
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from "@app/services/modal/modal.service";
import { DatePipe } from "@angular/common";
import { Title } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { ServiceConfigService } from '@app/services/service-config.service';
import { HttpHeaders  } from '@angular/common/http';

@Component({
  selector: 'nh-healthplan-details',
  templateUrl: './healthplan-details.component.html',
  styleUrls: ['./healthplan-details.component.scss']
})
export class HealthPlanDetailsComponent implements OnInit, DoCheck {
  indvId = null;
  caseNumber = null;
  healthPlanMCO = null;
  caseNumberoptions = [];
  caseNumberList=[];
  healthPlanMCOoptions = [];
  disableSubmit = true;
  disableMCOSubmit = true;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  defaultLanguage;
  translatedLang;
  hoHSW;
  changeHealthPlan: boolean = false;
  healthPlanCaseNumber = [];
  showHealthPlanInfoSection: boolean = false;
  showHealthPlanChangeReqSection: boolean = false;
  showHealthPlanMcoSection: boolean = false;
  showNotEligibleHealthPlanSection: boolean = false;
  showChangeReqSuccessAlert: boolean = false;
  noteligibleSection = false;
  notEligibleText:boolean = false;
  notDisplaySubmitText:boolean =false;
  priorHealthCaseSubmitted: boolean = false;
  hideHealthPlanOptions = false;
  changePlanRows = [];
  priorHealthPlanCaseNumber = [];
  caseNumberList1 = [];
  showSubmit:boolean = false;

  changePlanColumns = [
    { name: 'Member', prop: 'member' },
    { name: 'Case', prop: 'caseNumber' },
    { name: 'Assigned Health Plan', prop: 'providerName' },
    { name: 'Start Date', prop: 'startDate' },
    { name: 'End Date', prop: 'endDate' },
    { name: 'Future Health Plan', prop: 'futureProviderName' },
    { name: 'Start Date', prop: 'futurestartDate' }
  ];
  changeReqSummayRows = [];
  changeReqSummayColumns = [
    { name: 'Requested By', prop: 'requestedBy' },
    { name: 'Current Health Plan', prop: 'currentHealthPlan' },
    { name: 'Requested Health Plan', prop: 'newHealthPlan' },
    { name: 'Request Date', prop: 'requestDate' },
    { name: 'Status', prop: 'status' }
  ]

  isHealthPlanChanged: boolean = null;
  isPriorHealthPlanRequested : boolean = null;
  membersNotEligible = [];
  membersNotEligibleString: string = '';
  approvedcaseNumber;
  isCaseApprovedStatus;
  priorCaseSubmitted: boolean = null;
  eligibleCase: boolean = null;
  priorCaseNumber = [];
  showHealthPlanInfoSectionTable:boolean = false;
  sorryMsg:boolean = false;
  errorStatus: string = null;
  errorCode: string = null;
  mcoRequestYear;
  showMcoSummaryHistory: boolean = false;
  currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
  isHealthPlanWithinMcoNinetyDays: boolean = false;
  isHoh: boolean = false;
  submitMcoNewChangeSw: boolean = false;
  constructor(
    public apiService: ApiService, 
    public stateService: StateService,
    public router: Router,
    public translateService: TranslateService,
    // private app: ApplicationService,
    private userService: UserProfileService,    
    private datePipe: DatePipe,
    public userProfileComponent: UserProfileComponent,
    private modalService: ModalService,
    private titleService: Title,
    private readonly serviceConfigService: ServiceConfigService
  ) {
    this.titleService.setTitle('Health Plan Benefits');
  }

    
  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }
  
  ngOnInit(): void {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
    });
    this.submitMcoNewChangeSw = this.serviceConfigService.getServerConfig().submitMcoNewChangeSw;
    this.onLoad();
  }


  onLoad(isAfterSubmit: boolean = false, healthPlanCaseSummary?: any) {
    this.indvId = sessionStorage.getItem('individualId');
   // const url = `indi/checkmybenefits/cmbSumm/indv/${this.indvId}`;

    this.userService.getBenefitDashboard(this.indvId) .subscribe((res: any) => {
      if (res) {
      const { myBenefitDashboardTiles, cmbSummaryResponse: { caseSummaryResponse } } = res;
      this.caseNumberoptions = caseSummaryResponse;
      const caseSummary = caseSummaryResponse;
      this.caseNumberList=caseSummaryResponse.map(row=>row.caseNum);
      this.caseNumberList1=caseSummaryResponse.map(row=>row.caseNum);
      this.hoHSW = caseSummaryResponse.length == 1 ? caseSummaryResponse[0].hoHSW : null;
      this.changeHealthPlan = myBenefitDashboardTiles.changeHealthPlan;
      this.healthPlanCaseNumber = myBenefitDashboardTiles.caseNumberForHealthPlanRequest;
      this.showHealthPlanInfoSection = isAfterSubmit;
      this.showHealthPlanInfoSectionTable = isAfterSubmit;
      if (!this.submitMcoNewChangeSw) {
        this.showHealthPlanMcoSection = false;
      }
      this.sorryMsg = true;
      caseSummary?.forEach(element => {
        const userobj = JSON.parse(sessionStorage.getItem('currentUser'));
        const currentUserIndivId = JSON.stringify(userobj?.individualId);
        // checking whether logged user is hoh any of the case
        if (element?.hoHSW === 'Y' && element?.hoHID === currentUserIndivId) {
         this.isHoh = element?.hoHSW === 'Y' ? true : false;
        }
      });
      // this.showHealthPlanMcoSection = caseSummaryResponse.length == 1 && this.hoHSW === 'Y' && this.changeHealthPlan && this.healthPlanCaseNumber == caseSummaryResponse[0].caseNum;
      // const healthPlanCaseNumber = this.healthPlanCaseNumber.find(row => row.caseNumber === this.caseNumber);
      // this.showHealthPlanMcoSection = this.hoHSW === 'Y' &&  this.caseNumber === healthPlanCaseNumber.caseNumber;
      // caseSummaryResponse[0].indvSummaryResponse[0].mcoInfo

      //checking if the mcoinfolist is empty for all the cases
      const mcoempty = caseSummaryResponse.map(data => data.indvSummaryResponse.map(data1 => data1.mcoInfo));
      const mcoempty1 = (mcoempty.flat(1)).filter(data => data?.length>0);
      if(mcoempty1?.length > 0){
        this.sorryMsg = false;
        this.showHealthPlanInfoSectionTable = true;
        if(this.caseNumberoptions.length == 1){
          this.showHealthPlanInfoSection = true;
          this.showHealthPlanMcoSection = !isAfterSubmit;

        }
      }else{
        this.sorryMsg = true;
        this.showHealthPlanInfoSectionTable = true;
      }

      this.showNotEligibleHealthPlanSection = (caseSummaryResponse.length == 1 && !this.showHealthPlanMcoSection);
      this.showHealthPlanChangeReqSection = caseSummaryResponse.length == 1 && this.hoHSW !== 'Y' && !this.changeHealthPlan;

      //single case scenario after submitting
        if (this.caseNumberList?.length == 1) {
          // if(caseSummaryResponse[0].hoHSW == 'Y'){
          this.getRequestSummaryDetails(caseSummaryResponse[0].caseNum, caseSummaryResponse[0].hoHID);
          this.getHealthPlanDetails(caseSummaryResponse[0].caseNum);
          // }
        } else if (this.caseNumberList?.length > 1) {
          if (healthPlanCaseSummary) {
            this.getRequestSummaryDetails(healthPlanCaseSummary?.caseNum, healthPlanCaseSummary?.hoHID);
            this.getHealthPlanDetails(healthPlanCaseSummary?.caseNum);
          }
        }

      // if (this.showHealthPlanInfoSection) this.getHealthPlanDetails(caseSummaryResponse[0].caseNum);
      // this.isPriorHealthPlanRequested = myBenefitDashboardTiles?.priorHealthPlanRequest;
      this.isHealthPlanChanged = myBenefitDashboardTiles?.changeHealthPlan;
      this.approvedcaseNumber = myBenefitDashboardTiles?.caseNumberForHealthPlanRequest;
      if (this.approvedcaseNumber === null) { 
        this.isCaseApprovedStatus = true
      } else if(this.approvedcaseNumber !== null && this.hoHSW !== 'Y'){
        this.isCaseApprovedStatus = true
      };  
      
      //For static text (you or someone already submitted)
      this.priorCaseNumber = myBenefitDashboardTiles?.priorHealthPlanRequestCaseNumber;

      //multiple case scenario
      if(this.caseNumberList?.length > 1){
        this.priorHealthPlanCaseNumber = this.priorCaseNumber.find(row => row.caseNumber === this.caseNumber);
        if(this.priorHealthPlanCaseNumber){
          this.priorCaseSubmitted = true;
        }else{
          this.priorCaseSubmitted = false;
        }
      }else{
        //single case scenario
        this.priorHealthPlanCaseNumber = this.priorCaseNumber.find(row => row.caseNumber === this.caseNumberoptions[0].caseNum);
        if(this.priorHealthPlanCaseNumber){
          this.priorCaseSubmitted = true;
          if (!this.submitMcoNewChangeSw) {
            this.showHealthPlanMcoSection = false;
          }
        }else{
          this.priorCaseSubmitted = false;
        }
      }

      this.healthPlanCaseNumber = myBenefitDashboardTiles?.caseNumberForHealthPlanRequest;
      if (this.submitMcoNewChangeSw) {
        if (this.caseNumberList?.length > 1) {
          const eligibleHealthPlanCaseNumber = this.healthPlanCaseNumber.find(row => row.caseNumber === this.caseNumber);
          if (eligibleHealthPlanCaseNumber) {
            this.eligibleCase = true;
          } else {
            this.eligibleCase = false;
          }
        } else {
          const eligibleHealthPlanCaseNumber = this.healthPlanCaseNumber.find(row => row.caseNumber === this.caseNumberoptions[0].caseNum);
          if (eligibleHealthPlanCaseNumber) {
            this.eligibleCase = true;
          } else {
            this.eligibleCase = false;
          }
        }
      } else {
        const eligibleHealthPlanCaseNumber = this.healthPlanCaseNumber.find(row => row.caseNumber === this.caseNumber);
        if (eligibleHealthPlanCaseNumber?.length > 0) {
          this.eligibleCase = true;
        } else {
          this.eligibleCase = false;
        }
      }

      if((this.priorCaseNumber.length == 0 && this.healthPlanCaseNumber.length == 0)){
        this.notEligibleText = true;
        this.showHealthPlanMcoSection = false;
      }else{
        this.notEligibleText = false;
      }

    }
  });  
    
  }


  changecaseNumber() {
    this.disableSubmit = !this.caseNumber;
    this.showHealthPlanInfoSection = false;
    this.changeReqSummayRows = [];
    this.showChangeReqSuccessAlert = false;
    this.isCaseApprovedStatus = this.caseNumber == this.approvedcaseNumber || this.caseNumber == this.priorCaseNumber;
  }


  changeMCO(){
    this.disableMCOSubmit = !this.healthPlanMCO;
  }

  
  getTableDetails(providerCode) {
    const fDate = new Date(this.serviceConfigService.getServerConfig().wellpointEffDate);
    const cDate = new Date(this.currentDate);
    if (cDate >= fDate) {
      const url = `publ/reference-tables/retrieve?table=MCONAME_UBW`;
      this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCHPD'})}).subscribe((res) => {
        this.healthPlanMCOoptions = res && res['columnDetails'].filter(row => row.code !== providerCode);
      });
    } else {
      const url = `publ/reference-tables/retrieve?table=MCONAME`;
      this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCHPD'})}).subscribe((res) => {
        this.healthPlanMCOoptions = res && res['columnDetails'].filter(row => row.code !== providerCode);
      });
    }
  }

  //To get the summary table
  getRequestSummaryDetails(caseNumber, hoHID) {
    this.errorStatus = null;
    this.errorCode = null;
    const url = `publ/mcoservices/mcochangerequestsummary/case/${caseNumber}/indvid/${hoHID}`;
    this.apiService.get(url,true, {headers: new HttpHeaders({'pageId': 'TCHPD'})}).subscribe((res) => {
      this.changeReqSummayRows = res ? res['mcoChangeRequestSummaryResponse'] : null
      this.errorCode = this.changeReqSummayRows[0]?.errorCode;
      const mcoRes = this.changeReqSummayRows[0];
        if (mcoRes?.requestDate) {
          const year = new Date(mcoRes.requestDate).getFullYear();
          const currentYear = new Date().getFullYear();
          if (year === currentYear && mcoRes.status === 'SUBMITTED') {
            this.mcoRequestYear = true;
              this.errorStatus = mcoRes.status;
          } else if (year === currentYear && mcoRes.status === 'ERROR') {
            this.mcoRequestYear = false;
            this.errorStatus = mcoRes.status;
          }
        }
      this.changeReqSummayRows = this.changeReqSummayRows.filter((item) => (item.status !== 'ERROR'));
      if (this.changeReqSummayRows?.length) {
        this.showMcoSummaryHistory = true
      }
    });
  }  

  //To get the health plan info table
  getHealthPlanDetails(caseNumber) {
    const url = `publ/mcoservices/mcodetails/case/${caseNumber}/indvid/${this.indvId}`;
    this.apiService.get(url,true,  {headers: new HttpHeaders({'pageId': 'TCHPD'})}).subscribe((res) => {
      
      const healthPlanResponse = res['mcoInfoList'];
          /*
          commented the code for future use 
          this.membersNotEligible = res?.healthPlanRequestMemberDetails?.membersNotEligible || [];
          if (this.membersNotEligible.length > 1) {
            const members = this.membersNotEligible;
            const lastMember = members.pop();
            this.membersNotEligibleString = `${members.join(", ")} and ${lastMember}`;
          } else if (this.membersNotEligible.length === 1) {
            this.membersNotEligibleString = `${this.membersNotEligible[0]}`;
          }
          */
      this.changePlanRows = healthPlanResponse.map(row => {
        const finalResponse = {};
        finalResponse['member'] = row.clientName;
        finalResponse['caseNumber'] = row.caseNumber;
        finalResponse['providerName'] = row.providerName ? row.providerName : 'N/A';
        finalResponse['startDate'] = row.startDate ? row.startDate : 'N/A';
        if(row.endDate && row.endDate !== 'N/A'){
          const year = new Date(this.datePipe.transform(row.endDate, "MM/dd/yyyy")).getFullYear();
          if(year === 2299 ){
            row.endDate = 'N/A';
            finalResponse['endDate'] = row.endDate;
          }
          finalResponse['endDate'] = row.endDate;
        }
        if(row.endDate === null){
          row.endDate = 'N/A';
          finalResponse['endDate'] = row.endDate;
        }
        finalResponse['futureProviderName'] = row.futureProviderName ? row.futureProviderName : "N/A";
        finalResponse['futurestartDate'] = row.futureStartDate ? row.futureStartDate : 'N/A';
        return finalResponse;
      });

      if (healthPlanResponse && healthPlanResponse.length) {
        this.getTableDetails(healthPlanResponse[0]['providerCode']);
      }
      const healthPlanCaseNumber = this.priorCaseNumber.find(row => row.caseNumber === caseNumber);
      if(healthPlanCaseNumber){
        this.priorHealthCaseSubmitted = true;
        this.notDisplaySubmitText = false;
      }else{
        this.priorHealthCaseSubmitted = false;
      }
      if (this.submitMcoNewChangeSw) {
        this.showSubmit = res && res['displaySubmitFlag'];
      }
      if(res && res['displaySubmitFlag'] === false && !this.priorHealthCaseSubmitted ){
      this.hideHealthPlanOptions = false;
      this.notDisplaySubmitText = true;
      if (!this.submitMcoNewChangeSw) {
        this.showSubmit = false;
      }
      }else if(res && res['displaySubmitFlag'] === true){
        this.hideHealthPlanOptions = true;
        this.notDisplaySubmitText = false;
        if (!this.submitMcoNewChangeSw) {
          this.showSubmit = true;
        }
      }
    });
  }


  //First dropdown to click on 'select'
  submitCaseNumber() {
    this.showHealthPlanInfoSection = true;
    this.showHealthPlanInfoSectionTable = true;
    const caseSummary = this.caseNumberoptions.find(row => row.caseNum === this.caseNumber);
    // this.hoHSW = caseSummary.hoHSW;
    // this.showHealthPlanMcoSection = this.hoHSW === 'Y';
    const healthPlanCaseNumber = this.healthPlanCaseNumber?.find(row => row.caseNumber === this.caseNumber);
    const priorHealthPlanCaseNumber = this.priorCaseNumber?.find(row => row.caseNumber === this.caseNumber);
    // this.showHealthPlanMcoSection = (this.caseNumber === healthPlanCaseNumber?.caseNumber) || (this.caseNumber === priorHealthPlanCaseNumber?.caseNumber);
    this.showHealthPlanMcoSection = (this.caseNumber === healthPlanCaseNumber?.caseNumber);
    if (this.submitMcoNewChangeSw) {
      this.eligibleCase = (this.caseNumber === healthPlanCaseNumber?.caseNumber)
    }
    if(priorHealthPlanCaseNumber === undefined){
      this.priorCaseSubmitted = false;
    }else{
      this.priorCaseSubmitted = true;
    }

    if ((priorHealthPlanCaseNumber === undefined) && (healthPlanCaseNumber === undefined)) {
      this.notEligibleText = true;
    } else {
      this.notEligibleText = false;
    }
       
    // this.hoHSW = caseSummary.hoHSW;
    // this.showHealthPlanMcoSection = this.hoHSW === 'Y' && this.changeHealthPlan && this.healthPlanCaseNumber == this.caseNumber;
    // this.showNotEligibleHealthPlanSection = !this.showHealthPlanMcoSection;
    this.caseNumberList1=this.caseNumberoptions.filter(row => row.caseNum === this.caseNumber);

    this.getRequestSummaryDetails(caseSummary.caseNum, caseSummary.hoHID);
    this.getHealthPlanDetails(this.caseNumber);
  }

  async getHealthPlanDetailsProviderName(caseNumber) {
    let providerName;
    const url = `publ/mcoservices/mcodetails/case/${caseNumber}/indvid/${this.indvId}`;
    var res = await this.apiService.get(url,true, {headers: new HttpHeaders({'pageId': 'TCHPD'})}).toPromise();
      if (res && res['mcoInfoList'] && res['mcoInfoList'].length) {
        providerName = res['mcoInfoList'][0]['providerName']
      }  
   
    return providerName;
  }

  //Second dropdown to click on submit(which is beside the more info button)
  async submitMCOChange() {
    if (this.healthPlanMCO) {
      const caseSummary = this.caseNumber ? this.caseNumberoptions.find(row => row.caseNum === this.caseNumber) : this.caseNumberoptions[0];
      const providerName = await this.getHealthPlanDetailsProviderName(caseSummary.caseNum);
      this.isHealthPlanWithinMcoNinetyDays = caseSummary?.isWithinMcoNinetyDaysSw === 'Y';
      const mcoCancelReason = this.submitMcoNewChangeSw ? this.isHealthPlanWithinMcoNinetyDays ? "02" : "98" : null;
      const payload = {
        "caseNumber": caseSummary.caseNum,
        "indvId": caseSummary.hoHID,
        "mcoName": this.healthPlanMCO.code,
        "currentMcoName":providerName,
        "mcoChangeReason": mcoCancelReason,
        "startDt": null,
        "userId": JSON.parse(sessionStorage.getItem('currentUser')).userId,
      }
      this.disableMCOSubmit = true;
      this.showHealthPlanMcoSection = false;
      this.errorStatus = null;
      const contentHeader = new HttpHeaders({'pageId':'TCHPD'})
      this.apiService.post('publ/mcoservices/submitmcochangereq', payload,{headers: contentHeader},true).subscribe((res: any) => {
        const healthPlanSubmitResponse = res.returnType;
        if(healthPlanSubmitResponse.message === 'SUCCESS'){
          const url = `publ/mcoservices/mcochangerequestsummary/case/${caseSummary.caseNum}/indvid/${caseSummary.hoHID}`;
          this.apiService.get(url,true, {headers: new HttpHeaders({'pageId': 'TCHPD'})}).subscribe((res) => {
            this.changeReqSummayRows = res['mcoChangeRequestSummaryResponse'];
            this.errorStatus = this.changeReqSummayRows[0]?.status;
            if(this.errorStatus !== 'ERROR' ){
              this.showChangeReqSuccessAlert = true;
              this.openHealthPlanSubmitRequestModal(caseSummary);
            }
            this.onLoad(true, caseSummary);
          });
        }
      });
    }
  }


  openHealthPlanSubmitRequestModal(caseSummary) {
    const url = `publ/mcoservices/mcochangerequestsummary/case/${caseSummary.caseNum}/indvid/${caseSummary.hoHID}`;
    this.apiService.get(url, true, {headers: new HttpHeaders({'pageId': 'TCHPD'})}).subscribe(res => {
      const mcoChangeRequestSummaryResponse = res['mcoChangeRequestSummaryResponse'][0];
      const healthPlanDetails = { requestSubmittedDate: mcoChangeRequestSummaryResponse.requestDate, currentHealthPlan: mcoChangeRequestSummaryResponse.currentHealthPlan, futureHealthPlan: mcoChangeRequestSummaryResponse.newHealthPlan }
      this.modalService.openHealthPlanConfirmModal(healthPlanDetails).subscribe();
      this.getRequestSummaryDetails(caseSummary.caseNum, caseSummary.hoHID);
    });
  }

  openHealthPlansModal(): void {
    this.modalService.openHealthPlanTypes();
  }


  openHealthPlanCancelRequestModal(changeReqSummayResponse) {
    const mcoChangeRequestSummaryResponse = changeReqSummayResponse[0];
    const healthPlanDetails = { requestSubmittedDate: mcoChangeRequestSummaryResponse.requestDate }
    this.modalService.openHealthPlanCancelConfirmModal(healthPlanDetails).subscribe();
  }

  cancelHealthPlan(row){
    this.modalService.cancelHealthPlan().subscribe((res => {
      if(res){
        this.cancelMCOChange(row);
      }
    }))
  }

  async cancelMCOChange(healthPlanDetails) {
    if (healthPlanDetails) {
      this.showChangeReqSuccessAlert = false;
      const caseSummary = this.caseNumber ? this.caseNumberoptions.find(row => row.caseNum === this.caseNumber) : this.caseNumberoptions[0];
      const providerName = await this.getHealthPlanDetailsProviderName(caseSummary.caseNum);
      const payload = {
        "caseNumber": caseSummary.caseNum,
        "indvId": caseSummary.hoHID,
        "mcoName": healthPlanDetails?.newHealthPlanCode,
        "currentMcoName":providerName,
        "mcoChangeReason": '00',
        "startDt": null,
        "userId": JSON.parse(sessionStorage.getItem('currentUser')).userId,
        "hpRequestSeqNum": healthPlanDetails?.hpRequestSeqNum
      }
      this.disableMCOSubmit = true;
      this.showHealthPlanMcoSection = true;
      this.errorStatus = null;
      const contentHeader = new HttpHeaders({'pageId':'TCHPD'})
      this.apiService.post('publ/mcoservices/submitmcochangereq', payload,{headers: contentHeader},true).subscribe((res: any) => {
        const healthPlanSubmitResponse = res.returnType;
        if(healthPlanSubmitResponse.message === 'SUCCESS'){
          const url = `publ/mcoservices/mcochangerequestsummary/case/${caseSummary.caseNum}/indvid/${caseSummary.hoHID}`;
          this.apiService.get(url,true, {headers: new HttpHeaders({'pageId': 'TCHPD'})}).subscribe((res) => {
            this.changeReqSummayRows = res['mcoChangeRequestSummaryResponse'];
            this.errorStatus = this.changeReqSummayRows[0]?.status;
            const errorCode = this.changeReqSummayRows[0]?.errorCode
            if (this.errorStatus !== 'ERROR' && errorCode !== '50021' && errorCode !== '1') {
              this.openHealthPlanCancelRequestModal(this.changeReqSummayRows);
            } else if (this.errorStatus !== 'ERROR' && errorCode === '50021' || errorCode === '1') {
              this.modalService.healthPlanSubmitError();
            } 
            this.onLoad(null, caseSummary);
          });
        }
      });
    }
  }
}

