import { Component, DoCheck, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '@app/gateway/services/api.service';
import { ModalService } from '@app/services/modal/modal.service';
import { UserProfileService } from '../../user-profile.service';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

declare let gtag: Function;
@Component({
    selector: 'documents-needed',
    templateUrl: './documents-needed.component.html',
    styleUrls: ['./documents-needed.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentsNeededComponent implements OnInit, DoCheck {
    rows;
    columns = [
    { name: 'Name', prop: 'fullName', width: -100},
    { name: 'Case', prop: 'caseNum' },
    { name: 'Information Needed', prop: 'proofType' },
    { name: 'Due Date', prop: 'verfDue' }
    ];

    appealColumns = [
        { name: 'Name', prop: 'fullName', width:80},
        { name: 'Appeal', prop: 'appealNum',width:84 },
        { name: 'Information Needed', prop: 'verfDesc',width:124 },
        { name: 'Date Filed', prop: 'appealFileDate',width:80 },
        { name: 'Last Updated', prop: 'lastUpdatedDateApl',width:95 }
    ];

    houseHoldColumns = [
        { name: 'Head of Household', prop: 'hohName' },
        { name: 'Submission Type', prop: 'type' },
        { name: 'Date of Submission', prop: 'submissionDate' }
    ];

    kbColumns = [
        { name: 'Name', prop: 'fullName'},
        { name: 'Referral ID', prop: 'refId'}
    ]

    bccColumns = [
      { name: 'Name', prop: 'fullName', width: -100},
      { name: 'Case', prop: 'caseNum' }
    ]

    medicalAppealColumns = [
        { name: 'Name', prop: 'fullName', width:150},
        { name: 'Issue/Appeal ID', prop: 'issueId',width:150 },
        { name: 'Information Needed', prop: 'verfDesc',width:150 },
        { name: 'Due Date', prop: 'appealDueDate',width:125 }      
    ];

    householdRows;
    appealRows;
    medicalAppealRows;
    response;
    indiv;
    referenceData;
    mainPage = true;
    clickedRow;
    caseA = false;
    success = false;
    appTrackingNum;
    caseB = false;
    caseC = false;
    finalTableDispalyed: any;
    screenHeight: number;
    screenWidth: number;
    isMobileView = false;
    mobileWidth = 480;
    kbRows;
    linkMyCase = false;
    kbList;
    windowScrolled: boolean;
    defaultLanguage;
    translatedLang;
    application;
    renewal;
    change;
    vc190: boolean;
    bccRows;
    bccCase;
    bccEnable = false;
    caseNumber;
    fromMyAppeals;
    fromRMCDocUpload = false;
    preterm;
    vc136: boolean = false;
    readTCC = sessionStorage.getItem('ReadTCC') === 'true' ? true : false;
    isPartnerApp: boolean = false;
    constructor(private modalService: ModalService,
        private userProfileService: UserProfileService,
        private apiService: ApiService, private datePipe: DatePipe,
        public translateService: TranslateService,
        private titleService: Title
        ) {
            this.getScreenSize();
            this.titleService.setTitle('My Documents | Documents we need');
        }
        
        @HostListener("window:scroll", [])
        onWindowScroll() {
            if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
                this.windowScrolled = true;
            } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
                this.windowScrolled = false;
            }
        }
        
        scrollToTop() {
            setTimeout(() => {
                const container = document.body.querySelector('.ng-sidebar__content');
                if (container) {
                    container.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
            }, 100)
        }

        @HostListener('window:resize', ['$event'])
        getScreenSize() {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
        }

        ngDoCheck() {
            this.isMobileView = this.screenWidth <= this.mobileWidth;
        }

    ngOnInit(): void {
        this.defaultLanguage = this.translateService.getDefaultLang();
        this.translatedLang = this.defaultLanguage;
        this.userProfileService.fromMyAppeals.subscribe((bool) => {
            this.fromMyAppeals = bool;
        })
        const accountType = sessionStorage.getItem("userType");
        this.isPartnerApp = accountType === "RP" ? true : false;
        this.fromMyAppeals = JSON.parse(sessionStorage.getItem('myAppealsUploadProof'));
        if (this.fromMyAppeals) {
            this.clickedRow = JSON.parse(sessionStorage.getItem('appealUploadInfo'));
            this.caseA = true;
            sessionStorage.removeItem('myAppealsUploadProof');
            sessionStorage.removeItem('appealUploadInfo');
        }
        if(sessionStorage.getItem('fromRmcDocUpload') === 'true') {
            sessionStorage.removeItem('fromRmcDocUpload');
            this.fromRMCDocUpload = true;
            this.caseB = true;
            this.appTrackingNum = sessionStorage.getItem('appTrackingNum')
        }

        this.caseNumber = this.fromMyAppeals === 'true'? sessionStorage.getItem('appealCaseNumber') : null;
        if (this.defaultLanguage === 'en') {
            this.application = "Application: ";
            this.renewal = "Renewal: ";
            this.change = "Change: ";
            this.preterm = "Questions for Review: "
        } else {
            this.application = "Aplicacion: "
            this.renewal = "Renovación: ";
            this.change = "Cambio: ";
            this.preterm = "de las Preguntas para la Revisión: ";
        }
        this.translateService.onDefaultLangChange.subscribe((res) => {
            this.translatedLang = res.lang;
            if (this.translatedLang === 'en') {
                this.application = "Application: ";
                this.renewal = "Renewal: ";
                this.change = "Change: ",
                this.preterm = "Questions for Review: "
            } else {
                this.application = "Aplicacion: "
                this.renewal = "Renovación: ";
                this.change = "Cambio: ";
                this.preterm = "de las Preguntas para la Revisión: ";
            }
            this.getCmbData();
        });
        this.getCmbData();
    }

    getCmbData () {
        this.indiv = sessionStorage.getItem('individualId');
        if (!this.indiv || this.indiv === '0' || this.indiv == 'undefined' || this.indiv == 'null') {
            this.linkMyCase = true;
            }
        this.userProfileService.getDocumentsWeNeed(this.indiv).pipe(mergeMap((res: any): Observable<any> => {
            this.response = { ...res }
            this.kbRows = res.cmbCaseIndvData.filter((elem, index) => res.cmbCaseIndvData.findIndex(obj => obj.refId !== null && obj.refId === elem.refId) === index);
            if (res?.bccTreatment === true && res.bccCaseIndvData.length > 0) {
                this.bccCase = true
                res.bccCaseIndvData.forEach(list => {
                    list['fullName'] = list.firstName + " " + list.lastName;
            })
            this.bccRows = res.bccCaseIndvData.filter(data => data.fullName);
            } 
            if (res.appealInfo && res.appealInfo.length > 0) {
                res.appealInfo.forEach(list => {
                    list['fullName'] = list.firstName + " " + list.lastName;
                    if (list.appealFileDate !== null) {
                        list['appealFileDate']= this.datePipe.transform(list.appealFileDate, "MM/dd/yyyy");
                    }
                    if (list.lastUpdatedDateApl !== null) {
                        list['lastUpdatedDateApl'] = this.datePipe.transform(list.lastUpdatedDateApl, "MM/dd/yyyy");
                    }
                });

                this.appealRows = res.appealInfo.map(data => {
                    if(data.appealNum){
                        const item = {...data};
                        return item;
                    }
                }).filter(appealnum => appealnum !== undefined);

                this.appealRows = this.appealRows.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.appealNum === value.appealNum
                    ))
                );

                if (this.appealRows) {
                    for (let i = 0; this.appealRows.length > i; i++) {
                        if (this.translatedLang === 'en') {
                            this.appealRows[i].verfDesc = this.appealRows[i].uploadAppealProofVfd === true ? "Appeal Proof Documents, Documents for Appeals" : "Documents for Appeals";
                        } else {
                            this.appealRows[i].verfDesc = this.appealRows[i].uploadAppealProofVfd === true ? "Documentos de comprobación para apelación, Documentos para Apelaciones" : "Documentos para Apelaciones";
                        }
                    }
                }
            }

            if (res.medicalAppealInfo && res.medicalAppealInfo.length > 0) {
                res.medicalAppealInfo.forEach(list => {
                    list['fullName'] = list.firstName + " " + list.lastName;
                    if (list.dueDate !== null) {
                        list['appealDueDate']= this.datePipe.transform(list.dueDate, "MM/dd/yyyy");
                    }
                });

                this.medicalAppealRows = res.medicalAppealInfo.map(data => {
                    if(data.issueId){
                        const item = {...data};
                        return item;
                    }
                }).filter(issueId => issueId !== undefined);

                this.medicalAppealRows = this.medicalAppealRows.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.issueId === value.issueId
                    ))
                );

                if (this.medicalAppealRows) {
                    for (let i = 0; this.medicalAppealRows.length > i; i++) {
                        if (this.translatedLang === 'en') {
                            this.medicalAppealRows[i].verfDesc = "Additional Information Form";
                        } else {
                            this.medicalAppealRows[i].verfDesc = "Formulario para Información Adicional";
                        }
                    }
                }
            }

            if (res?.cmbCaseIndvData.length > 0) {
                this.rows = res?.cmbCaseIndvData;
                this.rows.forEach(element => {
                    if (element?.verfCd === 'VC136') {
                        this.vc136 = true;
                    }
                });
            } 
            if (res.caseScenario === 'B' && res.myAppInfoList.length > 0) {
                let submsnDate;
                this.householdRows = res.myAppInfoList.map(lis => {
                    submsnDate = new Date(lis.submittedTs.year, (lis.submittedTs.monthValue.length === 1 ? '0' + lis.submittedTs.monthValue : lis.submittedTs.monthValue) - 1, lis.submittedTs.dayOfMonth, lis.submittedTs.hour, lis.submittedTs.minute, lis.submittedTs.second);
                    const appType = lis.appType === 'AFB' ? this.application : lis.appType === 'RMB' ? this.renewal : lis.appType === 'PRT' ? this.preterm : this.change;
                    return {
                        hohName: lis.hohFirstName + " " + lis.hohLastName,
                        type: appType + '\n' + lis.wpAppNum,
                        submissionDate: this.datePipe.transform(submsnDate, "MM/dd/yyyy h:mm a"),
                        submittedStatus: lis.submittedStatus,
                        kbList: lis.kbDataList && lis.kbDataList.length > 0 ? lis.kbDataList : null
                    }
                })
                if (res.cmbCaseIndvData.length > 0) {
                    res.cmbCaseIndvData.forEach(list => {
                            list['fullName'] = list.firstName + " " + list.lastName;
                        if (list.verfDueDt !== null) {
                            list['verfDue'] = `${list.verfDueDt.monthValue}/${list.verfDueDt.dayOfMonth}/${list.verfDueDt.year}`;
                        }
                    })

                    this.rows = res.cmbCaseIndvData.filter(data => !data.appealNum &&  !data.refId);
                    if (this.rows) {
                        for (let i = 0; this.rows.length > i; i++) {
                            if (this.rows[i].proofType) {
                                if (this.translatedLang !== 'en') {
                                    this.rows[i].proofType = this.rows[i].proofTypeEs;
                                } else {
                                    this.rows[i].proofType = this.rows[i].proofType;
                                }
                            }
                        }
                    }
                }
            } else if (res.caseScenario === 'A') {
                if(res.cmbCaseIndvData.length > 0){
                    res.cmbCaseIndvData.forEach(list => {
                            list['fullName'] = list.firstName + " " + list.lastName;
                        if (list.verfDueDt !== null) {
                            list['verfDue'] = `${list.verfDueDt.monthValue}/${list.verfDueDt.dayOfMonth}/${list.verfDueDt.year}`;
                        }
                    })

                    this.rows = res.cmbCaseIndvData;
                    this.rows.forEach(element => {
                        if (element.verfCd === 'VC190') {
                            this.vc190 = true;
                        }
                    });
                    if (this.rows) {
                        for (let i = 0; this.rows.length > i; i++) {
                            if (this.rows[i].proofType) {
                                if (this.translatedLang !== 'en') {
                                    this.rows[i].proofType = this.rows[i].proofTypeEs;
                                } else {
                                    this.rows[i].proofType = this.rows[i].proofType;
                                }
                            }
                        }
                    }
                }
            }
            const url = 'publ/reference-tables?tables=TVDC';
            return this.apiService.get(url, null, {headers :new HttpHeaders({'pageId': 'TCDWN'})});
        })).subscribe((res) => {
                const tableDisplayed = [];
                this.referenceData = [...res.referenceGroup[0].referenceData];
                this.response['cmbCaseIndvData'].forEach((item) => {
                    if (item.verfCd !== 'AS') {
                        tableDisplayed.push(res.referenceGroup[0].referenceData.filter(tableData => tableData.code == item.verfCd)[0].value);
                    } else if (item.verfCd === 'AS') {
                        tableDisplayed.push('ASPR');
                    }
                })
                if (this.bccCase) {
                    tableDisplayed.push('BCPR');
                } 
                this.finalTableDispalyed = tableDisplayed.filter((c, index) => {
                    return tableDisplayed.indexOf(c) === index;
                });
        });

    }

    showProofTypes() {
        this.modalService.openTypesOfProofs(this.finalTableDispalyed);
    }

    documentUploaded(e) {
        if (e.success) {
            if (sessionStorage.getItem('fromRmcDocUpload') === 'true') {
                sessionStorage.removeItem('fromRmcDocUpload');
                this.fromRMCDocUpload = true;
            } else {
                this.fromRMCDocUpload = false;
            }
            this.scrollToTop();
            this.mainPage = true;
            this.success = true;
            gtag('event', 'DocumentUploaded', {
                'event_category': 'Document uploaded successfully',
                'event_label': 'Document uploaded successfully',
                'value': "success"
              });
            this.caseB = false;
            this.caseA = false;
            this.bccEnable = false;
            this.getCmbData();
        }
    }

    uploadProof (row) {
        this.mainPage = false;
        this.caseA = true;
        row.refId ? this.clickedRow = {...row, ...{ verfDesc: 'Katie Beckett Supporting Document '}} : this.clickedRow = {...row};
    }
    documentUploadB(row) {
        this.mainPage = false;
        this.caseB = true;
        this.appTrackingNum = row.type
        this.kbList = row.kbList ? [...row.kbList] : null;
        this.bccEnable = this.appTrackingNum ? false : true;
        this.caseNumber = row?.caseNum
    }
}