export const otherIncome = {
    otherIncomeTypeCode: 'OTHER_INCOME_SATIC_TEXT2',
    socialSecurityIncomeTypeCode: 'OTHER_INCOME_SATIC_TEXT3',
    veteranBenefitTypeCode: 'OTHER_INCOME_SATIC_TEXT4',
    alimonyOrderDate: 'OTHER_INCOME_SATIC_TEXT5',
    paymentStartDate: 'RMB_OTHER_INCOME_STATIC_TEXT1',
    paymentFrequency: 'RMB_OTHER_INCOME_STATIC_TEXT2',
    paymentAmount: 'RMB_OTHER_INCOME_STATIC_TEXT3',
    incomeEndDate: 'RMB_OTHER_INCOME_STATIC_TEXT10'
}

export const sp_otherIncome = {
    otherIncomeTypeCode: '¿Qué tipo de Otros Ingresos es esto?',
    socialSecurityIncomeTypeCode: '¿Qué tipo de ingresos del Seguro Social es esto?',
    veteranBenefitTypeCode: '¿Qué tipo de beneficio de veteranos es esto?',
    alimonyOrderDate: 'Fecha de la orden de pensión alimenticia',
    paymentStartDate: '¿Cuándo comenzó a recibir pagos de <Other Income Type>?',
    paymentFrequency: '¿Con qué frecuencia <Person> recibe pagos de <Other Income Type>?',
    paymentAmount: 'How much is each payment from this  incomeType?',
    incomeEndDate: '¿Cuándo comenzó a recibir pagos de <Other Income Type>?'
}

export const kindData = {
    someOnePayFoodMonthly: "RMB_OTHER_INCOME_STATIC_TEXT4",
    someOnePayLivesMonthly: 'RMB_OTHER_INCOME_STATIC_TEXT5',
    inkindSupportList: [ {
        amountPerMonth: 'OTHER_INCOME_SATIC_TEXT21',
        expenseType: 'OTHER_INCOME_SATIC_TEXT22',
        amountPaidByInHome: 'OTHER_INCOME_SATIC_TEXT23',
        amountPaidByOutHome: 'OTHER_INCOME_SATIC_TEXT24',
    }],
      liveWithOtherAdults: 'RMB_OTHER_INCOME_STATIC_TEXT6',
      paidHouseholdExpenseAmt: 'RMB_OTHER_INCOME_STATIC_TEXT7',
      howManyPeopleLive: 'RMB_OTHER_INCOME_STATIC_TEXT8',
}

export default {
    otherIncomeDetails : otherIncome,
    inKindData: kindData,
    removed: {
        otherIncomeEndDate: "OTHERINCOME_RMB_SUMMARY_STATIC1",
    }
}

export const eng_labels = {
    otherIncomeDetails : otherIncome,
    inKindData: kindData,
    removed: {
        otherIncomeEndDate: "OTHERINCOME_RMB_SUMMARY_STATIC2",
    }
}

export const sp_labels = {
    otherIncomeDetails : sp_otherIncome,
    inKindData: kindData,
    removed: {
        otherIncomeEndDate: "OTHERINCOME_RMB_SUMMARY_STATIC2",
    }
}
