<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title" [innerHtml]="title | translate | sanitize "></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <p [innerHtml]="'CONFIRM_OPEN_APPEAL_1' | translate | sanitize " class="pb-2 pt-2"></p>
  </div>
  <div class="modal-footer" style="justify-content: flex-start;">
    <div class="mr-2 ml-2">
      <button type="button" id="dialog" class="btn btn-block btn-secondary"  (click)="onConfirm()">{{
        'CONFIRM_OPEN_APPEAL_2' | translate }}</button>
    </div>
    <div class="mr-2 ml-2">
      <button type="button" id="dialog" class="btn btn-block btn-link" style="min-height: 0px;" (click)="onCancel()">{{
        'CONFIRM_OPEN_APPEAL_3' | translate }}</button>
    </div>
  </div>
</div>