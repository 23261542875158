import { AfterViewInit, Component, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nh-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrollTopComponent implements AfterViewInit{
  isButtonVisible: boolean = false;
  scrollProgress: number = 0;
  scrollContainer: HTMLElement | null = null;
  scrollListener: () => void = () => {};

  constructor(private renderer: Renderer2) {
  }
 
  ngAfterViewInit() {
      this.scrollContainer = document.querySelector('.ng-sidebar__content') as HTMLElement;
      console.log(this.scrollContainer)
    if(this.scrollContainer){
      this.scrollListener = this.renderer.listen(this.scrollContainer, 'scroll', () => {
        const scrollOffset = this.scrollContainer!.scrollTop;
        const documentHeight = this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight;
        this.scrollProgress = (scrollOffset / documentHeight) * 100;
        this.isButtonVisible = scrollOffset > 50;

      })
    } else {
      console.error('scrollable container not found');
    }
  }

  scrollToTop() {
    if(this.scrollContainer){
      this.scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  ngOnDestroy(): void{
    if(this.scrollContainer){
      this.scrollListener();
    }
  }
}
