<div class="p-1 mt-1">
  <h5 class="section-header">{{'UPLOAD_DOCUMENT2' | translate }} {{appTrackNum}}</h5>
  <hr>
  <p class="static-text">{{'UPLOAD_DOC_TEXT1' | translate }} <strong>{{'UPLOAD_DOC_TEXT3' | translate }}</strong> {{'UPLOAD_DOC_TEXT4' | translate }}
  </p>
  <div (click)="showProofTypes()" class="pb-3 popup">{{'Types of Proof' | translate }}
    <span><i class="fa fa-external-link"></i></span>
  </div>
  <div class="row mt-2">
    <div class="col-md-6">
      <span class="text-danger">*</span><label for="proofType" class="label-section">{{'PROOF_TYPE' | translate }}</label>
      <select *ngIf="translatedLang === 'en'" class="custom-select-info" aria-label="proofType" [(ngModel)]="proofType" (change)="changeProofType()"
        required>
        <option *ngIf="!appealsUpload" [ngValue]="null">Pick an option</option>
        <option *ngIf="appealsUpload" [ngValue]="appealOption">{{appealOption.enDescription}}</option>
        <option *ngFor="let option of options" [ngValue]="option">{{option.enDescription}}</option>
      </select>
      <select *ngIf="translatedLang !== 'en'" class="custom-select-info" aria-label="proofType" [(ngModel)]="proofType" (change)="changeProofType()"
      required>
      <option *ngIf="!appealsUpload" [ngValue]="null">Elija una opción</option>
      <option *ngIf="appealsUpload" [ngValue]="appealOption">{{appealOption.spDescription}}</option>
      <option *ngFor="let option of options" [ngValue]="option">{{option.spDescription}}</option>
    </select>
      <div *ngIf="!proofType && proofTypeError" class="error-messages position-relative">
        <span class="form-text invalid-tooltip d-block">
          <ng-container>{{'REQUIRED' | translate }}</ng-container>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="kbDocument && kbDataList" class="row pt-3">
    <div class="col-md-6">
      <span class="text-danger">*</span><label for="Ref Id" class="label-section">{{'UPLOAD_DOC_STATIC' | translate }}</label>
      <select class="custom-select-info" aria-label="refids" [(ngModel)]="refId" required>
        <option *ngIf="translatedLang === 'en'" [ngValue]="null">Pick an option</option>
        <option *ngIf="translatedLang !== 'en'" [ngValue]="null">Elija una opción</option>
        <option *ngFor="let option of kbDataList" [ngValue]="option">{{option.name}}</option>
      </select>
    </div>
  </div>
  <div class="pt-3" *ngIf="proofType">
    <div *ngFor="let doc of documents; index as i" [ngClass]="{'upload-document-border p-2 mt-2': i !== 0 && doc.show}">
      <div class="row">
        <div class="col-md-12">
          <span class="text-danger">*</span><label for="docType" class="label-section">{{'DOCUMENT_TYPE' | translate }}</label>
          <p class="static-text" [innerHtml]="'DOCUMENT_TEXT' | translate"></p>
            <a *ngIf="isMobileView && !doc.file && i !== 0" (click)="close(i)" class="mobile-upload-btn-close"><span class="pr-2">{{ 'CLOSE' | translate }}</span><i class="fa fa-times-circle"></i></a>
          <select  *ngIf="translatedLang === 'en'" class="custom-select-info" aria-label="documentType" [(ngModel)]="doc.docType" name="doc.docType"
            (change)="selectedOption(i)" required>
            <option [ngValue]="null">Pick an option</option>
            <option *ngFor="let option of docOptions" [ngValue]="option">{{option.enDescription}}</option>
          </select>
          <select  *ngIf="translatedLang !== 'en'" class="custom-select-info" aria-label="documentType" [(ngModel)]="doc.docType" name="doc.docType"
          (change)="selectedOption(i)" required>
          <option [ngValue]="null">Elija una opción</option>
          <option *ngFor="let option of docOptions" [ngValue]="option">{{option.spDescription}}</option>
        </select>
          <div *ngIf="doc.error" class="error-messages position-relative">
            <span class="form-text invalid-tooltip d-block">
              <ng-container>{{'REQUIRED' | translate }}</ng-container>
            </span>
          </div>
        </div>
        <div *ngIf="!isMobileView && !doc.file && i !== 0" class="col-md-2 offset-md-4">
          <div class="row pl-1 pt-1 hover-red upload-btn-close">
            <a (click)="close(i)"><span class="pr-2">{{'CLOSE' | translate }}</span><i class="fa fa-times-circle"></i></a>
          </div>
        </div>
      </div>
      <div class="row pt-3" *ngIf="doc.show">
        <div class="col-md-12">
          <nh-file-upload (passFileData)="getFileDetails($event, i)"></nh-file-upload>
        </div>
      </div>
      <div class="d-flex align-items-center p-3" *ngIf="doc.file">
        <div class="row uploaded-documentname">
          <div class="pr-2 file-icon"><img alt="uploadedDoc" src="../../../../../../assets/images/⚛️ icons-document-attach-outline.svg">
          </div>
          <div class="file-name" style="max-width:440px">
            <span>{{doc.file.name}}</span>
          </div>
          <div class="hover-red option-lables">
            <a class="btn btn-link hover-red" (click)="viewPdf(i)">
              <span>View</span>
              <svg id="_icons_search-outline" data-name="⚛️ icons/search-outline" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Background" width="24" height="24" fill="#174a7c" />
                  </clipPath>
                </defs>
                <g id="Mask_Group_1" data-name="Mask Group 1" clip-path="url(#clip-path)">
                  <path id="Combined_Shape" data-name="Combined Shape"
                    d="M18.232,19.292l-.013-.012-4.927-4.927a8.122,8.122,0,1,1,1.061-1.061l4.927,4.927a.75.75,0,0,1-1.049,1.073ZM1.5,8.114A6.614,6.614,0,1,0,8.114,1.5,6.621,6.621,0,0,0,1.5,8.114Z"
                    transform="translate(2.25 2.25)" fill="#174a7c" />
                </g>
              </svg>
            </a>

            <a class="btn btn-link hover-red" (click)="removeItem(i)">
              <span>{{'Remove' | translate }}</span>
              <nh-remove-svg></nh-remove-svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="proofType" class="row mt-3 col-md-12">
    <div class="ml-2">
      <button id="addAnother" (click)="addAnother()" class="btn btn-block btn-outline-secondary btn-add-another"
        [hidden]="error">{{'ADD_ANOTHER' | translate }}
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg"
          width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape"
            d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z"
            transform="translate(2.25 2.25)" fill="#174a7c" />
        </svg></button>
    </div>
    <div class="ml-2">
      <button [hidden]="error || showCZPRmessage" routerLinkActive="active" (click)="submitDocuments()" id="continue"
        class="btn btn-secondary">{{'SUBMIT' | translate }}</button>
    </div>
  </div>

  <div style="border:0px solid #f5e0af;margin-top: 15px;" *ngIf="showCZPRmessage">
    {{'UPLOAD_DOC_TEXT2' | translate }}
  </div>
</div>