import { Component, Input } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';

@Component({
  selector: 'nh-health-plan-cancel-confirm',
  templateUrl: './health-plan-cancel-confirm.component.html',
  styleUrls: ['./health-plan-cancel-confirm.component.scss']
})
export class HealthPlanCancelConfirmComponent extends BaseModalDirective{
  @Input() healthPlanDetails: any;
}