import { and } from 'ramda';
import { Component, AfterContentInit, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { isChangeReport, isNewAddReapplyMode, isRedeMode, isAddProgramOrRede, isPartner, isRmbRmc } from '@app/helpers/mode.helpers';
import { StateService } from '@app/services/state/state.service';
import { assign } from '@app/helpers/function.helpers';
import { ApplicationNumber, Mode, Modes } from '@app/models/app.model';
import { UserProfileService } from '@app/gateway/user-profile/user-profile.service';
import { ApiService } from '@app/gateway/services/api.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ServiceConfigService } from '@app/services/service-config.service';
import { HttpHeaders  } from '@angular/common/http';

@Component({
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.scss']
})
export class CongratsComponent implements AfterContentInit, OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  isChangeMode = false;
  rteCaseStatus;
  workerPortalAppNum;
  appealSummaryInfo;
  isNewAddReapply = false;
  breastCervicalCancerSw;
  isRedeModeOnly = false;
  isAddProgramOrRede = false;
  applicationNumber: ApplicationNumber;
  mode: Mode;
  appSubmittedDate: Date;
  isPartnerApp = false;
  isNotLinkedAcc = false;
  results = [];
  indvId = sessionStorage.getItem('individualId');
  hasKT = false;
  isPartner;
  isPresumptive = false;
  isAdmitDischarge = false;
  isNewborn = false;
  isBCCEXT = false;
  isIME = false;
  isFullMedicAidApp;
  isAFB;
  isAppeals;
  isApproved: boolean = false;
  isDenied: boolean = false;
  isKepro: boolean = false;
  appType = sessionStorage.getItem('appType');
  isRMC = sessionStorage.getItem('mode') === 'CHANGE_REPORT';
  hideFullCoverage;
  appKeyWord;
  appKeyWord_1;
  rmbRmc = false;
  kbDataList = [];
  headOfHousehold;
  ecfIndicator;
  isFinReassessment = false;
  showCommPref = true;
  linkyourcase = true;
  realTimeAppMessage;
  CHANGE_HOW_LETTERS;
  CHANGE_LETTERS_STATIC_TEXT1;
  CHANGE_LETTERS_STATIC_TEXT2;
  CONGRATS_STATIC_TEXT;
  CONGRATS_STATIC_TEXT0;
  CONGRATS_STATIC_TEXT1;
  CONGRATS_STATIC_TEXT2;
  CONGRATS_STATIC_TEXT3;
  CONGRATS_STATIC_TEXT4;
  CONGRATS_STATIC_TEXT4_QFR;
  CONGRATS_STATIC_TEXT4_1;
  CONGRATS_STATIC_TEXT5;
  CONGRATS_STATIC_TEXT6;
  CONGRATS_STATIC_TEXT6_app;
  CONGRATS_STATIC_TEXT7;
  CONGRATS_STATIC_TEXT8;
  CONGRATS_STATIC_TEXT_REAL_TIME8;
  CONGRATS_STATIC_TEXT_NOT_REAL_TIME8;
  CONGRATS_STATIC_TEXT_QFR1;
  CONGRATS_STATIC_TEXT_QFR2;
  CONGRATS_STATIC_TEXT_QFR3;
  CONGRATS_STATIC_TEXT_QFR4;
  CONGRATS_STATIC_TEXT_QFR5;
  CONGRATS_STATIC_TEXT_QFR6;
  CONGRATS_STATIC_TEXT_QFR7;
  CONGRATS_STATIC_TEXT_QFR8;
  CONGRATS_STATIC_TEXT_QFR9;
  CONGRATS_STATIC_TEXT9;
  CONGRATS_STATIC_TEXT10;
  CONGRATS_STATIC_TEXT11;
  CONGRATS_STATIC_TEXT11_1;
  CONGRATS_STATIC_TEXT12;
  CONGRATS_STATIC_TEXT13;
  CONGRATS_STATIC_TEXT14;
  CONGRATS_STATIC_TEXT14_1;
  CONGRATS_STATIC_TEXT15;
  CONGRATS_STATIC_TEXT15_1;
  CONGRATS_STATIC_TEXT16;
  CONGRATS_STATIC_TEXT16_1;
  CONGRATS_STATIC_TEXT17;
  CONGRATS_STATIC_TEXT17_1
  CONGRATS_STATIC_TEXT18;
  CONGRATS_STATIC_TEXT18_1;
  CONGRATS_STATIC_TEXT19;
  CONGRATS_STATIC_TEXT20;
  CONGRATS_STATIC_TEXT20_1;
  CONGRATS_STATIC_TEXT21;
  CONGRATS_STATIC_TEXT22;
  CONGRATS_STATIC_TEXT23;
  CONGRATS_STATIC_TEXT24;
  CONGRATS_STATIC_TEXT25;
  CONGRATS_STATIC_TEXT26;
  CONGRATS_STATIC_TEXT27;
  CONGRATS_STATIC_TEXT28;
  CONGRATS_STATIC_TEXT29;
  CONGRATS_STATIC_TEXT30;
  CONGRATS_STATIC_TEXT31;
  CONGRATS_STATIC_TEXT32;
  CONGRATS_STATIC_TEXT33;
  CONGRATS_STATIC_TEXT34;
  CONGRATS_STATIC_TEXT35;
  CONGRATS_STATIC_TEXT36;
  CONGRATS_STATIC_TEXT37;
  CONGRATS_STATIC_TEXT38;
  CONGRATS_STATIC_TEXT39;
  CONGRATS_STATIC_TEXT40;
  CONGRATS_STATIC_TEXT41;
  COMM_PREF_FINISHMODULE_BANNER_TEXT1;
  COMM_PREF_FINISHMODULE_BANNER_TEXT2;
  MOBILE_APP_STATIC1;
  MOBILE_APP_STATIC2;
  MOBILE_APP_STATIC3;
  MOBILE_APP_STATIC4;
  CLICK;
  HERE;
  LINK_YOUR_CASE_HEADER;
  LINK_YOUR_CASE_FOR_LETTERS;
  LINK_YOUR_CASE_HEADERen = "Link Your Case";
  LINK_YOUR_CASE_FOR_LETTERSen = " to link your case to your account. When you link your case, you can change how you get your letters, report changes, and renew your coverage when its time.";
  CHANGE_HOW_LETTERSen1 = "Change how you get Letters";
  CHANGE_LETTERS_STATIC_TEXTen1 = "We mail your letters to you unless say you want your letters electronically. Link your case to your TennCare Connect account to change your communication preferences and see your letters online.";
  CHANGE_LETTERS_STATIC_TEXTen2 = "to change your Communication Preference. Or go to your Coverage Dashboard and select Communication Preferences from the left side menu.";
  CONGRATS_STATIC_TEXTen0 = "Thanks for updating your information!";
  CONGRATS_STATIC_TEXTsp0 = "¡Gracias por actualizar su información!"
  CONGRATS_STATIC_TEXTen = "We have your"
  CONGRATS_STATIC_TEXTen1 = "Your";
  CONGRATS_STATIC_TEXTen2 = "tracking number is";
  CONGRATS_STATIC_TEXTen3 = "This number is important and might help you later so be sure to save it in a safe place.";
  CONGRATS_STATIC_TEXTen4 =  "The application was submitted to TennCare on";
  CONGRATS_STATIC_TEXT4_QFRen = "The Questions for Review was submitted to TennCare on"
  CONGRATS_STATIC_TEXTen4_1 =  "Your renewal was submitted to TennCare on";
  CONGRATS_STATIC_TEXTen5 =  "What Happens Next";
  CONGRATS_STATIC_TEXTen6 = "Congratulations! You've been approved! You'll get a letter in the next few days that tells you more about your approval.";
  CONGRATS_STATIC_TEXTen6_app = "We're reviewing your renewal now. It may take us a few days to make a decision.<br><br>If we need more information from you, you'll get a letter that tells you what we need and when the information is due back. If we can make a decision with the facts we have, you'll get a letter that tells you what we decide.<br><br>If you have documents that you think we may ask you for after we review your change (like bank account statements, job information, or proof of your resources), you can upload your documents in the mobile app's <strong>Documents Center.</strong> Tap the 'X' in the top right corner of this screen to go back to the mobile app.";
  CONGRATS_STATIC_TEXTen7 = "We're sorry, but your application is denied. You'll get a letter in the <strong>next</strong> few days that tells you why you were denied and how to appeal if you think we made a mistake."
  CONGRATS_STATIC_TEXTen8 = "We’re reviewing your application now. It may take us a few days to make a decision.<br><br><p>If we need more information from you, you'll get a letter that tells you what we need and when the information is due back. If we can make a decision with the facts we have, you'll get a letter that tells you what we decide.</p><p>If you have documents that you think we may ask you for after we review your application (like bank account statements, job information, or proof of your resources), you can click the <strong>Upload Documents</strong> button below to send them now.</p>"
  CONGRATS_STATIC_TEXT_REAL_TIMEen8 = "Your change was submitted and your case information has been updated.<br><br><p>If we need more information from you, you'll get a letter that tells you what we need and when the information is due back.  If we can make a decision with the facts we have, you'll get a letter that tells you what we decide.</p><p>If you have documents that you think we may ask you for after we review your application (like bank account statements, job information, or proof of your resources), you can click the <strong>Upload Documents</strong> button below to send them now.</p>"
  CONGRATS_STATIC_TEXT_NOT_REAL_TIMEen8 = "We're reviewing your update now.  It may take us a few days to make a decision and see if you still qualify. <br><br><p>If we need more information from you, you'll get a letter that tells you what we need and when the information is due back.  If we can make a decision with the facts we have, you'll get a letter that tells you what we decide.</p><p>If you have documents that you think we may ask you for after we review your application (like bank account statements, job information, or proof of your resources), you can click the <strong>Upload Documents</strong> button below to send them now.</p>"
  CONGRATS_STATIC_TEXT_QFR1_en8 = "We’re reviewing your submission now. It may take us a few days to make a decision.";
  CONGRATS_STATIC_TEXT_QFR2_en8 = "If you need treatment for Breast or Cervical Cancer, you must first get screened at your local health department. Please contact your local health department for assistance.";
  CONGRATS_STATIC_TEXT_QFR3_en8 = "If we need more information from you, you'll get a letter that tells you what we need and when the information is due back.  If we can make a decision with the facts we have, you'll get a letter that tells you what we decide. <br><br>If you have documents that you think we may ask you for after we review your submission (like bank account statements, job information, or proof of your resources), you can click the Upload Documents button below to send them now";
  CONGRATS_STATIC_TEXTen9 = "We've finished processing your application. To see our decision, click <strong>Next</strong> at the bottom of the page."
  CONGRATS_STATIC_TEXTen10 = "Your Katie Beckett Referral";
  CONGRATS_STATIC_TEXTen11 = "We've received your Katie Beckett referral.";
  CONGRATS_STATIC_TEXTen11_1 = "We've received your Katie Beckett referral.";
  CONGRATS_STATIC_TEXTen12 = "The Katie Beckett tracking number for";
  CONGRATS_STATIC_TEXTen13 = "is";
  CONGRATS_STATIC_TEXTen14 = "Keep it in a safe place.  It can be used if you have questions about this referral.";
  CONGRATS_STATIC_TEXTen14_1 = "Keep it in a safe place.  It can be used if you have questions about this referral.";
  CONGRATS_STATIC_TEXTen15 = "We'll review your information to see if";
  CONGRATS_STATIC_TEXTen15_1 = "We'll review your information to see if";
  CONGRATS_STATIC_TEXTen16 = "qualifies for Medicaid. If we need more information from you, you'll get a letter that tells you what we need and when the information is due back.  If we can make a decision with the facts we have, you'll get a letter that tells you what we decide.  And we'll send your referral to the Department of Intellectual and Developmental Disabilities (DIDD).  They'll reach out soon to the contact person you listed in the referral to schedule an interview to complete the evaluation for the Katie Beckett program.  They may reach out before we finish reviewing your information.";
  CONGRATS_STATIC_TEXTen16_1 = "qualifies for Medicaid. And we'll send your referral to the Department of Intellectual and Developmental Disabilities (DIDD). They'll reach out soon to the contact person you listed in the referral to schedule an interview to complete the evaluation for the Katie Beckett program. They may reach out before we finish reviewing your information.";
  CONGRATS_STATIC_TEXTen17 = "Do you have Katie Beckett supporting documents (such as medical records) that you want to add now? Click the <strong>Upload Documents</strong> button below.";
  CONGRATS_STATIC_TEXTen17_1 = "Do you have Katie Beckett supporting documents (such as medical records) that you want to add now? Tap <b><a href='https://tenncareconnect.tn.gov/' target='_blank'>here</a></b>  to login to TennCare Connect and upload your documents.";
  CONGRATS_STATIC_TEXTen18 = "For questions about this referral,  please call the DIDD regional office in your area for free:";
  CONGRATS_STATIC_TEXTen18_1 = "For questions about this referral, please call the DIDD regional office in your area:";
  CONGRATS_STATIC_TEXTen19 = "West";
  CONGRATS_STATIC_TEXTen20 = "Middle";
  CONGRATS_STATIC_TEXTen20_1 = "Middle";
  CONGRATS_STATIC_TEXTen21 = "East";
  CONGRATS_STATIC_TEXTen22 = "About Your Self-Referral for Employment and Community First Choices";
  CONGRATS_STATIC_TEXTen23 = "The Employment and Community First CHOICES program is for people of all ages who have an intellectual or developmental disability (I/DD) including people who have significant disabilities. Services in the program can help you learn skills to become employed and live as independently as possible in the community.";
  CONGRATS_STATIC_TEXTen24 = "To see if you can be served through this program, you must ";
  CONGRATS_STATIC_TEXTen25 = "complete the self-referral form";
  CONGRATS_STATIC_TEXTen26 = "*Clicking this link will take you away from TennCare Connect and you will be logged out.";
  CONGRATS_STATIC_TEXTen27 = "Do you have questions about a referral? Call the DIDD regional office in your area for free:";
  CONGRATS_STATIC_TEXTen28 = "Print Your ";
  CONGRATS_STATIC_TEXTen29 = "A copy of this application will be saved in your TennCare Connect account so you don't have to print it or save it on your computer. You can review it anytime by logging in to your account. But if you want to print it out or save it for your records, remember that it includes a lot of your private information.";
  CONGRATS_STATIC_TEXT_QFR4_en29 = "A copy of your Questions for Review will be saved in your TennCare Connect account so you don't have to print it or save it on your computer. You can review it anytime by logging in to your account.  But if you want to print it out or save it for your records, remember that it includes a lot of your private information.";
  CONGRATS_STATIC_TEXTen30 = "Are you using a computer in a library, community center, or other public place? If so, do these 3 things before you go:";
  CONGRATS_STATIC_TEXTen31 = "Remember to pick up your printed copy from the printer if you printed a copy.";
  CONGRATS_STATIC_TEXTen32 = "If the printer jams or fails to print, contact someone at your location to help you.";
  CONGRATS_STATIC_TEXTen33 = "After you’re finished, be sure to log out and close TennCare Connect.";
  CONGRATS_STATIC_TEXTen34 = "To print, click <b>Print</b> button below.";
  CONGRATS_STATIC_TEXTen35 = "Print";
  CONGRATS_STATIC_TEXTen36 = "If you're having trouble printing your ";
  CONGRATS_STATIC_TEXTen37 = "FAQ page.";
  CONGRATS_STATIC_TEXTen38 = "Upload Documents";
  CONGRATS_STATIC_TEXTen39 = "Next";
  CONGRATS_STATIC_TEXTen40 = "Print Your Change Report";
  CONGRATS_STATIC_TEXTen41 = " check out our ";
  COMM_PREF_FINISHMODULE_BANNERen1_Text1 = "Do you want to get your letters electronically?"; 
  MOBILE_APP_STATIC1en = "You can change your Communication Preferences if your case is linked to your account. Tap the 'X' in the top right corner of this screen to go back to the mobile app. Tap <strong>More</strong> on the bottom navigation menu then tap <strong>Settings.</strong>";
  MOBILE_APP_STATIC2en = "We mail your letters to you unless say you want your letters electronically. Link your case to change your Communication Preferences. Tap the 'X' in the top right corner of this screen to go back to the mobile app. Tap <strong>More</strong> on the bottom navigation menu then tap <strong>Settings.</strong>";
  MOBILE_APP_STATIC3en = "Return to Dashboard";
  MOBILE_APP_STATIC4en = "Tap the 'X' in the top right corner of this screen to go back to the Dashboard.";
  CLICKen = " Click"; 
  HEREen = " here";
  COMM_PREF_FINISHMODULE_BANNERen1_Text2 = " to change your <b>Communication Preferences</b>. You can choose to get an email or text when you have letters to read from us.";
  COMM_PREF_FINISHMODULE_BANNEResp1_Text1 = "¿Quieres recibir tus cartas electrónicamente?"; 
  MOBILE_APP_STATIC1sp = "Puede cambiar sus Preferencias de Comunicación si su caso está vinculado a su cuenta. Toque la 'X' en la esquina superior derecha de esta pantalla para volver a la aplicación móvil. Toque <strong>Más</strong> en el menú de navegación inferior y luego toque <strong>Configuración.</strong>";
  MOBILE_APP_STATIC2sp = "Nosotros le enviamos sus cartas por correo a menos que diga que quiere sus cartas electrónicamente. Vincule su caso para cambiar sus Preferencias de Comunicación. Toque la 'X' en la esquina superior derecha de esta pantalla para volver a la aplicación móvil. Toque <strong>Más</strong> en el menú de navegación inferior y luego toque <strong>Configuración.</strong>";
  MOBILE_APP_STATIC3sp = "Volver a la Tablero de control";
  MOBILE_APP_STATIC4sp = "Toque la 'X' en la esquina superior derecha de esta pantalla para volver a la Tablero de control."
  CLICKsp = " Haga clic"; 
  HEREsp = " aquí";
  LINK_YOUR_CASE_HEADERsp = "Vincule su caso";
  LINK_YOUR_CASE_FOR_LETTERSsp = " para vincular su caso a su cuenta. Cuando vincula su caso, puede cambiar cómo recibe sus cartas, reportar cambios y renovar su cobertura cuando le toque.";
  COMM_PREF_FINISHMODULE_BANNERsp1_Text2 = " para cambiar su <b>preferencia de comunicación</b>. Puede optar por recibir un correo electrónico o un mensaje de texto cuando tenga cartas para leer de nosotros.";
  CHANGE_HOW_LETTERSsp1 = "Cambie el método de recibir sus cartas";
  CHANGE_LETTERS_STATIC_TEXTsp1 = "Nosotros le enviamos sus cartas por correo a menos que diga que quiere sus cartas electrónicamente. Vincule su caso a su cuenta de TennCare Connect para cambiar sus preferencias de comunicación y ver sus cartas en línea.";
  CHANGE_LETTERS_STATIC_TEXTsp2 = "para cambiar su preferencia de comunicación. O vaya a su Tablero de Cobertura y seleccione Preferencias de comunicación en el menú del lado izquierdo.";
  CONGRATS_STATIC_TEXTsp = "¡Tenemos su"
  CONGRATS_STATIC_TEXT_QFR5_sp = "¡Tenemos sus"
  CONGRATS_STATIC_TEXTsp1 = "El número de seguimiento del informe de cambios";
  CONGRATS_STATIC_TEXT_QFR6_sp1 = "El número de seguimiento de sus";
  CONGRATS_STATIC_TEXTsp2 = "es el";
  CONGRATS_STATIC_TEXT_QFR7_sp2 = "es";
  CONGRATS_STATIC_TEXTsp3 = "Este número es importante y podría ayudarle más adelante, así que asegúrese de guardarlo en un lugar seguro.";
  CONGRATS_STATIC_TEXTsp4 = "La solicitud se envió a TennCare el";
  CONGRATS_STATIC_TEXT4_QFRsp = "Las Preguntas para la Revisión se enviaron a TennCare el";
  CONGRATS_STATIC_TEXTsp4_1 = "La renovación se envió a TennCare el";
  CONGRATS_STATIC_TEXTsp5 = "¿Qué ocurrirá después?";
  CONGRATS_STATIC_TEXTsp6 = "¡Felicitaciones! ¡Ha sido aprobado! Recibirá una carta en los próximos días que le informará más sobre su aprobación. ";
  CONGRATS_STATIC_TEXTsp6_app = "Estamos revisando su renovación ahora. Podríamos tardar varios días en tomar una decisión.<br><br>Si necesitamos más información de usted, recibirá una carta que dice lo que necesitamos y cuándo necesitamos la información. Si podemos tomar una decisión con la información que tenemos, le enviaremos una carta que le dice cuál fue nuestra decisión.<br><br>Si tiene documentos que cree que podríamos pedirle después de que revisemos su cambio (como extractos de cuentas bancarias, información de su trabajo o comprobantes de sus recursos), puede cargar sus documentos el <strong>Centro de Documentos</strong> de la aplicación móvil. Toque la 'X' en la esquina superior derecha de esta pantalla para volver a la aplicación móvil."
  CONGRATS_STATIC_TEXTsp7 = "Lo sentimos, pero se rechazó su solicitud. Recibirá una carta en los próximos días que le informará por qué se la negaron y cómo apelar si cree que cometimos un error. ";
  CONGRATS_STATIC_TEXTsp8 = "Estamos revisando su actualización ahora. Puede requerir algunos días tomar una decisión para ver si sigue calificando. <br><br><p>Si necesitamos más información, le enviaremos una carta que dice lo que necesitamos y la fecha en que la necesitamos. Si podemos tomar una decisión con la información que tenemos, recibirá una carta informándole nuestra decisión.</p><p>Si tiene documentos que cree que podríamos pedirle después de que revisemos cambio (como extractos de cuentas bancarias, información laboral o comprobantes de sus recursos), puede hacer clic en el botón <strong>Cargar documentos</strong> abajo para enviarlos ahora.</p>"
  CONGRATS_STATIC_TEXT_REAL_TIME_sp8 = "Su cambio fue enviado y la información de su caso ha sido actualizada.<br><br><p>Si necesitamos más información, le enviaremos una carta que dice lo que necesitamos y la fecha en que la necesitamos. Si podemos tomar una decisión con la información que tenemos, recibirá una carta informándole nuestra decisión.</p><p>Si tiene documentos que cree que podríamos pedirle después de que revisemos cambio (como extractos de cuentas bancarias, información laboral o comprobantes de sus recursos), puede hacer clic en el botón <strong>Cargar documentos</strong> abajo para enviarlos ahora.</p>"
  CONGRATS_STATIC_TEXT_NOT_REAL_TIME_sp8 = "Estamos revisando su actualización ahora.  Puede requerir algunos días tomar una decisión para ver si sigue calificando.<br><br><p>Si necesitamos más información, le enviaremos una carta que dice lo que necesitamos y la fecha en que la necesitamos. Si podemos tomar una decisión con la información que tenemos, recibirá una carta informándole nuestra decisión.</p><p>Si tiene documentos que cree que podríamos pedirle después de que revisemos cambio (como extractos de cuentas bancarias, información laboral o comprobantes de sus recursos), puede hacer clic en el botón <strong>Cargar documentos</strong> abajo para enviarlos ahora.</p>"
  CONGRATS_STATIC_TEXT_QFR1_sp8 = "Estamos revisando su envío ahora.  Podríamos tardar varios días en tomar una decisión.";
  CONGRATS_STATIC_TEXT_QFR2_sp8 = "Si necesita tratamiento para el cáncer de mama o de cuello uterino, primero debe hacerse una evaluación en el departamento de salud local. Comuníquese con el departamento de salud local para ayuda.";
  CONGRATS_STATIC_TEXT_QFR3_sp8 = "Si necesitamos más información de usted, recibirá una carta que dice lo que necesitamos y cuándo necesitamos la información.  Si podemos tomar una decisión con la información que tenemos, le enviaremos una carta que le dice cuál fue nuestra decisión.<br><br>Si tiene documentos que cree que podríamos pedirle después de que revisemos su envío (como extractos de cuentas bancarias, información laboral o comprobantes de sus recursos), puede hacer clic en el botón Cargar documentos abajo para enviarlos ahora.";
  CONGRATS_STATIC_TEXT_QFR4_sp29 = "Se guardará una copia de sus Preguntas para la Revisión en su cuenta de TennCare Connect para que no tenga que imprimirlas ni guardarlas en su computadora.  Las puede ver en cualquier momento entrando a su cuenta.  Pero, si quiere imprimirlas o guardarlas para sus expedientes, recuerde que se incluye mucha de su información privada.";
  CONGRATS_STATIC_TEXTsp9 = "Hemos terminado de procesar su solicitud. Para ver nuestra decisión, haga clic en <strong>Siguiente</strong> al pie de la página."
  CONGRATS_STATIC_TEXTsp10 = "Su derivación para Katie Beckett";
  CONGRATS_STATIC_TEXTsp11 = "Recibimos su remisión para Katie Beckett.";
  CONGRATS_STATIC_TEXTsp11_1 = "Recibimos su referido para Katie Beckett  ";
  CONGRATS_STATIC_TEXTsp12 = "El número de seguimiento de Katie Beckett para";
  CONGRATS_STATIC_TEXTsp13 = "es";
  CONGRATS_STATIC_TEXTsp14 = "Guárdelo en un lugar seguro. Lo puede utilizar si tiene preguntas sobre el estado de su derivación.";
  CONGRATS_STATIC_TEXTsp14_1 = "Guárdelo en un lugar seguro. Lo puede utilizar si tiene preguntas sobre el estado de su referido.";
  CONGRATS_STATIC_TEXTsp15 = "Revisaremos su información para ver si";
  CONGRATS_STATIC_TEXTsp15_1 = "Primero revisaremos su información para ver si";
  CONGRATS_STATIC_TEXTsp16 = "califica para Medicaid. Si necesitamos más información, le enviaremos una carta que dice lo que necesitamos y la fecha en que la necesitamos. Si podemos tomar una decisión con la información que tenemos, recibirá una carta informándole nuestra decisión. Y enviaremos su remisión al Departamento de Discapacidades Intelectuales y del Desarrollo (DIDD). Ellos se comunicarán pronto con la persona de contacto que mencionó en la remisión para programar una entrevista y completar la evaluación del programa Katie Beckett. Pueden comunicarse antes de que terminemos de revisar su información.";
  CONGRATS_STATIC_TEXTsp16_1 = "califica para Medicaid. Y enviaremos su referido al Departamento de Discapacidades Intelectuales y del Desarrollo (DIDD). Pronto se comunicarán con la persona de contacto que usted indicó en el referido a para programar una entrevista y completar la evaluación del programa Katie Beckett. Es posible que se comuniquen con usted antes de que terminemos de revisar su información.";
  CONGRATS_STATIC_TEXTsp17 = "¿Tiene documentos de apoyo para Katie Beckett (como expedientes médicos) que quiera agregar ahora? Haga clic en el botón <strong>Cargar documentos</strong> abajo.";
  CONGRATS_STATIC_TEXTsp17_1 = "¿Tiene documentos de apoyo para Katie Beckett (como expedientes médicos) que quiera agregar ahora? Toque <b><a href='https://tenncareconnect.tn.gov/' target='_blank'>aquí</a></b> para iniciar sesión en TennCare Connect y cargar sus documentos."
  CONGRATS_STATIC_TEXTsp18 = "Para preguntas sobre esta remisión, llame a la oficina regional del DIDD en su área:";
  CONGRATS_STATIC_TEXTsp18_1 = "Para preguntas sobre este referido, llame a la oficina regional del DIDD en su área:";
  CONGRATS_STATIC_TEXTsp19 = "Oeste";
  CONGRATS_STATIC_TEXTsp20 = "Media";
  CONGRATS_STATIC_TEXTsp20_1 = "Centro";
  CONGRATS_STATIC_TEXTsp21 = "Este";
  CONGRATS_STATIC_TEXTsp22 = "Acerca de su autorreferencia para empleos y Community First Choices";
  CONGRATS_STATIC_TEXTsp23 = "El programa Employment and Community First CHOICES es para personas de todas las edades que tienen una discapacidad intelectual o del desarrollo (I / DD), incluidas las personas que tienen discapacidades significativas. Los servicios del programa pueden ayudarlo a aprender habilidades para trabajar y vivir de la manera más independiente posible en la comunidad.";
  CONGRATS_STATIC_TEXTsp24 = "Para ver si puede recibir servicios a través de este programa, debe";
  CONGRATS_STATIC_TEXTsp25 = "complete el formulario de autorreferencia";
  CONGRATS_STATIC_TEXTsp26 = "* Haciendo clic en este El enlace lo alejará de TennCare Connect y se cerrará la sesión.";
  CONGRATS_STATIC_TEXTsp27 = "¿Tiene preguntas sobre una remisión? Llame gratis a la oficina regional de DIDD en su área:";
  CONGRATS_STATIC_TEXTsp28 = "Imprima su solicitud";
  CONGRATS_STATIC_TEXT_QFR8_sp28 = "Imprimir sus";
  CONGRATS_STATIC_TEXTsp29 = "Se guardará una copia de sus actualizaciones en su cuenta de TennCare Connect para que no tenga que  imprimirlos o guardarlos en su computadora. Puede revisar en cualquier momento su información iniciando sesión en su cuenta. <strong>Pero si quiere imprimirla o guardar una copia para sus expedientes, recuerde que incluye mucha de su información privada.</strong>";
  CONGRATS_STATIC_TEXTsp30 = "¿Está usando una computadora en una biblioteca, centro comunitario u otro lugar público? Si es así, haga estas 3 cosas antes de irse:";
  CONGRATS_STATIC_TEXTsp31 = "No se olvide de recoger su copia impresa de la impresora.";
  CONGRATS_STATIC_TEXTsp32 = "Si la impresora se atasca o no imprime, pídale ayuda a uno de los empleados.";
  CONGRATS_STATIC_TEXTsp33 = "Después de que haya terminado, asegúrese de cerrar la sesión y cerrar TennCare Connect.";
  CONGRATS_STATIC_TEXTsp34 = "Para imprimir, haga clic en el botón <strong>Imprimir</strong> a continuación.";
  CONGRATS_STATIC_TEXTsp35 = "Imprimir";
  CONGRATS_STATIC_TEXTsp36 = "Si tiene problemas para imprimir su"
  CONGRATS_STATIC_TEXT_QFR9_sp36 = "Si tiene problemas para imprimir sus"
  CONGRATS_STATIC_TEXTsp37 = "preguntas frecuentes.";
  CONGRATS_STATIC_TEXTsp38 = "Subir documentos";
  CONGRATS_STATIC_TEXTsp39 = "Siguiente"
  CONGRATS_STATIC_TEXTsp40 = "Imprimir su cambio";
  CONGRATS_STATIC_TEXTsp41 = "";
  CONGRATS_STATIC_TEXTsp42 = ", consulte nuestra página de ";
  CONGRATS_STATIC_TEXT42;
  CONGRATS_CHAGE_MODE;
  CONGRATS_CHAGE_MODE_en = 'Your update was submitted to TennCare on ';
  CONGRATS_CHAGE_MODE_sp = 'Su actualización se envió a TennCare el ';
  CONGRATS_APPEAL_STATIC_TEXT;
  CONGRATS_APPEAL_STATIC_TEXTen = "Your appeal was submitted Successfully!";
  CONGRATS_APPEAL_STATIC_TEXTsp = "Su apelación fue enviada correctamente";
  CONGRATS_APPEAL_STATIC_TEXT2;
  CONGRATS_APPEAL_STATIC_TEXT2en = "Your appeal tracking number is ";
  CONGRATS_APPEAL_STATIC_TEXT2sp = "El número de seguimiento de su apelación es ";
  CONGRATS_APPEAL_STATIC_TEXT3;
  CONGRATS_APPEAL_STATIC_TEXT3en = "This number is important and might help you later so be sure to save it in a safe place.";
  CONGRATS_APPEAL_STATIC_TEXT3sp = "Este número es importante y podría ayudarle más adelante, así que asegúrese de guardarlos en un lugar seguro.";
  CONGRATS_APPEAL_STATIC_TEXT4;
  CONGRATS_APPEAL_STATIC_TEXT4en = "The appeal was submitted to TennCare on ";
  CONGRATS_APPEAL_STATIC_TEXT4sp = "La apelación fue presentada a TennCare el ";
  CONGRATS_APPEAL_STATIC_TEXT5;
  CONGRATS_APPEAL_STATIC_TEXT5en = "What Happens Next";
  CONGRATS_APPEAL_STATIC_TEXT5sp = "¿Qué ocurrirá después?";
  CONGRATS_APPEAL_STATIC_TEXT6;
  CONGRATS_APPEAL_STATIC_TEXT6en = "<b>We will review</b> your appeal.";
  CONGRATS_APPEAL_STATIC_TEXT6sp = "<b>Revisaremos</b> su apelación.";
  CONGRATS_APPEAL_STATIC_TEXT7;
  CONGRATS_APPEAL_STATIC_TEXT7en = "<b>We’ll check</b> first to see if your appeal was filed timely. If it’s timely, we’ll take a look at your case.  What if we decide you’re right?  Then we’ll fix the problem.  We’ll send you a letter that tells you how we fixed the problem.";
  CONGRATS_APPEAL_STATIC_TEXT7sp = "<b>Primero verificamos</b> si su apelación se presentó a tiempo. Si se presentó a tiempo, evaluaremos su caso.  ¿Y si decidimos que usted tiene la razón?  Entonces, arreglaremos el problema.  Le enviaremos una carta que le indicará cómo solucionamos el problema.";
  CONGRATS_APPEAL_STATIC_TEXT8;
  CONGRATS_APPEAL_STATIC_TEXT8en = "<b>What if your problem is something we cannot fix?</b>";
  CONGRATS_APPEAL_STATIC_TEXT8sp = "<b>¿Y si su problema es algo que no podemos solucionar?</b>";
  CONGRATS_APPEAL_STATIC_TEXT9;
  CONGRATS_APPEAL_STATIC_TEXT9en = "<b>We may ask</b> you for more information. If we do, we’ll send you a letter telling you what information is needed.  Then we’ll see if you can have a fair hearing.  If you can have a fair hearing, you’ll get a letter that says when your hearing will be.  If you can’t have a fair hearing, we’ll tell you why.";
  CONGRATS_APPEAL_STATIC_TEXT9sp = "<b>Podríamos pedirle</b> más información. Si lo hacemos, le enviaremos una carta indicándole la información que necesitamos.  Entonces veremos si le corresponde una audiencia imparcial.  Si le corresponde una audiencia imparcial, recibirá una carta que dice la fecha en que se celebrará la audiencia.  Si no le corresponde una audiencia imparcial, le diremos por qué.";
  // CONGRATS_APPEAL_STATIC_TEXT10;
  // CONGRATS_APPEAL_STATIC_TEXT10en = "we will send you a letter and ask you to give us the proof. You will have 10 days to send us proof that you applied. After we get the proof from you, we’ll finish working your application. What if we don’t get proof that you applied within 10 days? You’ll get another letter that says your request for a delay hearing is denied.";
  // CONGRATS_APPEAL_STATIC_TEXT10sp = "we will send you a letter and ask you to give us the proof. You will have 10 days to send us proof that you applied. After we get the proof from you, we’ll finish working your application. What if we don’t get proof that you applied within 10 days? You’ll get another letter that says your request for a delay hearing is denied.";
  // CONGRATS_APPEAL_STATIC_TEXT11;
  // CONGRATS_APPEAL_STATIC_TEXT11en = "When we have proof of your application date, we’ll give you a decision on your application. Or we will give you a hearing within 45 days (or 90 days for CHOICES applications). If we give you a hearing, we start counting on the day we find proof you applied or on the day that you send your proof to us (whichever day is earlier).";
  // CONGRATS_APPEAL_STATIC_TEXT11sp = "When we have proof of your application date, we’ll give you a decision on your application. Or we will give you a hearing within 45 days (or 90 days for CHOICES applications). If we give you a hearing, we start counting on the day we find proof you applied or on the day that you send your proof to us (whichever day is earlier).";
  CONGRATS_APPEAL_STATIC_TEXT12;
  CONGRATS_APPEAL_STATIC_TEXT12en = "Print Your Appeal";
  CONGRATS_APPEAL_STATIC_TEXT12sp = "Imprima Su Apelación";
  CONGRATS_APPEAL_STATIC_TEXT13;
  CONGRATS_APPEAL_STATIC_TEXT13en = "A copy of this appeal will be saved in your TennCare Connect account so you don't have to print it or save it on your computer.  You can review it anytime by logging in to your account.  But if you want to print it out or save it for your records, remember that it includes a lot of your private information.";
  CONGRATS_APPEAL_STATIC_TEXT13sp = "Se guardará una copia de esta apelación en su cuenta de TennCare Connect para que no tenga que imprimirla ni guardarla en su computadora.  La puede ver en cualquier momento entrando a su cuenta.  Pero, si quiere imprimirla o guardarla para sus expedientes, recuerde que incluye mucha de su información privada.";
  CONGRATS_APPEAL_STATIC_TEXT14;
  CONGRATS_APPEAL_STATIC_TEXT14en = "Are you using a computer in a library, community center, or other public place?  If so, do these 3 things before you go:";
  CONGRATS_APPEAL_STATIC_TEXT14sp = "¿Está usando una computadora en una biblioteca, centro comunitario u otro lugar público?  Si es así, haga estas 3 cosas antes de irse:";
  CONGRATS_APPEAL_STATIC_TEXT15;
  CONGRATS_APPEAL_STATIC_TEXT15en = "Remember to pick up your printed copy from the printer if you printed a copy.";
  CONGRATS_APPEAL_STATIC_TEXT15sp = "No se olvide de retirar su copia impresa de la impresora.";
  CONGRATS_APPEAL_STATIC_TEXT16;
  CONGRATS_APPEAL_STATIC_TEXT16en = "If the printer jams or fails to print, contact someone at your location to help you.";
  CONGRATS_APPEAL_STATIC_TEXT16sp = "Si la impresora se atasca o no imprime, pídale ayuda a uno de los empleados.";
  CONGRATS_APPEAL_STATIC_TEXT17;
  CONGRATS_APPEAL_STATIC_TEXT17en = "After you’re finished, be sure to log out and close TennCare Connect.";
  CONGRATS_APPEAL_STATIC_TEXT17sp = "Después de haya terminado, asegúrese de salirse de la sesión y cerrar TennCare Connect.";
  CONGRATS_APPEAL_STATIC_TEXT18;
  CONGRATS_APPEAL_STATIC_TEXT18en = "To print, click ";
  CONGRATS_APPEAL_STATIC_TEXT18sp = "Para ";
  CONGRATS_APPEAL_STATIC_TEXT19;
  CONGRATS_APPEAL_STATIC_TEXT19en = "Print ";
  CONGRATS_APPEAL_STATIC_TEXT19sp = "imprimir, ";
  CONGRATS_APPEAL_STATIC_TEXT20;
  CONGRATS_APPEAL_STATIC_TEXT20en = "button below.";
  CONGRATS_APPEAL_STATIC_TEXT20sp = "haga clic ene l botón Imprimir abajo.";
  CONGRATS_APPEAL_STATIC_TEXT21;
  CONGRATS_APPEAL_STATIC_TEXT21en = "PRINT";
  CONGRATS_APPEAL_STATIC_TEXT21sp = "Imprimir";
  CONGRATS_APPEAL_STATIC_TEXT22;
  CONGRATS_APPEAL_STATIC_TEXT22en = "If you're having trouble printing your application, check out our ";
  CONGRATS_APPEAL_STATIC_TEXT22sp = "Si tiene problemas para imprimir su solicitud, consulte nuestra ";
  CONGRATS_APPEAL_STATIC_TEXT23;
  CONGRATS_APPEAL_STATIC_TEXT23en = "FAQ page";
  CONGRATS_APPEAL_STATIC_TEXT23sp = "página de preguntas frecuentes";
  CONGRATS_APPEAL_STATIC_TEXT24;
  CONGRATS_APPEAL_STATIC_TEXT24en = "These numbers are important and might help you later so be sure to save them in a safe place."
  CONGRATS_APPEAL_STATIC_TEXT24sp = "Estos números son importantes y pueden ayudarlo más adelante, así que asegúrese de guardarlos en un lugar seguro."
  RMC_DOC_UPLOAD_HEADER;
  RMC_DOC_UPLOAD_HEADER_en = "Documents for My Change";
  RMC_DOC_UPLOAD_HEADER_sp = "Documentos para Mi Cambio";
  CONGRATS_APPEAL_STATIC_TEXT25;
  CONGRATS_APPEAL_STATIC_TEXT25en = "<b>For Questions about your appeal, contact the Appeals Call center.<b>";
  CONGRATS_APPEAL_STATIC_TEXT25sp = "<b>Para preguntas sobre su apelación, comuníquese con el Centro de Llamadas de Apelaciones.<b>";
  CONGRATS_APPEAL_STATIC_TEXT26;
  CONGRATS_APPEAL_STATIC_TEXT26en = "<br>Monday – Friday 8:00-4:30 CST<br> Phone: 844.202.5618 <br>FAX: 844.563.1728<br>Mailing Address: PO BOX 23650, Nashville, TN 37202<br>Email: appeals.clerk.tenncare@tn.gov";
  CONGRATS_APPEAL_STATIC_TEXT26sp = "<br>Lunes – Viernes 8:00-4:30 CST<br> Teléfono: 844.202.5618 <br>FAX: 844.563.1728<br>Dirección postal: PO BOX 23650, Nashville, TN 37202<br>Correo electrónico: appeals.clerk.tenncare@tn.gov";
  CONGRATS_APPEAL_STATIC_TEXT27;
  CONGRATS_APPEAL_STATIC_TEXT27en = "Appeals Call Center"
  CONGRATS_APPEAL_STATIC_TEXT27sp = "Centro de llamadas de apelaciones"
  Exit;
  Exiten = "Exit";
  Exitsp = "Salir"
  isKbUser = false;
  showTracking = false;
  defaultLanguage;
  translatedLang;
  Name;
  APPEAL_NUMBER;
  rmcUpload = false;
  rmcDocUploadSwitch:boolean;
  rmcDocUploadRows = [];
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));
  
  constructor(private titleService: Title, private apiService: ApiService, private state: StateService, private userService: UserProfileService, private router: Router,  private datePipe: DatePipe, public translateService: TranslateService, private serverConfig: ServiceConfigService) {
    this.state.getCongrats().subscribe(assign(this));
    this.titleService.setTitle('Confirmation')
  }

  ngOnInit() {
    this.appSubmittedDate = this.appSubmittedDate? this.appSubmittedDate : new Date();
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.isNotLinkedAcc = (this.indvId !== null && this.indvId !== undefined) ? true : false;
    sessionStorage.removeItem('ssiSw');
    sessionStorage.removeItem('fosterCareSw');
    const userType = sessionStorage.getItem('userType');
    this.isKbUser = JSON.parse(sessionStorage.getItem('isKbUser'));
    this.isAFB = this.appType === 'AFB';
    this.isAppeals = this.appType === 'APL';
    if(this.appType === 'APL' && !this.isKepro) {
      sessionStorage.removeItem('appType')
    }
    this.isPartner = isPartner(userType);
    this.isFullMedicAidApp = and(this.isPartner, this.isAFB);
    this.isKepro=JSON.parse(sessionStorage.getItem('isKepro'));

    this.isPresumptive = this.appType === 'HPE' || this.appType === 'PRG' || this.appType === 'BCC';
    this.isAdmitDischarge = this.appType === 'ADF';
    this.isNewborn = this.appType === 'NBN';
    this.showTracking = this.hideFullCoverage === 'NO';
    this.isBCCEXT = sessionStorage.getItem('appType') === 'BXT';
    this.isIME = sessionStorage.getItem('appType') === 'IME';

    if (this.indvId !== null) {
      sessionStorage.setItem('realtimeIndvId', this.indvId);
      sessionStorage.setItem('individualId', this.indvId);
      sessionStorage.setItem('hoh', this.headOfHousehold);
    }
    sessionStorage.setItem('submitSuccess', 'true');

    if (this.isPresumptive) {
      this.getConfirmation();
      this.results = this['presumptiveResults'];
      this.isApproved = (this.results.filter(x => x.status == 'Approved').length > 0);
      this.isDenied = (this.results.filter(x => x.status == 'Denied').length == this.results.length);
      if (this.results.length) {
        for (let i = 0; this.results.length > i; i ++) {
          if (this.results[i].status === 'Approved') {
            this.results[i].dob = this.datePipe.transform(this.results[i].dob, "MM/dd/yyyy");
            this.results[i].eligibleBeginDate = this.datePipe.transform( this.results[i].eligibleBeginDate, "MM/dd/yyyy");
            this.results[i].eligibleEndDate = this.datePipe.transform( this.results[i].eligibleEndDate, "MM/dd/yyyy");;
          }
          if (this.results[i].status === null) {
            this.results[i].dob = null;
            this.results[i].name = null;
            this.results[i].gender.value = null;
            this.results[i].status = null
            this.results[i].eligCategory = null;
            this.results[i].eligibleBeginDate = null;
            this.results[i].eligibleEndDate = null;
          }
          if (this.results[i].status === 'Denied') {
            this.results[i].dob = this.datePipe.transform(this.results[i].dob, "MM/dd/yyyy");
            this.results[i].eligCategory = null;
            this.results[i].eligibleBeginDate = null;
            this.results[i].eligibleEndDate = null;
          }
        }
      }
    }
    this.rmcDocUploadSwitch = this.serverConfig.getServerConfig().rmcDocUploadSwitch;
  }

  ngAfterContentInit() {
    const currentMode = sessionStorage.getItem('mode');
    this.mode = currentMode ? Modes[currentMode] : 'NEW';
    this.isChangeMode = isChangeReport(this.mode);
    this.isNewAddReapply = isNewAddReapplyMode(this.mode);
    this.isRedeModeOnly = isRedeMode(this.mode);
    this.isAddProgramOrRede = isAddProgramOrRede(this.mode);
    this.rmbRmc = isRmbRmc(this.mode);
    this.appKeyWord = this.isChangeMode ? 'Change' : this.isRedeModeOnly && !this.isPreterm ? 'Renewal' : this.isRedeModeOnly && this.isPreterm ? 'Questions for Review' : 'Application';
    this.appKeyWord_1 = this.isChangeMode ? 'change' : this.isRedeModeOnly && !this.isPreterm ? 'renewal' : this.isRedeModeOnly && this.isPreterm ? 'Questions for Review' : 'application';
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    if (this.defaultLanguage !== 'en') {
      this.getSpanishLabels()
    } else {
      this.getEnglishLabels();
    }

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
        if (res.lang === 'en') {
          this.getEnglishLabels();
        } else {
          this.getSpanishLabels();
        }
    });
  }

  getEnglishLabels() {
    this.LINK_YOUR_CASE_FOR_LETTERS = this.LINK_YOUR_CASE_FOR_LETTERSen
    this.LINK_YOUR_CASE_HEADER = this.LINK_YOUR_CASE_HEADERen
    this.COMM_PREF_FINISHMODULE_BANNER_TEXT1 = this.COMM_PREF_FINISHMODULE_BANNERen1_Text1
    this.MOBILE_APP_STATIC1 = this.MOBILE_APP_STATIC1en
    this.MOBILE_APP_STATIC2 = this.MOBILE_APP_STATIC2en
    this.MOBILE_APP_STATIC3 = this.MOBILE_APP_STATIC3en
    this.MOBILE_APP_STATIC4 = this.MOBILE_APP_STATIC4en
    this.COMM_PREF_FINISHMODULE_BANNER_TEXT2 = this.COMM_PREF_FINISHMODULE_BANNERen1_Text2
    this.CLICK = this.CLICKen
    this.HERE = this.HEREen
    this.CHANGE_HOW_LETTERS = this.CHANGE_HOW_LETTERSen1
    this.CHANGE_LETTERS_STATIC_TEXT1 = this.CHANGE_LETTERS_STATIC_TEXTen1
    this.CHANGE_LETTERS_STATIC_TEXT2 = this.CHANGE_LETTERS_STATIC_TEXTen2
    this.CONGRATS_STATIC_TEXT = this.CONGRATS_STATIC_TEXTen
    this.CONGRATS_STATIC_TEXT_QFR5 = this.CONGRATS_STATIC_TEXTen
    this.CONGRATS_STATIC_TEXT_QFR6 = this.CONGRATS_STATIC_TEXTen1
    this.CONGRATS_STATIC_TEXT_QFR7 = this.CONGRATS_STATIC_TEXTen2
    this.CONGRATS_STATIC_TEXT0 = this.CONGRATS_STATIC_TEXTen0
    this.CONGRATS_STATIC_TEXT1 = this.CONGRATS_STATIC_TEXTen1
    this.CONGRATS_STATIC_TEXT2 = this.CONGRATS_STATIC_TEXTen2
    this.CONGRATS_STATIC_TEXT3 = this.CONGRATS_STATIC_TEXTen3
    this.CONGRATS_STATIC_TEXT4 = this.CONGRATS_STATIC_TEXTen4
    this.CONGRATS_STATIC_TEXT4_QFR = this.CONGRATS_STATIC_TEXT4_QFRen
    this.CONGRATS_STATIC_TEXT4_1 = this.CONGRATS_STATIC_TEXTen4_1
    this.CONGRATS_STATIC_TEXT5 = this.CONGRATS_STATIC_TEXTen5
    this.CONGRATS_STATIC_TEXT6 = this.CONGRATS_STATIC_TEXTen6
    this.CONGRATS_STATIC_TEXT6_app = this.CONGRATS_STATIC_TEXTen6_app
    this.CONGRATS_STATIC_TEXT7 = this.CONGRATS_STATIC_TEXTen7
    this.CONGRATS_STATIC_TEXT8 = this.CONGRATS_STATIC_TEXTen8
    this.CONGRATS_STATIC_TEXT_REAL_TIME8 = this.CONGRATS_STATIC_TEXT_REAL_TIMEen8;
    this.CONGRATS_STATIC_TEXT_NOT_REAL_TIME8 = this.CONGRATS_STATIC_TEXT_NOT_REAL_TIMEen8;
    this.CONGRATS_STATIC_TEXT_QFR1 = this.CONGRATS_STATIC_TEXT_QFR1_en8
    this.CONGRATS_STATIC_TEXT_QFR2 = this.CONGRATS_STATIC_TEXT_QFR2_en8
    this.CONGRATS_STATIC_TEXT_QFR3 = this.CONGRATS_STATIC_TEXT_QFR3_en8
    this.CONGRATS_STATIC_TEXT_QFR4 = this.CONGRATS_STATIC_TEXT_QFR4_en29
    this.CONGRATS_STATIC_TEXT9 = this.CONGRATS_STATIC_TEXTen9
    this.CONGRATS_STATIC_TEXT10 = this.CONGRATS_STATIC_TEXTen10
    this.CONGRATS_STATIC_TEXT11 = this.CONGRATS_STATIC_TEXTen11
    this.CONGRATS_STATIC_TEXT11_1 = this.CONGRATS_STATIC_TEXTen11_1
    this.CONGRATS_STATIC_TEXT12 = this.CONGRATS_STATIC_TEXTen12
    this.CONGRATS_STATIC_TEXT13 = this.CONGRATS_STATIC_TEXTen13
    this.CONGRATS_STATIC_TEXT14 = this.CONGRATS_STATIC_TEXTen14
    this.CONGRATS_STATIC_TEXT14_1 = this.CONGRATS_STATIC_TEXTen14_1
    this.CONGRATS_STATIC_TEXT15 = this.CONGRATS_STATIC_TEXTen15
    this.CONGRATS_STATIC_TEXT15_1 = this.CONGRATS_STATIC_TEXTen15_1
    this.CONGRATS_STATIC_TEXT16 = this.CONGRATS_STATIC_TEXTen16
    this.CONGRATS_STATIC_TEXT16_1 = this.CONGRATS_STATIC_TEXTen16_1
    this.CONGRATS_STATIC_TEXT17 = this.CONGRATS_STATIC_TEXTen17
    this.CONGRATS_STATIC_TEXT17_1 = this.CONGRATS_STATIC_TEXTen17_1
    this.CONGRATS_STATIC_TEXT18 = this.CONGRATS_STATIC_TEXTen18
    this.CONGRATS_STATIC_TEXT18_1 = this.CONGRATS_STATIC_TEXTen18_1
    this.CONGRATS_STATIC_TEXT19 = this.CONGRATS_STATIC_TEXTen19
    this.CONGRATS_STATIC_TEXT20 = this.CONGRATS_STATIC_TEXTen20
    this.CONGRATS_STATIC_TEXT20_1 = this.CONGRATS_STATIC_TEXTen20_1
    this.CONGRATS_STATIC_TEXT21 = this.CONGRATS_STATIC_TEXTen21
    this.CONGRATS_STATIC_TEXT22 = this.CONGRATS_STATIC_TEXTen22
    this.CONGRATS_STATIC_TEXT23 = this.CONGRATS_STATIC_TEXTen23
    this.CONGRATS_STATIC_TEXT24 = this.CONGRATS_STATIC_TEXTen24
    this.CONGRATS_STATIC_TEXT25 = this.CONGRATS_STATIC_TEXTen25
    this.CONGRATS_STATIC_TEXT26 = this.CONGRATS_STATIC_TEXTen26
    this.CONGRATS_STATIC_TEXT27 = this.CONGRATS_STATIC_TEXTen27
    this.CONGRATS_STATIC_TEXT28 = this.CONGRATS_STATIC_TEXTen28
    this.CONGRATS_STATIC_TEXT_QFR8 = this.CONGRATS_STATIC_TEXTen28
    this.CONGRATS_STATIC_TEXT29 = this.CONGRATS_STATIC_TEXTen29
    this.CONGRATS_STATIC_TEXT30 = this.CONGRATS_STATIC_TEXTen30
    this.CONGRATS_STATIC_TEXT31 = this.CONGRATS_STATIC_TEXTen31
    this.CONGRATS_STATIC_TEXT32 = this.CONGRATS_STATIC_TEXTen32
    this.CONGRATS_STATIC_TEXT33 = this.CONGRATS_STATIC_TEXTen33
    this.CONGRATS_STATIC_TEXT34 = this.CONGRATS_STATIC_TEXTen34
    this.CONGRATS_STATIC_TEXT35 = this.CONGRATS_STATIC_TEXTen35
    this.CONGRATS_STATIC_TEXT36 = this.CONGRATS_STATIC_TEXTen36
    this.CONGRATS_STATIC_TEXT_QFR9 = this.CONGRATS_STATIC_TEXTen36
    this.CONGRATS_STATIC_TEXT37 = this.CONGRATS_STATIC_TEXTen37
    this.CONGRATS_STATIC_TEXT38 = this.CONGRATS_STATIC_TEXTen38
    this.CONGRATS_STATIC_TEXT39 = this.CONGRATS_STATIC_TEXTen39
    this.CONGRATS_STATIC_TEXT40 = this.CONGRATS_STATIC_TEXTen40
    this.CONGRATS_STATIC_TEXT41 = this.CONGRATS_STATIC_TEXTen41
    this.CONGRATS_CHAGE_MODE = this.CONGRATS_CHAGE_MODE_en
    if (this.appKeyWord === 'solicitud') {
      this.appKeyWord = 'Application'
     } else if(this.appKeyWord === 'cambio') {
       this.appKeyWord = 'Change'
     } else if(this.appKeyWord === 'renovación') {
       this.appKeyWord = 'Renewal'
     } else if (this.appKeyWord_1 === 'solicitud') {
      this.appKeyWord_1 = 'application'
     } else if(this.appKeyWord_1 === 'cambio') {
       this.appKeyWord_1 = 'change'
     } else if(this.appKeyWord_1 === 'renovación') {
       this.appKeyWord_1 = 'renewal'
    } else if (this.appKeyWord === 'Preguntas para la Revisión') {
       this.appKeyWord = 'Questions for Review'
     }
     this.CONGRATS_APPEAL_STATIC_TEXT = this.CONGRATS_APPEAL_STATIC_TEXTen;
    this.CONGRATS_APPEAL_STATIC_TEXT2 = this.CONGRATS_APPEAL_STATIC_TEXT2en;
    this.CONGRATS_APPEAL_STATIC_TEXT3 = this.CONGRATS_APPEAL_STATIC_TEXT3en;
    this.CONGRATS_APPEAL_STATIC_TEXT4 = this.CONGRATS_APPEAL_STATIC_TEXT4en;
    this.CONGRATS_APPEAL_STATIC_TEXT5 = this.CONGRATS_APPEAL_STATIC_TEXT5en;
    this.CONGRATS_APPEAL_STATIC_TEXT6 = this.CONGRATS_APPEAL_STATIC_TEXT6en;
    this.CONGRATS_APPEAL_STATIC_TEXT7 = this.CONGRATS_APPEAL_STATIC_TEXT7en;
    this.CONGRATS_APPEAL_STATIC_TEXT8 = this.CONGRATS_APPEAL_STATIC_TEXT8en;
    this.CONGRATS_APPEAL_STATIC_TEXT9 = this.CONGRATS_APPEAL_STATIC_TEXT9en;
    // this.CONGRATS_APPEAL_STATIC_TEXT10 = this.CONGRATS_APPEAL_STATIC_TEXT10en;
    // this.CONGRATS_APPEAL_STATIC_TEXT11 = this.CONGRATS_APPEAL_STATIC_TEXT11en;
    this.CONGRATS_APPEAL_STATIC_TEXT12 = this.CONGRATS_APPEAL_STATIC_TEXT12en;
    this.CONGRATS_APPEAL_STATIC_TEXT13 = this.CONGRATS_APPEAL_STATIC_TEXT13en;
    this.CONGRATS_APPEAL_STATIC_TEXT14 = this.CONGRATS_APPEAL_STATIC_TEXT14en;
    this.CONGRATS_APPEAL_STATIC_TEXT15 = this.CONGRATS_APPEAL_STATIC_TEXT15en;
    this.CONGRATS_APPEAL_STATIC_TEXT16 = this.CONGRATS_APPEAL_STATIC_TEXT16en;
    this.CONGRATS_APPEAL_STATIC_TEXT17 = this.CONGRATS_APPEAL_STATIC_TEXT17en;
    this.CONGRATS_APPEAL_STATIC_TEXT18 = this.CONGRATS_APPEAL_STATIC_TEXT18en;
    this.CONGRATS_APPEAL_STATIC_TEXT19 = this.CONGRATS_APPEAL_STATIC_TEXT19en;
    this.CONGRATS_APPEAL_STATIC_TEXT20 = this.CONGRATS_APPEAL_STATIC_TEXT20en;
    this.CONGRATS_APPEAL_STATIC_TEXT21 = this.CONGRATS_APPEAL_STATIC_TEXT21en;
    this.CONGRATS_APPEAL_STATIC_TEXT22 = this.CONGRATS_APPEAL_STATIC_TEXT22en;
    this.CONGRATS_APPEAL_STATIC_TEXT23 = this.CONGRATS_APPEAL_STATIC_TEXT23en;
    this.CONGRATS_APPEAL_STATIC_TEXT24 = this.CONGRATS_APPEAL_STATIC_TEXT24en;
    this.RMC_DOC_UPLOAD_HEADER = this.RMC_DOC_UPLOAD_HEADER_en;
    this.CONGRATS_APPEAL_STATIC_TEXT25 = this.CONGRATS_APPEAL_STATIC_TEXT25en;
    this.CONGRATS_APPEAL_STATIC_TEXT26 = this.CONGRATS_APPEAL_STATIC_TEXT26en;
    this.CONGRATS_APPEAL_STATIC_TEXT27 = this.CONGRATS_APPEAL_STATIC_TEXT27en

    this.Exit = this.Exiten;
    this.Name = "Name";
    this.APPEAL_NUMBER = "Appeal Number"
  }

  getSpanishLabels() {
        this.LINK_YOUR_CASE_FOR_LETTERS = this.LINK_YOUR_CASE_FOR_LETTERSsp
        this.LINK_YOUR_CASE_HEADER = this.LINK_YOUR_CASE_HEADERsp
        this.COMM_PREF_FINISHMODULE_BANNER_TEXT1 = this.COMM_PREF_FINISHMODULE_BANNEResp1_Text1
        this.MOBILE_APP_STATIC1 = this.MOBILE_APP_STATIC1sp
        this.MOBILE_APP_STATIC2 = this.MOBILE_APP_STATIC2sp
        this.MOBILE_APP_STATIC3 = this.MOBILE_APP_STATIC3sp
        this.MOBILE_APP_STATIC4 = this.MOBILE_APP_STATIC4sp
        this.COMM_PREF_FINISHMODULE_BANNER_TEXT2 = this.COMM_PREF_FINISHMODULE_BANNERsp1_Text2
        this.CLICK = this.CLICKsp
        this.HERE = this.HEREsp
        this.CHANGE_HOW_LETTERS = this.CHANGE_HOW_LETTERSsp1
        this.CHANGE_LETTERS_STATIC_TEXT1 = this.CHANGE_LETTERS_STATIC_TEXTsp1
        this.CHANGE_LETTERS_STATIC_TEXT2 = this.CHANGE_LETTERS_STATIC_TEXTsp2
        this.CONGRATS_STATIC_TEXT = this.CONGRATS_STATIC_TEXTsp
        this.CONGRATS_STATIC_TEXT_QFR5 = this.CONGRATS_STATIC_TEXT_QFR5_sp
        this.CONGRATS_STATIC_TEXT_QFR6 = this.CONGRATS_STATIC_TEXT_QFR6_sp1
        this.CONGRATS_STATIC_TEXT_QFR7 = this.CONGRATS_STATIC_TEXT_QFR7_sp2
        this.CONGRATS_STATIC_TEXT0 = this.CONGRATS_STATIC_TEXTsp0
        this.CONGRATS_STATIC_TEXT1 = this.CONGRATS_STATIC_TEXTsp1
        this.CONGRATS_STATIC_TEXT2 = this.CONGRATS_STATIC_TEXTsp2
        this.CONGRATS_STATIC_TEXT3 = this.CONGRATS_STATIC_TEXTsp3
        this.CONGRATS_STATIC_TEXT4 = this.CONGRATS_STATIC_TEXTsp4
        this.CONGRATS_STATIC_TEXT4_QFR = this.CONGRATS_STATIC_TEXT4_QFRsp
        this.CONGRATS_STATIC_TEXT4_1 = this.CONGRATS_STATIC_TEXTsp4_1
        this.CONGRATS_STATIC_TEXT5 = this.CONGRATS_STATIC_TEXTsp5
        this.CONGRATS_STATIC_TEXT6 = this.CONGRATS_STATIC_TEXTsp6
        this.CONGRATS_STATIC_TEXT6_app = this.CONGRATS_STATIC_TEXTsp6_app
        this.CONGRATS_STATIC_TEXT7 = this.CONGRATS_STATIC_TEXTsp7
        this.CONGRATS_STATIC_TEXT8 = this.CONGRATS_STATIC_TEXTsp8
        this.CONGRATS_STATIC_TEXT_REAL_TIME8 = this.CONGRATS_STATIC_TEXT_REAL_TIME_sp8
        this.CONGRATS_STATIC_TEXT_NOT_REAL_TIME8 = this.CONGRATS_STATIC_TEXT_NOT_REAL_TIME_sp8
        this.CONGRATS_STATIC_TEXT_QFR1 = this.CONGRATS_STATIC_TEXT_QFR1_sp8
        this.CONGRATS_STATIC_TEXT_QFR2 = this.CONGRATS_STATIC_TEXT_QFR2_sp8
        this.CONGRATS_STATIC_TEXT_QFR3 = this.CONGRATS_STATIC_TEXT_QFR3_sp8
        this.CONGRATS_STATIC_TEXT_QFR4 = this.CONGRATS_STATIC_TEXT_QFR4_sp29
        this.CONGRATS_STATIC_TEXT9 = this.CONGRATS_STATIC_TEXTsp9
        this.CONGRATS_STATIC_TEXT10 = this.CONGRATS_STATIC_TEXTsp10
        this.CONGRATS_STATIC_TEXT11 = this.CONGRATS_STATIC_TEXTsp11
        this.CONGRATS_STATIC_TEXT11_1 = this.CONGRATS_STATIC_TEXTsp11_1
        this.CONGRATS_STATIC_TEXT12 = this.CONGRATS_STATIC_TEXTsp12
        this.CONGRATS_STATIC_TEXT13 = this.CONGRATS_STATIC_TEXTsp13
        this.CONGRATS_STATIC_TEXT14 = this.CONGRATS_STATIC_TEXTsp14
        this.CONGRATS_STATIC_TEXT14_1 = this.CONGRATS_STATIC_TEXTsp14_1
        this.CONGRATS_STATIC_TEXT15 = this.CONGRATS_STATIC_TEXTsp15
        this.CONGRATS_STATIC_TEXT15_1 = this.CONGRATS_STATIC_TEXTsp15_1
        this.CONGRATS_STATIC_TEXT16 = this.CONGRATS_STATIC_TEXTsp16
        this.CONGRATS_STATIC_TEXT16_1 = this.CONGRATS_STATIC_TEXTsp16_1
        this.CONGRATS_STATIC_TEXT17 = this.CONGRATS_STATIC_TEXTsp17
        this.CONGRATS_STATIC_TEXT17_1 = this.CONGRATS_STATIC_TEXTsp17_1
        this.CONGRATS_STATIC_TEXT18 = this.CONGRATS_STATIC_TEXTsp18
        this.CONGRATS_STATIC_TEXT18_1 = this.CONGRATS_STATIC_TEXTsp18_1
        this.CONGRATS_STATIC_TEXT19 = this.CONGRATS_STATIC_TEXTsp19
        this.CONGRATS_STATIC_TEXT20 = this.CONGRATS_STATIC_TEXTsp20
        this.CONGRATS_STATIC_TEXT20_1 = this.CONGRATS_STATIC_TEXTsp20_1
        this.CONGRATS_STATIC_TEXT21 = this.CONGRATS_STATIC_TEXTsp21
        this.CONGRATS_STATIC_TEXT22 = this.CONGRATS_STATIC_TEXTsp22
        this.CONGRATS_STATIC_TEXT23 = this.CONGRATS_STATIC_TEXTsp23
        this.CONGRATS_STATIC_TEXT24 = this.CONGRATS_STATIC_TEXTsp24
        this.CONGRATS_STATIC_TEXT25 = this.CONGRATS_STATIC_TEXTsp25
        this.CONGRATS_STATIC_TEXT26 = this.CONGRATS_STATIC_TEXTsp26
        this.CONGRATS_STATIC_TEXT27 = this.CONGRATS_STATIC_TEXTsp27
        this.CONGRATS_STATIC_TEXT28 = this.CONGRATS_STATIC_TEXTsp28
        this.CONGRATS_STATIC_TEXT_QFR8 = this.CONGRATS_STATIC_TEXT_QFR8_sp28
        this.CONGRATS_STATIC_TEXT29 = this.CONGRATS_STATIC_TEXTsp29
        this.CONGRATS_STATIC_TEXT30 = this.CONGRATS_STATIC_TEXTsp30
        this.CONGRATS_STATIC_TEXT31 = this.CONGRATS_STATIC_TEXTsp31
        this.CONGRATS_STATIC_TEXT32 = this.CONGRATS_STATIC_TEXTsp32
        this.CONGRATS_STATIC_TEXT33 = this.CONGRATS_STATIC_TEXTsp33
        this.CONGRATS_STATIC_TEXT34 = this.CONGRATS_STATIC_TEXTsp34
        this.CONGRATS_STATIC_TEXT35 = this.CONGRATS_STATIC_TEXTsp35
        this.CONGRATS_STATIC_TEXT36 = this.CONGRATS_STATIC_TEXTsp36
        this.CONGRATS_STATIC_TEXT_QFR9 = this.CONGRATS_STATIC_TEXT_QFR9_sp36;
        this.CONGRATS_STATIC_TEXT37 = this.CONGRATS_STATIC_TEXTsp37
        this.CONGRATS_STATIC_TEXT38 = this.CONGRATS_STATIC_TEXTsp38
        this.CONGRATS_STATIC_TEXT39 = this.CONGRATS_STATIC_TEXTsp39
        this.CONGRATS_STATIC_TEXT40 = this.CONGRATS_STATIC_TEXTsp40
        this.CONGRATS_STATIC_TEXT41 = this.CONGRATS_STATIC_TEXTsp41
        this.CONGRATS_STATIC_TEXT42 = this.CONGRATS_STATIC_TEXTsp42
        this.CONGRATS_CHAGE_MODE = this.CONGRATS_CHAGE_MODE_sp
        if (this.appKeyWord === 'Application') {
          this.appKeyWord = 'solicitud'
         } else if (this.appKeyWord === 'Change') {
           this.appKeyWord = 'cambio'
         } else if (this.appKeyWord === 'Renewal') {
           this.appKeyWord = 'renovación'
         } else if (this.appKeyWord_1 === 'application') {
          this.appKeyWord_1 = 'solicitud'
         } else if (this.appKeyWord_1 === 'change') {
           this.appKeyWord_1 = 'cambio'
         } else if (this.appKeyWord_1 === 'renewal') {
           this.appKeyWord_1 = 'renovación'
         } else if (this.appKeyWord === 'Questions for Review') {
          this.appKeyWord = 'Preguntas para la Revisión'
        }
    this.CONGRATS_APPEAL_STATIC_TEXT = this.CONGRATS_APPEAL_STATIC_TEXTsp;
    this.CONGRATS_APPEAL_STATIC_TEXT2 = this.CONGRATS_APPEAL_STATIC_TEXT2sp;
    this.CONGRATS_APPEAL_STATIC_TEXT3 = this.CONGRATS_APPEAL_STATIC_TEXT3sp;
    this.CONGRATS_APPEAL_STATIC_TEXT4 = this.CONGRATS_APPEAL_STATIC_TEXT4sp;
    this.CONGRATS_APPEAL_STATIC_TEXT5 = this.CONGRATS_APPEAL_STATIC_TEXT5sp;
    this.CONGRATS_APPEAL_STATIC_TEXT6 = this.CONGRATS_APPEAL_STATIC_TEXT6sp;
    this.CONGRATS_APPEAL_STATIC_TEXT7 = this.CONGRATS_APPEAL_STATIC_TEXT7sp;
    this.CONGRATS_APPEAL_STATIC_TEXT8 = this.CONGRATS_APPEAL_STATIC_TEXT8sp;
    this.CONGRATS_APPEAL_STATIC_TEXT9 = this.CONGRATS_APPEAL_STATIC_TEXT9sp;
    // this.CONGRATS_APPEAL_STATIC_TEXT10 = this.CONGRATS_APPEAL_STATIC_TEXT10sp;
    // this.CONGRATS_APPEAL_STATIC_TEXT11 = this.CONGRATS_APPEAL_STATIC_TEXT11sp;
    this.CONGRATS_APPEAL_STATIC_TEXT12 = this.CONGRATS_APPEAL_STATIC_TEXT12sp;
    this.CONGRATS_APPEAL_STATIC_TEXT13 = this.CONGRATS_APPEAL_STATIC_TEXT13sp;
    this.CONGRATS_APPEAL_STATIC_TEXT14 = this.CONGRATS_APPEAL_STATIC_TEXT14sp;
    this.CONGRATS_APPEAL_STATIC_TEXT15 = this.CONGRATS_APPEAL_STATIC_TEXT15sp;
    this.CONGRATS_APPEAL_STATIC_TEXT16 = this.CONGRATS_APPEAL_STATIC_TEXT16sp;
    this.CONGRATS_APPEAL_STATIC_TEXT17 = this.CONGRATS_APPEAL_STATIC_TEXT17sp;
    this.CONGRATS_APPEAL_STATIC_TEXT18 = this.CONGRATS_APPEAL_STATIC_TEXT18sp;
    this.CONGRATS_APPEAL_STATIC_TEXT19 = this.CONGRATS_APPEAL_STATIC_TEXT19sp;
    this.CONGRATS_APPEAL_STATIC_TEXT20 = this.CONGRATS_APPEAL_STATIC_TEXT20sp;
    this.CONGRATS_APPEAL_STATIC_TEXT21 = this.CONGRATS_APPEAL_STATIC_TEXT21sp;
    this.CONGRATS_APPEAL_STATIC_TEXT22 = this.CONGRATS_APPEAL_STATIC_TEXT22sp;
    this.CONGRATS_APPEAL_STATIC_TEXT23 = this.CONGRATS_APPEAL_STATIC_TEXT23sp;
    this.CONGRATS_APPEAL_STATIC_TEXT24 = this.CONGRATS_APPEAL_STATIC_TEXT24sp;
    this.RMC_DOC_UPLOAD_HEADER = this.RMC_DOC_UPLOAD_HEADER_sp;
    this.CONGRATS_APPEAL_STATIC_TEXT25 = this.CONGRATS_APPEAL_STATIC_TEXT25sp;
    this.CONGRATS_APPEAL_STATIC_TEXT26 = this.CONGRATS_APPEAL_STATIC_TEXT26sp;
    this.CONGRATS_APPEAL_STATIC_TEXT27 = this.CONGRATS_APPEAL_STATIC_TEXT27sp

    this.Exit = this.Exitsp;
    this.Name = "Nombre";
    this.APPEAL_NUMBER = "Número de apelación"
  }

  printPdf() {
    const a = document.createElement('a');
    this.userService.getPrintPdf(this.applicationNumber, 'TCCON').subscribe((res: any) => {
      const file = new Blob([res], { type: 'application/pdf' });
      let newVariable: any;
      newVariable = window.navigator;
      if (newVariable && newVariable.msSaveOrOpenBlob) {
        newVariable.msSaveOrOpenBlob(file);
      } else {
          const fileURL = URL.createObjectURL(file);
          const tab = window.open();
          tab.location.href = fileURL;
          a.href = fileURL;
          a.download = this.isPartner ? 'SummaryOfCaseInformation_TennCareAccess.pdf' : 'SummaryOfCaseInformation_MemberPortal.pdf';
          a.click();
      }
    })
  }

  printAppealPdf() {
    const a = document.createElement('a');
    this.userService.getAppealPrintPdf(this.applicationNumber, 'TCCON', this.translatedLang, '', 'appealsPrint').subscribe((res: any) => {
      const file = new Blob([res], { type: 'application/pdf' });
      let newVariable: any;
      newVariable = window.navigator;
      if (newVariable && newVariable.msSaveOrOpenBlob) {
        newVariable.msSaveOrOpenBlob(file);
      } else {
          const fileURL = URL.createObjectURL(file);
          const tab = window.open();
          tab.location.href = fileURL;
          a.href = fileURL;
          a.download = this.isPartner ? 'SummaryOfCaseInformation_TennCareAccess.pdf' : 'SummaryOfCaseInformation_MemberPortal.pdf';
          a.click();
      }
    })
  }

  goToDocuments() {
    if(this.isChangeMode && this.rmcDocUploadSwitch) {
      const caseNum = sessionStorage.getItem('caseNum')
      const url = `indi/rmcdocupload/case/${caseNum}/appnum/${this.applicationNumber}`
      this.apiService.get(url, null, {headers: new HttpHeaders({'pageId': 'TCCON'})}).subscribe(response => {
      if(response && response['docsForMyChange'] && response['docsForMyChange'].length) {
        this.rmcDocUploadRows = response['docsForMyChange'];
        this.rmcUpload = true;
      } else {
        sessionStorage.uploadDoc = true;
        this.router.navigate(['/user-profile', 'my-documents'])
      }       
    })
    } else {
      sessionStorage.uploadDoc = true;
      this.router.navigate(['/user-profile', 'my-documents'])
    }
  }

  getConfirmation() {
    const individualId = sessionStorage.getItem('individualId');

    if (individualId && individualId !== 'null' && individualId !== '0') {
      const confirmatonSub = this.apiService.get(`indi/users/confirmation?indvId=${individualId}`, null, {headers: new HttpHeaders({'pageId': 'TCCON'})}).subscribe();
      this.subscriptions.push(confirmatonSub);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    sessionStorage.removeItem('isPreterm');
  }

  onNextClick() {
    this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
  }

  navigateNext() {
    const fromRmcDocUpload = JSON.parse(sessionStorage.getItem('inRMCUploadDoc'));
    if(fromRmcDocUpload){
      sessionStorage.removeItem('inRMCUploadDoc')
    }
    if(this.isKepro){
      this.router.navigateByUrl('api/prov/submission-search');
    }
    else if (this.isFullMedicAidApp) {
     this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
    } else {
      if (sessionStorage.getItem("currentUser")) {
      const validUser = JSON.parse(sessionStorage.getItem("currentUser"));
      validUser["firstTimeAccessSw"] = "N";
      sessionStorage.setItem("currentUser", JSON.stringify(validUser));
      }
      this.router.navigateByUrl("user-profile/benefit-dashboard");
    }
  }

  moveToCommPref() {
    this.router.navigateByUrl("user-profile/communication-preferences");
  }
  
  moveToLinkMyCase() {
    this.router.navigateByUrl("user-profile/case-link");
  }

  onSearchClick() {
    this.router.navigateByUrl('api/prov/submission-search')
  }

  backToMemberSearch() {
    this.router.navigateByUrl('api/prov/member-search?type=LOC')
  }

  backToMyAppeals() {
    this.router.navigateByUrl("user-profile/my-appeals");
  }
}