import { HostListener, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isNilOrEmpty } from 'ramda-adjunct';
import { isEmpty, equals } from 'ramda';
import { merge } from 'ramda'; 

import { emptyTables } from '@app/helpers/models.helpers';
import { prefixWith } from '@app/helpers/services.helpers';
import { createParams } from '@app/helpers/http.helpers';

import { ApplicationNumber, Mode, PartialOptions, CaseNumber } from '@app/models/app.model';
import { SectionId, TopLevelAnswer } from '@app/models/section.model';
import { Record, Identifier } from '@app/models/record.model';
import { TableCodes } from '@app/models/tables.model';
import { VerificationAnswer } from '@app/models/partials.model';

import { AppSectionResponse, AppResponse } from '@app/models/http.model';
import { TablesResponse, ActionResponse, NoContentResponse } from '@app/models/http.model';
import { SectionResponse, SectionTablesResponse, RecordResponse } from '@app/models/http.model';
import { StorageService } from '@app/services/storage/storage.service';
import { getDataValue } from '@app/helpers/object.helpers';
import { ServiceConfigService } from '../service-config.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from "@angular/common";

@Injectable()
export class ApplicationService {
  constructor(private http: HttpClient, protected storageService: StorageService, private serverConfig: ServiceConfigService, private injector: Injector, private datePipe: DatePipe,) {}
  apiContext = `easy-app/api`;
  appType;
  screenWidth: number;
  isMobileView: boolean;
  mobileWidth = 480;
  viewType;
  currentLang;
  topLevelAnswer;
  bccFirstName$ = new BehaviorSubject<string>("");
  bccLastName$ = new BehaviorSubject<string>("");
  bccDob$ = new BehaviorSubject<string>("");
  bccSsn$ = new BehaviorSubject<string>("");
  bccGender$ = new BehaviorSubject<string>("");
  bccCaseNum$ = new BehaviorSubject<string>("");
  bccCoeCode$ = new BehaviorSubject<string>("");
  renewalSw$ = new BehaviorSubject<string>("");
  imeCaseNumber$ = new BehaviorSubject<string>("");
  genderCd$ = new BehaviorSubject<string>("");
  ssnNumber$ = new BehaviorSubject<string>("");
  lastName$ = new BehaviorSubject<string>("");
  firstName$ = new BehaviorSubject<string>("");
  dateOfBirth$ = new BehaviorSubject<string>("");
  imeCoeCode$ = new BehaviorSubject<string>("");
  caseNumber$ = new BehaviorSubject<string>("");
  coeCode$ = new BehaviorSubject<string>("");

  gatewayURL() {
    return this.serverConfig.getServerConfig()['gatewayURL'];
  }

  getDocumentsWeNeed (indivNumber) {
    return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/prov/checkmybenefits/DocsWeNeed/indv/${indivNumber}`, {headers: new HttpHeaders({'pageId': 'TCMDT'})})
  }

  getHeadOfHouseholdInfo (caseNumber, mode) {
    const isReassessment = sessionStorage.getItem('isReassessment');
    const isPreterm = sessionStorage.getItem('isPreterm');
    if (isReassessment) {
      return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/headofhousehold?caseNumber=${caseNumber}&appModeCode=${mode}&financialReassessment=${'Y'}`, {headers: new HttpHeaders({'pageId': 'TCRMB'})});
    } else if (isPreterm) {
      return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/headofhousehold?caseNumber=${caseNumber}&appModeCode=${mode}&redeterminationType=${"PRT"}`, {headers: new HttpHeaders({'pageId': 'TCPRT'})});
     } else {
      const pageID = mode === "NEW" ? 'TCAFC' : mode === "APL" ? 'TCAPL' : mode === "REDETERMINATION" ? 'TCRMB' : mode === "CHANGE_REPORT" ? 'TCRMC' : '';
      return this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/headofhousehold?caseNumber=${caseNumber}&appModeCode=${mode}`, {headers: new HttpHeaders({'pageId': pageID })});
    }
  }

  getPendingApplications (caseNumber, mode, sectionId, payload?) {
    const isReassessment = sessionStorage.getItem('isReassessment');
    const isPreterm = sessionStorage.getItem('isPreterm');
    const isAhsAFB = sessionStorage.getItem('AfbAHSSearch');
    if (isReassessment) {
      const postObj = {"caseNumber":caseNumber, "applicationType":mode}
      return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/inprogress`, postObj,{headers: new HttpHeaders({'pageId': sectionId})});
    } else if (isPreterm) {
      const postObj = {"caseNumber":caseNumber, "applicationType":"RENEW_BENEFITS", "redeterminationType":"PRT"}
      return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/inprogress`, postObj,{headers: new HttpHeaders({'pageId': sectionId})});
     } else if(isAhsAFB) {
      const postObj = payload;
      return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/inprogress`, postObj,{headers: new HttpHeaders({'pageId': sectionId})});
     } else {
      const postObj = {"caseNumber":caseNumber, "applicationType":mode}
      return this.http.post(`${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications/inprogress`, postObj,{headers: new HttpHeaders({'pageId': sectionId})});
    }
  }

  appPrefix(applicationNumber: ApplicationNumber): string {
    const appNum = (applicationNumber === this.storageService.getAppNumber()) ? applicationNumber : this.storageService.getAppNumber();
    return `${this.prefix()}/${appNum}`;
  }

  prefix(): string {
    return `${this.serverConfig.getServerConfig()['gatewayURL']}${this.apiContext}/indi/applications`;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  summaryParams(): string {
    this.getScreenSize();
    this.isMobileView = this.screenWidth <= this.mobileWidth;
    this.viewType = this.isMobileView ? "mobile" : "web";
    const translateService= this.injector.get(TranslateService);
    this.currentLang = translateService.getDefaultLang() == "sp" ? "ES" : "EN";
    return `lang${prefixWith('=', this.currentLang)}&device${prefixWith('=', this.viewType)}`;
  }

  sectionPrefix(applicationNumber: ApplicationNumber, sectionId: SectionId): string {
    const isKepro = sessionStorage.getItem('isKepro');
    const isAHSlanding = sessionStorage.getItem('isAHSlanding');
    const caseNum = sessionStorage.getItem('caseNum');
    if (isKepro && isAHSlanding && sectionId === 'APREQ') {
      const indivId = sessionStorage.getItem('encryptedIndvId');
      return `${this.appPrefix(applicationNumber)}/sections/${sectionId}/${indivId}/${caseNum}/isCCUser/Y`;
    } else {
      return `${this.appPrefix(applicationNumber)}/sections/${sectionId}`;
    }
  }

  sectionPeopleSumPrefix(applicationNumber: ApplicationNumber, sectionId: SectionId): string {
    return `${this.appPrefix(applicationNumber)}/sections/${sectionId}/records`;
  }

  removeRecordsPrefix(applicationNumber: ApplicationNumber, identifier): string {
    if (identifier.sectionId === 'HHTJF') {
      return `${this.appPrefix(applicationNumber)}/sections/${identifier.sectionId}/records/${identifier.individualId}-taxfilingExport`;
    }
    if (identifier.sectionId === 'ARPDT') {
      return `${this.appPrefix(applicationNumber)}/sections/${identifier.sectionId}/records`;
    }
    return `${this.appPrefix(applicationNumber)}/sections/${identifier.sectionId}/exported/records/${identifier.individualId}${prefixWith('-', identifier.recordId)}`;
  }

  removeImeRecordPrefix(applicationNumber: ApplicationNumber, identifier, record) {
    return `${this.appPrefix(applicationNumber)}/sections/${identifier.sectionId}/records/${record.indvId}-${record.expenseSeqNum}`
  }

  loadApp(applicationNumber: ApplicationNumber): AppSectionResponse {
    return forkJoin(this.getApp(applicationNumber), this.getIndividuals(applicationNumber)) as AppSectionResponse;
  }

  startApp(mode: Mode, caseNumber: CaseNumber): AppResponse { 
    const appType = this.storageService.getApplicationType();
    const renewalSw = sessionStorage.getItem('renewalSw') === 'null' ? null : sessionStorage.getItem('renewalSw');
    const isReassessment = sessionStorage.getItem('isReassessment');
    const isPreterm = sessionStorage.getItem('isPreterm');
    const mtlToken = sessionStorage.getItem('mtlToken');
    const isAplReadOnly = sessionStorage.getItem('appealReadOnly') === 'true';
    this.appType = 
    equals(appType, 'RC' ) ? 'REPORT_CHANGES' :
    equals(appType, 'RB' ) ? 'RENEW_BENEFITS' :
    equals(appType, 'HPE' ) ? 'PRESUMPTIVE_HOSPITAL' : 
    equals(appType, 'PRG' ) ? 'PRESUMPTIVE_PREGNANCY' : 
    equals(appType, 'BCC' ) ? 'PRESUMPTIVE_BCC' :
    equals(appType, 'ADF' ) ? 'ADMIT_DISCHARGE' : 
    equals(appType, 'NBN' ) ? 'NEW_BORN' : 
    equals(appType, 'AFB' ) ? 'APPLY_BENEFITS' :
    equals(appType, 'APL' ) ? 'FILE_APPEAL' : 
    equals(appType, 'BXT' ) ? 'BCC_TREATMENT_EXTENSION' : 
    equals(appType, 'IME' ) ? 'INCURRED_MEDICAL_EXPENSES' : ''; 

    const pageId = 
    equals(appType, 'RC' ) ? 'SURCH' : 
    equals(appType, 'RB' ) ? 'SACHS' : 
    equals(appType, 'HPE' ) ? 'PRHAS' : 
    equals(appType, 'PRG' ) ? 'PRHAS' : 
    equals(appType, 'BCC' ) ? 'PRHAS' :
    equals(appType, 'ADF' ) ? 'ADPIN' : 
    equals(appType, 'NBN' ) ? 'NBINF' : 
    equals(appType, 'AFB' ) ? 'SACHS' :
    equals(appType, 'APL' ) ? 'APREQ' : 
    equals(appType, 'BXT' ) ? 'BCEXS' : 
    equals(appType, 'IME' ) ? 'IMEST' : '';
    const contentHeaders = new HttpHeaders({'pageId':pageId})
    const caseNum = caseNumber ? caseNumber : sessionStorage.getItem('caseNumber');
    const individualId = sessionStorage.getItem('individualId')
    if(appType === 'BXT') {
      return new Observable(subscriber => {
        this.http.post(`${this.prefix()}`, {
          mode,
          applicationType: this.appType,
          mtlToken: mtlToken,
          individualDetails : {
            individualNumber : individualId,
            genderCd : this.bccGender$.value,
            ssnNumber : this.bccSsn$.value,
            lastName: this.bccLastName$.value,
            firstName: this.bccFirstName$.value,
            dateOfBirth : this.datePipe.transform(this.bccDob$.value, "yyyy-MM-dd"),
            caseNumber: this.bccCaseNum$.value,
            coeCode : this.bccCoeCode$.value
          }

        } ,{headers: contentHeaders}).subscribe(rtn => {
          const appNum = getDataValue('applicationNumber', rtn);
          if (appNum && this.storageService.getAppNumber() !== appNum) {
            sessionStorage.setItem('appNum', appNum);
            this.storageService.setAppNumber(appNum);
          }
          // @ts-ignore
          subscriber.next(rtn);
        });
      });
    } else if (appType === 'IME') {
      return new Observable(subscriber => {
        this.http.post(`${this.prefix()}`, {
          mode,
          applicationType: this.appType,
          mtlToken: mtlToken,
          renewalSw : this.renewalSw$.value,
          individualDetails : {
            individualNumber : individualId,
            genderCd : this.genderCd$.value,
            ssnNumber : this.ssnNumber$.value,
            lastName: this.lastName$.value,
            firstName: this.firstName$.value,
            dateOfBirth : this.datePipe.transform(this.dateOfBirth$.value, "yyyy-MM-dd"),
            caseNumber: this.imeCaseNumber$.value,
            coeCode : this.imeCoeCode$.value
          }

        },{headers: contentHeaders}).subscribe(rtn => {
          const appNum = getDataValue('applicationNumber', rtn);
          if (appNum && this.storageService.getAppNumber() !== appNum) {
            sessionStorage.setItem('appNum', appNum);
            this.storageService.setAppNumber(appNum);
          }
          // @ts-ignore
          subscriber.next(rtn);
        });
      });
    } else if (this.appType === 'NEW_BORN' || this.appType === 'ADMIT_DISCHARGE' || isReassessment) {
      return new Observable(subscriber => {
        this.http.post(`${this.prefix()}`, { mode, caseNumber: caseNum, applicationType: this.appType, 
          individualDetails : {
            individualNumber : individualId,
            genderCd : this.genderCd$.value,
            ssnNumber : this.ssnNumber$.value,
            lastName: this.lastName$.value,
            firstName: this.firstName$.value,
            dateOfBirth : this.datePipe.transform(this.dateOfBirth$.value, "yyyy-MM-dd"),
            caseNumber: this.caseNumber$.value,
            coeCode : this.coeCode$.value
          },
          financialReassessment : isReassessment ? 'Y' : null, renewalSw :  renewalSw, mtlToken: mtlToken}, {headers: contentHeaders}).subscribe(rtn => {
          const appNum = getDataValue('applicationNumber', rtn);
          if (appNum && this.storageService.getAppNumber() !== appNum) {
            sessionStorage.setItem('appNum', appNum);
            this.storageService.setAppNumber(appNum);
          } 
          // @ts-ignore
          subscriber.next(rtn);
        });
      });
      
      
     } else {
      return new Observable(subscriber => {
        this.http.post(`${this.prefix()}`, { mode, caseNumber: caseNum, applicationType: this.appType, financialReassessment : isReassessment ? 'Y' : null, renewalSw : this.appType === 'NEW_BORN' ||  this.appType === 'ADMIT_DISCHARGE' ? renewalSw : null, mtlToken: mtlToken, appealReadOnly : isAplReadOnly ? 'Y' : null, redeterminationType : isPreterm ? "PRT" : null}, {headers: contentHeaders}).subscribe(rtn => {
          const appNum = getDataValue('applicationNumber', rtn);
          if (appNum && this.storageService.getAppNumber() !== appNum) {
            sessionStorage.setItem('appNum', appNum);
            this.storageService.setAppNumber(appNum);
          } 
          if (isPreterm && sessionStorage.getItem('eligibleIndivs')) {
            let eligibleIndivs = JSON.parse(sessionStorage.getItem('eligibleIndivs'));
            const postObj = {"eligibleIndividualDetails": eligibleIndivs?.eligibleIndividualDetails}
            this.http.post(`${this.gatewayURL()}easy-app/api/applications/preterm/${appNum}/${caseNum}`, postObj).subscribe(() => {
              sessionStorage.removeItem('eligibleIndivs')
            })
          }
          // @ts-ignore
          subscriber.next(rtn);
        });
      });
    }
  }
 
  deleteApp(applicationNumber: ApplicationNumber, headersPageId) {
    const headers = {
      headers: new HttpHeaders({
        'pageId': headersPageId,
        'action': 'delete'
      }),
    };
    return this.http.post(`${this.prefix()}/` + applicationNumber, {}, headers);
  }

  deleteFinancialApp(postObj) {
    const headers = new HttpHeaders({
      action: 'delete',
      'pageId': "TCFWB"
    })
    return this.http.post(`${this.gatewayURL()}rest/api/indi/financialRA/FinancialApp`, postObj, { headers });
  }

  deleteImeDocument(postObj) {
    const headers = new HttpHeaders({
      action: 'delete',
      'pageId': postObj.sectionID
    })
    
    return this.http.post(`${this.gatewayURL()}easy-app/api/indi/applications/${postObj.appNum}/sections/${postObj.sectionID}/document/${postObj.docId}`, null, { headers });
  }

  determineAhsContinueUserApp(appNum, payLoad, sectionId) {
    const headers = new HttpHeaders({
      'pageId': sectionId
    })
    return this.http.post(`${this.gatewayURL()}easy-app/api/indi/applications/applicationContinuance/${appNum}`, payLoad, { headers })
  }

  getApp(applicationNumber: ApplicationNumber): AppResponse {
    return this.http.get(this.appPrefix(applicationNumber), {headers: new HttpHeaders({'pageId': 'TCLGN'})}) as AppResponse;
  }

  updateApp(applicationNumber: ApplicationNumber, options: PartialOptions): AppResponse {
    const pageId = options?.incompleteSection !== null ? options?.incompleteSection : "" ;
    return this.http.put(this.appPrefix(applicationNumber),options, { headers: new HttpHeaders({'pageId': pageId})}) as AppResponse;
  }

  getIndividuals(applicationNumber: ApplicationNumber): SectionResponse {
    return this.getSection(applicationNumber, 'HHIND') as SectionResponse;
  }

  loadSection(applicationNumber: ApplicationNumber, sectionId: SectionId, tables: TableCodes): SectionTablesResponse {
    return forkJoin(this.getSection(applicationNumber, sectionId), this.loadTables(tables));
  }

  updateSection(applicationNumber: ApplicationNumber, sectionId: SectionId, answer: TopLevelAnswer): SectionResponse {
    const options = {
      headers: new HttpHeaders({
        'pageId': sectionId
      }),
    };
    return this.http.put(this.sectionPrefix(applicationNumber, sectionId), { answer }, options) as SectionResponse;
  }

  updatePeopleSumSection(
    applicationNumber: ApplicationNumber,
    sectionId: SectionId,
    answer: Array<any>
  ): SectionResponse {
    const headers = new HttpHeaders({
      action: 'delete',
      'pageId': sectionId
    })
    return this.http.post(this.sectionPeopleSumPrefix(applicationNumber, sectionId), answer, {
      headers,
    }) as SectionResponse;
  }

  getSection(applicationNumber: ApplicationNumber, sectionId: SectionId): SectionResponse {
    const isKepro = sessionStorage.getItem('isKepro');
    if(isKepro && sectionId === 'APREQ'){
      sessionStorage.setItem('isAHSlanding', 'true');
    }
    if (sectionId === 'SUPSM') {
      return this.http.get(`${this.appPrefix(applicationNumber)}/sections/${sectionId}?${this.summaryParams()}`, {headers: new HttpHeaders({'pageId': 'SUPSM'})}) as SectionResponse;
    } else if (sectionId === 'BCEXS') {
      const individualId = sessionStorage.getItem('individualId')
      return this.http.get(`${this.appPrefix(applicationNumber)}/sections/${sectionId}/${individualId}`, {headers: new HttpHeaders({'pageId': 'BCEXS'})}) as SectionResponse;
    } else {
      return this.http.get(this.sectionPrefix(applicationNumber, sectionId), {headers: new HttpHeaders({'pageId': sectionId})}) as SectionResponse;
    }    
  }

  loadTables(tables: TableCodes): TablesResponse {
    return isEmpty(tables) ? of(emptyTables) : (this.spanishTableNotRequired() ? this.getTablesNonTrans(tables) : this.getTables(tables));
  }

  getTables(tables: TableCodes): TablesResponse { 
    return this.http.get(`${this.gatewayURL()}${this.apiContext}/publ/reference-tables/complete`, createParams({ tables })) as TablesResponse;
  }

  getTablesNonTrans(tables: TableCodes): TablesResponse { 
    return this.http.get(`${this.gatewayURL()}${this.apiContext}/publ/reference-tables`, createParams({ tables })) as TablesResponse;
}

  membersearch(postObj, sectionId) {
    return this.http.post(`${this.gatewayURL()}rest/api/indi/accountorbenefitlookup/search`, postObj, {headers: new HttpHeaders({'pageId': sectionId})});
  }

  coverageSearch(postObj) {
    return this.http.post(`${this.gatewayURL()}${this.apiContext}/indi/applications/searchHeadofhousehold`, postObj);
  }

  confirmAppContinue(postObj, appNum, sectionId) {
    return this.http.post(`${this.gatewayURL()}${this.apiContext}/indi/applications/applicationContinuance/${appNum}`, postObj,{headers: new HttpHeaders({'pageId': sectionId})});
  }
 
  bccExtDuplicateApp(personID) {
    return this.http.get(`${this.gatewayURL()}${this.apiContext}/indi/applications/BCEXS/apps/${personID}`, {headers: new HttpHeaders({'pageId': 'BCEXS'})});
  }

  rmcPopUpUser(postObj) {
    return this.http.post(`${this.gatewayURL()}rest/api/indi/users/rmcPopUp`, postObj, {headers: new HttpHeaders({'pageId': 'TCCD'})});
  }

  financialReassessment(indivId) {
    return this.http.get(`${this.gatewayURL()}rest/api/indi/financialRA/appDetails/indv/${indivId}`, {headers: new HttpHeaders({'pageId': 'TCFRM'})});
  }

  getKbInfo(userName) {
    return this.http.get(`${this.gatewayURL()}rest/api/indi/users/callKbService?userId=${userName}`, {headers: new HttpHeaders({'pageId': 'TCCKB'})});
  }

  search(keyword, postObj) {
    return this.http.post(`${this.gatewayURL()}rest/api/indi/` + `${keyword}/search`, postObj,{headers: new HttpHeaders({'pageId': 'TCPSM'})});
  }

  admitDischargeNursingFacility(postObj) {
    return this.http.post(`${this.gatewayURL()}${this.apiContext}/indi/nursingfacilitysearch`, postObj,{headers: new HttpHeaders({'pageId': 'ADNFI'})});
  }

  identifiers({ individualId, recordId }: Identifier): string {
    return `records${prefixWith('/', individualId)}${prefixWith('-', recordId)}`;
  }

  actionsPath(applicationNumber: ApplicationNumber, identifier: Identifier): string {
    let gatepostSectionIds = ['ICEMI', 'ICSEM','ICOTH', 'HHHLC', 'XPUTL', 'XPDEP', 'XPSUP', 'ASLIQ', 'ASVEH', 'ASPRP', 'ASBUR', 'ASLIF', 'OTHRS', 'ASCLD', 'INMED']
    const isKepro = sessionStorage.getItem('isKepro');
    if (isKepro) {
      sessionStorage.removeItem('isAHSlanding');
    }
    if (identifier.sectionId === 'AIBAO' || (gatepostSectionIds.includes(identifier.sectionId) && this.topLevelAnswer ==='NO')) {
      return `${this.sectionPrefix(applicationNumber, identifier.sectionId)}`;
    }
    if (identifier.sectionId === 'ADNFI') {
      identifier.individualId = sessionStorage.getItem('individualId')
      return `${this.sectionPrefix(applicationNumber, identifier.sectionId)}/${this.identifiers(identifier)}`;
    }
    if (identifier.sectionId === 'NBINF') {
      identifier.individualId = sessionStorage.getItem('individualId')
      return `${this.sectionPrefix(applicationNumber, identifier.sectionId)}/${this.identifiers(identifier)}`;
    }
    if (identifier.sectionId === 'NBIMM') {
      identifier.individualId = sessionStorage.getItem('individualId')
      return `${this.sectionPrefix(applicationNumber, identifier.sectionId)}/${this.identifiers(identifier)}`;
    }
    return `${this.sectionPrefix(applicationNumber, identifier.sectionId)}/${this.identifiers(identifier)}`;
  }

  createRecord(applicationNumber: ApplicationNumber, record: Record, answer: TopLevelAnswer): ActionResponse {
    this.topLevelAnswer = answer;
    if (record?.identifier?.sectionId !== 'APSUM') {
    if (answer && answer !== 'NO') {
      return this.http
      .post(this.actionsPath(applicationNumber, record.identifier), record.data, {headers: new HttpHeaders({'pageId': record?.identifier?.sectionId})})
      .pipe(map(merge({}))) as ActionResponse;
    } else {
      answer = answer;
      return this.http
      .post(this.actionsPath(applicationNumber, record.identifier), {answer},{headers: new HttpHeaders({'pageId': record?.identifier?.sectionId})})
      .pipe(map(merge({}))) as ActionResponse;
    }
    } else {
      const appealNum = sessionStorage.getItem('appealNum');
      let currentLang = sessionStorage.getItem('currentLang');
      currentLang = currentLang === 'en'? "EN" : "SP"
      const data = { "aplNum": appealNum, "currentLang": currentLang }
      return this.http
        .post(this.actionsPath(applicationNumber, record.identifier), data,{headers: new HttpHeaders({'pageId': record?.identifier?.sectionId})} )
        .pipe(map(merge({}))) as ActionResponse;
    }
  }

  updateRecord(applicationNumber: ApplicationNumber, record: Record) {
    const data = record.data;
    data.languageCode = (sessionStorage.currentLang == 'sp')? 'ES' : 'EN';
    const headers = {
      headers: new HttpHeaders({
        'pageId': record.identifier.sectionId
      }),
    };
    return this.http.put(this.actionsPath(applicationNumber, record.identifier), data, headers).pipe(
      map(resp => {
        return merge({})(resp);
      })
    ) as ActionResponse;
  }

  loadRecord(applicationNumber: ApplicationNumber, identifier: Identifier): RecordResponse {
    return this.http.get(this.actionsPath(applicationNumber, identifier), {headers: new HttpHeaders({'pageId': identifier.sectionId})}) as RecordResponse;
  }

  removeRecord(applicationNumber: ApplicationNumber, identifier: Identifier): NoContentResponse {
    const options = {
      headers: new HttpHeaders({
        'pageId': identifier.sectionId
      }),
    };
    return this.http.delete(this.actionsPath(applicationNumber, identifier), options) as NoContentResponse;
  }
  
  removeRecordPresumptive(applicationNumber: ApplicationNumber, identifier: Identifier, data: any): NoContentResponse {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'pageId': identifier.sectionId
      }),
      body: data
    };
    return this.http.delete(this.actionsPath(applicationNumber, identifier), options) as NoContentResponse;
  }

  removeDataRecord(applicationNumber: ApplicationNumber, identifier: Identifier, record: Record, exportingStatus?: String): NoContentResponse {
    if (exportingStatus && exportingStatus === 'REMOVED') {
     
      const headers = new HttpHeaders({
        action: 'undo',
        'pageId': identifier.sectionId
      })
      return this.http.post(this.actionsPath(applicationNumber, identifier), record, {headers}) as NoContentResponse;
    }
    if(identifier.sectionId === 'IMEDT') {
      const headers = new HttpHeaders({
        action: 'delete',
        'pageId': identifier.sectionId
      })
    return this.http.post(this.removeImeRecordPrefix(applicationNumber, identifier, record), record, { headers }) as NoContentResponse;
    }
    const headers = new HttpHeaders({
      action: 'delete',
      'pageId': identifier.sectionId
    })
    return this.http.post(this.removeRecordsPrefix(applicationNumber, identifier), record, { headers }) as NoContentResponse;
  }

  loadComments(applicationNumber: ApplicationNumber) {
    return this.http.get(`${this.appPrefix(applicationNumber)}/comments`, {headers: new HttpHeaders({'pageId': 'SUCOM'})});
  }

  updateComments(applicationNumber: ApplicationNumber, comments: string) {
    return this.http.put(`${this.appPrefix(applicationNumber)}/comments`, { comments });
  }

  resourceSearch(applicationNumber: ApplicationNumber, criteria: any, resource: string): NoContentResponse {
    return this.http.get(`${this.appPrefix(applicationNumber)}/${resource}`, createParams(criteria));
  }

  proofPrefix(applicationNumber) {
    return `${this.appPrefix(applicationNumber)}/id-proofing-quests/quests-verification`;
  }

  saveQuestionnaire(applicationNumber: ApplicationNumber, verification: VerificationAnswer): ActionResponse {
    return this.http.post(this.proofPrefix(applicationNumber), verification) as ActionResponse;
  }

  loadCongrats(applicationNumber: ApplicationNumber) {
    return this.getSection(applicationNumber, 'SUCFM');
  }

  getCookie(name: string) {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }

    return null;
  }

  refreshToken() {
    const tcamKey = this.serverConfig.getServerConfig().TCAM_KEY;
    const tcam = isNilOrEmpty(this.getCookie(tcamKey)) ? sessionStorage.getItem('tcam') : this.getCookie(tcamKey);
    const postObj = { 'tcam': tcam };
    const contentHeader = new HttpHeaders({ "Content-Type": "application/json", "pageId": "TCRFT"});
    return this.http.post(`${this.gatewayURL()}${this.apiContext}/indi/token/refresh`, postObj, {headers: contentHeader, observe: 'response'});
  }
  
  getBankDetails(bankdetails: any, sectionId: string) {
    const contentHeaders = new HttpHeaders({'pageId' : sectionId});
    return this.http.post(`${this.gatewayURL()}rest/api/indi/avsbanksearch`, bankdetails, {headers: contentHeaders});
  } 

  spanishTableNotRequired(): boolean {
    const isMobileUser = JSON.parse(sessionStorage.getItem('isMobileUser'));
    if(!isMobileUser){
      return (sessionStorage.getItem('currentUser') === null);
    }else{
      return false;
    }
  }

  preTermData(indiv) {
    return this.http.get(`${this.gatewayURL()}easy-app/api/applications/preterm/indv/${indiv}`,{headers: new HttpHeaders({'pageId': 'TCPRT'})});
  }
}