import { Component } from '@angular/core';
import { BaseModalDirective } from '../base-modal';

@Component({
  selector: 'nh-health-plan-cancel-error',
  templateUrl: './health-plan-cancel-error.component.html'
})
export class HealthPlanCancelErrorComponent extends BaseModalDirective {

}
