import { Component, AfterContentInit, HostListener, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { findModule } from '@app/helpers/function.helpers';
import { getDataValue } from '@app/helpers/object.helpers';
import Vs from "@app/services/validators/validators.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceConfigService } from '@app/services/service-config.service';
import { TranslateService } from '@ngx-translate/core';
import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
@Component({
  templateUrl: './easyRmb.component.html',
  styleUrls: ['./easyRmb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EasyRMBStartComponent extends SectionFormDirective implements AfterContentInit {
  @ViewChild('owlCar', {static: false}) owlCar: CarouselComponent
  @ViewChild('carouselContainer', {static:false}) carouselContainer!: ElementRef;
  displayHohInfo = true;
  displayPeopleInYourHome = false;
  displayHouseHoldInfo = false;
  displayIncomeEmployment = false;
  displayOtherBenefits = false;
  displayExpenses = false;
  displayResources = false;
  displayTaxInformation = false;
  appType = sessionStorage.getItem('appType');
  isRenewBenefits = false;
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION' && sessionStorage.getItem('isKepro') === "true"));
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  modules;
  expandedPanels: { [key: string]: boolean } = {
    hoh: true,
    peopleInYourHome: true,
    householdInfo: true,
    taxInformation: true,
    incomeEmployment: true,
    otherBenefits: true,
    expenses: true,
    resources: true
  }
  container;
  contactInfoRows = [];
  apiContext = `easy-app/api`;
  initialSelection = new Array<string>();
  hohInfoRows = [];
  peopleInfoRows = [];
  relationshipRows = [];
  schoolAndEmploymentRows = [];
  otherHealthInsuranceRows = [];
  employmentIncomeRows = [];
  selfEmploymentIncomeRows = [];
  otherIncomeRows = [];
  defaultLanguage;
  response
  relationIndivLeverArray = [];
  otherBenefitsRows = [];
  utilityExpensesRows = [];
  dependentCareExpensesRows = [];
  mandatoryExpensesRows = [];
  medicialBillsExpensesRows = [];
  accesstoOtherCoverageRows = [];
  kbRows = [];
  financialRows= [];
  vehicleRows = [];
  propertyRows = [];
  burrialRows = [];
  lifeResourcesRows = [];
  otherResourceRows = [];
  transferredRows = [];
  taxRows = [];
  taxOutsideRows = [];
  displaySchoolAndEmployment = false
  customOptions: OwlOptions = {
    loop: false,
    margin:5,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    autoHeight: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    }
  }
  currentSlide = 0;
  totalSlides = 6;
  selectedOptions: {[key: string]:string} = {};
  missingSelections: string[] = [];
  previousIndex = 0;
  slideHeaders: { [key: string]: string } = {
    'hoh': 'HOH',
    'peopleInYourHome': 'People in Your Home',
    'householdInfo': 'RMB_STARTSCREEN_TEXT_8',
    'taxInformation': 'RMB_STARTSCREEN_TEXT_9',
    'incomeEmployment': 'RMB_STARTSCREEN_TEXT_10'
  }
  slides = [
    { module: 'hoh' },
    { module: 'peopleInYourHome' },
    { module: 'householdInfo' },
    { module: 'taxInformation' },
    { module: 'incomeEmployment' },
    { module: 'otherBenefits' }
  ]
  userSelections: {[key: string]: string} = {};
  currentSlideIndex = 0;
  showBanner = false;
  finalBanner = false;
  constructor(public builder: FormBuilder, private titleService: Title, private http: HttpClient, private serverConfig: ServiceConfigService, public translateService: TranslateService) {
    super(builder);
    this.titleService.setTitle('Renew My Benefits');
  };

  getTablesData(appNum){
    this.http.get(`${this.serverConfig.getServerConfig()['gatewayURL']}easy-app/api/indi/applications/${appNum}/snapshot`, { headers: new HttpHeaders({ 'pageId': 'RMBSL' }) }).subscribe(res => {
      this.response = res;
      const peopleRow = res['HHIND']?.data
      if(res['HHIND']) {
      peopleRow.forEach((element, index: any) => {
        this.peopleInfoRows[index] = {
          "member": element?.data?.name?.fullName + ", " + element?.data?.age + element?.data?.gender?.code,
          "ssn": element?.data?.ssn?.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3'),
          "citizenship": element?.data?.additionalData?.usCitizen?.code === "Y" ? "US Citizen" : "Non US Citizen",
          "tnResident": element?.data?.tennesseeResident?.code === "Y" ? "Tennessee Resident" : "Non Tennessee Resident",
          "livingArrangement": this.defaultLanguage === 'en' ? element?.data?.livingAreaType?.value : element?.data?.livingAreaType?.spValue,
          "additionalDetails": element?.data?.additionalData ? element?.data.additionalData?.pregnancyOne?.isPregnant ? "Pregnant" : element?.data.additionalData?.formerFosterCare?.code === "Y" ? "Foster Care Assistance" : "No" : "No",
          "dueDate": element?.data.additionalData?.pregnancyOne?.dueDate ? element?.data.additionalData?.pregnancyOne?.dueDate : null
        }

      })
    }
      const hohRow = res['HHAPL']?.data?.data
      const cell = hohRow?.physicalAddress?.contactPhones?.cell ? hohRow?.physicalAddress?.contactPhones?.cell : null
      const home = hohRow?.physicalAddress?.contactPhones?.home ? hohRow?.physicalAddress?.contactPhones?.home : null
      const extenssion = hohRow?.physicalAddress?.contactPhones?.extension ? hohRow?.physicalAddress?.contactPhones?.extension : null
      const message = hohRow?.physicalAddress?.contactPhones?.message ? hohRow?.physicalAddress?.contactPhones?.message : null
      this.hohInfoRows = [{
        "headOfHousehold": hohRow?.casehead?.fullName,
        "homeAddressLine1": hohRow?.physicalAddress?.address ? hohRow?.physicalAddress?.address?.addressLine1 : null,
        "homeAddressLine2": hohRow?.physicalAddress?.address ? hohRow?.physicalAddress?.address?.addressLine2 : null,
        "homeAddressCity": hohRow?.physicalAddress?.address?.city ?  hohRow?.physicalAddress?.address?.city : null,
        "homeAddressState": hohRow?.physicalAddress?.address?.state ?  this.defaultLanguage === 'en' ? hohRow?.physicalAddress?.address?.state?.value : hohRow?.physicalAddress?.address?.state?.spValue : null,
        "homeAddressZip": hohRow?.physicalAddress?.address?.zip ? this.getZip(hohRow?.physicalAddress?.address?.zip) : null,
        "mailingAddress": hohRow?.mailingAddress?.address ? hohRow?.mailingAddress?.address?.addressLine1 + "," + hohRow?.mailingAddress?.address?.city + "," + hohRow?.mailingAddress?.address?.state?.value + "-" + hohRow?.mailingAddress?.address?.zip + " SAH ID:" + hohRow?.sahMailId : "No",
        "mailingAddressLine1": hohRow?.mailingAddress?.address ? hohRow?.mailingAddress?.address?.addressLine1 : hohRow?.physicalAddress?.address?.addressLine1,
        "mailingAddressLine2": hohRow?.mailingAddress?.address ? hohRow?.mailingAddress?.address?.addressLine2 : hohRow?.physicalAddress?.address?.addressLine2,
        "mailingAddressCity": hohRow?.mailingAddress?.address?.city ?  hohRow?.mailingAddress?.address?.city : hohRow?.physicalAddress?.address?.city,
        "mailingAddressState": hohRow?.mailingAddress?.address?.state ?  this.defaultLanguage === 'en' ? hohRow?.mailingAddress?.address?.state?.value : hohRow?.mailingAddress?.address?.state?.spValue : hohRow?.physicalAddress?.address?.state ?  this.defaultLanguage === 'en' ? hohRow?.physicalAddress?.address?.state?.value : hohRow?.physicalAddress?.address?.state?.spValue : null,
        "mailingAddressZip": hohRow?.mailingAddress?.address?.zip ? this.getZip(hohRow?.mailingAddress?.address?.zip) : this.getZip(hohRow?.physicalAddress?.address?.zip),
        "cell": cell ? cell : null,
        "home": home ? home : null,
        "extenssion": extenssion ? extenssion : null,
        "message": message ? message : null,
        "assistingPerson": hohRow?.assistingPerson?.name?.fullName ? hohRow?.assistingPerson?.name?.fullName : "No",
        "assistingRelation": hohRow?.assistingPerson?.relationship ? this.defaultLanguage === 'en' ? hohRow?.assistingPerson?.relationship.value : hohRow?.assistingPerson.relationship.spValue : null,
        "assistingOrganization": hohRow?.assistingPerson?.organizationName ? hohRow?.assistingPerson.organizationName : null,
        "sahMailId": hohRow?.sahMailId ? hohRow?.sahMailId : null
      }]
    
      const relationRow = res['HHRLS']?.data?.relationshipList
      if(res['HHRLS']) {
      relationRow.forEach((element, index: any) => {
        const refName = peopleRow?.find((item) => item.identifier?.individualId === String(element.referenceIndividual))
        const sourceName = peopleRow?.find((item) => item.identifier?.individualId === String(element.sourceIndividual))
        this.relationshipRows[index] = {
          "relation": element?.relation?.value ? this.defaultLanguage === 'en' ? element?.relation?.value : element?.relation.spValue : null,
          "primaryCareGiver": element?.primaryCaregiver?.value ? this.defaultLanguage === 'en' ? element?.primaryCaregiver?.value : element?.primaryCaregiver.spValue : "No",
          "refIndivName": refName?.data?.name?.fullName,
          "sourceName": sourceName?.data?.name?.fullName,
          "sourceIndiv": element.sourceIndividual,
          "refIndiv": element.referenceIndividual
        }
      }) 
      peopleRow?.forEach((element, i) => {
        this.relationIndivLeverArray[i] = []
        this.relationshipRows.forEach(item => {
          if(element.identifier?.individualId === String(item.sourceIndiv)) {
            this.relationIndivLeverArray[i].push(item)
          }
        })
      })
      this.relationIndivLeverArray.forEach((element, i)=> {
        if(!element.length){
          this.relationIndivLeverArray.splice(i,1)
        }
      })
    }

      const schoolEmployment = res['SCAEM']?.data?.indvData
      if(res['SCAEM']) {
        let i = 0;
      schoolEmployment.forEach((element) => {
        const name = peopleRow?.find((item) => item.identifier?.individualId === String(element.individualNumber))
        if(peopleRow.length) {
            const individualsBelowTwentyThree =  peopleRow.filter((person) => person?.data?.age <= 22);
            this.displaySchoolAndEmployment = individualsBelowTwentyThree?.length ? true : false;
        }
        if (name?.data?.name?.fullName && element?.education?.currentEnrollmentStatus && element.isWorkingFullTime) {
          this.schoolAndEmploymentRows[i] = {
            "name": name?.data?.name?.fullName,
            "education": element?.education?.currentEnrollmentStatus ? this.defaultLanguage === 'en' ? element?.education?.currentEnrollmentStatus?.value : element?.education?.currentEnrollmentStatus?.spValue : "N/A",
            "isWorkingFullTime": element.isWorkingFullTime ? this.defaultLanguage === 'en' ? element.isWorkingFullTime?.value : element.isWorkingFullTime?.spValue : "N/A"
          }
          i++
        }

      })
    }

      const otherHealthInsurance = res['HHHLC']?.data
      if(res['HHHLC']) {
        let i = 0;
      otherHealthInsurance.forEach((element) => {
        if (element?.data?.policyHolder?.policyHolderFirstName) {
          this.otherHealthInsuranceRows[i] = {
            "policyHolderName": element?.data?.policyHolder?.policyHolderFirstName + " " + element?.data?.policyHolder?.policyHolderLastName,
            "whoIsCovered": element?.data?.coveredIndividualDetails?.converedIndiv ? this.getCoveredIndivData(element?.data?.coveredIndividualDetails?.converedIndiv, peopleRow) : null,
            "outsidehouseholdWhoIsCovered": element?.data?.coveredIndividualDetails?.converedIndiv ? this.getCoveredIndivData(element?.data?.coveredIndividualDetails?.converedIndiv, peopleRow) : null,
            "coverageType": element?.data?.coverageType ? this.defaultLanguage === 'en' ? element?.data?.coverageType.value : element?.data?.coverageType.spValue : null,
            "coverageSTartDate": element?.data?.policyStartDate ? element?.data?.policyStartDate : null,
            "companyName": element?.data?.insuranceCompanyName ? element?.data?.insuranceCompanyName : null,
            "policyNum": element?.data?.policyNumber ? element?.data?.policyNumber : "Not Answered",
            "groupNum": element?.data?.groupNumber ? element?.data?.groupNumber : "Not Answered",
            "premiumAmount": element?.data?.monthlyPremiumAmountForKB ? "$" + element?.data?.monthlyPremiumAmountForKB : "N/A"
          }
          i++
        }
      })
    }

      const employmentIncome = res['ICEMI']?.data
      if(res['ICEMI']) {
        let i = 0;
      employmentIncome.forEach((element) => {
        const name = peopleRow?.find((item) => item.identifier?.individualId === element.identifier?.individualId)
        if (name?.data?.name?.fullName) {
          this.employmentIncomeRows[i] = {
            "name": name?.data?.name?.fullName,
            "employer": element?.data?.employer?.name ? element?.data?.employer?.name : "Not Answered",
            "startDate": element?.data?.startDate ? element?.data?.startDate : null,
            "wage": element?.data?.wage?.paymentAmountPerPayCheck ? "$" + element?.data?.wage?.paymentAmountPerPayCheck : element?.data?.wage?.paymentAmtPerHr ? "$" + element?.data?.wage?.paymentAmtPerHr : null,
            "frequency": element?.data?.wage?.paymentPeriod ? this.defaultLanguage === 'en' ? element?.data?.wage?.paymentPeriod?.value : element?.data?.wage?.paymentPeriod?.spValue : element?.data?.wage?.hoursWorkedPeriod ? this.defaultLanguage === 'en' ? "Hourly" : "Cada hora" : null
          }
          i++
        }
      })
    }

      const selfEmploymentIncome = res['ICSEM']?.data
      if(res['ICSEM']) {
        let i = 0;
      selfEmploymentIncome.forEach((element) => {
        const name = peopleRow?.find((item) => item.identifier?.individualId === element.identifier?.individualId)
        if (name?.data?.name?.fullName) {
          this.selfEmploymentIncomeRows[i] = {
            "member": name?.data?.name?.fullName,
            "bussinessType": element?.data?.type ? this.defaultLanguage === 'en' ? element?.data?.type?.value : element?.data?.type?.spValue : "N/A",
            "netIncome": element?.data?.averageMonthlyProfit ? "$" + element?.data?.averageMonthlyProfit : "N/A",
            "bussinessName": element?.data?.businessName ? element?.data?.businessName : "Not Answered",
            "coOwner": element?.data?.coOwners ? this.createCoOwners(element?.data?.coOwners, peopleRow, "selfEmployment") : null
          }
          i++
        }
      })
    }

      const otherIncome = res['ICOTH']?.data
      if(res['ICOTH']) {
        let i = 0;
      otherIncome.forEach((element) => {
        const name = peopleRow?.find((item) => item.identifier?.individualId === element.identifier?.individualId)
        if (name?.data?.name?.fullName) {
          this.otherIncomeRows[i] = {
            "name": name?.data?.name?.fullName,
            "incometype": element?.data?.otherIncomeDetails?.otherIncomeTypeCode ? this.defaultLanguage === 'en' ? element?.data?.otherIncomeDetails?.otherIncomeTypeCode.value : element?.data?.otherIncomeDetails?.otherIncomeTypeCode?.spValue : "N/A",
            "startDate": element?.data?.otherIncomeDetails?.paymentStartDate ? element?.data?.otherIncomeDetails?.paymentStartDate : null,
            "wage": element?.data?.otherIncomeDetails?.paymentAmount ? "$" + element?.data?.otherIncomeDetails?.paymentAmount : null,
            "frequency": element?.data?.otherIncomeDetails?.paymentFrequency ? this.defaultLanguage === 'en' ? element?.data?.otherIncomeDetails?.paymentFrequency?.value : element?.data?.otherIncomeDetails?.paymentFrequency?.spValue : null
          }
          i++
        }
      })
    }

      if(res['AIBAO']) {
        const data = res['AIBAO']?.data[0]?.data
          this.otherBenefitsRows = [{
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT2',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "otherBenefits"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT41',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "ssiBenefits"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT7',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "bccCervCancer"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT10',
            "value": this.getName(data, peopleRow, "kbSwitch"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT13',
            "value":  this.getName(data.otherBenefitsIndvProfileData, peopleRow, "medicalBills"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT15',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "medicalFacility"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT27',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "hospicCare"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT29',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "hcbs"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT32',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "ecf"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT35',
            "value": this.getName(data.otherBenefitsIndvProfileData, peopleRow, "icf"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT45',
            "value": this.getName(data, peopleRow, "transferredResSw"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT37',
            "value": this.getName(data, peopleRow, "expensesSw"),
          },
          {
            "key": 'OTHER_HEALTHCARE_STATIC_TEXT39',
            "value": this.getName(data, peopleRow, "resourcesSw"),
          }
        ]      
     
    }

  //   const kbReferral = res['OTKBR']?.data
  //   if(res['OTKBR']) {
  //     kbReferral.forEach((element, index: any) => {
  //     const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId)
  //       if (name?.data?.name?.fullName) {
  //         this.kbRows[index] = {
  //           "name": name?.data?.name?.fullName,
  //           "reasons": element?.data?.reasonsForApplyingToKb.length ? this.getKBRason(element?.data?.reasonsForApplyingToKb) : null,
  //           "addressLine1": element?.data?.livingArrangement?.address ? element?.data?.livingArrangement?.address?.addressLine1 : null,
  //           "addressLine2": element?.data?.livingArrangement?.address ? element?.data?.livingArrangement?.address?.addressLine2 : null,
  //           "addressCity": element?.data?.livingArrangement?.address?.city ? element?.data?.livingArrangement?.address?.city : null,
  //           "addressState": element?.data?.livingArrangement?.address?.state ? this.defaultLanguage === 'en' ? element?.data?.livingArrangement?.address?.state?.value : element?.data?.livingArrangement?.address?.state?.spValue : null,
  //           "addressZip": element?.data?.livingArrangement?.address?.zip ? this.getZip(element?.data?.livingArrangement?.address?.zip) : null,
  //           "relationship": element?.data?.kbContactRelationship?.code ? this.defaultLanguage === 'en' ? element?.data?.kbContactRelationship?.value : element?.data?.kbContactRelationship?.spValue : null,
  //           "livingArrangement": element?.data?.livingArrangement?.currentLivingArrangement?.code ? this.defaultLanguage === 'en' ? element?.data?.livingArrangement?.currentLivingArrangement?.value : element?.data?.livingArrangement?.currentLivingArrangement?.spValue : null
  //         }
  //       }
  //   })
  // }

    //   const utilityExpenses = res['XPUTL']?.data
    //   if(res['XPUTL']) {
    //   utilityExpenses.forEach((element, index: any) => {
    //     const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId)
    //     if (name?.data?.name?.fullName) {
    //       this.utilityExpensesRows[index] = {
    //         "name": name?.data?.name?.fullName,
    //         "type": element?.data?.housingTypeExpense?.code ? this.defaultLanguage === 'en' ? element?.data?.housingTypeExpense?.value : element?.data?.housingTypeExpense?.spValue : null,
    //         "amount": element?.data?.expensePaymentInformation?.expenseAmount ? "$" + element?.data?.expensePaymentInformation?.expenseAmount : null,
    //         "frequency": element?.data?.expensePaymentInformation?.expenseFrequencyCode?.code ? this.defaultLanguage === 'en' ? element?.data?.expensePaymentInformation?.expenseFrequencyCode?.value : element?.data?.expensePaymentInformation?.expenseFrequencyCode?.spValue : null,
    //       }
    //     }
    //   })
    // }
    //   const dependentCareExp = res['XPDEP']?.data
    //   if(res['XPDEP']) {
    //   dependentCareExp.forEach((element, index: any) => {
    //     const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
    //     const dependentName = peopleRow.find((item) => item.identifier?.individualId === element?.data?.dependantCareInformation?.dependantIndivNumber);
    //     if (name?.data?.name?.fullName) {
    //       this.dependentCareExpensesRows[index] = {
    //         "whoPays": name?.data?.name?.fullName,
    //         "paidFor": dependentName?.data?.name?.fullName,
    //         "amount": element?.data?.expensePaymentInformation?.expenseAmount ? "$" + element?.data?.expensePaymentInformation?.expenseAmount : null,
    //         "frequency": element?.data?.expensePaymentInformation?.expenseFrequencyCode?.code ? this.defaultLanguage === 'en' ? element?.data?.expensePaymentInformation?.expenseFrequencyCode?.value : element?.data?.expensePaymentInformation?.expenseFrequencyCode?.spValue : null,
    //       }
    //     }
    //   })
    // }

    //   const mandatoryExp = res['XPSUP']?.data
    //   if(res['XPSUP']) {
    //   mandatoryExp.forEach((element, index: any) => {
    //     const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
    //     if (name?.data?.name?.fullName) {
    //       this.mandatoryExpensesRows[index] = {
    //         "whoPays": name?.data?.name?.fullName,
    //         "type": element?.data?.supportKindCode?.code ? this.defaultLanguage === 'en' ? element?.data?.supportKindCode?.value : element?.data?.supportKindCode?.spValue : null,
    //         "amount": element?.data?.expenseAmount ? "$" + element?.data?.expenseAmount : null,
    //       }
    //     }
    //   })
    // }

    //   const medicalBills = res['INMED']?.data
    //   if(res['INMED']) {
    //   medicalBills.forEach((element, index: any) => {
    //     const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
    //     const dependentName = peopleRow.find((item) => item.identifier?.individualId === element?.data?.careReceivingIndvNumber);
    //     if (name?.data?.name?.fullName) {
    //       this.medicialBillsExpensesRows[index] = {
    //         "whoPaid": name?.data?.name?.fullName,
    //         "paidFor": dependentName?.data?.name?.fullName,
    //         "type": element?.data?.medicalBillType?.code ? this.defaultLanguage === 'en' ? element?.data?.medicalBillType?.value : element?.data?.medicalBillType?.spValue : null,
    //         "amount": element?.data?.medicalBillOweAmt ? "$" + element?.data?.medicalBillOweAmt : null,
    //         "frequency": element?.data?.billPayingFrequencycd?.code ? this.defaultLanguage === 'en' ? element?.data?.billPayingFrequencycd?.value : element?.data?.billPayingFrequencycd?.spValue : null,

    //       }
    //     }
    //   })
    // }

  //   const accessToOtherCoverage = res['XPOTH']?.data
  //   if(res['XPOTH']) {
  //     accessToOtherCoverage.forEach((element, index: any) => {
  //     const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
  //     if (name?.data?.name?.fullName) {
  //       this.accesstoOtherCoverageRows[index] = {
  //         "name": name?.data?.name?.fullName,
  //         "qualify": element?.data?.minRequirementMetSw?.code ? this.defaultLanguage === 'en' ? element?.data?.minRequirementMetSw?.value : element?.data?.minRequirementMetSw?.spValue : "Not Answered",
  //         "amount": element?.data?.policyPremiumAmount ? "$" + element?.data?.policyPremiumAmount : null,
  //         "frequency": element?.data?.premiumFreqCd?.code ? this.defaultLanguage === 'en' ? element?.data?.premiumFreqCd?.value : element?.data?.premiumFreqCd?.spValue : null,
  //         "plan": element?.data?.cobraSw?.code ? this.defaultLanguage === 'en' ? element?.data?.cobraSw?.value : element?.data?.cobraSw?.spValue : null,
  //         "retireHealthPlan": element?.data?.retireeSw?.code ? this.defaultLanguage === 'en' ? element?.data?.retireeSw?.value : element?.data?.retireeSw?.spValue : null,
  //       }
  //     }
  //   })
  // }

//   const financialResources = res['ASLIQ']?.data
//   if(res['ASLIQ']) {
//     financialResources.forEach((element, index: any) => {
//     const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
//     if (name?.data?.name?.fullName) {
//       this.financialRows[index] = {

//         "name": name?.data?.name?.fullName,
//         "type": element?.data?.assetType?.code ? this.defaultLanguage === 'en' ? element?.data?.assetType?.value : element?.data?.assetType?.spValue : "Not Answered",
//         "bankName": element?.data?.accountInformation?.bankSearch?.bankName ? element?.data?.accountInformation?.bankSearch?.bankName : "Not Answered",
//         "totalValue": element?.data?.assetAmount ? "$" + element?.data?.assetAmount : "Not Answered",
//         "coOwner": element?.data?.jointOwnershipInformation ? this.createCoOwners(element?.data?.jointOwnershipInformation, peopleRow, "resources") : null,
//       }
//     }
//   })
// }

// const vehicleResources = res['ASVEH']?.data
// if(res['ASVEH']) {
//   vehicleResources.forEach((element, index: any) => {
//   const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
//   if (name?.data?.name?.fullName) {
//     this.vehicleRows[index] = {
//       "name": name?.data?.name?.fullName,
//       "type": element?.data?.assetType?.code ? this.defaultLanguage === 'en' ? element?.data?.assetType?.value : element?.data?.assetType?.spValue : "Not Answered",
//       "model": element?.data?.vehicleModel ? element?.data?.vehicleModel : null,
//       "make": element?.data?.vehicleMake ? element?.data?.vehicleMake : null,
//       "makeYear": element?.data?.vehicleYear ? element?.data?.vehicleYear : null,
//       "totalValue": element?.data?.assetAmount ?  "$" + element?.data?.assetAmount : "Not Answered",
//       "amount": element?.data?.owedAmount ? "$" + element?.data?.owedAmount : "Not Answered",
//       "coOwner": element?.data?.jointOwnershipInformation ? this.createCoOwners(element?.data?.jointOwnershipInformation, peopleRow, "resources") : null,
//     }
//   }
// })
// }

// const propertyResources = res['ASPRP']?.data
// if(res['ASPRP']) {
//   propertyResources.forEach((element, index: any) => {
//   const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
//   if (name?.data?.name?.fullName) {
//     this.propertyRows[index] = {
//       "name": name?.data?.name?.fullName,
//       "type": element?.data?.assetType?.code ? this.defaultLanguage === 'en' ? element?.data?.assetType?.value : element?.data?.assetType?.spValue : "Not Answered",
//       "totalValue": element?.data?.assetAmount ?  "$" + element?.data?.assetAmount : "Not Answered",
//       "amount": element?.data?.owedAmount ? "$" + element?.data?.owedAmount : "Not Answered",
//       "coOwner": element?.data?.jointOwnershipInformation ? this.createCoOwners(element?.data?.jointOwnershipInformation, peopleRow, "resources") : null,
//     }
//   }
// })
// }

// const burrialResources = res['ASBUR']?.data
// if(res['ASBUR']) {
//   burrialResources.forEach((element, index: any) => {
//   const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
//   if (name?.data?.name?.fullName) {
//     this.burrialRows[index] = {
//       "name": name?.data?.name?.fullName,
//       "type": element?.data?.assetType?.code ? this.defaultLanguage === 'en' ? element?.data?.assetType?.value : element?.data?.assetType?.spValue : "Not Answered",
//       "bankName": element?.data?.institutionName ? element?.data?.institutionName : "Not Answered",
//       "totalValue": element?.data?.assetAmount ?  "$" + element?.data?.assetAmount : "Not Answered",
//       "amount": element?.data?.owedAmount ? "$" + element?.data?.owedAmount : "Not Answered",
//       "coOwner": element?.data?.jointOwnershipInformation ? this.createCoOwners(element?.data?.jointOwnershipInformation, peopleRow, "resources") : null,
//     }
//   }
// })
// }
// const lifeInsuranceResources = res['ASLIF']?.data
// if(res['ASLIF']) {
//   lifeInsuranceResources.forEach((element, index: any) => {
//   const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
//   if (name?.data?.name?.fullName) {
//     this.lifeResourcesRows[index] = {
//       "name": name?.data?.name?.fullName,
//       "type": element?.data?.assetType?.code ? this.defaultLanguage === 'en' ? element?.data?.assetType?.value : element?.data?.assetType?.spValue : "Not Answered",
//       "faceValue": element?.data?.faceAmount ? "$" + element?.data?.faceAmount : "Not Answered",
//       "cashSurrenderValue": element?.data?.assetAmount ? "$" + element?.data?.assetAmount : "N/A",
//       "policyNumber": element?.data?.policyNumber ? element?.data?.policyNumber : "Not Answered",
//     }
//   }
// })
// }

// const otherResources = res['OTHRS']?.data
// if(res['OTHRS']) {
//   otherResources.forEach((element, index: any) => {
//   const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
//   if (name?.data?.name?.fullName) {
//     this.otherResourceRows[index] = {
//       "name": name?.data?.name?.fullName,
//       "type": element?.data?.assetType?.code ? this.defaultLanguage === 'en' ? element?.data?.assetType?.value : element?.data?.assetType?.spValue : "Not Answered",
//       "value": element?.data?.assetAmount ? "$" + element?.data?.assetAmount : "Not Answered",
//       "amount": element?.data?.owedAmount ? "$" + element?.data?.owedAmount : "Not Answered",
//       "coOwner": element?.data?.jointOwnershipInformation ? this.createCoOwners(element?.data?.jointOwnershipInformation, peopleRow, "resources") : null,
//     }
//   }
// })
// }

// const transferredResources = res['ASCLD']?.data
// if(res['ASCLD']) {
//   transferredResources.forEach((element, index: any) => {
//   const name = peopleRow.find((item) => item.identifier?.individualId === element.identifier?.individualId);
//   if (name?.data?.name?.fullName) {
//     this.transferredRows[index] = {
//       "name": name?.data?.name?.fullName,
//       "type": element?.data?.typeOfAsset?.code ? this.defaultLanguage === 'en' ? element?.data?.typeOfAsset?.value : element?.data?.typeOfAsset?.spValue : null,
//       "newOwner":  element?.data?.assetTransferredTo,
//       "date": element?.data?.assetTransferDate ? element?.data?.assetTransferDate : null,
//       "totalValue": element?.data?.valueOfAsset ? "$" + element?.data?.valueOfAsset : null,
//       "amountRecvd": element?.data?.inReturnAmount ? "$" + element?.data?.inReturnAmount : null,
//     }
//   }
// })
// }

      const taxData = res['HHTJF']?.data
      if (res['HHTJF']) {
        let i = 0;
        let j = 0;
        taxData.forEach((element) => {
          const name = peopleRow?.find((item) => item.identifier?.individualId === element.identifier?.individualId);
          const jointFilerName = peopleRow?.find((item) => item.identifier?.individualId === String(element?.data?.jointTaxFilingIndividualId));
          if (element.data.taxFilingStatus !== "Out of Household Filer" && name?.data?.name?.fullName) {
            this.taxRows[j] = {
              "name": name?.data?.name?.fullName ? name?.data?.name?.fullName : null,
              "filingJointly": element?.data?.jointFilerSw?.code === "Y" && element?.data?.jointTaxFilingIndividualId !== null && element?.data?.jointTaxFilingIndividualId !== 0 ? jointFilerName?.data?.name?.fullName : element?.data?.jointFilerOutsideHousehold === "Yes" ? element?.data?.jointFilerOutsideDifference ? element?.data?.jointFilerOutsideDifference.name : "No" : "No",
              "dependents": element?.data?.taxDependents?.length ? this.getTaxDependents(element?.data?.taxDependents, peopleRow) : null,
              "deductions": element?.data?.taxDeductionsDifference?.taxDeductionsIndvData?.length ? this.getTaxDeductionsData(element?.data?.taxDeductionsDifference?.taxDeductionsIndvData, peopleRow) : null,
              "outOfHouseDependents": element?.data?.taxDependentOutsideDifference?.taxDependentsOutside ? this.getOutsideTaxDependents(element?.data?.taxDependentOutsideDifference?.taxDependentsOutside) : null,
            }
            j++
          }
          if (element.data.taxFilingStatus === "Out of Household Filer" && element?.data?.taxFilerOutsideData?.taxDependents.length) {
            element?.data?.taxFilerOutsideData?.taxDependents.forEach(item =>{
              const outsideDependentName = peopleRow?.find((person) => person.identifier?.individualId === item?.dependent);
              this.taxOutsideRows[i] = {
                "outOfHouseFiler": item?.taxFilerName ? item?.taxFilerName : null,
                "claimedDependent": outsideDependentName?.data?.name?.fullName ? outsideDependentName.data?.name?.fullName : "No",
                "relationshipToOutsideTaxFiler": item?.relationToTaxFiler?.code ? this.defaultLanguage === 'en' ? item?.relationToTaxFiler?.value : item?.relationToTaxFiler?.spValue : "No",
              }
              i++
            })
          }
        })
      }

      this.modules = getDataValue('record.data.modules', this);
      const HCPPG = findModule('HCPPG', this.modules);
      const HHPPG = findModule('HHPPG', this.modules);
      const SEPPG = findModule('SEPPG', this.modules);
      const XPPPG = findModule('XPPPG', this.modules);
      const ICPPG = findModule('ICPPG', this.modules);
      const ASPPG = findModule('ASPPG', this.modules);
      const INPPG = findModule('INPPG', this.modules);
      const TXPPG = findModule('TXPPG', this.modules);
      const KTPPG = findModule('KTPPG', this.modules);
      const HIPPG = findModule('HIPPG', this.modules);
      const TRPPG = findModule('TRPPG', this.modules);

      const disableInitial = (moduleName, tileValue) => {
        if (moduleName && moduleName.moduleId !== 'SUPPG') {
          const section = moduleName.sections.find(s => s.changeRequested === true ? s.sectionId : null);
          if (section) {
            this.initialSelection.push(tileValue);
          }
        }
        if (moduleName && moduleName.moduleId === 'SUPPG') {
          const section = moduleName.sections.find(s => s.sectionId === 'SUCOV');
          if (section.sectionId === 'SUCOV' && section.changeRequested === true) {
            this.initialSelection.push(tileValue);
          }
        }

        if (JSON.parse(sessionStorage.getItem('disableKb'))) {
          this.initialSelection.push('kb');
        }
      }

      disableInitial(KTPPG, 'kb');
      disableInitial(TXPPG, 'tax');
      disableInitial(INPPG, 'other');
      disableInitial(ASPPG, 'assets');
      disableInitial(ICPPG, 'income');
      disableInitial(TRPPG, 'transfer');
      disableInitial(XPPPG, 'expenses');
      disableInitial(SEPPG, 'endCoverage');
      disableInitial(HCPPG, 'contactInfo');
      disableInitial(HIPPG, 'houseHoldInfo');
      disableInitial(HHPPG, 'houseHoldMembers');
      this.isRenewBenefits = (this.appType === 'RB' || this.appType === "REDETERMINATION");

      if (this.status === "COMPLETE") {
        const hohRadioValue = HCPPG?.sections[0]['changeRequested'] ? "edit" : "noChange";
        this.getControl('extras.helpers.hoh').patchValue(hohRadioValue);
        this.getControl('extras.helpers.hoh').updateValueAndValidity();
        const peopleInYourHomeRadioValue = HHPPG?.sections[0]['changeRequested'] ? "edit" : "noChange";
        this.getControl('extras.helpers.peopleInYourHome').patchValue(peopleInYourHomeRadioValue);
        this.getControl('extras.helpers.peopleInYourHome').updateValueAndValidity();
        const householdInfoRadioValue = HIPPG?.sections.find(rec => rec['changeRequested'] === true) ? "edit" : "noChange";
        this.getControl('extras.helpers.householdInfo').patchValue(householdInfoRadioValue);
        this.getControl('extras.helpers.householdInfo').updateValueAndValidity();
        const taxInformationRadioValue = TXPPG?.sections[0]['changeRequested'] ? "edit" : "noChange";
        this.getControl('extras.helpers.taxInformation').patchValue(taxInformationRadioValue);
        this.getControl('extras.helpers.taxInformation').updateValueAndValidity();
        const incomeEmploymentRadioValue = ICPPG?.sections.find(rec => rec['changeRequested'] === true) ? "edit" : "noChange";
        this.getControl('extras.helpers.incomeEmployment').patchValue(incomeEmploymentRadioValue);
        this.getControl('extras.helpers.incomeEmployment').updateValueAndValidity();
        const otherBenefitsRadioValue = INPPG?.sections[0]['changeRequested'] ? "edit" : "noChange";
        this.getControl('extras.helpers.otherBenefits').patchValue(otherBenefitsRadioValue);
        this.getControl('extras.helpers.otherBenefits').updateValueAndValidity();
        const expensesRadioValue = XPPPG?.sections.find(rec => rec['changeRequested'] === true) ? "edit" : "noChange";
        this.getControl('extras.helpers.expenses').patchValue(expensesRadioValue);
        this.getControl('extras.helpers.expenses').updateValueAndValidity();
        const resourcesRadioValue = ASPPG?.sections.find(rec => rec['changeRequested'] === true) ? "edit" : "noChange";
        this.getControl('extras.helpers.resources').patchValue(resourcesRadioValue);
        this.getControl('extras.helpers.resources').updateValueAndValidity();
        this.updateMissingSelections();
      } else if (this.status === "IN_PROGRESS") {
        this.getControl('extras.helpers.hoh').setValidators(Vs.required);
        this.getControl('extras.helpers.hoh').updateValueAndValidity();
        this.getControl('extras.helpers.peopleInYourHome').setValidators(Vs.required);
        this.getControl('extras.helpers.peopleInYourHome').updateValueAndValidity();
        this.getControl('extras.helpers.householdInfo').setValidators(Vs.required);
        this.getControl('extras.helpers.householdInfo').updateValueAndValidity();
        this.getControl('extras.helpers.taxInformation').setValidators(Vs.required);
        this.getControl('extras.helpers.taxInformation').updateValueAndValidity();
        this.getControl('extras.helpers.incomeEmployment').setValidators(Vs.required);
        this.getControl('extras.helpers.incomeEmployment').updateValueAndValidity();
        // this.getControl('extras.helpers.expenses').setValidators(Vs.required);
        // this.getControl('extras.helpers.expenses').updateValueAndValidity();
        // this.getControl('extras.helpers.resources').setValidators(Vs.required);
        // this.getControl('extras.helpers.resources').updateValueAndValidity();
      }
    })
  }

  getName(data, people, section) {
    let name = ""
    if ((section !== "kbSwitch") && (section !== "transferredResSw") && (section !== "expensesSw") && (section !== "resourcesSw")) {
      data.forEach(element => {
        if ((section === "otherBenefits" && element.medicareIndSw?.code === "Y") || (section === "ssiBenefits" && element.ssiBenefitsSw?.code === "Y") ||
          (section === "bccCervCancer" && element.brstCervCancerSw?.code === "Y") || (section === "medicalBills" && element.medicalBillsIndSw?.code === "Y") ||
          (section === "medicalFacility" && element.medFacilitySw?.code === "Y") || (section === "hospicCare" && element.hospiceSw?.code === "Y") ||
          (section === "hcbs" && element.hcbsSw?.code === "Y") || (section === "ecf" && element.ecfSw?.code === "Y") || (section === "icf" && element.intelDisbSw?.code === "Y")) {
          const nameObj = people.find((item) => item.identifier?.individualId === element.indvId);
          name = name.length === 0 ? nameObj?.data?.name?.fullName : name + ", " + nameObj?.data?.name?.fullName
        } else if ((section === "otherBenefits" && element.medicareIndSw?.code === "N") || (section === "ssiBenefits" && element.ssiBenefitsSw?.code === "N") ||
        (section === "bccCervCancer" && element.brstCervCancerSw?.code === "N") || (section === "medicalBills" && element.medicalBillsIndSw?.code === "N") ||
        (section === "medicalFacility" && element.medFacilitySw?.code === "N") || (section === "hospicCare" && element.hospiceSw?.code === "N") ||
        (section === "hcbs" && element.hcbsSw?.code === "N") || (section === "ecf" && element.ecfSw?.code === "N") || (section === "icf" && element.intelDisbSw?.code === "N")) {
        name = "No"
      }
      })
    } else if ((section === "kbSwitch" && data.kbReferralSw?.code) || (section === "transferredResSw" && data.transferredResInd?.code) ||
      (section === "expensesSw" && data.expensesInd?.code) || (section === "resourcesSw" && data.assetInd?.code)) {
      if (section === "kbSwitch") {
        name = data.kbReferralSw?.value
      } else if (section === "transferredResSw") {
        name = data.transferredResInd?.value
      } else if (section === "expensesSw") {
        name = data.expensesInd?.value
      } else if (section === "resourcesSw") {
        name = data.assetInd?.value
      }     
    } 
    return name;
  }


  ngAfterContentInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.defaultLanguage = res.lang
      let appNum = sessionStorage.getItem('appNum')
      this.getTablesData(appNum)
    });
    let appNum = sessionStorage.getItem('appNum')
    this.getTablesData(appNum)
  }

  getZip(zip){
    if(zip?.length > 5){
      return `${zip.substring(0,5)}-${zip.substring(5)}`
    }
    return zip
  }

  getCoveredIndivData(coveredIndivs, peopleRow) {
    const tempArray = []
    coveredIndivs.forEach(element => {
      let tempObj = {}
      const name = element.coveredIndividualNumber ? peopleRow?.find((item) => item.identifier?.individualId === String(element.coveredIndividualNumber)) : element.coveredIndividualNumber
      tempObj = {
        name: name?.data?.name?.fullName,
      }
      tempArray.push(tempObj)
    })
    return tempArray
  }

  getKBRason(reasons) {
    const tempArray = []
    reasons.forEach(element => {
      let tempObj = {}
      const reason = element.code ? this.defaultLanguage === "en" ? element.value : element.spValue : null
      tempObj = {
        reason : reason
      }
      tempArray.push(tempObj)
    });
    return tempArray
  }

  getTaxDependents(dependents, peopleRow) {
    const tempArray = []
    dependents.forEach(element => {
      let tempObj = {}
      const name = element.indvId !== 0 ? peopleRow?.find((item) => item.identifier?.individualId === String(element.individualId)) : element.individualId;
      tempObj = {
        name : name?.data?.name?.fullName,
      }
      tempArray.push(tempObj)
    })
    return tempArray
  }

  getOutsideTaxDependents(dependents) {
    const tempArray = []
    dependents.forEach(element => {
      let tempObj = {}
      tempObj = {
        name : element.name,
        relation : element.relation?.code ? this.defaultLanguage === "en" ? element.relation?.value : element.relation?.spValue : null,
      }
      tempArray.push(tempObj)
    })
    return tempArray
  }

  getTaxDeductionsData(deductionsIndivData, peopleRow) {
    const tempArray = []
    deductionsIndivData.forEach(element => {
      let tempObj = {}
      const name = element.indvId !== 0 ? peopleRow?.find((item) => item.identifier?.individualId === String(element.indvId)) : element.indvId;
      tempObj = {
        name : name?.data?.name?.fullName,
        type : element.deductionType?.code ? this.defaultLanguage === "en" ? element.deductionType?.value : element.deductionType?.spValue : null,
        amount: element.deductionAmount ? "$" + element.deductionAmount : null,
        frequency : "Monthly"
      }
      tempArray.push(tempObj)
    })
    return tempArray
  }

  createCoOwners(coOwners, peopleRow, from) {
    const tempArray = []
    if(from !== "resources") {
    coOwners.forEach(element => {
      let tempObj = {}
      const name = element.coOwnerIndividual.individualId !== 0 ? peopleRow?.find((item) => item.identifier?.individualId === String(element.coOwnerIndividual.individualId)) : element.coOwnerIndividual.individualId
      tempObj = {
        namePercent: element.coOwnerIndividual?.individualId !== 0 ? name?.data?.name?.fullName + "-" + element.ownershipPercentage + "%" : element.outsideTheHomeFullName + "-" + element.ownershipPercentage + "%",
      }
      tempArray.push(tempObj)
    })
  } else if(from === "resources") {
    coOwners.forEach(element => {
      let tempObj = {}
      const name = element.jointOwnerIndividual.individualId !== 0 ? peopleRow?.find((item) => item.identifier?.individualId === String(element.jointOwnerIndividual.individualId)) : element.jointOwnerIndividual.individualId;
      tempObj = {
        namePercent: element.jointOwnerIndividual.individualId !== 0 ? name?.data?.name?.fullName + "-" + element.jointOwnerPercentage + "%" : element.outOfHouseHoldname + "-" + element.jointOwnerPercentage + "%",
      }
      tempArray.push(tempObj)
    })
  }
    return tempArray
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }
  ngAfterViewInit(): void {
    window.scroll(0, 0);
    window.dispatchEvent(new Event('resize'))
    this.container = document.body.querySelector('.ng-sidebar__content');
    this.container.addEventListener('scroll', () => {
      this.checkScroll()
    })

    this.container.addEventListener('load', () => {
      this.checkScroll()
    })
    setTimeout(() => this.updateMissingSelections());
  }

  updateMissingSelections(): void {
    const formValue = this.formGroup?.value?.extras?.helpers;
    this.missingSelections = this.slides.slice(0, 5).map(slide => slide.module).filter(module => !formValue[module] || formValue[module] === null);
    this.showBanner = this.missingSelections.includes(this.slides[this.currentSlideIndex].module);

    if (this.currentSlideIndex === this.slides.length - 1) {
      this.finalBanner = this.missingSelections.length > 0;
    } else if (this.missingSelections.length === 0) {
      this.finalBanner = false;
    }
  } 

  get missingSelectionText(): string {
    const missing = this.missingSelections.map(module => this.translateService.instant(this.slideHeaders[module]));
    const translatedAnd = this.translateService.instant('and');
    if (missing.length === 1) {
      return `<strong>${missing[0]}</strong>`;
    } else if (missing.length === 2) {
      return `<strong>${missing[0]}</strong> ${translatedAnd} <strong>${missing[1]}</strong>`
    } else {
      return missing.map((item, index) => {
        if (index === missing.length - 1) {
          return `${translatedAnd} <strong>${item}</strong>`
        } else {
          return `<strong>${item}</strong>,`
        }
      }).join(' ')
    }
  }

  disable(controlName: string) {
    return this.initialSelection.some(v => v === controlName);
  }


  toggleValue(module: string, event) {
    event.stopPropagation()
    const target = event.target as HTMLElement;
    if(target && target?.tagName === 'INPUT'){
      this.userSelections[module] = event.target?.value;
      this.updateMissingSelections();
    }
    if (this.disable(module)) return;
    this[module] = !this[module];
    let control: any;
    let val: any;
    let controlName =
      module === 'hoh'
        ? 'HCPPG'
        : module === 'peopleInYourHome'
          ? 'HHPPG'
          : module === 'householdInfo'
            ? 'HIPPG'
            : module === 'taxInformation'
              ? 'TXPPG'
              : module === 'incomeEmployment'
                ? 'ICPPG'
                : module === 'otherBenefits'
                  ? 'INPPG'
                  : module === 'expenses'
                    ? 'XPPPG'
                    : module === 'resources'
                      ? 'ASPPG'
                      : '';

    const dataArray = this.formGroup.value.data.modules
    let k;
    dataArray.forEach((element, i) => {
      if (element.moduleId === controlName) {
        k = i
      }
    })
    let radioControlName = 'extras.helpers.' + module
    let controlpart = this.getControl("data.modules");
    let controlPart2 = controlpart['controls']
    control = controlPart2[k]
    if (control) {
      val = control.value.sections;
      if (val) {
        val = val.map(s => {
          if (event.target.value === "edit") {
            if(module === 'householdInfo' && ((s.sectionId === 'HHRLS' && !this.relationshipRows.length) || (s.sectionId === 'SCAEM' && !this.displaySchoolAndEmployment))) {
              s.changeRequested = false;
            } else {
              s.changeRequested = true;
            }
            this.getControl(radioControlName).patchValue("edit");
            this.getControl(radioControlName).updateValueAndValidity();
          } else {
            s.changeRequested = false;
            this.getControl(radioControlName).patchValue("noChange");
            this.getControl(radioControlName).updateValueAndValidity()
          }
          return s;
        });
        control.setValue(control.value);
      }
    }
  }

  onSlideChange(event: SlidesOutputData): void {
    this.currentSlide = event?.startPosition || 0;
    this.currentSlideIndex = this.currentSlide;
    this.updateMissingSelections()
    if(this.currentSlideIndex === this.slides.length -1 && this.missingSelections.length > 0){
      this.finalBanner = true;
    }
    this.checkScroll()
  }

  checkScroll() {
    const leftArrow = document.getElementById('leftarrow');
    const rightArrow = document.getElementById('rightarrow');
    const slides = document.querySelectorAll('.slide-content');
    if (!leftArrow || !rightArrow || slides.length === 0) return;
    const activeSlide = document.querySelector('.owl-item.active .slide-content') as HTMLElement;
    const activeSlideIndex = Array.from(slides).indexOf(activeSlide);
    leftArrow.style.display = activeSlideIndex === 0 ? 'none' : 'block';
    rightArrow.style.display = activeSlideIndex === slides.length - 1 ? 'none' : 'block';
    const activeSlidePosition = activeSlide?.getBoundingClientRect();
    if (activeSlidePosition) {
      const defaultTop = 50;
      const slideTop = activeSlidePosition.top;
      if (slideTop < window.innerHeight && activeSlidePosition.bottom > 0) {
        const dynamicTop = Math.max(defaultTop, slideTop + activeSlidePosition.height / 9);
        leftArrow.style.top = `${dynamicTop}px`
        rightArrow.style.top = `${dynamicTop}px`
      } else {
        leftArrow.style.top = `${defaultTop}px`
        rightArrow.style.top = `${defaultTop}px`
        rightArrow.style.display = "none"
        leftArrow.style.display = "none"
      }
    }
  }

  scrollTop() {
    const blueBox = document.getElementById('blueBox');
    blueBox.scrollIntoView({ behavior: 'smooth' })
  }

  onExpand(panelKey: string, isExpanded: boolean) {
    this.expandedPanels[panelKey] = isExpanded;
  }
}