import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/gateway/services/api.service';
import { Router } from "@angular/router";
import { StateService } from "@app/services/state/state.service";
import { StorageService } from "@app/services/storage/storage.service";
import { ApplicationService } from "@app/services/application/application.service";
import { AppealsService } from './appeals.service';
import { ModalService } from '@app/services/modal/modal.service';
import { HttpHeaders  } from '@angular/common/http';

@Component({
  selector: 'nh-my-appeals',
  templateUrl: './my-appeals.component.html',
  styleUrls: ['./my-appeals.component.scss']
})
export class MyAppealsComponent implements OnInit {

    appealDetailsColumns = [
        { name: 'Name', prop: 'memberName' },
        { name: '#Appeal', prop: 'appealNumber' },
        //{ name: '#Case', prop: 'caseNumber' },
        { name: 'Appeal Filing Date', prop: 'appealFilingDate' },
        { name: 'Appeal Reason', prop: 'appealReasonValue' },
        { name: 'Appeal Status', prop: 'appealStatusValue' },
        //{ name: 'Hearing Date', prop: 'hearingDate' },
        { name: 'Coverage Type', prop: 'coverageType' }
    ];
    pastAppealDetailsColumns = [
        { name: 'Name', prop: 'memberName' },
        { name: '#Appeal', prop: 'appealNumber' },
        { name: '#Case', prop: 'caseNumber' },
        { name: 'Appeal Filing Date', prop: 'appealFilingDate' },
        { name: 'Appeal Reason', prop: 'appealReasonValue' },
        { name: 'Appeal Status', prop: 'pastAppealStatus' },
        { name: 'Hearing Date', prop: 'hearingDate' },
        { name: 'Coverage Type', prop: 'coverageType' }
    ];
    appealDetailsRows;
    pastAppealDetailsRows;
    translatedLang;
    indivId = sessionStorage.getItem('individualId');
    clickViewAppeals = true;
    clickHideAppeals = false;
    AppealsAction = "AppealsAction";
    applicationNum;
    showViewMyLetters = false;
    indvId;
    enableFileAppealButton = true;
    uploadProofTrue = false;

    
    constructor(public translateService: TranslateService, public app: ApiService, private router: Router, protected storageService: StorageService,
        private stateService: StateService, private application: ApplicationService, public appealsService: AppealsService, public modal: ModalService) { }

    ngOnInit() {
        const defaultLanguage = this.translateService.getDefaultLang();
        this.translatedLang = defaultLanguage;
        const url = `appeals/details/indvid/${this.indivId}`;
        this.app.get(url, true, {headers: new HttpHeaders({'pageId': 'TCAPL'})}).subscribe((resp) => {
            if (resp) { 
                this.appealDetailsRows = resp['currentAppeals'];
                this.pastAppealDetailsRows = resp['pastAppeals'];
                this.uploadProofAlert()
            }
            this.getTranslatedStatus(this.appealDetailsRows, this.pastAppealDetailsRows);
         })
         this.translateService.onDefaultLangChange.subscribe((res) => {
            this.translatedLang = res.lang;
            this.getTranslatedStatus(this.appealDetailsRows, this.pastAppealDetailsRows);
        });
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.indvId = currentUser?.individualId;
        const url2 = `indi/checkmybenefits/viewMyLetters/indv/${this.indvId}?letterType=AP`;
        this.app.get(url2, null, {headers: new HttpHeaders({'pageId': 'TCAPL'})}).subscribe(res => {
        this.showViewMyLetters = res[0] ? true : false;
        }) 
    }

    getTranslatedStatus(appealDetailsRows, pastAppealDetailsRows) {
        if (appealDetailsRows?.length) {
            for (let i = 0; i < appealDetailsRows.length; i++) { 
                  if (this.translatedLang === 'sp' && appealDetailsRows[i]['appealReason']) {
                    appealDetailsRows[i]['appealReasonValue'] = appealDetailsRows[i]['appealReason']['spValue'] ? appealDetailsRows[i]['appealReason']['spValue'] : "N/A";
                  } else if (this.translatedLang === 'en' && appealDetailsRows[i]['appealReason']) {
                    appealDetailsRows[i]['appealReasonValue'] = appealDetailsRows[i]['appealReason']['value'] ? appealDetailsRows[i]['appealReason']['value'] : "N/A";
                  }
                  if (this.translatedLang === 'sp' && appealDetailsRows[i]['appealStatusEs']) {
                    appealDetailsRows[i]['appealStatusValue'] = appealDetailsRows[i]['appealStatusEs'] ? appealDetailsRows[i]['appealStatusEs'] : "N/A";
                  } else if (this.translatedLang === 'en' && appealDetailsRows[i]['appealStatus']) {
                    appealDetailsRows[i]['appealStatusValue'] = appealDetailsRows[i]['appealStatus'] ? appealDetailsRows[i]['appealStatus'] : "N/A";
                  }
                  appealDetailsRows[i]['hearingDate'] = appealDetailsRows[i]['hearingDate'] ? appealDetailsRows[i]['hearingDate'] : "N/A";
                  appealDetailsRows[i]['appealNumber'] = appealDetailsRows[i]['appealNumber'] ? appealDetailsRows[i]['appealNumber'] : "N/A";
                  appealDetailsRows[i]['caseNumber'] = appealDetailsRows[i]['caseNumber'] ? appealDetailsRows[i]['caseNumber'] : "N/A";
                  appealDetailsRows[i]['appealFilingDate'] = appealDetailsRows[i]['appealFilingDate'] ? appealDetailsRows[i]['appealFilingDate'] : "N/A";
                  appealDetailsRows[i]['appealStatus'] = appealDetailsRows[i]['appealStatus'] ? appealDetailsRows[i]['appealStatus'] : "N/A";
                  appealDetailsRows[i]['coverageType'] = appealDetailsRows[i]['coverageType'] ? appealDetailsRows[i]['coverageType'] : "N/A";
            }

        }
        if (pastAppealDetailsRows?.length) {
            for (let i = 0; i < pastAppealDetailsRows.length; i++) { 
                  if (this.translatedLang === 'sp' && pastAppealDetailsRows[i]['appealStatusEs']) {
                    pastAppealDetailsRows[i]['pastAppealStatus'] = pastAppealDetailsRows[i]['appealStatusEs'] ? pastAppealDetailsRows[i]['appealStatusEs'] : "N/A";
                  } else if (this.translatedLang === 'en' && pastAppealDetailsRows[i]['appealStatus']) {
                    pastAppealDetailsRows[i]['pastAppealStatus'] = pastAppealDetailsRows[i]['appealStatus'] ? pastAppealDetailsRows[i]['appealStatus'] : "N/A";
                  }
                  if (this.translatedLang === 'sp' && pastAppealDetailsRows[i]['appealReason']) {
                    pastAppealDetailsRows[i]['appealReasonValue'] = pastAppealDetailsRows[i]['appealReason']['spValue'] ? pastAppealDetailsRows[i]['appealReason']['spValue'] : "N/A";
                  } else if (this.translatedLang === 'en' && pastAppealDetailsRows[i]['appealReason']) {
                    pastAppealDetailsRows[i]['appealReasonValue'] = pastAppealDetailsRows[i]['appealReason']['value'] ? pastAppealDetailsRows[i]['appealReason']['value'] : "N/A";
                  }
                  pastAppealDetailsRows[i]['hearingDate'] = pastAppealDetailsRows[i]['hearingDate'] ? pastAppealDetailsRows[i]['hearingDate'] : "N/A";
                  pastAppealDetailsRows[i]['appealNumber'] = pastAppealDetailsRows[i]['appealNumber'] ? pastAppealDetailsRows[i]['appealNumber'] : "N/A";
                  pastAppealDetailsRows[i]['caseNumber'] = pastAppealDetailsRows[i]['caseNumber'] ? pastAppealDetailsRows[i]['caseNumber'] : "N/A";
                  pastAppealDetailsRows[i]['appealFilingDate'] = pastAppealDetailsRows[i]['appealFilingDate'] ? pastAppealDetailsRows[i]['appealFilingDate'] : "N/A";
                  pastAppealDetailsRows[i]['appealStatus'] = pastAppealDetailsRows[i]['appealStatus'] ? pastAppealDetailsRows[i]['appealStatus'] : "N/A";
                  pastAppealDetailsRows[i]['coverageType'] = pastAppealDetailsRows[i]['coverageType'] ? pastAppealDetailsRows[i]['coverageType'] : "N/A";
            }

        }

    }

    clickedViewAppeals() {
        this.clickViewAppeals = false;
        this.clickHideAppeals = true;
    }

    clickedHideAppeals() {
        this.clickHideAppeals = false;
        this.clickViewAppeals = true;
    }

    uploadProof(row) {
          row.verfDesc = this.translatedLang === 'en' ? "Appeal Proof Documents" : "Documentos de comprobación para apelación";
        row.fullName = row.memberName;
        sessionStorage.setItem('myAppealsUploadProof', 'true');
        sessionStorage.setItem('appealCaseNumber', row.caseNumber);
        sessionStorage.setItem('appealUploadInfo', JSON.stringify(row));
        console.log(row)
        this.router.navigate(['user-profile/my-documents', 'documents-we-need'])
    }

  uploadProofAlert() {
    if (Array.isArray(this.appealDetailsRows) && this.appealDetailsRows?.length) {
        this.uploadProofTrue = !!this.appealDetailsRows.find(item => (item.uploadAppealAiProof
        ) || (item.uploadAppealProofVfd
        ))

    }
  }
    
    fileContinuance(row) {
      if (row.duplicateContinuance) {
        this.modal.openDuplicateContinuanceAppeal().subscribe(
          resp => {
            if (resp.confirm) {
            this.appealContinue(row);
            }
          });
      } else {
        this.appealContinue(row);
      }
    }

    fileWithdrawal(row) {
      if (row.duplicateWithrawal) {
        this.modal.openDuplicateWithdrawlAppeal().subscribe(
          resp => {
            if (resp.confirm) {
            this.appealWithdrawl(row);
            }
          });
      } else {
        this.appealWithdrawl(row);
      }
    }

    appealContinue(row) {
      this.router.navigateByUrl("/user-profile/appeal-continuance?indvid=" + row.encryptIndvId + "&caseNumber=" + row.encryptCaseNum + "&appealNumber=" + row.encryptAppealNum);
      this.appealsService.showMessageWithdrawal = false;
      this.appealsService.showMessage = false;
    }

    appealWithdrawl(row) {
      this.router.navigateByUrl("/user-profile/appeal-withdrawal?indvid=" + row.encryptIndvId + "&caseNumber=" + row.encryptCaseNum + "&appealNumber=" + row.encryptAppealNum);
      this.appealsService.showMessage = false;
      this.appealsService.showMessageWithdrawal = false;
    }

    appealLetters() {
        sessionStorage.setItem('appealLetter', 'true')
        this.router.navigate(['user-profile/my-letters'])
    }

    startApp (appType) {
      let isOpenOrExistingAppeal;
      this.appealDetailsRows?.map((data)=> { if(data?.appealStatus !== 'Appeal Resolved' || data?.appealStatus !== 'Appeal Closed'){isOpenOrExistingAppeal = true;}});
      this.pastAppealDetailsRows?.map((data)=> { if(data?.appealStatus !== 'Appeal Resolved' || data?.appealStatus !== 'Appeal Closed'){isOpenOrExistingAppeal = true;}});
      
      if(isOpenOrExistingAppeal){
        this.modal.openConfirmOpenAppeal().subscribe((modalData) => {
          if(modalData){
            this.startAppFn(appType);
          }
        });
      }else{
        this.startAppFn(appType);
      }
    };


    startAppFn(appType){
      this.enableFileAppealButton = false;
      this.application.getHeadOfHouseholdInfo(0,'APL').subscribe((res: any) => {
        this.applicationNum = res?.applicationNumber;
        if(sessionStorage.getItem('removedAppealRecord')) {
          sessionStorage.removeItem('removedAppealRecord')
        }
        if (this.applicationNum && this.storageService.getAppNumber() !== this.applicationNum) {
          sessionStorage.setItem('appNum', this.applicationNum);
          this.storageService.setAppNumber(this.applicationNum);
        }
        if (this.applicationNum && this.applicationNum !== '0' && this.applicationNum !== 0) {
          this.stateService.resetStates();
          this.application.deleteApp(this.applicationNum, 'TCAPL').subscribe(() => {
            sessionStorage.setItem('appNum', null);
            this.storageService.setApplicationType(appType);
            this.router.navigateByUrl('/application/' + 'NEW');
          })
        }
        else {
          this.stateService.resetStates();
          sessionStorage.setItem('appNum', null);
          this.storageService.setApplicationType(appType);
          this.router.navigateByUrl('/application/' + 'NEW');
        }
      });
    }
}
