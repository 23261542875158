import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { minus120Years } from "@app/helpers/date.helpers";
import { FormControl, FormGroup } from "@angular/forms";
import Vs from "@app/services/validators/validators.service";
import { distinctUntilChanged } from "rxjs/operators";
import { ApplicationService } from "@app/services/application/application.service";
import { toISO } from "@app/helpers/date.helpers";
import { Router } from "@angular/router";
import { ModalService } from '@app/services/modal/modal.service';
import { StateService } from "@app/services/state/state.service";
import { StorageService } from "@app/services/storage/storage.service";
import { contains } from 'ramda';
import { DatePipe } from "@angular/common";
import { ApiService } from "@app/gateway/services/api.service";
import { UserProfileService } from "../../gateway/user-profile/user-profile.service";
import { ServiceConfigService } from "@app/services/service-config.service";
import { ActivatedRoute } from '@angular/router';
import { HttpHeaders } from "@angular/common/http";
import { AuthService } from '@app/gateway/services/auth.service';
import { Title } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { isNotNil } from "ramda-adjunct";

@Component({
  selector: "nh-provider-member-search",
  templateUrl: "./member-search.component.html",
  styleUrls: ["./member-search.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProviderMemberSearchComponent implements OnInit {
  memberSearchForm = new FormGroup({
    dateOfBirth: new FormControl(null, Vs.required),
    ssn: new FormControl(null, [Vs.required, Vs.ssn]),
    personID: new FormControl(null, [Vs.required, Vs.personId]),
    applicantBehalf: new FormControl(null, Vs.required),
  });
  minimumDate = minus120Years();
  today: any = new Date();
  columns = [
    { name: "Name", prop: "Name" },
    { name: "Person ID", prop: "personId" },
    { name: "Date of Birth", prop: "dob" },
    { name: "Address", prop: "address" },
  ];
  imeColumns1 = [
    { name: "Name", prop: "Name" },
    { name: "Case Number", prop: "caseNum" },
    { name: "Person ID", prop: "personId" },
    { name: "Date of Birth", prop: "dob" }
  ];
  imeColumns2= [
    { name: "Address", prop: "address"},
    { name: "Category", prop: "coeDescription"},
    { name: "Case<br>Status", prop: "caseStatus"},
  ];
  rows;
  isEligibleForBCCExt;
  limit = 10;
  start = 1;
  end;
  search;
  isFinReacess = false;
  tennCareConnect = false;
  readTCC = false;
  bccTreatment = false;
  imeSubmission = false;
  heading1;
  heading2;
  heading3;
  heading4;
  isMedicaidEligible = false;
  isSearchCompleted = false;
  caseStatusNotEligible = false;
  tcamId;
  mpToken;
  isKbUser = false;
  eligibleRecord = [];
  submittedDate;
  duplicateWarning = false;
  showTable = false;

  constructor(
    private router: Router,
    private modal: ModalService,
    private stateService: StateService,
    protected storageService: StorageService,
    protected application: ApplicationService,
    private datePipe: DatePipe,
    public apiService: ApiService,
    private userProfileService: UserProfileService,
    private serverConfig: ServiceConfigService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Provider Search');
  }

  getCookie(name) {
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search)
    if (!sessionStorage.getItem('tokenId') && !urlParams.get('mpToken')) {
      this.router.navigateByUrl('/logout');
      return;
    }
    this.route.queryParams.subscribe((response: any) => {
      this.mpToken = response.mpToken;
      this.apiService.removeQueryParam('mpToken');
    });
    this.isFinReacess = this.router.routerState.snapshot.url.endsWith('LOC');
    this.tennCareConnect = this.router.routerState.snapshot.url.endsWith('TCC');
    this.readTCC = this.router.routerState.snapshot.url.endsWith('VTC');
    this.bccTreatment = this.router.routerState.snapshot.url.endsWith('BXT');
    this.imeSubmission = this.router.routerState.snapshot.url.endsWith('IME');
    if (this.mpToken) {
      debugger;
      this.tcamId = this.serverConfig.getServerConfig().TCAM_KEY;
      const payload = { tcam: this.getCookie(this.tcamId), mpToken: this.mpToken };
      const contentHeader = new HttpHeaders({ "Content-Type": "application/json", pageId: "TCPMS" });
      this.apiService.post(`publ/users/KBLogin`, payload, { headers: contentHeader, observe: 'response' }).subscribe((response: any) => {
        if (response && response.body.isValidSession === 'true') {
          sessionStorage.setItem('isKbUser', 'true');
          const authToken = response.headers.get('x-auth-token');
          this.authService.setToken(authToken);
          this.isKbUser = true;
          this.isFinReacess = true;
          const dateOfBirthFrmResp =  this.datePipe.transform(response.body.dob , "MM/dd/yyyy");
          this.memberSearchForm.controls["dateOfBirth"].patchValue(dateOfBirthFrmResp);
          this.memberSearchForm.controls["personID"].patchValue(response.body?.indvId);

          this.heading1 = 'Financial Reassessment for Medicaid';
          this.heading2 = 'Search Criteria';
          this.heading3 = `Use the search criteria below to see a if you can submit a Financial Reassessment.`;
          this.heading4 = `By checking this box, I certify that this member has given me permission to view and update their case information including the information about other people in their household.  I understand and have explained to the patient that any updates made may also impact coverage for other people in their household.`
        }
      });
    }

    if (this.tennCareConnect) {
      this.heading1 = 'View TennCare Connect Account';
      this.heading2 = 'Search for a Member';
      this.heading3 = `Use the search criteria below to see a member's TennCare Connect account. This allows you to view their
      coverage, letters, upload documents, report changes, and submit renewals on their behalf.`;
      this.heading4 = `By checking this box, I certify that this member has given me permission to view and update their case information including the information about other people in their household.`
    } else if (this.readTCC) {
      this.heading1 = 'View TennCare Connect Account (View Only) ';
      this.heading2 = 'Search for a Member';
      this.heading3 = `Use the search criteria below to see a member's TennCare Connect account. This allows you to view their coverage, letters, and documents.`;
      this.heading4 = `By checking this box, I certify that this member has given me permission to view and update their case information including the information about other people in their household.`
    } else if (this.isFinReacess) {
      this.heading1 = 'Financial Reassessment for Medicaid';
      this.heading2 = 'Search Criteria';
      this.heading3 = `Use the search criteria below to see a if you can submit a Financial Reassessment.`;
      this.heading4 = `By checking this box, I certify that this member has given me permission to view and update their case information including the information about other people in their household.  I understand and have explained to the patient that any updates made may also impact coverage for other people in their household.`
    } else if (this.bccTreatment) {
      this.heading1 = 'Search a Member';
      this.heading2 = 'Search Criteria';
      this.heading3 = `Use the search criteria below to see if you can submit a BCC Treatment Plan Extension Request. `;
      this.heading4 = `By checking this box, I certify that this member has given me permission to view and update their case information including the information about other people in their household.  I understand and have explained to the patient that any updates made may also impact coverage for other people in their household.`
    } else if(this.imeSubmission) {
      this.heading1 = 'Search a Member';
      this.heading2 = 'Search Criteria';
      this.heading3 = `Use the search criteria to see if you can add an Incurred Medical Expense for this member.`;
      this.heading4 = `By checking this box, I certify that this member has given me permission to view and update their case information including the information about other people in their household.  I understand and have explained to the patient that any updates made may also impact coverage for other people in their household.`
    }

    this.memberSearchForm.controls["ssn"].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        if (res && res.length === 9) {
          this.memberSearchForm.controls["personID"].setValidators(Vs.personId);
          this.memberSearchForm.controls["personID"].updateValueAndValidity();
        } else {
          this.memberSearchForm.controls["personID"].setValidators([
            Vs.required, Vs.personId
          ]);
          this.memberSearchForm.controls["personID"].updateValueAndValidity();
        }
      });
    this.memberSearchForm.controls["personID"].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        if (res && (res.length === 9 || res.length === 10)) {
          this.memberSearchForm.controls["ssn"].setValidators(Vs.ssn);
          this.memberSearchForm.controls["ssn"].updateValueAndValidity();
        } else {
          this.memberSearchForm.controls["ssn"].setValidators([Vs.required,Vs.ssn]);
          this.memberSearchForm.controls["ssn"].updateValueAndValidity();
        }
      });
  }

  onSearch() {
    const dob = this.isKbUser ? this.datePipe.transform(this.memberSearchForm.controls["dateOfBirth"].value , "yyyy-MM-dd") : toISO( this.memberSearchForm.controls["dateOfBirth"].value );

    const ssn = this.memberSearchForm.controls["ssn"].value;
    const personID = this.memberSearchForm.controls["personID"].value;
    this.isSearchCompleted = false;
    this.duplicateWarning = false;

 if( this.imeSubmission) {
  const postObj = {
    dateOfBirth: dob,
    ssn: ssn,
    individualId: personID,
    imeSwitch: "Y"
  };
  this.application.membersearch(postObj, 'TCPMS').subscribe((res: any) => {
    if (res && res.length > 0) {
      const data = [...res];
     if (this.imeSubmission && data.length > 0) {
      this.isSearchCompleted = true;

      }
      if(data[0]['appDetailsType']?.length > 0) {
        this.showTable = false;
        this.rows = data[0]['appDetailsType'].map(element => {
        return {
            Name: data[0].firstName + ' ' + data[0].lastName,
            personId: data[0].individualId,
            dob: this.datePipe.transform(data[0].dateOfBirth, "MM/dd/yyyy"),
            address: data[0].indvAddress,
            firstName : data[0].firstName,
            lastName : data[0].lastName,
            ssn : data[0].ssn,
            gender : data[0].genderCd,
            caseNum: element['caseNum'],
            livingArrangement : data[0]['livingArrangementCd']['value'],
            coe: element['coeCodes'],
            coeDescription : element['cobSubCategory'],
            caseStatus : element['caseStatus'],
            isMedicaidEligible: element['imeElgSW'] === 'Y' ? true : false,
            renewalSw : data[0].renewalSw
        };
       });
      } else {
        this.rows = [];
        this.showTable = true;
      }
      this.end = this.rows?.length < 10 ? this.rows?.length : this.limit;
    } else {
      this.start = 0;
      this.end = 0;
      this.rows = [];
      this.showTable = false;
    }
  });
 } else {
  const postObj = {
    dateOfBirth: dob,
    ssn: ssn,
    individualId: personID,
  };
  this.application.membersearch(postObj, 'TCPMS').subscribe((res: any) => {
    if (res && res.length > 0) {
      const data = [...res];
      const fullName = data[0].firstName + ' ' + data[0].lastName;
      const firstName = data[0].firstName;
      const lastName = data[0].lastName;
      const searchResPersonID = data[0].individualId;
      if (this.isFinReacess && data.length > 0) {
        this.userProfileService.getBenefitDashboard(data[0].individualId).subscribe((response: any) => {
          if (response) {
            //const terminatedOrDenied = ["TN", "DN"];
            //const caseStatus = response.cmbSummaryResponse?.caseSummaryResponse;
            response.cmbSummaryResponse?.caseSummaryResponse.forEach(element => {
              const searchedIndv = element.indvSummaryResponse.filter(indv => indv.indvId === searchResPersonID);
              const eligibleCodes = ["L01", "L02", "L03", "L04", "W01", "K03", "K01", "K02"];
              searchedIndv.find(record => {
                  if (contains(record.programStatus?.length && record.programStatus[0]?.coeCode, eligibleCodes) && record.programStatus?.length && record.programStatus[0]?.edgStatus === 'AP') {
                  this.isMedicaidEligible = true;
                  this.eligibleRecord.push(record);
                  this.isSearchCompleted = true;
                } else {
                  this.isMedicaidEligible = this.isMedicaidEligible === true ? true : false;
                  this.isSearchCompleted = true;
                }
                // return this.isMedicaidEligible = contains(record.programStatus[0]?.coeCode, eligibleCodes);
              })
            })
            // caseStatus.find(item => {
            //   return this.caseStatusNotEligible = contains(item.applicationStatusDesc, terminatedOrDenied);
            // })
          }
        })
      } else if (this.bccTreatment && data.length > 0) {
        this.isEligibleForBCCExt = data[0]['appDetailsType'].filter(element => element.bccTreatmentExtElgSW === 'Y');
        if(this.isEligibleForBCCExt?.length) {
          this.isMedicaidEligible = true;
          this.eligibleRecord.push(data[0]);
          this.isSearchCompleted = true;
          const personID = data[0]['individualId'];
          this.application.bccExtDuplicateApp(personID).subscribe((res: any) => {
            const statusCodes = ['S', 'E']
            const dupLicateRec = res.filter(element => statusCodes.includes(element.statusCd));
            if(dupLicateRec?.length) {
              const result = dupLicateRec.reduce((a, b) => a.applicationSubmittedDate > b.applicationSubmittedDate ? a : b);
              const currentDate = new Date()
              const appSubmittedDate = new Date(result['applicationSubmittedDate']  + ' 00:00:00')
              let days = Math.floor((currentDate.getTime() - appSubmittedDate.getTime()) / 1000 / 60 / 60 / 24);
              if(days <= 30) {
                this.submittedDate = result['applicationSubmittedDate'] ? formatDate(result['applicationSubmittedDate'], 'MM/dd/yyyy', 'en') : "";
                this.duplicateWarning = true;
              } else 
              this.duplicateWarning = false;
            }
          })
        } else {
          this.isMedicaidEligible = false;
          this.isSearchCompleted = true;
          this.duplicateWarning = false;
        }
      } else {
        this.isSearchCompleted = true;
      }
        this.rows = data.map((search) => {
          return {
            Name: fullName,
            personId: search.individualId,
            dob: this.datePipe.transform(search.dateOfBirth, "MM/dd/yyyy"),
            address: search.indvAddress,
            firstName : firstName,
            lastName : lastName,
            ssn : search.ssn,
            gender : search.genderCd,
            caseNum: search.appDetailsType[0].caseNum,
            appDetailsType : search.appDetailsType,
            coe: search.appDetailsType[0]['coeCodes'],
          };
        });       
      this.end = this.rows.length < 10 ? this.rows.length : this.limit;
    } else {
      this.start = 0;
      this.end = 0;
      this.rows = [];
    }
  });
 }

    if (this.rows === undefined) {
      this.search = false;
    }
  }

  checkMedicaidEligiblility(item, searchIndv) {
    let medicaidEligible = false;
    let tempItem = [];
    if (item && item.appDetailsType !== undefined) {
      tempItem = item;
    } else if (item.appDetailsType === undefined) {
      tempItem = item[0];
    }
        for (let i = 0; tempItem['appDetailsType'].length > i; i ++) {
          const appDetailsType = tempItem['appDetailsType'][i];
          if (appDetailsType.coeCodes && appDetailsType.coeCodes.length > 0) {
            for (let j = 0;  appDetailsType.coeCodes.length > j; j ++) {
              const coeCodes = appDetailsType.coeCodes[j]
              const acceptedList = ['L01', 'L02', 'L03', 'L04', 'W01', 'SSI', 'SST'];
              if (contains(coeCodes.coeCode, acceptedList)) {
                medicaidEligible = true;
              }
            }
          }
        }
        if (searchIndv.length > 0 && searchIndv[0]?.programStatus[0]?.edgStatus === 'DN') {
          medicaidEligible = false;
        }
    return medicaidEligible;
  }

  toTenncare(indivId) {
    sessionStorage.setItem("individualId", indivId);
    sessionStorage.setItem('userType', 'RP');
    sessionStorage.setItem('ViewTCC', 'true');
    this.router.navigateByUrl("user-profile/benefit-dashboard");
  }


  toReadTenncare(indivId) {
    sessionStorage.setItem("individualId", indivId);
    sessionStorage.setItem('userType', 'RP');
    sessionStorage.setItem('ReadTCC', 'true');
    this.router.navigateByUrl("user-profile/benefit-dashboard");
  }

  onPrevious() {
    if (this.memberSearchForm.pristine) {
      this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
      return;
    }
    this.modal.confirmDeactivate().subscribe(resp => {
      if (resp) {
        this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
      }
    });
  }

  startReassessment(row) {
    this.application.financialReassessment(row.personId).subscribe((res: any) => {
      sessionStorage.setItem('isReassessment', 'true');
      sessionStorage.setItem('caseNum', res.caseNum);
      sessionStorage.setItem('individualId', row.personId);
      const renewalSW = "N";
      const genderCd  = row['gender'];
      const ssnNumber = row['ssn'];
      const lastName = row['lastName'];
      const firstName = row['firstName'];
      const dateOfBirth  = row['dob'];
      const caseNumber = row['caseNum'];
      let temp = "";
      this.application.caseNumber$.next(caseNumber);
      this.application.renewalSw$.next(renewalSW);
      this.application.genderCd$.next(genderCd);
      this.application.ssnNumber$.next(ssnNumber);
      this.application.lastName$.next(lastName);
      this.application.firstName$.next(firstName);
      this.application.dateOfBirth$.next(dateOfBirth);
      if (row['coe']?.length) {
        row['coe'].forEach((element, idx) => {
          if (row['coe'].length === 1 || row['coe'].length === idx + 1) {
            temp = temp + element.coeCode;
            this.application.coeCode$.next(temp);
          } else {
            temp = temp + element.coeCode + ",";
          }
        });
      }
      if (res.appNum && res.appNum !== 0 || res.appNum !== null) {
        this.router.navigateByUrl("api/prov/welcome-back");
      } else {
        this.storageService.setApplicationType('RB');
        this.router.navigateByUrl('/application/' + 'REDETERMINATION');
      }
    })
  }

  startIMEApplication(row) {
    const personId  = row['personId'];
    const renewalSw = "N";
    const imeCaseNumber = row['caseNum'];
    const genderCd  = row['gender'];
    const ssnNumber = row['ssn'];
    const lastName = row['lastName'];
    const firstName = row['firstName'];
    const dateOfBirth  = row['dob'];
    const appType = 'IME';
    let temp = "";
    this.storageService.setApplicationType(appType);
    sessionStorage.setItem('individualId', personId);
    this.application.renewalSw$.next(renewalSw);
    this.application.imeCaseNumber$.next(imeCaseNumber);
    this.application.genderCd$.next(genderCd);
    this.application.ssnNumber$.next(ssnNumber);
    this.application.lastName$.next(lastName);
    this.application.firstName$.next(firstName);
    this.application.dateOfBirth$.next(dateOfBirth);
    if (row['coe']?.length) {
      row['coe'].forEach((element, idx) => {
        if (row['coe'].length === 1 || row['coe'].length === idx + 1) {
          temp = temp + element.coeCode;
          this.application.imeCoeCode$.next(temp);
        } else {
          temp = temp + element.coeCode + ",";
        }
      });
    }
    if(sessionStorage.getItem('removedIMERecord')) {
      sessionStorage.removeItem('removedIMERecord')
    }
    this.stateService.resetStates();
    sessionStorage.setItem('isNewApp', 'true');
    return this.startAppUrl()
  }

  
  startBccExtension(row?) {
    this.stateService.resetStates();
    if (this.bccTreatment && isNotNil(row)) {
      let temp = "";
      const { personId, firstName, lastName, dob, ssn, gender } = row;
      const appType = 'BXT';
      this.storageService.setApplicationType(appType);
      sessionStorage.setItem('individualId', personId);
      this.application.bccFirstName$.next(firstName);
      this.application.bccLastName$.next(lastName);
      this.application.bccDob$.next(dob);
      this.application.bccSsn$.next(ssn);
      this.application.bccGender$.next(gender);
      if (this.isEligibleForBCCExt?.length) {
        this.isEligibleForBCCExt[0]['coeCodes'].forEach((element, idx) => {
          const caseNum = this.isEligibleForBCCExt[0]['caseNum']
          this.application.bccCaseNum$.next(caseNum);
          if (this.isEligibleForBCCExt[0]['coeCodes'].length === 1 || this.isEligibleForBCCExt[0]['coeCodes'].length === idx + 1) {
            temp = temp + element.coeCode;
            this.application.bccCoeCode$.next(temp);
          } else {
            temp = temp + element.coeCode + ",";
          }
        });
      }
    }
    sessionStorage.setItem('isNewApp', 'true');
    return this.startAppUrl()
  }

  startAppUrl = () => this.router.navigateByUrl('/application/' + 'NEW');

  startApp (appType) {
    this.stateService.resetStates();
    this.storageService.setApplicationType(appType);
    this.router.navigateByUrl('/application/' + 'NEW');
  };
}