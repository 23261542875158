import { sortWith, descend, prop, isEmpty, compose, map, evolve } from 'ramda';
import { RelationshipsPresumptiveComponent } from './relationships-presumptive.component';
import { createTaxHousehold, sectionConfiguration} from '@app/helpers/models.helpers';
import { FormArray } from '@angular/forms';
import Vs from '@app/services/validators/validators.service';
import { aPath } from '@app/helpers/function.helpers';
import { toISO, toDate } from '@app/helpers/date.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';

const formValidation = (builder, recordData, { individuals }) => {
  const relationships = aPath(['relationshipList'], recordData.householdRelationshipInformation);
  const taxJointFiler = lookIn(recordData);
  
  const createCtrls = (sourceIdentifier, refIndentifier, title, valueObj, name, isReverseFlow) => {
    return builder.group({
      relation: [valueObj && valueObj.relation ? valueObj.relation : null, Vs.required],
      inverse: null,
      sourceIndividual: sourceIdentifier,
      referenceIndividual: refIndentifier,
      primaryCaregiver: [valueObj && valueObj.primaryCaregiver ? valueObj.primaryCaregiver : null],
      title: title,
      isReverseFlow: isReverseFlow,
      jointFilers: [valueObj && valueObj.jointFilers ? valueObj.jointFilers : null],
      name: name
    });
  };

  const createMinorCtrls = (minorIndvs, idx, primaryTitle, valueObj) => {
    const careTaker = valueObj.filter(val => val.primaryCaregiver?.code === 'Y' && val.referenceIndividual === minorIndvs);
    const actualCareTakers = [];
    careTaker.forEach(indv => actualCareTakers.push({ individualId: indv.sourceIndividual, name: indv.name }));

    return builder.group({
      ['primaryCaregiver' + idx]: [!isEmpty(careTaker) ? careTaker[0]?.primaryCaregiver : isEmpty(relationships) ? null : { code: 'N', value: 'No'}],
      ['indvId']: minorIndvs,
      ['primaryCaregiverIndividual' + idx]: [{ value: actualCareTakers, disabled: careTaker[1]?.primaryCaregiver?.code !== 'Y' }],
      ['items' + idx]: '',
      primaryTitle: primaryTitle
    });
  };

  const relationshipList = (): FormArray => {
    const peopleArray: any = [];
    const descendSort = sortWith([descend(prop('age'))]);
    const sortedIndvs = descendSort(individuals);

    for (let i = 0; i < sortedIndvs.length; i++) {
      let title = sortedIndvs[i].name.fullName + `${"'s Relationships"}`;
      for (let j = i + 1; j < sortedIndvs.length; j++ ) {
        if (relationships) {
          peopleArray.push(createCtrls(sortedIndvs[i].identifier, sortedIndvs[j].identifier, title, findRelation(sortedIndvs[i].identifier, sortedIndvs[j].identifier), sortedIndvs[i].name.fullName, false));
        } else {
          peopleArray.push(createCtrls(sortedIndvs[i].identifier, sortedIndvs[j].identifier, title, null, sortedIndvs[i].name.fullName, false));
        }
        if(sortedIndvs[i].gender.code === 'U' || sortedIndvs[j].gender.code === 'U' ) {
          if (relationships.length) {
           peopleArray.push(createCtrls(sortedIndvs[j].identifier, sortedIndvs[i].identifier, title, findRelation(sortedIndvs[j].identifier, sortedIndvs[i].identifier),sortedIndvs[i].name.fullName, true));
          } else {
            peopleArray.push(createCtrls(sortedIndvs[j].identifier, sortedIndvs[i].identifier, title, null,sortedIndvs[i].name.fullName, true));
          }
        }
        title = '';
      }
    }
    return peopleArray as FormArray;
  };

  let dependentListGrpCtrls;
  if (recordData?.outsideHousehold?.length > 0) {
    dependentListGrpCtrls = taxJointFiler(['outsideHousehold']);
  } else {
    dependentListGrpCtrls = [{
      jointFiler: null,
      dependentLastName: null,
      dependentFirstName: null,
      dependentBirthDate: null,
      relation: null,
      taxDependent: null,
      taxFilerIndividual: null,
      sourceIndividual : null,
      hasTaxDependentOutside : null,
      birthDate: null,
      name: null,
      taxFilingIndividualId: null
    }]
  }

  const dependentListCreator = ({ jointFiler, dependentLastName, dependentFirstName, dependentBirthDate, relation, taxDependent, taxFilerIndividual }) => ({
    jointFiler: jointFiler?.code === 'Y' ? {code: 'Y', value: 'Yes', spValue: 'Si'} : jointFiler?.code === 'N' ? {code: 'N', value: 'No', spValue: 'No'} : null,
    dependentLastName: dependentLastName ? dependentLastName : null,
    dependentFirstName: dependentFirstName ? dependentFirstName : null,
    dependentBirthDate: dependentBirthDate ? toDate(dependentBirthDate) : null,
    relation: relation ? relation : null,
    taxDependent: taxDependent?.code === 'Y' ? {code: 'Y', value: 'Yes',spValue: 'Si'} : taxDependent?.code === 'N' ? {code: 'N', value: 'No',spValue: 'No'} : null,
    taxFilerIndividual: taxFilerIndividual ? [taxFilerIndividual.toString()] : null,
    sourceIndividual : taxFilerIndividual ? [taxFilerIndividual.toString()] : null,
    hasTaxDependentOutside : taxDependent ? taxDependent : null,
    birthDate: null,
    name: null,
    taxFilingIndividualId: null
  })

  const dependentListGrp = compose(
    (control: any) => builder.group(control),
    dependentListCreator,
  );

  const findRelation = (src, ref) => {
    const valueObj = relationships.filter(i => ((i.sourceIndividual == src) && (i.referenceIndividual == ref)))[0]
    return valueObj ? valueObj : null;
  }

  const findCareGivers = (primaryIndv) => {
    return relationships.filter(rel => (rel.referenceIndividual === primaryIndv));
  };

  const primaryCareList = (): FormArray => {
    const minorIndivs: any = [];
    const primaryIndv = individuals.filter(i => i.age >= 0);
    if (individuals.length !== primaryIndv.length) {
      for (let i = 0; i < primaryIndv.length; i++ ) {
        let primaryTitle = primaryIndv[i].name.fullName + `${"'s Primary Caregiver"}`;
        if (relationships) {
          minorIndivs.push(createMinorCtrls(primaryIndv[i].identifier, i, primaryTitle, findCareGivers(primaryIndv[i].identifier)));
        } else {
          minorIndivs.push(createMinorCtrls(primaryIndv[i].identifier, i, primaryTitle, null));
        }
        primaryTitle = '';
      }
    }
    return minorIndivs as FormArray;
  };

  const taxDependentsHousehold = compose(
      control => builder.group(control, { updateOn: 'change' }),
      createTaxHousehold
  );


  let taxDependentsHouseholdLastest;
  if (recordData?.taxFilingData?.length > 0) {
    taxDependentsHouseholdLastest = aPath(['taxFilingData'], recordData);
  } else {
    taxDependentsHouseholdLastest =  [{
    taxFilingIndividualId: '',
    taxDependentIndividualId: ''
    }];
  }


  const data = builder.group({
    householdRelationshipInformation: builder.group({
      relationshipList: builder.array(relationshipList()),
      primaryCareList: builder.array(primaryCareList()),
    }),
    taxFilingData: builder.array(map(taxDependentsHousehold, taxDependentsHouseholdLastest )),
    outsideHousehold: builder.array(map(dependentListGrp, dependentListGrpCtrls)),
    hiddenField: null,
    hiddenRelationField: null,
    relationshipValidation: null,
    UnknownGenderValidation: null,
    parentRelationValidation: null,
    youngerParent: null
  });

  return { data };
};

const postObject = evolve({
  outsideHousehold: map(
    evolve({
      dependentBirthDate: toISO,
    })
  )
});

export default sectionConfiguration(RelationshipsPresumptiveComponent, formValidation, ['YESNO', 'RELATIONTYPE', 'RELATIONTYPE_GENDER'], [], {}, { postObject: postObject, hasSummary: false });
